<template src="./catalogo.html"></template>

<script>
import PagCat from "./PaginaCatalogo.vue";
import PdfViewer from "@/components/dialog/Viewer.vue";
import dialogDettaglioPrezzi from "@/components/dialog/catalogo/DettaglioPrezzi.vue";

export default {
  components: {
    dialogDettaglioPrezzi,
    PdfViewer,
    PagCat,
  },

  props: {
    testata: { type: String, default: "" },
    suffisso: { type: String, default: "0" },
    codArt: { type: String, default: "" },

    filtroRisultati: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data: () => ({
    pagineScorri: [],

    floatingModal: {
      transformStyle: { transform: "" },
      isPermanent: false,
      isHover: false,
    },
    datiArticolo: {},

    pdfDialog: {
      show: false,
      type: "",
      src: "",
      pdfOptions: {
        scale: "page-width",
        numPages: 0,
      },
      titolo: "",
    },

    imgSearched: false,

    beforeMout_Y: 0,
  }),

  methods: {
    scroll() {

      // NOTA: l'evento scroll viene attivato anche se faccio lo scroll su prodotti.vue
      //       per cui faccio il controllo
      if (this.beforeMout_Y > 0) {
        this.beforeMout_Y = 0
        return
      }

      let nearBottomOfWindow =
        window.scrollY + window.innerHeight >=
        document.documentElement.offsetHeight - 300;

      if (nearBottomOfWindow == false) return;

      if (this.pagineScorri.length == 0) return;


      let trovato = false;
      let testataMem =
        this.pagineScorri[this.pagineScorri.length - 1].codTestata;
      let suffissoMem =
        this.pagineScorri[this.pagineScorri.length - 1].suffisso;

      // Ciclo sui risultati della ricerca
      for (let element of this.filtroRisultati) {
        if (trovato == true) {
          this.pagineScorri.push(element);
          break;
        }

        if (
          element.codTestata == testataMem &&
          element.suffisso == suffissoMem
        ) {
          trovato = true;
        }
      }
    },

    openDialogPdf(item) {
      this.pdfDialog.type = item.type;
      this.pdfDialog.src = item.src;
      this.pdfDialog.titolo = item.titolo;
      this.pdfDialog.show = true;
    },

    closeDialogPdf() {
      this.pdfDialog.type = "";
      this.pdfDialog.src = "";
      this.pdfDialog.show = false;
    },

    showOver(item) {
      if (item === false) {
        this.floatingModal.isHover = false;
        return;
      }
      if (this.floatingModal.isPermanent) return;

      if (item.distintaBaseDinamica) {
        return;
      }

      this.floatingModal.isHover = true;

      this.setDati(item);
    },

    showPermanent(item) {
      if (!this.auth) return;

      if (this.floatingModal.isPermanent) {
        this.floatingModal.isPermanent = false;
      }

      this.floatingModalPermanent(true);

      this.setDati(item);
    },

    floatingModalPermanent(vis) {
      this.floatingModal.isPermanent = vis;

      // NOTA: bloccando la scrollbar la funzione scrollIntoView non funziona più
      //       scrollIntoView-> viene usata x spostare le scroll sull'articolo selezionato

      /*    if (vis) {
           document.body.style.overflow = "hidden";
           document.body.style.position = "fixed";
         } else {
           document.body.style.overflow = "";
           document.body.style.position = "";
         } */
    },

    setDati(item) {
      this.datiArticolo = item;
    },

    updateMousePos(p) {
      if (
        !this.floatingModal.isPermanent &&
        this.floatingModal.isHover &&
        document.getElementById("floating-div")
      ) {
        let y = p.clientY - 160;
        if (
          y + document.getElementById("floating-div").clientHeight >
          window.innerHeight
        )
          y =
            window.innerHeight -
            document.getElementById("floating-div").clientHeight;

        this.floatingModal.transformStyle = {
          transform:
            "translate3d(" +
            (p.clientX -
              document.getElementById("floating-div").clientWidth -
              32) +
            "px," +
            y +
            "px,0)",
        };
      }
    },

    getTitolo: (state) => (Link) => {
      let itemFind = state.menu.find((x) => x.link === Link);
      if (itemFind)
        return itemFind.text + "|" + itemFind.img + "|" + itemFind.color;

      return "";
    },
  },

  mounted() {
    window.scrollTo(0, 0);

    this.pagineScorri.push({
      codTestata: this.testata,
      suffisso: this.suffisso,
    });

  },

  created() {
    this.beforeMout_Y = window.scrollY

    window.addEventListener("scroll", this.scroll);
    window.addEventListener("mousemove", this.updateMousePos);

  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
    window.removeEventListener("mousemove", this.updateMousePos);
  },

  watch: {
    filtroRisultati() {
      //console.log("filtro risultati watch");

      // Scroll top pagina
      window.scrollTo(0, 0);

      this.pagineScorri = [];

      if (this.filtroRisultati.length > 0) {

        this.pagineScorri.push({
          codTestata: this.filtroRisultati[0].codTestata,
          suffisso: this.filtroRisultati[0].suffisso,
        });
      }
    },
  },

  computed: {
    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },

    role() {
      return this.$store.getters["user/getRole"];
    },

    group() {
      return this.$store.getters["user/getGroup"];
    },
  },
};
</script>
