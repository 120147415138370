<template>
  <v-dialog scrollable v-model="dialogComputed" width="500px">
    <v-card tile>
      <v-toolbar light dense flat>
        <v-toolbar-title>Clienti</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <comboClienti v-model="clienteSel" label="Clienti" :items="getClienti" itemText="ragioneSociale" class="mx-3" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="closeDialog()">Annulla</v-btn>
        <v-btn class="btn-conferma" @click.native="conferma()">Conferma</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import comboClienti from "@/components/intranet/ComboClienti";

export default {
  components: {
    comboClienti,
  },

  props: {
    dialog: { type: Boolean },
  },
  data() {
    return {
      clienteSel: { cdAnagrafica: "", ragioneSociale: "" },
    };
  },

  methods: {
    conferma() {
      this.$store.commit("app/setDialogResult", this.clienteSel);

      this.closeDialog();
    },

    closeDialog() {
      this.$store.commit("app/setDialog", "");
    },
  },
  mounted() { },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
    getClienti() {
      return this.$store.getters["app/getClienti"];
    },
  },

  watch: {
    dialog(v) {
      if (v) {
        // Reset cliente (altrimenti proporrebbbe quello precendentemente selezionato)
        this.$store.commit("app/setDialogResult", {
          cdAnagrafica: "",
          ragioneSociale: "",
        });
      }
    },
  },
};
</script>
