<template>
  <v-dialog scrollable v-model="dialogComputed" width="400px">
    <v-card tile>
      <v-toolbar light dense flat>
        <v-toolbar-title>Cerca</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-text-field v-model="search" clearable append-icon="search" placeholder="Cerca" @keydown.enter="conferma"
          style="margin-bottom: -10px; padding: 0px; height: 60px; font-size: large;" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="closeDialog()">Annulla</v-btn>
        <v-btn class="btn-conferma" @click.native="conferma()" :loading="attesa">Conferma</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_cercaLocalita } from "@/api/api.intranet.js";

export default {
  props: {
    dialog: { type: Boolean },
  },

  data: () => ({
    search: "",
    attesa: false,
  }),

  methods: {
    async conferma() {
      this.attesa = true;

      let res = await get_cercaLocalita(this.search);

      this.attesa = false;

      this.closeDialog(res.data);
    },

    closeDialog(dati) {
      this.$emit("chiudiDialog", dati);
    },
  },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },
};
</script>