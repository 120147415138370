<template>
  <v-dialog v-model="dialog" width="360">
    <v-card width="360" :loading="attesa" class="pa-2">
      <v-container>
        <v-row>
          <v-col cols="5">
            <v-text-field v-model="suffisso" disabled label="Suffisso"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field autofocus v-model="suffissoNew" label="Nuovo Suffisso"></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text light @click="chiudi()">Annulla</v-btn>
        <v-btn color="primary" text light @click="conferma()" :disabled="attesa">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { set_nuovoSuffisso, set_nuovoSuffissoTestata } from "@/api/api.catalogo.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    testata: { type: String, default: "" },
    suffisso: { type: Number, default: 0 },
    codArticolo: { type: String, default: "" },
  },

  data: () => ({
    suffissoNew: "",
    attesa: false,
  }),

  methods: {
    chiudi() {
      this.$emit("chiudi");
    },

    async conferma() {
      let par = ""
      let res = ""

      if (this.testata.length > 0) {
        par = this.testata + "/" + this.suffisso + "/" + this.suffissoNew
        res = await set_nuovoSuffissoTestata(par);

      } else {
        par = {
          cdArticolo: this.codArticolo,
          ctnumSUFFISSO: this.suffissoNew,
        };

        res = await set_nuovoSuffisso(par);
      }


      if (res.data == "OK") {
        this.$toast.info("Suffisso aggiornato");
        this.chiudi();
      } else this.$toast.alert("Errore: " + res.data);
    },
  },
};
</script>