<template src="./paginaCatalogo.html"></template>

<script>
import {
  get_datiPaginaCatalogo,
  ins_vuoto,
  del_rigoCatalogo,
  set_nuovoTipo,
  set_hImg,
  get_areaTecnicaCatalogo,
  salva_catMerceologica,
} from "@/api/api.catalogo.js";

import {
  get_pdfProdotto,
} from "@/api/api.pdf.js";

import ordinamento from "@/components/dialog/catalogo/Ordinamento";
import modificaRigo from "@/components/dialog/catalogo/ModificaDescRiga";
import eliminaTestata from "@/components/dialog/catalogo/EliminaTestata";
import cambiaSuffisso from "@/components/dialog/catalogo/CambiaSuffisso";
import cambiaQuantita from "@/components/dialog/catalogo/CambiaQuantita";
import dialogTecnici from "@/components/dialog/catalogo/DialogTecnici";
import DialogArtTecnici from "@/components/dialog/catalogo/DialogTecniciArticolo";
import DialogInputAltezzaImg from "@/components/dialog/catalogo/DialogInput";
import DialogGeneraPdf from "@/components/dialog/catalogo/GeneraPdf";
import DialogParoleChiave from "@/components/dialog/catalogo/ParoleChiave.vue";
import DialogMerceologica from "@/components/dialog/archivi/DialogMerceologica.vue";
import DialogInfoTestata from "@/components/intranet/DialogInfoTestata.vue";

import mixinMagazVerticale from "@/views/intranet/magazzino/mixinMagVerticale.js";

export default {
  mixins: [mixinMagazVerticale],

  components: {
    ordinamento,
    modificaRigo,
    eliminaTestata,
    cambiaSuffisso,
    cambiaQuantita,
    dialogTecnici,
    DialogArtTecnici,
    DialogInputAltezzaImg,
    DialogGeneraPdf,
    DialogParoleChiave,
    DialogMerceologica,
    DialogInfoTestata,
  },

  props: {
    testata: { type: String, default: "" },
    suffisso: { type: Number, default: 0 },
    codArt: { type: String, default: "" },
  },

  data: () => ({
    articoli: [],
    descTecniche: [],
    datiTestata: {},
    valoriTecnici: [],
    videoCollegati: ["GnAsjngT3d0", "aN0xOtiSOpQ"],
    areaTecnica: [],
    prodottiCorrelati: [],

    headers: [
      { text: "", value: "", sortable: false },
      { text: "Articolo", value: "", sortable: false },
      { text: "Descrizione", value: "", sortable: false },
      { text: "CdFornitore", value: "", sortable: false },
      { text: "Prezzo", value: "", sortable: false },
      { text: "Um", value: "", sortable: false },
      { text: "QtScatola", value: "", sortable: false },
    ],

    attesa: false,

    rules: {
      required: (value) => !!value || "Richiesto.",
      quantita: (value) => {
        return (/^\d+$/g.test(value) && parseInt(value) > 0) || "Numero non valido.";
      },
    },

    tabDocumenti: "desc",

    tipoMenuDx: "",
    tipoModificaMenuDx: "",
    articoloDxSel: {},
    testataDxSel: {},
    articoloDistintaSel: {},
    dialogOrdinamento: false,
    dialogDescRiga: false,
    dialogEliminaTestata: false,
    dialogCambiaSuffisso: false,
    dialogDistintaBase: false,
    dialogQta: false,
    dialogDatiTecnici: false,
    dialogDatiTecniciArticolo: false,
    dialogInputHImg: false,
    dialogGeneraPdf: false,
    dialogParoleChiave: false,
    showMenuArticolo: false,
    showMenuTestata: false,
    showMenuImg: false,
    showMenuImgOrizz: false,
    tipoImgTastoDx: "",
    x: 0,
    y: 0,
    attesaPdf: false,
    attesaSchedaTecnica: false,

    menuDocDx: {
      show: false,
      x: 0,
      y: 0,
      activatedBy: "",
    },

    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      //[{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      //["clean"],
    ],

    elencoTipologie: [
      {
        singolo: true,
        width: 20,
        height: 20,
        nome: "obsoleto",
        testo: "Obsoleto",
        stile: "fill: orange; stroke-width: 3",
      },
      {
        singolo: true,
        width: 20,
        height: 20,
        nome: "fuoricatalogo",
        testo: "Fuori catalogo",
        stile: "fill: yellow; stroke-width: 3",
      },
      {
        singolo: true,
        width: 20,
        height: 20,
        nome: "eliminato",
        testo: "Eliminato",
        stile: "fill: blue; stroke-width: 3",
      },
      {
        singolo: false,
        width: 20,
        height: 20,
        nome: "disattivainternet",
        testo: "Disattiva Internet",
        stile: "fill: #FF1493; stroke-width: 3",
      },
    ],

    dialogMerceologica: false,
    search: "",

    dialogInfo: false,
  }),

  methods: {
    async caricaDati() {
      this.attesa = true;

      let str = this.testata + "/" + this.suffisso;

      try {
        let res = await get_datiPaginaCatalogo(str);

        this.articoli = res.data.articoli;
        this.descTecniche = res.data.descTecniche;
        this.datiTestata = res.data.testata;
        this.valoriTecnici = res.data.valoriTecnici;
        this.areaTecnica = res.data.areaTecnica;
        this.prodottiCorrelati = res.data.correlati;

        // Controllo ricerca esatta per articolo
        if (this.codArt) {
          let resArt = this.getArticoli.find((f) => f.cdArticolo == this.codArt);

          if (!!resArt && Object.keys(resArt).length > 0) {
            // Dettaglio prezzi
            this.$emit("showPermanent", resArt);

            // Scroll alla riga selezionata
            let self = this;
            this.$nextTick(() => {
              self.$refs[self.codArt].scrollIntoView(false);
            });
          }
        }

        // Impostazione tab area tecnica
        // Apro il primo che contiene i dati
        if (this.descTecniche.length > 0 || this.valoriTecnici.length > 0)
          this.tabDocumenti = "desc";
        else if (this.areaTecnica.length > 0) this.tabDocumenti = "schede";
        else if (this.prodottiCorrelati.length > 0) this.tabDocumenti = "correlati";


      } catch (error) {
        console.log(error);
      }

      this.attesa = false;

      // FA CASINO
      // NOTA: sui monitor grandi, se il prodotto ha poche righe non compare la scroll bar, devo forzare
      //       per attivare lo scorrimento
      //document.body.style.overflow = 'scroll';
    },

    getTpRiga(props) {
      let testata = props.item.codTestata.replace('"', "");

      testata = testata.substring(0, 3) + " " + testata.substring(3, 8);

      if (props.item.prevCtTpRiga == "D") return testata;

      return "";
    },

    getPrezzo(item) {
      if (item.distintaBaseDinamica) return "Distinta base";

      // Se terzista il prezzo non è visibile
      if (this.role == 3) return "Dettagli";
      return "€ " + item.prezzoListino.toFixed(3).replace(".", ",");
    },

    getCodFornitore(item) {
      if (item.distintaBaseDinamica) return "Kit composto";

      return item.cdArticoloFornitore;
    },

    gotoCorrelati(item) {
      this.$router.push({
        path:
          "/ricerca/" +
          item.codTestata +
          "/catalogo/" +
          item.codTestata +
          "/" +
          item.suffisso,
      });

      // Avvio la ricerca
      this.$store.commit("ricerca/setFind");
    },

    chiudiDialogDatiTecnici() {
      this.dialogDatiTecnici = false;

      document.body.style.overflow = "";
      document.body.style.position = "";
    },

    // ------- Tasto dx -----------
    tastoDestroRigo(e, item) {
      this.showMenuTestata = false;
      this.showMenuImg = false;

      this.articoloDxSel = item;

      this.tipoMenuDx = "articolo";

      if (this.auth == false) return;

      // Controllo intranet
      if (this.role != 2 && this.role != 4) return;

      e.preventDefault();

      this.showMenuArticolo = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenuArticolo = true;
      });
    },

    tastoDestroTestata(e, item) {
      this.showMenuArticolo = false;
      this.showMenuImg = false;

      this.testataDxSel = item;

      this.tipoMenuDx = "testata";

      if (this.auth == false) return;

      // Controllo intranet
      if (this.role != 2) return;

      e.preventDefault();

      this.showMenuTestata = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenuTestata = true;
      });
    },

    tastoDestroImg(e, item, tipo) {
      this.showMenuArticolo = false;
      this.showMenuTestata = false;
      this.showMenuImgOrizz = false;

      this.testataDxSel = item;

      this.tipoMenuDx = "img";

      if (this.auth == false) return;

      // Controllo intranet
      if (this.role != 2) return;

      this.tipoImgTastoDx = tipo;

      e.preventDefault();

      this.showMenuImg = false;
      this.x = e.clientX;
      this.y = e.clientY;

      this.$nextTick(() => {
        if (tipo == "laterale") this.showMenuImg = true;
        else this.showMenuImgOrizz = true;
      });
    },
    // ----------------------------------

    // ------------ Dialog --------------
    apriDatiTecnici(apriTecnica) {
      if (this.tabDocumenti == "schede" && apriTecnica) {
        this.apriAreaTecnica();
        return;
      }

      this.dialogDatiTecnici = true;

      document.body.style.overflow = "hidden";
      document.body.style.position = "fixed";
    },

    apriAreaTecnica() {
      this.$functionsApp.webViewCommand(
        "areaTecnica|" + this.testata + "|" + this.suffisso
      );
    },

    apriOrdinamento() {
      this.dialogOrdinamento = true;
    },

    chiudiOrdinamento(aggiorna) {
      this.dialogOrdinamento = false;

      if (aggiorna) this.caricaDati();
    },

    apriModificaRiga(tipo) {
      this.tipoModificaMenuDx = tipo;
      this.dialogDescRiga = true;
    },

    chiudiModificaRiga(aggiorna) {
      this.dialogDescRiga = false;

      if (aggiorna) this.caricaDati();
    },

    chiudidialogElimina(aggiorna) {
      this.dialogEliminaTestata = false;

      if (aggiorna) this.caricaDati();
    },

    apriDialogElimina() {
      this.dialogEliminaTestata = true;
    },

    async inserisciVuoto() {
      try {
        let res = await ins_vuoto(this.articoloDxSel);
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.caricaDati();
    },

    inserisciRigaTecnica() {
      this.dialogDatiTecniciArticolo = true;
    },

    chiudiDialogDatiTecniciArticolo() {
      this.dialogDatiTecniciArticolo = false;

      this.caricaDati();
    },

    apriDistintaBase(item) {
      if (item.distintaBaseDinamica == false) return;

      this.articoloDistintaSel = item;

      this.dialogDistintaBase = true;
    },

    getColore(item) {
      if (this.group == "local") {
        // Intranet locale
        return {
          backgroundColor: this.$functionsApp.getColoreCatalogo(
            item.obsoleto,
            item.eliminato,
            item.nonStampCatalogo,
            false
          ),
        };
      }
    },

    getColoreDisponibilita(item) {
      // Nel caso di obsoleto su internet, prevale su tutto
      if (item.obsoleto && this.group != "local") {
        return {
          backgroundColor: this.$functionsApp.getColoreCatalogo(
            item.obsoleto,
            false,
            false,
            false
          ),
        };
      } else {
        return {
          backgroundColor: item.disponibile ? "green" : "red",
        };
      }
    },

    getTooltipPallino(item) {
      if (this.group == "local") {
        // Intranet locale
        return this.$functionsApp.getTooltipPallinoCatalogo(
          item.obsoleto,
          item.eliminato,
          item.nonStampCatalogo,
          false
        );
      }

      return "";
    },

    getColoreArticoloRicerca(item) {

      if (item.cdArticolo == this.codArt) {
        return {
          backgroundColor: "red",
        };
      }

      return "";
    },

    getTooltipPallinoDisponibilita(item) {
      if (item.obsoleto) return "Obsoleto";
      else if (item.disponibile) return "Prodotto gestito a magazzino";
      else return "Prodotto gestito su ordinazione";
    },

    async eliminaRigo() {
      let confirm = await this.$confirm.open("Elimina", "Vuoi eliminare il rigo ?");
      if (!confirm) return;

      let res = await del_rigoCatalogo(this.articoloDxSel);

      this.caricaDati();
    },

    cambiaSuffisso() {
      this.dialogCambiaSuffisso = true;
    },

    chiudiDialogSuffisso() {
      this.dialogCambiaSuffisso = false;
    },

    cambiaQta() {
      this.dialogQta = true;
    },

    chiudiDialogQta(aggiorna) {
      this.dialogQta = false;

      if (aggiorna) this.caricaDati();
    },

    async cambiaTipo(nuovoTipo, tipologia, item) {
      //item mi serve quando clicco sul pallino della riga
      //tipologia mi serve per capire se cambio il tipo di una testata
      let par = null;
      let url = tipologia + "/" + nuovoTipo;

      if (tipologia == "testata") {
        par = {
          CodTestata: this.testataDxSel.codTestata,
          ctnumSUFFISSO: this.testataDxSel.suffisso,
        };
      } else if (tipologia == "dettaglio" && item) {
        par = {
          CdArticolo: this.articoloDxSel.cdArticolo,
          ctnumSUFFISSO: this.articoloDxSel.ctnumSUFFISSO,
        };
      }

      try {
        let res = await set_nuovoTipo(url, par);
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.caricaDati();
    },

    async eseguiCmdMagVerticale(item) {
      if (item == null || item == undefined) item = this.articoloDxSel;
      if (item.ubicazione.length == 0) {
        this.$toast.alert("Errore. Ubicazione non valida");
        return;
      }
      // Mixin
      this.estrai(this.getCassetto(item.ubicazione));
    },

    showUbicazione(ubi) {
      if (ubi.startsWith("9")) {
        let nmag = ubi.substring(0, 2);
        return this.getUser.includes(nmag); // return true;
      }
      return false;
    },

    getMagazzino(ubi) {
      let arr = ubi.split("-");
      if (arr.length > 1) return arr[0];
      else return ubi.substring(0, 2);
    },

    getCassetto(ubi) {
      let arr = ubi.split("-");
      if (arr.length > 1) return arr[1];
      else return ubi.substring(2, 4);
    },

    selArticoloOrdiniClienti(item) {
      if (this.role != 6) return;

      this.$functionsApp.webViewCommand("articoloSelezionato|" + item.cdArticolo);
    },

    avviaComandoTestata(comando) {
      this.$functionsApp.webViewCommand(
        comando + "|" + this.testata + "|" + this.suffisso
      );
    },

    avviaComandoArticolo(comando) {
      this.$functionsApp.webViewCommand(comando + "|" + this.articoloDxSel.cdArticolo);
    },

    async GeneraPdfProdotto() {
      if (this.role == 2) {
        this.dialogGeneraPdf = true;
      } else {
        let str = this.testata + "/" + this.suffisso;

        this.attesaPdf = true;
        try {
          let res = await get_pdfProdotto(str);

          window.open(res.data, "_blank");
        } catch (error) {
          console.log(error);
        }

        this.attesaPdf = false;
      }
    },

    vediInfo() {
      if (this.dialogInfo == false) this.dialogInfo = true;
      else this.dialogInfo = false;
    },

    gestioneProdotto() {
      this.$functionsApp.webViewCommand(
        "gestioneProdotto|" +
        this.articoloDxSel.cdArticolo +
        "|" +
        this.$functionsApp.getClienteSelezionato("cdAnagrafica")
      );
    },

    getDescrizioneTecniche() {
      let res = this.$functionsApp.getDescrizioneTecniche(this.descTecniche);

      return res;
    },

    async impostaAltezzaImg(valore) {
      try {
        if (this.$i2kFunctions.isNumber(valore) == false) {
          this.$toast.alert("Numero non valido");
          return;
        }
        let res = await set_hImg(this.testata + "/" + this.suffisso + "/" + valore);

        this.caricaDati();
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async generaAreaTecnica() {
      this.attesaSchedaTecnica = true;

      try {
        let res = await get_areaTecnicaCatalogo(this.testata + "/" + this.suffisso);
        this.areaTecnica = res.data;
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaSchedaTecnica = false;
    },

    apriMenuDocDx(e) {
      if (this.role != 2) return;

      e.preventDefault();
      this.menuDocDx.show = false;
      this.menuDocDx.x = e.clientX;
      this.menuDocDx.y = e.clientY;
      this.menuDocDx.activatedBy = this.tabDocumenti;
      this.$nextTick(() => {
        this.menuDocDx.show = true;
      });
    },

    chiudiDialogInputHImg(salva, valore) {
      this.dialogInputHImg = false;

      if (salva) {
        this.impostaAltezzaImg(valore);
      }
    },

    chiudiDialogGeneraPdf() {
      this.dialogGeneraPdf = false;
    },

    copiaTesto() {
      try {
        document.execCommand("copy");
        this.$toast.info("testo copiato negli appunti");
      } catch (error) {
        console.error(error);
      }
    },

    apriParoleChiave() {
      // Se il sito è aperto da infoanzienda, apro in una nuova form
      /* if (window.chrome.webview) {
        this.$functionsApp.webViewCommand(
          "dialogParoleChiave|" + this.testata + "|" + this.suffisso
        );
      } else { */
      this.dialogParoleChiave = true;
      //}
    },

    async chiudiParoleChiave() {
      this.dialogParoleChiave = false;
      await this.caricaDati();
    },

    async caricaCatMer(categoria) {
      if (this.dialogMerceologica === false) {
        this.dialogMerceologica = true;
        this.search = this.testataDxSel.catMerceologica.substring(0, 3);//setto filtro ricerca categoria merceologica
      }
      else this.dialogMerceologica = false;
      if (categoria != undefined)
        if (categoria.ctMer != "" && categoria.ctMer) {
          this.testataDxSel.catProgDescOrdine = 0;//altrimenti va in errore perché null
          categoria.ctMer = categoria.ctMer.substring(0, 3) + categoria.ctMer.substring(4, 7);
          this.testataDxSel.catMerceologica = categoria.ctMer;
          let res = await salva_catMerceologica(this.testataDxSel);

          if (res.data != "OK") this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
          else this.$toast.info("Dati aggiornati con successo");
        }
    },

    copiaLink() {
      var inputc = document.body.appendChild(document.createElement("input"));
      inputc.value = "https://www.battisti.cloud/ricerca/" + this.testata + "/catalogo/" + this.testata + "/" + this.suffisso;
      inputc.select();
      navigator.clipboard.writeText(inputc.value);
      inputc.parentNode.removeChild(inputc);
    }
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },

    role() {
      return this.$store.getters["user/getRole"];
    },

    group() {
      return this.$store.getters["user/getGroup"];
    },

    getUser() {
      return this.$store.getters["user/getUser"];
    },

    filtriObsoletoEliminato() {
      return this.$store.getters["ricerca/getFiltroObsoletoEliminatoFuoriCat"];
    },

    getArticoli() {
      let res = this.articoli;

      // Controllo spunte obsoleto/eliminato/fuori cat
      let filtriTipologia = this.$functionsApp.splitBooleanArray(
        this.filtriObsoletoEliminato.split(";")
      );

      if (!!filtriTipologia) {
        let swap = [];

        for (let k = 0; k <= res.length - 1; k++) {
          // Solo spunta verde attiva
          if (
            filtriTipologia[4] == true &&
            filtriTipologia[0] == false &&
            filtriTipologia[1] == false &&
            filtriTipologia[2] == false &&
            filtriTipologia[3] == false
          ) {
            if (res[k].nonStampCatalogo) continue;
            if (res[k].obsoleto) continue;
            if (res[k].eliminato) continue;

            if (res[k].nrArt == 0) continue;
          } else {
            // Spunta verde disattiva
            if (filtriTipologia[4] == false) {
              if (
                res[k].nonStampCatalogo == false &&
                res[k].obsoleto == false &&
                res[k].eliminato == false
              )
                continue;

              if (res[k].nonStampCatalogo && filtriTipologia[0] == false) continue;

              if (res[k].obsoleto && filtriTipologia[1] == false) continue;

              if (res[k].eliminato && filtriTipologia[2] == false) continue;
            } else {
              // Spunta verde attiva
              if (res[k].nonStampCatalogo && filtriTipologia[0] == false) continue;

              if (res[k].obsoleto && filtriTipologia[1] == false) continue;

              if (res[k].eliminato && filtriTipologia[2] == false) continue;

              //if (res[k].nrArt == 0) continue;
            }
          }

          swap.push(res[k]);
        }

        res = swap;
      }
      return res;
    },

    getArrayTestata() {
      let res = this.$store.getters["ricerca/risultatiRicerca"].find(f => f.codTestata == this.datiTestata.codTestata && f.suffisso == this.datiTestata.suffisso)
      return new Array(res);
    },
  },

  watch: {
    auth() {
      this.caricaDati();
    },

    tabDocumenti() {
      if (this.tabDocumenti == "schede") {
        // Controllo anteprima da generare
        if (this.areaTecnica.find((k) => k.anteprimaDaGenerare == true)) {
          this.generaAreaTecnica();
        }
      }
    },
  },
};
</script>

<style>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.03);
}

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
