<template>
    <div style="width:100%; height:91vh;">
        <FilterComuni ref="filtro" @caricaDati="caricaKml" :zone="zone">
        </FilterComuni>

        <!-- Mappa -->
        <div class="google-map" :id="mapName"></div>

        <!-- Dialog modifica-->
        <v-dialog v-model="dialog" width="360">
            <v-card width="360" class="pa-2">
                <div class="pa-2">
                    <v-text-field v-model="comune" outlined readonly label="Comune"></v-text-field>

                    <v-select v-model="zonaSel" :items="zone" item-text="zona_Passaggio" item-value="zona_Passaggio"
                        label="Zona" outlined style="max-width: 110px" return-object />

                    <v-text-field v-model="descZona" outlined label="Descrizione zona"></v-text-field>

                    <v-text-field v-model="colore" outlined label="Colore BGRA"></v-text-field>
                </div>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-2" @click="dialog = false">ANNULLA</v-btn>
                    <v-btn class="mr-2 btn-conferma" @click="confermaZone()" :loading="attesaConferma">Conferma</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>
</template>

<script>
import gmapsInit from "@/utils/maps";
import FilterComuni from "@/components/intranet/FilterComuni.vue";
import { get_zoneComuni, set_zoneComuni } from "@/api/api.intranet.js";

export default {
    components: {
        FilterComuni
    },

    data: () => ({
        dati: {},

        mapName: "google-map",
        map: null,
        google: null,
        kmlLayer: null,
        kmlLayerProv: null,

        dialog: false,
        comune: '',
        zonaPassaggio: '',

        zonaSel: '',
        zone: [],
        attesaConferma: false,
        colore: '',
        descZona: ''


    }),

    methods: {

        async init() {
            await this.initMappa()

            await this.caricaKml()
        },

        async caricaKml() {

            await this.caricaZone()

            // Reset
            this.dati = {};
            if (this.kmlLayer)
                this.kmlLayer.setMap(null);
            if (this.kmlLayerProv)
                this.kmlLayerProv.setMap(null);

            // ---- Comuni
            let src = this.$load.getUrl_Kml() + '/piemonte.kml'
            src += "?dt=" + (new Date()).getTime();

            this.kmlLayer = new this.google.maps.KmlLayer(src, {
                suppressInfoWindows: true,
                preserveViewport: false,
                disableAutoPan: true,
                map: this.map
            });

            let _this = this
            this.google.maps.event.addListener(this.kmlLayer, 'click', function (evt) {

                _this.comune = evt.featureData.name
                _this.zonaPassaggio = evt.featureData.description

                _this.zonaSel = _this.zone.find(f => f.zona_Passaggio.toString().toUpperCase() == _this.zonaPassaggio.toUpperCase())

                _this.dialog = true
            });


            // ---- Province
            let srcProv = this.$load.getUrl_Kml() + '/prov_cuneo.kml'
            srcProv += "?dt=" + (new Date()).getTime();

            this.kmlLayerProv = new this.google.maps.KmlLayer(srcProv, {
                suppressInfoWindows: true,
                preserveViewport: false,
                disableAutoPan: true,
                map: this.map
            });



        },


        async initMappa() {

            // --------- Creazione mappa --------
            if (this.map == null) {
                this.google = await gmapsInit(this.$load.getGoogleKey());

                const element = document.getElementById(this.mapName);
                const options = {
                    zoom: 5,
                    mapTypeId: this.google.maps.MapTypeId.ROADMAP,
                };

                this.map = new this.google.maps.Map(element, options);
            }

        },

        async caricaZone() {

            // Reset
            this.zone = []

            let res = await get_zoneComuni("")
            this.zone = res.data
        },

        async caricaDatiZone() {

            if (this.dialog == false)
                return

            let res = await get_zoneComuni(this.zonaSel.zona_Passaggio)
            this.colore = res.data.coloreKml
            this.descZona = res.data.descrizioneZona
        },

        async confermaZone() {
            this.attesaConferma = true

            this.zonaSel.comune = this.comune
            this.zonaSel.coloreKml = this.colore
            this.zonaSel.descrizioneZona = this.descZona

            let res = await set_zoneComuni(this.zonaSel)

            this.attesaConferma = false
            this.dialog = false

            this.caricaKml()
        }
    },

    mounted() {
        this.init();
    },

    watch: {
        dialog() {
            this.caricaDatiZone()
        }
    }
}
</script>

<style scoped>
.google-map {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: gray;
    color: black;
}
</style>