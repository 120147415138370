<template>
  <v-dialog scrollable v-model="dialogComputed" width="400px">
    <v-card tile :loading="attesa">
      <v-toolbar light dense flat>
        <v-toolbar-title>Impostazioni</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field label="Orario partenza" style="width: 150px" outline v-model="orarioPartenza" mask="##:##"
                :return-masked-value="true" />
            </v-col>
            <v-col>
              <v-switch label="No pausa pranzo" hide-details v-model="noPausaPranzo" style="float: left" class="ml-2" />
            </v-col>

            <v-col>
              <v-text-field label="Ritardo %" style="width: 150px" outline v-model="ritardo" type="number" />
            </v-col>

            <v-col>
              <v-text-field label="Minuti pausa pranzo" style="width: 150px" outline v-model="pausaPranzo"
                type="number" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="closeDialog()">Annulla</v-btn>
        <v-btn class="btn-conferma" @click.native="conferma()">Conferma</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  get_consegneImpostazioni,
  set_consegneImpostazioni,
} from "@/api/api.intranet.js";

export default {
  props: {
    dialog: { type: Boolean },
    autista: { type: String },
    dataConsegna: { type: String },
  },

  data() {
    return {
      orarioPartenza: "",
      noPausaPranzo: false,
      dati: [],
      aggiorna: false,
      ritardo: 0,
      pausaPranzo: 0,
      attesa: false
    };
  },

  methods: {
    async caricaDati() {
      // Reset
      this.dati = [];
      this.aggiorna = false;

      if (this.dialog == false) return;

      this.attesa = true

      try {
        let res = await get_consegneImpostazioni(
          this.dataConsegna + "/" + this.autista
        );

        this.dati = res.data;

        this.orarioPartenza = this.dati.oraPartenza.toString().substr(11, 5);
        this.noPausaPranzo = this.dati.noPausaPranzo;
        this.ritardo = this.dati.ritardo;
        this.pausaPranzo = this.dati.pausaPranzo;

      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false
    },

    async conferma() {
      let data = this.$i2kFunctions.getDateTimeIso(
        new Date(
          this.dati.oraPartenza.substr(0, 10) + " " + this.orarioPartenza
        )
      );

      this.dati.oraPartenza = data;
      this.dati.noPausaPranzo = this.noPausaPranzo;
      this.dati.ritardo = this.ritardo;
      this.dati.pausaPranzo = this.pausaPranzo;

      if (Number.isInteger(parseInt(this.dati.ritardo)) == false) {
        this.$toast.error("Ritardo valore numerico non valido");
        return;
      }

      try {
        let res = await set_consegneImpostazioni(
          this.dataConsegna + "/" + this.autista,
          this.dati
        );
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.closeDialog(true);
    },

    closeDialog(aggiorna) {
      this.$emit("chiudiDialog", aggiorna);
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(v) {
      if (v) {
        this.caricaDati();
      }
    },
  },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },
};
</script>