<template>
  <div>
    <div style="height: 60px; background-color: #f5f5f5"></div>

    <v-data-table
      :items="dati"
      :headers="headers"
      height="350"
      disable-pagination
      hide-default-footer
      item-key="cdFiglio"
      @item-expanded="aggiornaDatiFor = !aggiornaDatiFor"
      show-expand
      single-expand
      dense
    >
      <!-- Personalizzo la colonna articolo -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.cdFiglio="{ item }">
        <td
          class="pr-0"
          v-html="$functionsApp.getCodArticoloFormat(item.cdFiglio)"
        ></td>
      </template>

      <!-- Personalizzo la colonna descrizione -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.descrizione="{ item }">
        <td
          class="pr-0"
          v-html="$functionsApp.convertiTestoGrassetto(item.descrizione)"
        ></td>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.prezzoVendita="{ item }">
        <td
          class="pr-0"
          style="text-align: right"
          v-text="'€ ' + item.prezzoVendita"
        ></td>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.qtaDisponibile="{ item }">
        <td
          class="pr-0"
          style="text-align: right"
          v-text="item.qtaDisponibile"
          v-if="group == 'local'"
        ></td>
      </template>

      <!-- Expand -->
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="background-color: grey"
          class="py-4"
        >
          <!-- Nota: se devo aggiornare delle props all'apertura\chiusura devo mettere la key-->
          <!--:key="item.CodCliente" -->

          <dialogOrdinato
            class="mb-4 mx-4"
            :cdArticolo="item.cdFiglio"
            :aggiorna="aggiornaDatiFor"
            :tipo="'fornitore'"
            style="height: 240px"
            altezzaGrid="calc(20vh)"
          >
          </dialogOrdinato>
        </td>
      </template>
    </v-data-table>

    <!-- Footer -->
    <v-footer app color="#fff" class="pa-2" elevation="2">
      <v-container
        style="background-color: #fff; bottom: 0px"
        class="ma-0 py-0"
        fluid
      >
        <v-row>
          <v-col cols="2">
            <v-btn color="primary" small @click="attivaTabInfo()">
              <v-icon class="mr-2" left style="display: inline-flex"
                >shopping_cart</v-icon
              >
              Ordina
            </v-btn>
          </v-col>

          <v-col cols="10" style="text-align: right" class="mt-2">
            Totale:<span class="primary--text mr-4 font-weight-bold">
              {{ getTotale }}</span
            >

            Totale scontato:
            <span class="primary--text font-weight-bold">{{
              getTotaleScontato
            }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import dialogOrdinato from "@/components/dialog/catalogo/DettaglioOrdiniCliFor.vue";

export default {
  props: {
    dati: { type: Array },
    role: { type: Number, default: 0 },
    group: { type: String, default: "" },
  },

  components: {
    dialogOrdinato,
  },

  data: () => ({
    headers: [
      {
        text: "Codice",
        value: "cdFiglio",
        sortable: true,
      },
      {
        text: "Descrizione",
        value: "descrizione",
        sortable: true,
      },
      { text: "Um", value: "unMisura", sortable: false, width: 40 },
      {
        text: "Coef.",
        value: "coefficenteImpiego",
        sortable: false,
        width: 40,
        align: "end",
      },
      {
        text: "Dispo.",
        value: "qtaDisponibile",
        sortable: false,
        width: 40,
        align: "end",
      },
      {
        text: "Prezzo",
        value: "prezzoVendita",
        sortable: false,
        align: "end",
      },
      {
        text: "Sconto",
        value: "sconti",
        sortable: false,
        width: 40,
        align: "end",
      },
    ],

    aggiornaDatiFor: false,
  }),

  methods: {
    attivaTabInfo() {
      this.$emit("selezionaTabInfo");
    },
  },

  computed: {
    getTotale() {
      if (this.dati.length == 0) return 0;

      let res = this.dati
        .map((bill) => bill.prezzoVenditaUnitario * bill.coefficenteImpiego)
        .reduce((acc, bill) => bill + acc);

      return new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
      }).format(res);
    },

    getTotaleScontato() {
      if (this.dati.length == 0) return 0;

      let res = this.dati
        .map((bill) => bill.prezzoVendita)
        .reduce((acc, bill) => bill + acc);

      return new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR",
      }).format(res);
    },
  },
};
</script>