<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>

    <!-- Plugin -->
    <toast></toast>
    <confirm></confirm>
    <wait></wait>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style >
html {
  overflow-y: auto !important;
}
</style>



