import {
    get_datiRicerca
} from "@/api/api.catalogo.js";
import storage from "@/services/storage.js"
import router from '@/router'


const functionsRicerca = {

    confronta(valore1, valore2) {
        let v1 = "$" + valore1.toString().toLowerCase() + "$";
        let v2 = "$" + valore2.toString().toLowerCase() + "$";
        return v1.includes(v2);
    },
}



const ricerca = {
    namespaced: true,

    state: {
        ricerca: [],
        filtri: [],
        valoreRicerca: '',
        tipoRicerca: '',
        valoreFiltro: [],
        find: false,
        attesaRicerca: false,
        selTutti: false,
        filtroObsoletoEliminatoFuoriCat: "",
        drawerAzioni: false,
        oggettiPerAzioni: [],
        manutenzione: false,
        multiSelezione: false,
        sommario: []
    },

    mutations: {

        saveRicerca(state, data) {
            state.ricerca = data
        },
        saveFiltriRicerca(state, data) {
            state.filtri = data
            // Reset valore filtro selezionato
            state.valoreFiltro = []
        },
        setValoreRicerca(state, data) {
            state.valoreRicerca = data
        },
        setTipoRicerca(state, data) {
            state.tipoRicerca = data
        },
        setValoreFiltro(state, data) {
            state.valoreFiltro = data
        },
        setFiltri(state, data) {
            state.filtri = data
        },
        setFind(state, data) {
            state.find = !state.find
        },
        setAttesaRicerca(state, data) {
            state.attesaRicerca = data
        },
        setDrawerAzioni(state, data) {
            state.drawerAzioni = !state.drawerAzioni
        },
        setOggettiPerAzioni(state, data) {
            state.oggettiPerAzioni = data;
        },

        setSelTutti(state, data) {
            state.selTutti = data
        },

        setManutenzione(state, data) {
            state.manutenzione = data
        },

        setMultiSelezione(state, data) {
            state.multiSelezione = data
        },

        setFiltroObsoletoEliminatoFuoriCat(state, data) {
            // NOTA: per avere le computed e le watch funzionanti non posso usare oggetti come gli array
            state.filtroObsoletoEliminatoFuoriCat = data.join(";")

            storage.setStorageFiltriRicerca(data);
        },

        saveSommario(state, data) {
            state.sommario = data
        }

    },

    actions: {

        cerca({
            commit,
            state
        }, par) {
            // Attesa
            commit("setAttesaRicerca", true);

            return new Promise((resolve, reject) => {

                get_datiRicerca(par)
                    .then(resp => {


                        // Memorizzo i risultati
                        commit("saveRicerca", resp.data.testate);

                        // Memorizzo i filtri
                        commit("saveFiltriRicerca", resp.data.filtro);

                        // Memorizzo il tipo ricerca
                        commit("setTipoRicerca", resp.data.tipoRicerca);

                        // Memorizzo la parola cercata
                        commit("setValoreRicerca", par.valore);

                        // Fine attesa
                        commit("setAttesaRicerca", false);


                        // Controllo ricerca per catalogo
                        if (resp.data.tipoRicerca == "CatalogoPdf") {
                            commit("saveSommario", resp.data.capitoloCategorie);
                            commit("saveRicerca", resp.data.capitoloCategorie[0].sottoCategorie[0].testate);
                            router.push({ path: "/ricerca/" + par.valore + "/sommario/" + resp.data.capitoloCategorie[0].codCategoria })
                        }
                        // Controllo ricerca esatta per codice articolo\fornitore
                        else if (resp.data.testate.length == 1 && resp.data.testate[0].codArticoloRicerca.length > 0) {
                            router.push({ path: "/ricerca/" + par.valore + "/catalogo/" + resp.data.testate[0].codTestata + "/" + resp.data.testate[0].suffisso + "/" + resp.data.testate[0].codArticoloRicerca })
                        }

                        resolve(resp)

                    })
                    .catch(err => {
                        // Fine attesa
                        commit("setAttesaRicerca", false);

                        reject(err)
                    });

            })
        },

    },

    getters: {
        risultatiRicerca: state => state.ricerca,
        filtriRicerca: state => state.filtri,
        valoreRicerca: state => state.valoreRicerca == null ? '' : state.valoreRicerca, // NOTA: se usano il bottone cleareable, il valore diventa null
        find: state => state.find,
        getDrawerAzioni: state => state.drawerAzioni,
        oggettiPerAzioni: state => state.oggettiPerAzioni,
        valoreFiltro: state => state.valoreFiltro,
        tipoRicerca: state => state.tipoRicerca,
        selTutti: state => state.selTutti,
        manutenzione: state => state.manutenzione,
        multiSelezione: state => state.multiSelezione,
        sommario: state => state.sommario,

        getFiltroObsoletoEliminatoFuoriCat: state => state.filtroObsoletoEliminatoFuoriCat
    }

}

export default ricerca