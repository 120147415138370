import request from '@/services/interceptor.js'


export const set_datiEtichette = (par) => {
    return request({
        url: '/intranet/etichette',
        method: 'post',
        data: par
    })
};

export const get_datiDocumento = (data) => {
    return request({
        url: '/intranet/etichette/documento/info',
        method: 'post',
        data: data,
    })
};

export const del_documento = (data) => {
    return request({
        url: '/intranet/etichette/documento/delete',
        method: 'delete',
        data: data,
    })
}

export const del_spedizione = (data) => {
    return request({
        url: '/intranet/etichette/spedizione/delete',
        method: 'delete',
        data: data,
    })
}

export const get_datiCliente = (par) => {
    return request({
        url: '/intranet/etichette/cliente/info/' + par,
        method: 'get',
    })
};

export const get_spedizioni = (data) => {
    return request({
        url: '/intranet/etichette/elenco',
        method: 'post',
        data: data
    })
}

export const get_spedizione = (par) => {
    return request({
        url: '/intranet/etichette/spedizione/' + par,
        method: 'get',
    })
}

export const set_spedizione = (url, data) => {
    return request({
        url: '/intranet/etichette/spedizione/' + url,
        method: 'post',
        data: data,
    })
}

export const get_pesoSpedizione = (par) => {
    return request({
        url: '/intranet/etichette/documento/peso/' + par,
        method: 'get',
    })
}

export const get_elencoDocumenti = (data) => {
    return request({
        url: '/intranet/etichette/documento/elenco',
        method: 'post',
        data: data
    })
}

export const get_codiceSpedizione = (par) => {
    return request({
        url: '/intranet/etichette/spedizione/codice/' + par,
        method: 'get',
    })
}

export const get_infoSpedizione = (par) => {
    return request({
        url: '/intranet/etichette/spedizione/info/' + par,
        method: 'get',
    })
}

export const get_elencoSpedizioni = (data) => {
    return request({
        url: '/intranet/etichette/spedizione/elenco',
        method: 'post',
        data: data
    })
}

export const stampa_etichette = (data) => {
    return request({
        url: '/intranet/etichette/stampa',
        method: 'post',
        data: data,
    })
}

export const get_messaggioStampa = (data) => {
    return request({
        url: '/intranet/etichette/stampa/messaggio',
        method: 'post',
        data: data,
    })
}

export const get_messaggioSpedizione = (data) => {
    return request({
        url: 'intranet/etichette/spedizione/messaggio',
        method: 'post',
        data: data,
    })
}

export const get_pdfBordero = (data) => {
    return request({
        url: 'intranet/etichette/bordero',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}
export const get_tracking = (par) => {
    return request({
        url: 'intranet/etichette/tracking/' + par,
        method: 'get',
    })
}

export const get_erroriSpedizioni = () => {
    return request({
        url: 'intranet/etichette/errori/elenco',
        method: 'get',
    })
}