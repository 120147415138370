<template>
  <v-fab-transition>
    <v-btn v-show="!hidden" color="primary" fab dark fixed bottom right @click="$vuetify.goTo(0)">
      <v-icon>arrow_upward</v-icon>
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: "scroll-top",

  data: () => ({
    hidden: true
  }),

  methods: {
    toggleOnScroll() {
      if (window.scrollY > window.innerHeight + 200 ) {
        this.hidden = false;
      } else {
        this.hidden = true;
      }
    }
  },

  created() {
    window.addEventListener("scroll", this.toggleOnScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.toggleOnScroll);
  },
}
</script>
