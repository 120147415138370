<template>
  <v-card style="height: 600px" class="elevation-0">
    <filtro @filtroDati="filtroDati"></filtro>

    <div>
      <v-data-table
        :headers="headers"
        :items="getDati"
        item-key="cdArticolo"
        class="elevation-0"
        disable-pagination
        :loading="attesa"
        hide-default-footer
        @item-expanded="rowExpand"
        single-expand
        height="350"
        no-data-text="Nessun dato"
      >
        <!-- Personalizzo la colonna articolo -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.cdArticolo="{ item }">
          <span v-html="$functionsApp.getCodArticoloFormat(item.cdArticolo)">
          </span>
        </template>

        <!-- Personalizzo la colonna descrizione -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.descrizione="{ item }">
          <td
            class="pr-0"
            v-html="$functionsApp.convertiTestoGrassetto(item.descrizione)"
          ></td>
        </template>
        <!-- Data di consegna confermata -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.dtConsConfermata="{ item }">
          <td class="pl-0 pr-0">
            <span> {{ item.dtConsConfermata }}</span>
            <v-btn icon @click="ApriModificaData(item)">
              <v-icon> edit</v-icon>
            </v-btn>
          </td>
        </template>
        <!-- Personalizzo la colonna img -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.pathImg="{ item }">
          <td class="pl-0 pr-0">
            <img style="width: 100px" :src="item.pathImg" />
          </td>
        </template>

        <!-- Expand -->
        <!--         <template v-slot:expanded-item="{ headers }">
          <td :colspan="headers.length"></td>
        </template> -->
      </v-data-table>
    </div>

    <v-dialog v-model="dialogModifica" width="400">
      <v-card width="400" class="pa-2">
        <v-card-title class="mt-0 pt-0 mb-4 pl-1">
          Data di consegna confermata
        </v-card-title>

        <v-menu
          :close-on-content-click="false"
          v-model="calendar"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-on="on"
              v-model="newDataConsegna_IT"
              label="Data consegna"
              prepend-icon="event"
              hide-details
              style="max-width: 150px !important"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="newDataConsegna"
            locale="it-it"
            first-day-of-week="1"
            event-color="black"
            class="b-date-picker"
            @input="calendar = false"
            hide-details
          ></v-date-picker>
        </v-menu>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text light @click="chiudiModificaData()">
            Annulla
          </v-btn>
          <v-btn
            color="primary"
            text
            light
            @click="confermaModifica()"
            :disabled="attesa"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  get_ordiniForTerzisti,
  set_dataConsegnaConf,
} from "@/api/api.catalogo.js";

import filtro from "@/components/ui/FiltroDettaglioPrezzi.vue";

export default {
  components: { filtro },

  props: {
    aggiorna: { type: Boolean, default: false },
    cdArticolo: { type: String, default: "" },
  },
  data: () => ({
    headers: [
      {
        text: "CodArticolo",
        value: "cdArticolo",
        width: "16%",
        sortable: false,
      },
      {
        text: "Descrizione",
        value: "descrizione",
        sortable: false,
      },
      { text: "Um", value: "unMisura", sortable: false },
      { text: "Qta", value: "qtOrdFor", width: "8%", sortable: false },
      {
        text: "Num Ordine",
        value: "nrDocuStampato",
        width: "8%",
        sortable: false,
      },
      {
        text: "Data documento",
        value: "dtDocu",
        width: "8%",
        sortable: false,
      },
      {
        text: "Data consegna richiesta",
        value: "dtConsRichiesta",
        width: "8%",
        sortable: false,
      },
      {
        text: "Data consegna confermata",
        value: "dtConsConfermata",
        width: "120px",
        sortable: false,
      },
      { text: "Prezzo", value: "", sortable: false },
      { text: "Img", value: "pathImg", sortable: false },
    ],

    dati: [],

    attesa: false,

    calendar: false,

    dialogModifica: false,
    editItem: {},
    newDataConsegna: "",
    newDataConsegna_IT: "",

    datiFiltro: {},
  }),

  methods: {
    async caricaDati() {
      this.attesa = true;

      // Reset
      this.dati = [];

      let res = await get_ordiniForTerzisti(this.cdArticolo);

      this.dati = res.data;

      this.attesa = false;
    },

    filtroDati(par) {
      this.datiFiltro = par;

      //this.caricaDati();
    },

    chiudi() {
      this.$emit("esci");
    },

    rowExpand(item) {
      if (!!item.item == false || item.item.length == 0) return;

      /* console.log(item);
      console.log("Expand: " + item.value); */
    },

    ApriModificaData(item) {
      this.editItem = item;
      this.newDataConsegna = item.dtConsConfermata.toISOString;
      this.newDataConsegna_IT = item.dtConsConfermata;
      this.dialogModifica = true;
    },

    chiudiModificaData() {
      this.editItem = {};
      this.dialogModifica = false;
    },

    async confermaModifica() {
      this.editItem.dtConsConfermata = this.newDataConsegna;
      let res = await set_dataConsegnaConf(this.editItem);

      if (res.data == "OK") {
        this.caricaDati();
        this.chiudiModificaData();
      } else this.$toast.alert(res.data);
    },
  },

  mounted() {
    this.dataDa = new Date(new Date().getFullYear(), 0, 1)
      .toISOString()
      .substring(0, 10);
    this.dataA = new Date().toISOString().substring(0, 10);

    this.dataConsegnaFormat = this.$i2kFunctions.formatISODate(this.dataDa);
    this.dataConsegnaFormat2 = this.$i2kFunctions.formatISODate(this.dataA);

    //this.caricaDati();
  },

  watch: {
    aggiorna() {
      this.caricaDati();
    },
    dataDa() {
      this.dataConsegnaFormat = this.$i2kFunctions.formatISODate(this.dataDa);
    },
    dataA() {
      this.dataConsegnaFormat2 = this.$i2kFunctions.formatISODate(this.dataA);
    },

    newDataConsegna() {
      this.newDataConsegna_IT = this.$i2kFunctions.formatISODate(
        this.newDataConsegna
      );
    },
  },

  computed: {
    getDate() {
      return this.$i2kFunctions.formatISODate(
        new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
      );
    },

    getDati() {
      if (
        this.datiFiltro.dataConsegna == undefined ||
        this.datiFiltro.dataConsegna2 == undefined ||
        this.datiFiltro.dataConsegna == "" ||
        this.datiFiltro.dataConsegna2 == ""
      )
        return this.dati;
      //filtro sulle date
      let dataDa = new Date(this.datiFiltro.dataConsegna);
      let dataA = new Date(this.datiFiltro.dataConsegna2);
      return this.dati.filter((dato) => {
        //converto la data documento in ISO format e faccio il fitro
        let dataDocu = new Date(
          this.$i2kFunctions.formatITDateToISO(dato.dtDocu)
        );

        return dataDocu >= dataDa && dataDocu <= dataA;
      });
    },
  },
};
</script>