<template>
    <v-container>
        <v-row>
            <v-col cols="12" v-for="(item, i) in sommario.filter(f => f.codCategoria == codcategoria)" :key="i">


                <div v-for="(itemIndice, j) in item.sottoCategorie" :key="j">
                    <router-link :to="gotoPage()" class="unstyle-link">
                        {{ itemIndice.descrizione }}
                    </router-link>
                   
                </div>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>

export default {
    props: {
        codcategoria: { type: String, default: "" }

    },

    methods: {
        gotoPage() {
            
            return "/ricerca/" + this.$store.getters["ricerca/valoreRicerca"]  + "/prodotti" 
           
        }
    },

    mounted() {
        console.log(this.codcategoria)
    },

    computed: {
        sommario() {
            return this.$store.getters["ricerca/sommario"];
        }
    }
}
</script>