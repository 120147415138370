<template src="./testataProdotto.html"></template>

<script>
import {
  get_prodotti,
  edit_testata,
  add_testata,
  delete_testata,
  getTestata,
  get_listaCataloghi,
  set_infoTestata,
  get_codiceLinea,
  get_filtriParoleChiaveArchivi,
  get_numeroFiltriParoleChiaveArchivi,
  get_numeroNomiFiltri,
  get_numeroValoriFiltri,
  get_valoriFiltri,
  add_filtro, carica_file_filtri
} from "@/api/api.archivi.js";

import {
  /* get_datiRicerca, */
  get_paroleChiaveRicerca,
  get_filtriParoleChiave,
} from "@/api/api.catalogo.js";

import DialogAziende from "@/components/dialog/archivi/DialogAziende.vue";
import DialogMerceologica from "@/components/dialog/archivi/DialogMerceologica.vue";
import DialogCategorie from "@/components/dialog/archivi/DialogCategorie.vue";
import GestioneRicerca from "@/components/intranet/GestioneRicerca2.vue";
import DialogDettCategorie from "@/components/dialog/archivi/DialogDettCategorie.vue";
import DialogEditDeleteFiltro from "@/components/dialog/archivi/DialogEditDeleteFiltro.vue";
import DialogSuffisso from "@/components/dialog/archivi/DialogSuffisso.vue";

import LogOperazioni from "@/views/intranet/LogOperazioni.vue";
import InformazioniTestata from "@/components/intranet/InformazioniTestata.vue";
import objectArchivi from "@/views/intranet/archivi/objectArchivi.js";

export default {
  components: {
    DialogAziende,
    DialogMerceologica,
    LogOperazioni,
    InformazioniTestata,
    GestioneRicerca,
    DialogCategorie,
    DialogDettCategorie,
    DialogEditDeleteFiltro,
    DialogSuffisso,
  },

  mixins: [objectArchivi],
  data: () => ({
    headerProps: {
      sortByText: "Ordina per",
    },
    loadingTabella: false,
    page: 1,
    pageCount: 0,
    search: "",
    searchCat: "",
    headerAnagrafiche: [],
    headerLog: [],
    listaProdotti: [],
    listaProdottiSupp: [],
    listaLog: [],
    filtri: {},
    testata: null,
    testataSel: false,
    tabsSel: true,
    testataSelezionata: null,
    aggiungi: false,
    campoReadOnly: false,
    rules: {
      required: (value) => !!value || "Campo obbligatorio.",
      suffisso: (value) => value >= 0 || "Camo obbligatorio.",
    },
    tabs: 0,
    storico: true,
    //testateSelected: [],
    listaCataloghi: [],
    catalogoPrimario: "",
    cataloghiSecondari: true,
    menuDocDx: {
      show: false,
      x: 0,
      y: 0,
      activatedBy: "",
    },
    menuDocDxF: {
      show: false,
      x: 0,
      y: 0,
      activatedBy: "",
    },
    menuDocDxS: {
      show: false,
      x: 0,
      y: 0,
      activatedBy: "",
    },
    menuDocDxD: {
      show: false,
      x: 0,
      y: 0,
      activatedBy: "",
    },
    codiceMarchioAzienda: "",

    //dialog
    dialogAzienda: false,
    dialogMerceologica: false,
    dialogCategorie: false,
    codiceLinea: "",
    testataCategorie: null,
    dialogDettCategorie: false,
    floatingModal: {
      transformStyle: { transform: "" },
      isPermanent: false,
      isHover: false,
    },
    dialogEditDeleteFilter: false,
    Filtro: {
      nome: "",
      numero: 0,
    },
    dialogTestateFiltri: false,

    //form validate
    valid: true,

    //date
    menu: false,
    date: new Date().toISOString().substr(0, 10),
    dateIT: new Date().toISOString().substr(0, 10),
    menu1: false,
    date1: new Date().toISOString().substr(0, 10),
    dateIT1: new Date().toISOString().substr(0, 10),

    //GESTIONALE FILTRI
    attesaFiltri: false,
    listaParoleChiave: [],
    listaParoleDizionario: [],
    listaNomiFiltri: [],
    listaNomiFiltriSupp: [],
    listaValoriFiltri: [],
    valoreParolaChiave: [],
    filtroSel: [],
    filtriSel: [],
    parolaChiave: "",
    parolaDizionario: "",
    nomeParola: "",
    valoreParola: "",
    listaNumTestateParoleChiave: [],
    listaNumTestateNomiFiltri: [],
    listaNumTestateValoriFiltri: [],

    dialogSuffisso: false,
    TestataSuffisso: null,
    suffisso: -1,
    cambiaSuffisso: "",

    file: null,
  }),

  methods: {
    async caricaDati() {
      this.loadingTabella = true;

      this.listaProdotti = [];
      this.listaProdottiSupp = [];
      let par = {
        CodTestata: this.filtri.CodTestata,
        Descrizione: this.filtri.Descrizione,
      };
      let res = await get_prodotti(par);
      this.listaProdotti = res.data;
      //tabella dinamica --> carico solo i primi 50 elementi
      for (let i = 0; i < this.listaProdotti.length; i++) {
        if (i < 50) this.listaProdottiSupp.push(res.data[i]);
      }

      this.caricaLoghiAziende(); //carico loghi delle aziende
      this.setCataloghi(); //setto colonna cataloghi
      this.setColoreCatalogo(); //setto colori cataloghi

      this.loadingTabella = false;
    },

    async caricaLoghiAziende() {
      for (let i = 0; i < this.listaProdotti.length; i++) {
        let cataloghi =
          this.listaProdotti[i].codTestata.substring(0, 3) +
          " " +
          this.listaProdotti[i].codTestata.substring(
            3,
            this.listaProdotti[i].codTestata.length
          );
        this.listaProdotti[i].codTestataCompleto = cataloghi;
        this.listaProdotti[i].catMerceologica =
          this.listaProdotti[i].catMerceologica.substring(0, 3) +
          " " +
          this.listaProdotti[i].catMerceologica.substring(
            3,
            this.listaProdotti[i].catMerceologica.length
          );
      }
    },

    setCataloghi() {
      this.listaProdotti.forEach((element) => {
        element.listaCataloghi = element.codiceCataloghi.split(",");
      });
    },

    setColoreCatalogo() {
      this.listaProdotti.forEach((element) => {
        element.listaColoriCatalogo = element.coloreRgb.split("|");
      });
    },

    async caricaCataloghi() {
      this.loadingTabella = true;

      this.listaCataloghi = [];
      let res = await get_listaCataloghi();
      this.listaCataloghi = res.data;
      //carico i cataloghi (se esistenti)
      if (this.testata.codiceCataloghi != "" && this.testata.codiceCataloghi != null) {
        let cataloghi = this.testata.codiceCataloghi.split(",");
        //catalogo primario
        this.listaCataloghi.forEach((element) => {
          if (cataloghi[0] == element.codiceCatalogoCapitoli) {
            element.catalogoPrimarioFlag = true;
            this.catalogoPrimario = cataloghi[0];
          }
        });
        let i = 0;
        //catalogo secondario
        this.listaCataloghi.forEach((element) => {
          if (
            cataloghi.includes(element.codiceCatalogoCapitoli) == true &&
            element.catalogoPrimarioFlag == false
          )
            element.catalogoSecondarioFlag = true;
          i += 1;
        });
      }

      this.loadingTabella = false;
    },

    refresh() {
      this.aggiungi = false;
      this.campoReadOnly = false;
      this.tabsSel = true;
      //this.filtri = this.filtriTabelle();
      //this.listaProdottiSupp = [];
      this.resetTabella();
      this.caricaDati();
    },

    ricaricaFiltri() {
      //richiamo metodi figlio dal padre
      this.$refs.gestioneRicerca.caricaDati(true); //ricarico tutti i filtri
      this.$refs.gestioneRicerca.setValoriFiltriTestata(); //assegno quelli già selezionati
    },

    async selectRow(i) {
      this.testata = i;
      this.testata.pathImg = this.testata.img;
      this.tabsSel = false;
      //carico il dettaglio della Testata Prodotto selezionata
      //chiamata per nomeAzienda e cmDes
      let res = await getTestata(this.testata);

      //carico cataloghi
      await this.caricaCataloghi();
      //mi serve per caricare i filtri già selezionati
      let parolaChiave = this.testata.paroleChiave.split(",");
      this.testata.valoreRicerca = parolaChiave[0];
      //carico filtri ricerca
      await this.GetRicerca();
    },

    async selectRowDouble() {
      //carico il dettaglio della Testata Prodotto selezionata
      //chiamata per nomeAzienda e cmDes
      let res = await getTestata(this.testata);
      this.testata = res.data;
      this.setDateTestataProdotto();
      this.testataSelezionata = this.testata;
      this.testataSelezionata.pathImg = this.testata.img;

      //carico cataloghi
      await this.caricaCataloghi();
      //carico filtri ricerca
      await this.GetRicerca();

      this.testataSel = true;
      this.aggiungi = false;
    },

    setDateTestataProdotto() {
      if (this.testata.dataPromozioneIniziale != null) {
        this.date = this.testata.dataPromozioneIniziale.substring(0, 10);
        this.dateIT = this.parseData(this.date);
        this.date1 = this.testata.dataPromozioneFinale.substring(0, 10);
        this.dateIT1 = this.parseData(this.date1);
      }
    },

    async salvaDati() {
      if (this.$refs.form.validate() == false) return;
      else {
        let confirm = await this.$confirm.open("Salva dati", "Salvare i dati correnti?");
        if (!confirm) return;

        if (this.testata.promozione == true)
          if (this.date1 >= this.date) {
            this.testata.dataPromozioneIniziale = this.date;
            this.testata.dataPromozioneFinale = this.date1;
          } else {
            this.$toast.alert("Date inserite non valide");
            return;
          }

        this.salvaCataloghi(); //setto i cataloghi

        if (this.aggiungi === true) {
          //insert
          this.testata.codTestata = this.testata.codTestataCompleto;
          console.log(this.testata);
          let res = await add_testata(this.testata);
          if (res.data == "OK") {
            //this.refresh();
            this.aggiungi = false;
            this.$toast.info("Caricamento testata completato.");
            this.caricaDati(); //carico dati aggiornati
          } else this.$toast.error("Errore. " + res.data);
        } else {
          //update
          let res = await edit_testata(this.testata);
          if (res.data == "OK") {
            //this.refresh();
            this.$toast.info("Dati aggiornati.");
            this.caricaDati(); //carico dati aggiornati
          } else this.$toast.error("Errore. " + res.data);
        }
      }
    },

    salvaCataloghi() {
      this.testata.codiceCataloghi = ""; //resetto i cataloghi
      //catalogo primario
      this.listaCataloghi.forEach((element) => {
        if (element.catalogoPrimarioFlag === true)
          this.testata.codiceCataloghi = element.codiceCatalogoCapitoli;
      });
      //catalogo secondario
      this.listaCataloghi.forEach((element) => {
        if (
          element.catalogoPrimarioFlag === false &&
          element.catalogoSecondarioFlag === true
        )
          this.testata.codiceCataloghi =
            this.testata.codiceCataloghi + "," + element.codiceCatalogoCapitoli;
      });
    },

    setCatPrimario(cat) {
      if (cat.catalogoPrimarioFlag === false) this.catalogoPrimario = "";
      else this.catalogoPrimario = cat.codiceCatalogoCapitoli;
    },

    setCatSecondario() {
      if (this.listaCataloghi.filter((i) => i.catalogoSecondarioFlag === true).length < 2)
        this.cataloghiSecondari = true;
      else this.cataloghiSecondari = false;
    },

    caricaAziende(azienda) {
      if (this.dialogAzienda === false) this.dialogAzienda = true;
      else this.dialogAzienda = false;
      if (azienda.codiceAzienda != "" && azienda.codiceAzienda != null) {
        this.testata.codiceAzienda = azienda.codiceAzienda;
        this.testata.nomeAzienda = azienda.nomeAzienda;
      }
    },

    caricaCatMer(categoria) {
      if (this.dialogMerceologica === false) {
        if (this.testata.catMerceologica != null)
          this.searchCat = this.testata.catMerceologica.substring(0, 3); //setto filtro ricerca categoria merceologica
        this.dialogMerceologica = true;
      } else this.dialogMerceologica = false;
      if (categoria.ctMer != "" && categoria.ctMer) {
        this.testata.catMerceologica =
          categoria.ctMer.substring(0, 3) +
          " " +
          categoria.ctMer.substring(3, categoria.ctMer.length);
        this.testata.cmDes = categoria.cmDes;
      }
    },

    resetTabella() {
      this.testata = null;
      this.testataSel = false;
      this.campoReadOnly = false;
      this.testataSelezionata = null;
      this.catalogoPrimario = "";
      this.tabsSel = true;
    },

    async eliminaTestata() {
      let confirm = await this.$confirm.open("Elimina", "Vuoi eliminare questa testata?");
      if (!confirm) return;

      let res = "";
      res = await delete_testata(this.testata);
      if (res.data == "OK") {
        this.refresh();
      } else this.$toast.error("Errore nell'eliminazione");
    },

    aggiungiTestata() {
      this.testata = {};
      this.aggiungi = true;
      this.testataSel = true;
      this.campoReadOnly = true;
      this.caricaCataloghi();
    },

    modificaTestata() {
      if (this.testata != null) this.selectRowDouble();
    },

    async duplicaTestata() {
      /* let confirm = await this.$confirm.open("Duplica", "Vuoi duplicare questa testata?");
      if (!confirm) return; */

      let par = null;
      par = this.TestataSuffisso;
      par.suffisso_old = this.TestataSuffisso.suffisso;
      par.suffisso = par.suffisso_old;
      par.chiaveRicerca = par.codTestata + par.suffisso;

      this.apriSuffissoTestata();//possibilità cambio suffisso

    },

    //per filtri prodotto
    async GetRicerca() {
      let parola = this.testata.paroleChiave.split(",");
      this.testata.valoreRicerca = parola[parola.length - 1];
      this.testataSelezionata = this.testata;
    },

    onScroll() {
      if (this.listaProdottiSupp.length <= 0) return;

      let nearBottomOfWindow =
        window.scrollY + window.innerHeight >=
        document.documentElement.offsetHeight - 1000;

      if (nearBottomOfWindow === false) return;
      else {
        let first = 0;
        let last = 0;
        //carico altri elementi nella lista testate prodotto
        first = this.listaProdottiSupp.length - 1; //primo indice
        last = this.listaProdotti.splice(first, first + 50).length; //ultimo indice
        for (let i = first; i < last; i++) {
          //carico 50 elementi alla volta
          this.listaProdottiSupp.push(this.listaProdotti[i]);
        }
      }
    },

    apriMenuDocDx(e, item) {
      this.menuDocDxS.show = false;
      e.preventDefault();
      this.codiceMarchioAzienda = item.codiceAzienda;
      this.menuDocDx.show = false;
      this.menuDocDx.x = e.clientX;
      this.menuDocDx.y = e.clientY;
      this.$nextTick(() => {
        this.menuDocDx.show = true;
      });
    },

    apriMarchioAzienda() {
      this.$router.push({
        path: "/marchiAzienda",
        query: { name: this.codiceMarchioAzienda },
      });
    },

    apriMenuDocDxS(e, item) {
      this.menuDocDx.show = false;
      if (this.role == 2) {
        e.preventDefault();
        this.TestataSuffisso = item;
        this.suffisso = item.suffisso;
        this.menuDocDxS.show = false;
        this.menuDocDxS.x = e.clientX;
        this.menuDocDxS.y = e.clientY;
        this.$nextTick(() => {
          this.menuDocDxS.show = true;
        });
      }
    },

    apriSuffissoTestata(value) {
      if (value == 'cambio') this.cambiaSuffisso = value;
      this.dialogSuffisso = true;
    },

    chiudiDialogSuffisso(refresh) {
      this.dialogSuffisso = false;
      this.TestataSuffisso = null;
      this.suffisso = -1;
      this.cambiaSuffisso = "";
      if (refresh)
        this.caricaDati();
    },

    rinominaEliminaFiltro(e, item, num) {
      if (e != undefined) e.preventDefault();
      this.Filtro = {
        nome: item,
        numero: num,
      };
      if (this.dialogEditDeleteFilter === false) this.dialogEditDeleteFilter = true;
      else this.dialogEditDeleteFilter = false;

      if (num == -9999) this.refreshGestioneFiltri(); //ricarico filtri aggiornati
    },

    async salvaDatiInfo() {
      let res = await set_infoTestata(this.testata);
      if (res.data == "OK") this.$toast.info("Informazioni salvate");

    },

    setCategorie(codiceLinea, testata) {
      this.codiceLinea = codiceLinea;
      this.testataCategorie = this.testata;
      if (this.dialogCategorie === false) this.dialogCategorie = true;
      else {
        this.testata = testata;
        this.dialogCategorie = false;
      }
    },

    //converto data picker
    parseData(data) {
      if (data == undefined || data.length == 0) return "";

      const [year, month, day] = data.split("-");
      return `${day}/${month}/${year}`;
    },

    resetDate() {
      //prendere poi la data dal model senza fare il reset
      this.date = new Date().toISOString().substr(0, 10);
      this.dateIT = this.parseData(this.date);
      this.date1 = new Date().toISOString().substr(0, 10);
      this.dateIT1 = this.parseData(this.date1);
    },

    async apriCategorie() {
      //ricavo codiceLinea catalogo
      let res = await get_codiceLinea(this.testata);
      if (res.data != "") {
        this.codiceLinea = res.data;
        this.testataCategorie = this.testata;
        if (this.dialogCategorie === false) this.dialogCategorie = true;
        else {
          this.testata = null;
          this.dialogCategorie = false;
        }

        this.floatingModal.isHover = false;
        this.dialogDettCategorie = false;
      }
    },

    async showOver(item, cat) {
      if (cat === undefined || cat === "") {
        this.floatingModal.isHover = false;
        this.dialogDettCategorie = false;
        return;
      }
      if (item === false) {
        this.floatingModal.isHover = false;
        this.dialogDettCategorie = false;
        return;
      }
      if (this.floatingModal.isPermanent) return;

      if (item.distintaBaseDinamica) {
        return;
      }

      let res = await get_codiceLinea(item);
      if (res.data != "") {
        this.codiceLinea = res.data;
        this.testataCategorie = item;
      }
      if (this.dialogCategorie === false) {
        this.dialogDettCategorie = true;
        this.floatingModal.isHover = true;
      } else {
        this.dialogDettCategorie = false;
        this.floatingModal.isHover = false;
      }
    },

    showPermanent(item) {
      if (!this.auth) return;

      if (this.floatingModal.isPermanent) {
        this.floatingModal.isPermanent = false;
      }

      this.floatingModalPermanent(true);
    },

    floatingModalPermanent(vis) {
      this.floatingModal.isPermanent = vis;
    },

    /* updateMousePos(p) {
      if (
        !this.floatingModal.isPermanent &&
        this.floatingModal.isHover &&
        document.getElementById("floating-div")
      ) {
        let y = p.clientY - 50;
        if (y + document.getElementById("floating-div").clientHeight > window.innerHeight)
          y = window.innerHeight - document.getElementById("floating-div").clientHeight;

        this.floatingModal.transformStyle = {
          transform:
            "translate3d(" +
            (p.clientX - document.getElementById("floating-div").clientWidth - 32) +
            "px," +
            y +
            "px,0)",
        };
      }
    }, */

    /* GESTSIONALE FILTRI */
    async refreshGestioneFiltri() {
      this.listaParoleChiave = [];
      this.listaParoleDizionario = [];
      this.listaNomiFiltri = [];
      this.listaValoriFiltri = [];
      this.valoreParolaChiave = [];
      this.parolaChiave = "";
      this.parolaDizionario = "";
      this.nomeParola = "";
      this.valoreParola = "";
      this.filtroSel = [];
      this.filtriSel = [];
      this.listaNumTestateParoleChiave = [];
      this.listaNumTestateNomiFiltri = [];
      this.listaNumTestateValoriFiltri = [];
      this.listaNomiFiltriSupp = [];
      await this.getListaParoleChiave(); //parole chiave
      await this.getListaFiltri(); //filtri nomi
    },
    //PAROLE CHIAVE
    async getListaParoleChiave() {
      this.attesaFiltri = true;

      this.listaParoleChiave = [];
      this.listaNumTestateParoleChiave = [];
      this.filtroSel = [];
      this.filtriSel = [];
      this.valoreParolaChiave = [];
      //carico parole chiave
      let res = await get_paroleChiaveRicerca("");
      this.listaParoleChiave = res.data;
      //carico numero di testate per ogni parola
      let num = await get_numeroFiltriParoleChiaveArchivi();
      this.listaNumTestateParoleChiave = num.data;

      this.attesaFiltri = false;
    },
    setListaNumTestateParoleChiave(valore) {
      this.listaNumTestateParoleChiave = [];
      this.listaNumTestateParoleChiave[0] = valore;
    },

    //DIZIONARIO
    async getListaDizionario() {
      this.attesaFiltri = true;
      this.listaParoleDizionario = [];

      this.attesaFiltri = false;
    },

    //NOMI FILTRI
    async getListaFiltri() {
      this.attesaFiltri = true;
      this.filtriSel = [];
      this.listaNomiFiltri = [];
      this.listaValoriFiltri = [];
      this.listaNumTestateNomiFiltri = [];
      //CARICO NOMI FILTRI
      if (this.valoreParolaChiave.length > 0 && this.filtroSel.length <= 0) {
        this.listaParoleChiave = this.listaParoleChiave.filter(
          (i) => i === this.valoreParolaChiave[0]
        );
        let res = await get_filtriParoleChiave(this.valoreParolaChiave[0]);
        this.listaNomiFiltri = res.data;
      } else {
        //FILTRO PAROLE CHIAVE E NOMI FILTRI E CARICO VALORI FILTRI
        if (this.valoreParolaChiave.length > 0 && this.filtroSel.length > 0) {
          this.listaParoleChiave = this.listaParoleChiave.filter(
            (i) => i === this.valoreParolaChiave[0]
          );
          this.listaNomiFiltri = this.listaNomiFiltriSupp.filter(
            (i) => i === this.filtroSel[0]
          );
          let res1 = await get_filtriParoleChiave(
            "valori/" + this.valoreParolaChiave[0] + "/" + this.filtroSel[0]
          );
          this.listaValoriFiltri = res1.data;
        } else {
          //RICARICO TUTTO TRANNE VALORI FILTRI
          this.listaValoriFiltri = [];
          this.valoreParolaChiave = [];
          await this.getListaParoleChiave(); //ricarico parole chiave
          let res = await get_filtriParoleChiaveArchivi(); //ricarico nomi filtro
          this.listaNomiFiltri = res.data;
        }
      }
      //carico numero di testate per ogni nome
      let res = await get_numeroNomiFiltri();
      this.listaNumTestateNomiFiltri = res.data;

      this.attesaFiltri = false;
    },
    setListaNumTestateNomiFiltri(valore) {
      this.listaNumTestateNomiFiltri = [];
      this.listaNumTestateNomiFiltri[0] = valore;
    },

    //VALORI FILTRI
    async getListaValoriFiltri() {
      this.attesaFiltri = true;
      this.listaValoriFiltri = [];
      this.listaNumTestateValoriFiltri = [];
      //SOLO VALORI FILTRI --> DA PAROLA CHIAVE + NOME FILTRO
      if (this.valoreParolaChiave.length > 0 && this.filtroSel.length > 0) {
        this.listaNomiFiltri = this.listaNomiFiltri.filter(
          (i) => i === this.filtroSel[0]
        );
        let res = await get_filtriParoleChiave(
          "valori/" + this.valoreParolaChiave[0] + "/" + this.filtroSel[0]
        );
        this.listaValoriFiltri = res.data;
      } else {
        //SOLO VALORI FILTRI --> DA NOME FILTRO
        if (this.filtroSel.length > 0) {
          this.listaNomiFiltri = this.listaNomiFiltri.filter(
            (i) => i === this.filtroSel[0]
          );
          let res = await get_valoriFiltri(this.filtroSel[0]);
          this.listaValoriFiltri = res.data;
        } else {
          await this.getListaFiltri(); //ricarico nomi filtro
          this.filtroSel = [];
          this.listaValoriFiltri = [];
        }
      }
      this.listaNomiFiltriSupp = this.listaNomiFiltri;
      //carico numero di testate per ogni valore
      let res = await get_numeroValoriFiltri(
        this.valoreParolaChiave[0] + "/" + this.filtroSel[0]
      );
      this.listaNumTestateValoriFiltri = res.data;

      this.attesaFiltri = false;
    },

    async addFiltro(filtro, num) {
      this.attesaFiltri = true;

      let res = await add_filtro(num, filtro);
      //ricarico filtri aggiornati
      if (res.data == "OK") this.refreshGestioneFiltri();
      else return;

      this.attesaFiltri = false;
    },

    apriTestate(valoreFiltro) {
      //nuova pagina con tabella testate filtrate per valoreFiltro
      window.open(
        window.location.origin + "/ricerca/" + valoreFiltro + "/prodotti",
        "_blank"
      );
    },

    async uploadExcel() {
      if (this.file == null) {
        this.$toast.alert("Nessun file selezionato");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.file);
      let res = await carica_file_filtri(formData);
      if (res.status != 200) {
        this.$toast.alert("Errore nel caricamento del file. " + res.data);
      } else
        this.$toast.info("Dati caricati");
    },

    handleFileChange(e) {
      this.file = e.target.files[0];
    },
  },

  created() {
    this.dateIT = this.parseData(this.date);
    this.dateIT1 = this.parseData(this.date1);

    //window.addEventListener("scroll", this.scroll);
    //window.addEventListener("mousemove", this.updateMousePos);
  },
  beforeDestroy() {
    //window.removeEventListener("scroll", this.scroll);
    //window.removeEventListener("mousemove", this.updateMousePos);
  },

  mounted() {
    document.addEventListener("wheel", this.onScroll);
    this.headerAnagrafiche = this.headerTabellaTestata();
    this.headerLog = this.headerTabellaLog();
    this.filtri = this.filtriTabelle();
    this.refreshGestioneFiltri(); //carico gestione filtri
  },

  watch: {
    tabs() {
      if (this.tabs == 3)
        if (this.testata.informazioni != "" && this.testata.informazioni != null)
          this.testata.numLogInfo = 2;
        //add new log
        else this.testata.numLogInfo = 3; //edit log
    },
    date(val) {
      this.dateIT = this.parseData(val);
    },
    date1(val) {
      this.dateIT1 = this.parseData(val);
    },
  },

  computed: {
    role() {
      return this.$store.getters["user/getRole"];
    },
  },
};
</script>

<style scoped>
#buttonAnnulla {
  position: fixed;
  bottom: 2vh;
  right: 15vh;
}

#buttonConferma {
  position: fixed;
  bottom: 2vh;
  right: 2vh;
}

#riga {
  position: fixed;
  bottom: 8%;
}

.outside {
  width: 14vh;
  height: 14vh;
  position: relative;
  display: table;
}

.inside {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: white;
}

.titolo {
  font-size: 16px;
}
</style>
