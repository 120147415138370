<template>
  <v-card style="height: 600px" class="elevation-0">
    <filtro @filtroDati="filtroDati"></filtro>

    <div>
      <v-data-table
        :headers="headers"
        :items="getDati"
        item-key="name"
        class="elevation-0"
        disable-pagination
        :loading="attesa"
        hide-default-footer
        height="550"
      >
        <!-- Personalizzo la colonna articolo -->
        <!-- eslint-disable-next-line -->
        <template v-slot:item.dtEvasioneStoccaggio="{ item }">
          {{ $i2kFunctions.formatISODate(item.dtEvasioneStoccaggio) }}
        </template>

        <template slot="body.append">
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td style="text-align: right">
              Totale:
              <span>
                <b>{{ totRicevuto }}</b>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import { get_mastrinoForTerzisti } from "@/api/api.catalogo.js";
import filtro from "@/components/ui/FiltroDettaglioPrezzi.vue";

export default {
  components: { filtro },

  props: {
    aggiorna: { type: Boolean, default: false },
    cdArticolo: { type: String, default: "" },
  },
  data: () => ({
    headers: [
      {
        text: "Ragione Sociale",
        value: "ragioneSociale",
        sortable: false,
        width: "28%",
      },
      {
        text: "Numero DDT",
        value: "nrBolla",
        sortable: false,
        align: "end",
      },
      { text: "Data DDT", value: "dtEvasioneStoccaggio", sortable: false },
      {
        text: "Quantità",
        value: "carico",
        sortable: false,
        align: "end",
      },
    ],

    dati: [],
    totRicevuto: 0,

    attesa: false,

    datiFiltro: {},
  }),

  methods: {
    async caricaDati() {
      this.attesa = true;

      // Reset
      this.dati = [];
      this.totRicevuto = 0;

      try {
        let res = await get_mastrinoForTerzisti(this.cdArticolo);

        this.dati = res.data;
        for (let index = 0; index < this.dati.length; index++) {
          this.totRicevuto += parseFloat(this.dati[index].carico);
        }
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    filtroDati(par) {
      this.datiFiltro = par;

      //this.caricaDati();
    },

    resetTot() {
      this.totRicevuto = 0;
    },
  },

  mounted() {
    this.dataDa = new Date(new Date().getFullYear(), 0, 1)
      .toISOString()
      .substring(0, 10);
    this.dataA = new Date().toISOString().substring(0, 10);

    this.dataConsegnaFormat = this.$i2kFunctions.formatISODate(this.dataDa);
    this.dataConsegnaFormat2 = this.$i2kFunctions.formatISODate(this.dataA);
    //this.caricaDati();
  },

  watch: {
    aggiorna() {
      this.caricaDati();
    },

    dataDa() {
      this.dataConsegnaFormat = this.$i2kFunctions.formatISODate(this.dataDa);
    },
    dataA() {
      this.dataConsegnaFormat2 = this.$i2kFunctions.formatISODate(this.dataA);
    },
  },

  computed: {
    getDate() {
      return this.$i2kFunctions.formatISODate(
        new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
      );
    },

    getDati() {
      if (
        this.datiFiltro.dataConsegna == undefined ||
        this.datiFiltro.dataConsegna2 == undefined ||
        this.datiFiltro.dataConsegna == "" ||
        this.datiFiltro.dataConsegna2 == ""
      )
        return this.dati;
      //filtro sulle date
      this.resetTot();
      let dataDa = new Date(this.datiFiltro.dataConsegna);
      let dataA = new Date(this.datiFiltro.dataConsegna2);

      return this.dati.filter((dato) => {
        let dataEvasione = new Date(dato.dtEvasioneStoccaggio);
        if (dataEvasione >= dataDa && dataEvasione <= dataA) {
          this.totRicevuto += dato.carico;
          return true;
        } else return false;
      });
    },
  },
};
</script>