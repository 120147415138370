<template >
  <div style="width: 100%; height: 100%">
    <!-- Filtri -->
    <FilterConsegne ref="filtro" @caricaDati="caricaDati" @caricaDatiConsegna="caricaDatiConsegna"
      @cercaLocalita="cercaLocalita" :dati="dati" :attesa="attesa" @autistiSel="autistiSel" :autisti="autisti">
    </FilterConsegne>

    <!-- Mappa -->
    <div v-show="!attesa" class="google-map" :id="mapName"></div>

  </div>
</template>

<script>
import FilterConsegne from "@/components/intranet/FilterConsegne.vue";
import gmapsInit from "@/utils/maps";

import {
  get_consegne,
  get_consegneSingola,
  get_gps,
  get_consegneImpostazioni
} from "@/api/api.intranet.js";

export default {
  components: {
    FilterConsegne
  },

  data: () => ({
    dati: [],
    datiGps: [],
    mapName: "google-map",
    map: null,
    bounds: null,
    infoWindow: null,
    markers: [],
    polylines: [],
    attesa: false,
    autisti: [],
    dataSel: null,
    impostazioni: {},
  }),

  methods: {
    async caricaDati(data) {
      if (this.attesa) return;

      // Reset
      this.dati = [];
      this.autisti = [];
      this.impostazioni = {}
      this.pulisciMappa();
      this.$refs.filtro.pulisci();

      this.attesa = true;

      this.dataSel = data;

      // Ricavo i dati
      try {
        let resp = await get_consegne(data);
        this.dati = resp.data;

        // Autisti
        for (let index = 0; index < this.dati.length; index++) {
          this.autisti.push(this.dati[index].autista);
        }

        // Impostazioni generali
        let resImpostaz = await get_consegneImpostazioni("generali");
        this.impostazioni = resImpostaz.data;


        // Mappa
        this.caricaMappa();
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    async caricaDatiConsegna(data, autista) {
      // Ricavo l'indice della consegna autista
      let rowIndex = this.dati.indexOf(
        this.dati.find((f) => f.autista == autista)
      );

      // Reset
      this.pulisciMappa();
      this.dati[rowIndex].listaConsegne = [];

      this.attesa = true;

      // Ricavo i dati
      try {

        let resp = await get_consegneSingola(data + "/" + autista);

        let consegna = resp.data;

        // Devo aggiornare la riga su this.dati con i dati ricavati
        this.dati.splice(rowIndex, 1, consegna);

        // Mappa
        this.caricaMappa();
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },



    caricaMappa() {
      // Ciclo sulle consegne
      for (let index = 0; index < this.dati.length; index++) {
        if (!!this.dati[index].listaConsegne == false) continue;

        // Controllo filtro autisti
        if (this.autisti.indexOf(this.dati[index].autista) == -1) continue;

        // Marker
        this.addMarker(this.dati[index].listaConsegne);

        // Linee percorso
        this.addPolyline(
          this.dati[index].punti,
          this.dati[index].geometry,
          this.dati[index].colore
        );

      }

      // Gps
      this.caricaDatiGps();
    },

    async caricaDatiGps() {
      // Controllo gps
      if (this.impostazioni.gps == false) return;

      // Ricavo i dati gps
      let res = await get_gps(this.dataSel);

      this.datiGps = res.data;

      // Marker gps
      this.addMarkerGps(this.datiGps);
    },


    pulisciMappa() {
      // Marker
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];

      // Polilinee
      for (let i = 0; i < this.polylines.length; i++) {
        this.polylines[i].setMap(null);
      }
      this.polylines = [];
    },

    addMarker(markerCoord) {
      let textMarkerSwap = "";

      // Img marker (svg)
      let MAP_MARKER =
        "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z";

      let cont = 0;

      // Ciclo sulle coordinate
      markerCoord.forEach((coord, i) => {
        const position = new google.maps.LatLng(
          coord.latitudine,
          coord.longitudine
        );

        // ---- Controllo partenza\arrivo
        if (cont == 0) {
          // Partenza
          const marker = new google.maps.Marker({
            position,
            map: this.map,
            icon: { url: "/img/homeMap.png" },
            label: {
              color: "#FFF",
              fontWeight: "bold",
              fontSize: "14px",
              text: " ",
            },
          });
        } else if (cont == markerCoord.length - 1) {
          // Arrivo
        } else {
          // Consegne
          textMarkerSwap = coord.prog.toString();

          let colore = coord.fuoriOrario ? "rgba(0,0,0,1)" : coord.marker;

          const marker = new google.maps.Marker({
            position,
            map: this.map,
            label: {
              color: "#FFF",
              fontWeight: "bold",
              fontSize: "15px",
              text: textMarkerSwap,
            },

            icon: {
              path: MAP_MARKER,
              scale: 1.4,
              fillColor: colore, // Use RGBa function
              fillOpacity: 1,
              labelOrigin: new google.maps.Point(0, -30),
            },
          });

          let self = this;

          let contentString =
            "<div>" +
            '<div style="background-color: #48b5e9;">' +
            '<h1 style="color:#FFF; padding:5px;">' +
            coord.ragioneSociale +
            "<br>" +
            "</h1>" +
            '<h3 style="padding: 3px;">' +
            coord.indirizzo +
            " - " +
            coord.localita +
            "<br>Orari:" +
            coord.apertura +
            " </h3>" +
            "</div>" +
            '<h3 style="padding:5px;">' +
            coord.colli +
            "</h3>" +
            '<h2 style="padding:5px;">' +
            "Peso: " +
            coord.peso +
            " (kg) " +
            "<span style='padding-left:50px;'>Arrivo:" +
            self.$functionsApp.getTimeFromDate(coord.tempoPrevisto) +
            "</span></h2>" +
            "</div>";

          // Fumetto
          google.maps.event.addListener(marker, "mouseover", function () {
            self.infoWindow.setContent(contentString);
            self.infoWindow.open(self.map, this);
          });
          google.maps.event.addListener(marker, "mouseout", function () {
            self.infoWindow.close();
          });

          // Inserisco il marker
          this.markers.push(marker);
        }
        this.map.fitBounds(this.bounds.extend(position));

        cont++;
      });

      // Imposto lo zoom
      this.map.setZoom(9);
    },

    addPolyline(arrPunti, geometry, colore) {

      let poly = null

      if (geometry) {
        // geometry
        poly = new window.google.maps.Polyline({
          path: google.maps.geometry.encoding.decodePath(geometry),
          strokeColor: colore,
        });
      }
      else {

        // Punti
        let punti = arrPunti.split(";");

        let lineVertices = new Array();

        for (let i = 0; i < punti.length - 1; i++) {
          if (punti[i] == "") continue;

          lineVertices[i] = new window.google.maps.LatLng(
            parseFloat(punti[i].split(",")[0]),
            parseFloat(punti[i].split(",")[1])
          );
        }

        poly = new window.google.maps.Polyline({
          path: lineVertices,
          strokeColor: colore,
        });
      }

      poly.setMap(this.map);

      this.polylines.push(poly);
    },

    addMarkerGps(markerCoord) {
      // Img marker
      let MAP_MARKER = google.maps.SymbolPath.CIRCLE;

      // Ciclo sulle coordinate
      markerCoord.forEach((coord, i) => {
        const position = new google.maps.LatLng(
          coord.latitudine,
          coord.longitudine
        );

        // 25102021 disattivato (x riattivare bisogna commentare il codice sotto)
        coord.sosta = false;

        let colore = coord.sosta
          ? "rgba(30, 144, 255, 1)"
          : "rgba(50, 205, 50, 1)";

        let scala = coord.sosta ? 7 : 4;

        let zIndex = coord.sosta ? 1 : 0;

        let tipo = coord.sosta
          ? "Sosta " + coord.tempoSosta.split("T")[1].split(".")[0]
          : "Viaggio";

        const marker = new google.maps.Marker({
          position,
          map: this.map,
          text: coord.data,
          zIndex: zIndex,

          icon: {
            path: MAP_MARKER,
            scale: scala,
            fillColor: colore,
            fillOpacity: 1,
            strokeWeight: 0,
          },
        });

        let self = this;

        let contentString =
          "<div>" +
          '<div style="background-color: #48b5e9;">' +
          '<h1 style="color:#FFF; padding:5px;">' +
          self.$i2kFunctions.DateFormat(new Date(coord.data)) +
          "</h1>" +
          "<br>" +
          '<h2 style="padding:5px;">' +
          tipo +
          "</h2>" +
          "</div>" +
          '<h3 style="padding:5px;">' +
          "Velocità :" +
          coord.speed +
          "<br>";

        contentString += "</h3>" + "</div>";

        // Fumetto
        google.maps.event.addListener(marker, "mouseover", function () {
          self.infoWindow.setContent(contentString);
          self.infoWindow.open(self.map, this);
        });
        google.maps.event.addListener(marker, "mouseout", function () {
          self.infoWindow.close();
        });

        // Inserisco il marker
        this.markers.push(marker);

        this.map.fitBounds(this.bounds.extend(position));
      });
    },

    toTimeString(seconds) {
      return new Date(seconds * 1000)
        .toUTCString()
        .match(/(\d\d:\d\d:\d\d)/)[0];
    },

    autistiSel(autisti) {
      this.autisti = autisti;

      // Reset
      this.pulisciMappa(true);

      this.caricaMappa();
    },

    cercaLocalita(coord) {
      if (!!coord == false || Object.keys(coord).length == 0) return;

      // Tengo il giro visibile
      // Reset
      //this.pulisciMappa();

      // Img marker
      let MAP_MARKER = google.maps.SymbolPath.BACKWARD_OPEN_ARROW;

      const position = new google.maps.LatLng(
        coord.latitudine,
        coord.longitudine
      );

      let zIndex = 1;

      const marker = new google.maps.Marker({
        position,
        map: this.map,
        text: coord.data,
        zIndex: zIndex,

        icon: {
          path: MAP_MARKER,
          scale: 10,
          fillOpacity: 1,
          strokeWeight: 0,
        },
      });

      // Inserisco il marker
      this.markers.push(marker);

      this.map.fitBounds(this.bounds.extend(position));
    },

    async initMappa() {
      // --------- Creazione mappa --------
      if (this.map == null) {
        const google = await gmapsInit(this.$load.getGoogleKey());

        const element = document.getElementById(this.mapName);
        const options = {
          zoom: 7,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          scrollwheel: true,
        };

        this.map = new google.maps.Map(element, options);

        this.bounds = new google.maps.LatLngBounds();
        this.infoWindow = new google.maps.InfoWindow();
      }
    },

  },

  mounted() {
    this.pulisciMappa();
    this.initMappa();
  },
};
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 90vh;
  margin: 0 auto;
  background: gray;
  color: black;
}
</style>