import {
    set_comandoPLC
} from "@/api/api.intranet.js";

export default {

    methods: {

        async esegui(data) {
            let res = await set_comandoPLC(data);
            if (res.data == null) return ("Errore. Impossibile inviare il comando");
            else if (res.data.Message != null && res.data.Message != undefined)
                return (res.data.Message);
            else return (res.data);
        },

        async esegui_read(data) {
            let res = await set_comandoPLC(data);
            return res.data
        },

        async estrai(cassetto) {
            let data = {
                comando: "EXTRACT_CMD#",
                cassetto: cassetto,
                baia: 1,
            };
            return await this.esegui(data);
        },

        async riponi() {
            let data = {
                comando: "STORE_CMD#",
                cassetto: 1,
                baia: 1,
            };
            return await this.esegui(data);
        },

        async info() {
            let data = {
                comando: "STATUS_REQUEST_CMD#",
                cassetto: 0,
                baia: 1,
            };
            return this.esegui_read(data);
        },

        async altre_info(mission) {
            let data = {
                comando: "REQUEST_MISSION_TRAY#",
                cassetto: 0,
                baia: 1,
                missionType: mission,
            };
            return this.esegui_read(data);
        },
    }
}