<template>
  <v-navigation-drawer width="260" fixed app v-model="leftBarVis" mobile-breakpoint="400"
    :clipped="$vuetify.breakpoint.mdAndUp" right>
    <v-card-title class="pa-1 ml-2">
      <v-select v-model="origineSel" :items="documenti" label="Origine" style="max-width: 230px"
        v-on:change="caricaDati()" />

      <v-select :items="areaArr" item-text="area" item-value="area" label="Area" v-model="areaSel"
        style="max-width: 230px" @change="tipoDocSel = {}" />
      <v-select :items="getTipoDoc" item-text="tipoDoc" label="Tipo" v-model="tipoDocSel" return-object
        style="max-width: 230px" />
      <v-select :items="annoArr" label="Anno" v-model="annoSel" style="max-width: 170px" />

      <v-text-field label="Numero doc." style="max-width: 170px" v-model="nrDoc" :disabled="autoNrDoc" clearable />

      <v-text-field label="Cod. Cliente" style="max-width: 170px" v-model="clienteSel.cdAnagrafica"
        v-on:keyup.enter="cercaCliente()" clearable append-icon="search" @click:append="zoomCliente()" hide-details />

      <v-text-field readonly v-model="clienteSel.ragioneSociale" style="max-width: 240px" disabled hide-details
        class="pt-0 pb-4" />

      <v-text-field label="Nr. Rimanenza" style="max-width: 200px" class="ml-2" v-model="nrRimanenza"
        v-show="origineSel == 'CodBarError'" />

      <v-text-field label="Nr. Pagina" style="max-width: 200px" class="ml-2" v-model="nrPagina"
        v-show="origineSel == 'CodBarError'" />

      <v-btn @click="caricaDati()" style="width: 100%" class="mb-4">Visualizza
        <v-icon>arrow_forward</v-icon>
      </v-btn>

      <v-btn @click="elimina()" style="width: 45%;"> Elimina </v-btn>
      <v-spacer />
      <v-btn @click="salva()" v-show="origineSel != 'Archivio'" class="btn-conferma" style="width: 45%;"> Salva </v-btn>
    </v-card-title>
  </v-navigation-drawer>
</template>

<script>
import { get_areaDoc, get_cliente } from "@/api/api.intranet.js";

export default {
  props: {
    check_selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterpanel: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data: () => ({
    areaArr: [],
    annoArr: [],

    areaSel: "",
    tipoDocSel: {},
    annoSel: "",
    nrDoc: "",
    clienteSel: { cdAnagrafica: "", ragioneSociale: "" },
    nrRimanenza: 0,
    nrPagina: 0,

    documenti: ["Archivio", "Scansioni", "CodBarError"],
    origineSel: "",
  }),

  methods: {
    inizializza() {
      for (let i = 2007; i <= this.$i2kFunctions.getYear(); i++) {
        this.annoArr.push("", i.toString());
      }

      this.origineSel = "Scansioni";

      this.annoSel = this.$i2kFunctions.getYear(new Date()).toString();

      this.caricaAree();
    },

    async caricaAree() {
      try {
        let res = await get_areaDoc(this.origineSel);

        this.areaArr = res.data;
        this.areaArr.unshift("");
      } catch (error) {
        console.log(error);
      }
    },

    caricaDati() {
      let par = "";

      // Controllo archivio
      if (this.origineSel == "Archivio") {
        par = {
          Area: this.areaSel,
          TipoDocumento: this.tipoDocSel.tipoDoc || "",
          Anno: this.annoSel,
          NrDocumento: parseInt(this.nrDoc || 0),
          CdAnagrafica: this.clienteSel.cdAnagrafica,
        };
      } else if (this.origineSel == "Scansioni") {
        par = this.origineSel;
      } else if (this.origineSel == "CodBarError") {
        par = this.origineSel;
        this.nrRimanenza = 1;
      }

      this.$emit("caricaDati", this.origineSel, par);
    },

    getParam() {
      let par = {
        Area: this.areaSel,
        TipoDocumento: this.tipoDocSel.tipoDoc || "",
        NrDocAuto: this.tipoDocSel.autoNrDocumento || false,
        Anno: this.annoSel,
        NrDocumento: parseInt(this.nrDoc || 0),
        CdAnagrafica: this.clienteSel.cdAnagrafica,
        NrRimanenza: parseInt(this.nrRimanenza || 0),
        NrPagina: parseInt(this.nrPagina || 0),
      };

      return par;
    },

    salva() {
      this.$emit("salva", this.origineSel, this.getParam());
    },

    async cercaCliente() {
      try {
        let res = await get_cliente(this.clienteSel.cdAnagrafica);

        this.clienteSel.cdAnagrafica = res.data.cdAnagrafica;
        this.clienteSel.ragioneSociale = res.data.ragioneSociale;
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    elimina() {
      this.$emit("elimina", this.origineSel, this.getParam());
    },

    zoomCliente() {
      this.$store.commit("app/setDialog", "clienti");
    },

    pulisciFiltro() {
      this.nrDoc = "";
      this.clienteSel.cdAnagrafica = "";
      this.clienteSel.ragioneSociale = "";
    },
  },

  mounted() {
    this.inizializza();

    this.caricaDati();
  },

  watch: {
    cliente(val) {
      // Controllo se l'utente ha pulito la textbox (con la x)
      if (!!val == false) {
        this.clienteSel.cdAnagrafica = "";
        this.clienteSel.ragioneSociale = "";
      }
    },

    origineSel(old, newVal) {
      if (old == "CodBarError" || newVal == "CodBarError") {
        this.caricaAree();
      }
    },

    dialogClienti(val) {
      // Controllo se è stato selezionato un cliente
      if (Object.keys(val).length > 0 && val.cdAnagrafica.length > 0) {
        this.clienteSel = Object.assign({}, val);
      }
    },
  },

  computed: {
    leftBarVis: {
      get() {
        return this.$store.getters["app/filterDrawerVisibility"];
      },
      // NOTA: la set viene impostata da vuetify
      set(valore) {
        // this.$store.commit("app/setFilterDrawer", valore);
      },
    },

    cliente() {
      return this.clienteSel.cdAnagrafica;
    },

    getTipoDoc() {
      let filtro = this.areaArr.filter((f) => f.area == this.areaSel);

      if (filtro.length > 0) {
        let res = this.areaArr.filter((f) => f.area == this.areaSel)[0]
          .tipoDocumenti;

        res.unshift("");
        return res;
      }
      return [];
    },

    getClienti() {
      return this.$store.getters["app/getClienti"];
    },

    autoNrDoc() {
      return !!this.tipoDocSel.autoNrDocumento;
    },

    dialogClienti() {
      return this.$store.getters["app/getDialogResult"];
    },
  },
};
</script>



