<template>
  <div>
    <v-dialog v-model="dialogHelp" width="50%" style="min-height: 100% !important" v-if="dialogHelp" persistent>
      <v-card style="min-width: 100%; min-height: 100%">
        <v-system-bar window color="white">
          <span class="title"> Info utili</span>
          <v-spacer></v-spacer>
          <v-btn small icon>
            <v-icon v-on:click="chiudiHelp">close</v-icon></v-btn>
        </v-system-bar>

        <v-form ref="form">
          <v-container>
            <!-- style="visibility: hidden;" class="ql-toolbar ql-snow"-->
            <vue-editor :editorToolbar="customToolbar" v-if="this.role == 2" placeholder="Scrivi qui..."
              :disabled="this.role != 2" v-model="Help.Informazioni1"></vue-editor>

            <v-textarea v-if="this.role != 2" v-html="Help.Informazioni1" :disabled="this.role != 2" readonly
              auto-grow></v-textarea>
          </v-container>
        </v-form>

        <v-card-actions style="margin-top: 0px">
          <v-spacer></v-spacer>
          <v-btn v-if="this.role == 2" color="primary" text v-on:click="salva">
            Salva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { get_help, set_help } from "@/api/api.user.js";

export default {
  props: {
    dialogHelp: { type: Boolean, default: false },
  },

  components: {
    VueEditor,
  },

  data: () => ({
    Help: {
      Id: 0,
      Informazioni1: "",
      CodAnagrafica: "",
      HelpName: "",
    },

    rules: {
      required: (value) => !!value || "Campo obbligatorio.",
    },

    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"],
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      //[{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      //["clean"],
    ],
  }),

  methods: {
    async caricaDati() {
      if (!this.dialogHelp) return;

      // Reset
      this.Help = {};

      try {
        if (this.getUrlPage().substring(0, 8) == "/ricerca") {
          let url = this.getUrlPage().substring(0, 8);

          let res = await get_help(url);

          this.Help = {
            Id: res.data.id,
            Informazioni1: res.data.informazioni1,
            CodAnagrafica: res.data.codAnagrafica,
            HelpName: res.data.helpName,
          };
        } else {
          let res = await get_help(this.getUrlPage());

          this.Help = {
            Id: res.data.id,
            Informazioni1: res.data.informazioni1,
            CodAnagrafica: res.data.codAnagrafica,
            HelpName: res.data.helpName,
          };
        }
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async salva() {
      try {
        if (!this.$refs.form.validate()) return;

        let confirm = await this.$confirm.open(
          "Conferma",
          "Vuoi salvare i dati?"
        );

        if (!confirm) return;

        this.Help.HelpName = this.getUrlPage();

        let res = await set_help(this.Help);

        if (res.data == "OK") {
          this.$toast.info("Informazioni salvate con successo");
          this.chiudiHelp();
        } else if (res.data == "NO") {
          this.$toast.alert(
            "Errore di autenticazione. Impossibile cambiare le informazioni"
          );
        } else {
          this.$toast.alert("Errore. Impossibile cambiare le informazioni");
        }
      } catch (error) {
        this.$toast.alert("Errore. Impossibile cambiare le informazioni");
      }

      this.caricaInformazioni();
    },

    chiudiHelp() {
      this.$emit("chiudiHelp");
    },

    getUrlPage() {
      return this.$store.getters["app/getUrlPage"];
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    role() {
      return this.$store.getters["user/getRole"];
    },
  },

  watch: {
    dialogHelp(val) {
      if (val) {
        this.caricaDati();
      }
    },
  },
};
</script>
