var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"1"}},[_c('v-text-field',{staticStyle:{"font-weight":"bold"},attrs:{"hide-details":"","label":"CodTestata","readonly":true},model:{value:(_vm.testata.codTestataCompleto),callback:function ($$v) {_vm.$set(_vm.testata, "codTestataCompleto", $$v)},expression:"testata.codTestataCompleto"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"1"}},[_c('v-text-field',{staticStyle:{"font-weight":"bold"},attrs:{"hide-details":"","label":"Suffisso","type":"number","readonly":true},model:{value:(_vm.testata.suffisso),callback:function ($$v) {_vm.$set(_vm.testata, "suffisso", $$v)},expression:"testata.suffisso"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"10"}},[_c('v-text-field',{staticStyle:{"font-weight":"bold"},attrs:{"hide-details":"","label":"Descrizione","readonly":true},model:{value:(_vm.testata.descrizione),callback:function ($$v) {_vm.$set(_vm.testata, "descrizione", $$v)},expression:"testata.descrizione"}})],1)],1),_c('v-layout',{staticClass:"mt-3 mb-3",attrs:{"justify-center":"","column":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 mt-2",attrs:{"hide-details":"","text":"","fab":"","small":"","elevation":"0"},on:{"click":_vm.refreshLog}},on),[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Ricarica")])])],1),_c('div',{staticClass:"ml-5"},[_c('v-select',{staticStyle:{"width":"50% !important"},attrs:{"disabled":_vm.loadingTabella,"hide-details":"","color":"primary","items":_vm.listaSelect,"label":"Filtro Operazioni"},on:{"change":_vm.caricaLogOperazioni},model:{value:(_vm.tipoFiltro),callback:function ($$v) {_vm.tipoFiltro=$$v},expression:"tipoFiltro"}})],1),_c('v-spacer'),_c('div',{staticStyle:{"width":"30%"}},[_c('v-text-field',{attrs:{"disabled":_vm.loadingTabella,"hide-details":"","color":"primary","append-icon":"search","label":"Cerca"},model:{value:(_vm.searchLog),callback:function ($$v) {_vm.searchLog=$$v},expression:"searchLog"}})],1)],1)],1),_c('v-row',{staticClass:"mb-0 mt-0",attrs:{"no-gutters":""}},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 mt-0",staticStyle:{"border-bottom":"1px solid black"},attrs:{"id":"wheel","dense":"","headers":_vm.headerLog,"items":_vm.listaLog,"items-per-page":50,"search":_vm.searchLog,"loading":_vm.loadingTabella,"loading-text":"Caricamento dati...","no-data-text":"Nessun dato disponibile","mobile-breakpoint":"0","fixed-header":"","hide-default-header":"","page":_vm.page,"height":"calc(92vh - 320px)"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(head,i){return _c('th',{key:i},[_c('span',{domProps:{"innerHTML":_vm._s(head.text)}})])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.tabella))]),_c('td',[_vm._v(_vm._s(item.colonna))]),_c('td',[_vm._v(_vm._s(_vm.convertData(item.dataOra)))]),_c('td',[_vm._v(_vm._s(item.note))]),_c('td',[_vm._v(_vm._s(item.utente))]),_c('td',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.operazione === 'add')?_c('v-icon',_vm._g({attrs:{"color":"green"}},on),[_vm._v(_vm._s(item.operazione))]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Aggiunta")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.operazione === 'delete')?_c('v-icon',_vm._g({attrs:{"color":"red"}},on),[_vm._v(_vm._s(item.operazione))]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Elimina")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.operazione === 'edit')?_c('v-icon',_vm._g({},on),[_vm._v(_vm._s(item.operazione))]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Modifica")])])],1)])]}}])})],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"mt-5"},[_c('span',[_vm._v(" Tot. Record: "),_c('b',[_vm._v(_vm._s(_vm.listaLog.length))])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }