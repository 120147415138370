<template>
  <div>
    <v-autocomplete v-model="cliente" :items="getClienti" :placeholder="label" :item-text="itemText" return-object
      :loading="attesa" clearable no-data-text="Nessun dato disponibile" style="width:250px; z-index: 800; " filled
      rounded hide-details dense ref="autocomplete"><template v-slot:append>
        <v-icon :color="getColoreCliente" @click=" apriMenuDocDx($event)">
          assignment_ind
        </v-icon>
      </template></v-autocomplete>

    <!-- Menu dati cliente selezionato-->
    <v-menu :position-x="menuDocDx.x" :position-y="menuDocDx.y" v-model="menuDocDx.show"
      :close-on-content-click="false">
      <v-card light style="width: 790px !important" v-if="clienteSel != null">
        <v-row no-gutters>
          <v-col cols="6" class="ma-1 pa-1">
            <v-text-field readonly hide-details v-model="clienteSel.nomeAgente" label="Agente:" validate-on-blur>
            </v-text-field>
          </v-col>
          <v-col cols="3" class="ma-1 pa-1">
            <v-text-field readonly hide-details v-model="clienteSel.punteggioTotale" label="Punteggio:"
              validate-on-blur>
            </v-text-field>
          </v-col>
          <v-col cols="2" class="ma-1 pa-1">
            <v-text-field readonly hide-details v-model="clienteSel.zona_Passaggio" label="Zona consegna:"
              validate-on-blur>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ma-1 pa-1">
            <v-text-field readonly hide-details v-model="clienteSel.giornoPassaggio" label="Giorno passaggio:"
              validate-on-blur>
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ma-1 pa-1">
            <v-text-field readonly hide-details v-model="clienteSel.frequenzaPassaggio" label="Frequenza passaggio:"
              validate-on-blur>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ma-1 pa-1">
            <v-text-field readonly hide-details v-model="clienteSel.giornoConsegna" label="Giorno consegna:"
              validate-on-blur>
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ma-1 pa-1">
            <v-text-field readonly hide-details v-model="clienteSel.autistaConsegna" label="Autista:" validate-on-blur>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="5" class="ma-1 pa-1">
            <v-autocomplete return-object no-data-text="Nessun dato disponibile" class="bold-text" hide-details
              v-model="referenteSel" clearable :items="clienteSel.referente" itemText="completo"
              label="Referenti"></v-autocomplete>
          </v-col>
          <v-col class="ma-1 pa-1">
            <v-btn v-tooltip.bottom="'Referente'" hide-details text fab small elevation="0" class="mr-2 mt-2"
              v-on:click="nuovoReferente">
              <v-icon color="primary" large>add</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="referenteSel != null" no-gutters>
          <v-col cols="12" class="ma-0 pa-0">
            <v-divider hide-details></v-divider>
          </v-col>
          <v-col cols="4" class="ma-1 pa-1">
            <v-text-field hide-details append-icon="engineering" v-model="referenteSel.mansione" label="Mansione:"
              validate-on-blur>
            </v-text-field>
          </v-col>
          <v-col cols="3" class="ma-1 pa-1">
            <v-text-field append-icon="call" @click:append="chiama(referenteSel)" hide-details
              v-model="referenteSel.telefono" label="Telefono:" validate-on-blur>
            </v-text-field>
          </v-col>
          <v-col cols="3" class="ma-1 pa-1">
            <v-text-field append-icon="call" @click:append="chiama(referenteSel)" hide-details
              v-model="referenteSel.cell" label="Cellulare:" validate-on-blur>
            </v-text-field>
          </v-col>
          <v-row no-gutters>
            <v-col cols="6" class="ma-1 pa-1">
              <v-text-field hide-details @click:append="mandaMail(referenteSel)" append-icon="mail"
                v-model="referenteSel.mail" label="Mail:" validate-on-blur @focus="$event.target.select()">
              </v-text-field>
            </v-col>
            <v-col cols="2" class="ma-1 pa-1"></v-col>
            <v-col cols="1" class="ma-1 pa-1">
              <v-btn small id="buttonAnnulla" hide-details @click="resetReferente">ANNULLA</v-btn>
            </v-col>
            <v-col cols="1" class="ma-1 pa-1">
              <v-btn small id="buttonConferma" hide-details class="btn-conferma ml-8"
                @click="salvaReferente(referenteSel)">CONFERMA</v-btn>
            </v-col>
          </v-row>
        </v-row>
      </v-card>
    </v-menu>


    <dialog-referente-dettaglio :dialogReferente="dialogReferente" @nuovoReferente="nuovoReferente"
      :referenteSel="referenteSel" :NominativoRef="NominativoRef"
      @addNuovoReferente="addNuovoReferente"></dialog-referente-dettaglio>
  </div>
</template>

<script>
import { set_referente, get_clienteReferenti } from "@/api/api.intranet.js";

import DialogReferenteDettaglio from "@/components/dialog/DialogReferenteDettaglio.vue";

export default {
  props: {
    label: { type: String, required: true },
    itemText: { type: String, required: true },
  },

  components: {
    DialogReferenteDettaglio
  },


  data: () => ({
    attesa: false,

    menuDocDx: {
      show: false,
      x: 0,
      y: 0,
      activatedBy: "",
    },

    clienteSel: null,
    menuCliente: false,
    Referente: null,

    referenteSel: null,
    dialogReferente: false,
    NominativoRef: null,
  }),

  methods: {
    apriMenuDocDx(e) {
      // Evito l'apertura del menu
      this.$refs.autocomplete.isMenuActive = false

      if (!!this.clienteSel == false)
        return

      e.preventDefault();
      this.menuDocDx.show = false;
      this.menuDocDx.x = e.clientX;
      this.menuDocDx.y = e.clientY;
      this.menuDocDx.activatedBy = this.tabDocumenti;
      this.$nextTick(() => {
        this.menuDocDx.show = true;
      });
    },

    chiama(referente) {
      console.log(referente);
    },

    async salvaReferente(referente) {
      let res = await set_referente(referente);
      if (res.data == "OK") this.$toast.info("Dati aggiornati.");
      else this.$toast.toast("Errore. " + res.data);
    },

    resetReferente() {
      this.Referente = null;
      this.referenteSel = null;
    },

    mandaMail(referente) {
      var urlMailto = "mailto:" + referente.mail;
      window.location.href = urlMailto;
    },

    nuovoReferente() {
      if (this.referenteSel == null) this.referenteSel = {};
      if (this.dialogReferente == false) {
        this.dialogReferente = true;
        this.NominativoRef = {
          nome: this.referenteSel.nome,
          cognome: this.referenteSel.cognome,
        };
      }
      else {
        this.dialogReferente = false;
        this.NominativoRef = null;
      }
    },

    async addNuovoReferente(nuovoReferente, value) {
      if (value == 0) {
        nuovoReferente.completo = nuovoReferente.nome + " " + nuovoReferente.cognome;
        this.clienteSel.referente.push(nuovoReferente);
      } else {
        this.referenteSel = null;
        //carico referenti aggiornati
        let res = await get_clienteReferenti(nuovoReferente);
        this.clienteSel.referente = res.data.referente;
      }
    },
    async getClienteReferenti(val) {
      if (val == null) return;
      let res = await get_clienteReferenti(val);
      val.referente = res.data.referente;
    },
  },

  watch: {
    menu() {
      this.Menu = this.menu;
      this.resetReferente();
    },
    clienteSel() {
      if (this.clienteSel == null) this.referenteSel = null;
      this.resetReferente();
    },
    Referente() {
      this.referenteSel = this.Referente;
    },
  },

  computed: {
    getClienti() {
      return this.$store.getters["app/getClientiToolbar"];
    },
    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },


    cliente: {
      get: function () {
        return this.$store.getters["app/setCliente"];
      },
      set: function (val) {
        //carico referenti cliente
        this.getClienteReferenti(val);
        this.$store.commit("app/setCliente", val);

        this.clienteSel = val;

      },
    },

    getColoreCliente() {
      //setto colore cliente
      if (this.clienteSel != null) {
        if (this.clienteSel.punteggioTotale > 0 && this.clienteSel.punteggioTotale <= 4)
          return "red"
        else if (
          this.clienteSel.punteggioTotale > 4 &&
          this.clienteSel.punteggioTotale <= 8
        )
          return "yellow";
        else if (
          this.clienteSel.punteggioTotale > 8 &&
          this.clienteSel.punteggioTotale <= 12
        )
          return "green"
      }
      return "grey"
    }
  },


};
</script>

<style scoped>
.bold-text {
  font-weight: bold;
}

#buttonAnnulla {
  position: fixed;
  bottom: 1vh;
}

#buttonConferma {
  position: fixed;
  bottom: 1vh;
}
</style>
