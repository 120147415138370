<template>
  <v-card style="height: 600px" class="elevation-0">
    <filtro @aggiornaDati="filtroDati"></filtro>

    <v-data-table
      :items="getScarti"
      :headers="headers"
      hide-default-footer
      height="350"
      no-data-text="Nessun dato"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.recuperabile="{ item }">
        {{ item.recuperabile ? "Sì" : "No" }}
      </template>

      <template slot="body.append">
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>Recuperabili: {{ totRecup }}</td>
          <td>Non Recuperabili: {{ totNoRecup }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>


<script>
import { get_scartiTerzisti } from "@/api/api.catalogo.js";
import filtro from "@/components/ui/FiltroDettaglioPrezzi.vue";

export default {
  components: { filtro },

  props: {
    aggiorna: { type: Boolean, default: false },
    cdArticolo: { type: String, default: "" },
  },

  data: () => ({
    headers: [
      {
        text: "Articolo",
        value: "cdArticoloPadre",
        sortable: false,
      },
      {
        text: "Num. Conferma",
        value: "confermaOrdine_For",
        sortable: false,
      },
      {
        text: "Data Conferma",
        value: "dataConferma_For",
        sortable: false,
      },
      {
        text: "Bolla",
        value: "nrBolla",
        sortable: false,
      },
      {
        text: "Num. Ordine",
        value: "nrDocuStampato",
        sortable: false,
      },
      {
        text: "Quantità",
        value: "qta",
        sortable: false,
      },
      {
        text: "Recuperabile",
        value: "recuperabile",
        sortable: false,
      },
      {
        text: "Tipo Scarto",
        value: "tipoScarto",
        sortable: false,
      },
    ],

    datiScarti: [],
    totRecup: 0,
    totNoRecup: 0,
  }),

  methods: {
    async caricaDati() {
      let res = await get_scartiTerzisti(this.cdArticolo);
      this.datiScarti = res.data;
      this.datiScarti.forEach((el) => {
        el.dataConferma_For = this.$i2kFunctions
          .formatISODateTime(el.dataConferma_For)
          .substring(0, 10);
        if (el.recuperabile) this.totRecup += el.qta;
        else this.totNoRecup += el.qta;
      });
    },

    filtroDati(par) {
      this.datiFiltro = par;

      this.caricaDati();
    },
  },

  mounted() {
    this.otRecup = 0;
    this.totNoRecup = 0;
    this.caricaDati();
  },

  watch: {
    aggiorna() {
      this.caricaDati();
    },
  },

  computed: {
    getScarti() {
      return this.datiScarti;
    },
  },
};
</script>