<template>
  <div
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(2);
    "
  >
    <v-img
      :src="require('@/assets/img/battisti.svg')"
      @click="$router.push({ path: '/' })"
      alt="Battisti – Ferramenta per l’architettura"
      class="logo"
      width="170"
    />
    <h1>404</h1>
    <h2>Pagina non trovata.</h2>
    <v-btn @click="$router.push({ path: '/' })" text block>Torna alla home</v-btn>
  </div>
</template>

<script>
export default {
  // NOTA: serve per keep-alive
  name: "NotFound",

  title: "Pagina non trovata",

  components: {},

  props: {},

  data: () => ({}),

  methods: {},

  mounted() {},
};
</script>
