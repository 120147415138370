<template src="./ricerca.html"></template>

<script>
import ScrollTop from "@/components/ricerca/ScrollTop.vue";
import FilterCheckbox from "@/components/ricerca/FiltriRicerca.vue";

import storage from "@/services/storage.js";


export default {
  components: {
    ScrollTop,
    FilterCheckbox,
  },

  props: {
    valore: { type: String, default: "" },
  },

  data: () => ({
    check_selected: [],
    filterpanel: [],
    filtroRisultati: [],
    pSelect: [],
    ordinamento: "",
    ordineInverso: false,
    attesa: false,
    checkObsoleti: false,
    filtriParoleChiave: [
      "",
      "Con parole chiave",
      "Senza parole chiave",
      "Parole nella descrizione",
    ],
    filtroParoleChiave: "",
    ordinamentoNew: false,

    // NoStampaCatalogo, articoli_Obsoleti, Eliminati, DisattivaInternet
    filtroCheck: [false, false, false, false, true],
    filtroCheckTerzista: [false, false, false],

    menuManutenzione: {
      show: false,
      x: 0,
      y: 0,
    },

    ricercaTuttiFiltri: false,

    //panelOpen                     : espande tutti i pannelli di ricerca
    //panelMultiplo                 : permette di espandere più pannelli contemporaneamente
    //filtroZeroVis                 : visualizza i filtri con 0 risultati (sul panel attivo sono sempre visibili)
    //filtroMultiplo                : possibilità di spuntare più filtri senza che uno escluda l'altro
    //filtroRiconteggia             : riconteggia i risultati dei filtri in base agli altri filtri spuntati
    opzioniFiltriRicerca: { panelOpen: true, panelMultiplo: true, filtroZeroVis: true, filtroMultiplo: false, filtroRiconteggia: true }

  }),

  methods: {
    async caricaDati() {
      // Scroll top pagina
      window.scrollTo(0, 0);


      // Reset
      this.check_selected = [];
      this.pSelect = [];
      this.filtroRisultati = [];
      this.ordinamento = "";
      this.ordineInverso = false;
      this.filtri = [];
      this.attesa = true;

      try {
        let par = {
          valore: this.valore,
          filtroObsoletoEliminato: this.filtroCheck,
          ricercaFiltri: this.ricercaTuttiFiltri ? "TuttiFiltri" : "",
          ordinamento: this.ordinamentoNew ? 2 : 1
        };

        let res = await this.$store.dispatch("ricerca/cerca", par);

        // Controllo apertura\chiusura pannelli
        if (this.opzioniFiltriRicerca.panelOpen) {
          // Apro tutti i pannelli
          for (let i = 0; i <= this.filtri.length; i++) {
            this.filterpanel.push(i);
          }
        }
        else {
          // Chiudo tutti i pannelli
          this.filterpanel = [];
        }


        // Avvio il filtro
        this.filtra();

        // Filtro querystring
        this.attivaFiltroQuerystring();

      } catch (error) {
        console.log(error);
      }

      this.attesa = false;
    },

    impostaFiltriObsoletoEliminatoFuoriCat() {
      // Ricavo i filtri obsoleto,eliminato,fuoricat memorizzati nella storage
      let f = storage.getFiltriRicerca();
      if (f.length > 0) this.filtroCheck = f;

      // Memorizzo i filtri obsoleto,eliminato,fuoricat in vuex
      this.$store.commit(
        "ricerca/setFiltroObsoletoEliminatoFuoriCat",
        this.filtroCheck
      );
    },

    attivaFiltroQuerystring() {
      // NOTA: filtro vuex utilizzato su avanti\indietro
      //       filtro querystring utilizzato su refresh pagina



      let filtroVuex = this.$store.getters["ricerca/valoreFiltro"];

      // Controllo presenza filtri
      if (
        Object.keys(this.$route.query).length == 0 &&
        Object.keys(filtroVuex).length == 0
      )
        return;

      // Controllo filtro vuex
      if (Object.keys(filtroVuex).length > 0) {
        // Query Vuex
        for (let i = 0; i <= filtroVuex.length - 1; i++) {
          this.avviaFiltro(filtroVuex[i], true);
        }
      } else {
        // Query string
        let qs = this.$route.query;

        for (let item in qs) {
          this.check_selected.push({ filtro: item, valore: qs[item] });
        }
      }


      this.filtra();

      if (this.opzioniFiltriRicerca.panelOpen) {
        // Apro tutti i pannelli
        for (let i = 0; i <= this.filtri.length; i++) {
          this.filterpanel.push(i);
        }

      }
      else {
        // Chiudo tutto
        this.filterpanel = [];

        // Apro i pannelli con i filtri attivi
        for (let i = 0; i <= this.check_selected.length; i++) {
          let _t = this;

          let resFiltro
          if (_t.check_selected.length > 0) {
            resFiltro = this.filtri.filter((f) =>
              f.valoriFiltri.find((f2) => f2.valore == _t.check_selected[0].valore)
            );
          }


          if (!!resFiltro == false)
            return

          // Ricavo l'indice del filtro principale
          let indicePann = this.filtri.findIndex(
            (f) => f.filtro == resFiltro[0].filtro
          );

          if (indicePann == -1) continue;

          // Espando il pannello con il filtro attivo
          for (let i = 0; i <= this.filtri.length - 1; i++) {
            this.filterpanel.push(i == indicePann ? true : false);
          }
        }

      }

    },

    arrayFiltriOrdine() {

      let res = [];
      for (let f = 0; f < this.filtri.length; f++) {
        res = res.concat(this.filtri[f].filtro);
      }
      return res;
    },

    ordinaPer(ord) {
      if (ord == undefined || ord == "") return;

      if (ord == this.ordinamento) {
        this.ordineInverso = !this.ordineInverso;
        this.filtroRisultati.reverse();
      } else {
        this.ordineInverso = false;

        this.filtroRisultati.sort((a, b) => {
          let filtri_a_raw = a.filtriRicerca.split("|").reverse();
          let filtri_b_raw = b.filtriRicerca.split("|").reverse();

          let filtri_a = {},
            filtri_b = {};
          for (let f = 0; f < filtri_a_raw.length; f++) {
            filtri_a[filtri_a_raw[f].split("#")[0]] =
              filtri_a_raw[f].split("#")[1];
          }
          for (let f = 0; f < filtri_b_raw.length; f++) {
            filtri_b[filtri_b_raw[f].split("#")[0]] =
              filtri_b_raw[f].split("#")[1];
          }

          // caso: tutti e due hanno valore per filtro "ord"
          if (filtri_a[ord] != undefined && filtri_b[ord] != undefined) {
            if (filtri_a[ord] < filtri_b[ord]) return -1;
            if (filtri_a[ord] > filtri_b[ord]) return 1;
            if (filtri_a[ord] == filtri_b[ord]) {
              return a.codTestata < b.codTestata ? -1 : 1;
            }
          }

          // caso: solo a ha valore per filtro "ord"
          if (filtri_a[ord] != undefined && filtri_b[ord] == undefined) {
            return -1;
          }

          // caso: solo b ha valore per filtro "ord"
          if (filtri_a[ord] == undefined && filtri_b[ord] != undefined) {
            return 1;
          }

          // caso: nessuno ha valore per filtro "ord"
          if (filtri_a[ord] == undefined && filtri_b[ord] == undefined) {
            return a.codTestata < b.codTestata ? -1 : 1;
          }
        });

        // console.log("riordinato.");
        this.ordinamento = ord;
      }
    },

    confronta(valore1, valore2) {
      let v1 = "$" + valore1.toString().toLowerCase() + "$";
      let v2 = "$" + valore2.toString().toLowerCase() + "$";
      return v1.includes(v2);
    },


    avviaFiltro(item, disableAddUrlParams) {

      // Controllo spunte
      let r = this.check_selected.findIndex(
        (f) => f.filtro == item.filtro && f.valore == item.valore
      );

      if (r == -1) this.check_selected.push(item);
      else this.check_selected.splice(r, 1);

      // Vuex filtro attivo
      this.$store.commit("ricerca/setValoreFiltro", this.check_selected);


      if (!!disableAddUrlParams == false) {
        // Url param
        this.$functionsApp.addParamsToLocation(this.$route, this.check_selected);
      }
      else {
        return
      }


      // Reset risultati
      this.filtroRisultati = [];

      // NOTA: il timeout serve per il rendering della checkbox sul click
      //       devo gestire l'attesa prima di avviare il filtro
      this.attesa = true;
      setTimeout(() => this.filtra(), 200);
    },



    filtra() {
      let dati = this.risultatiRicerca;


      // --------------------------------------------------
      // Controllo filtro parole chiave
      // --------------------------------------------------
      if (this.filtroParoleChiave) {
        if (this.filtroParoleChiave.toLowerCase().includes("senza"))
          dati = this.risultatiRicerca.filter((f) => f.contFiltri == 0);
        else if (this.filtroParoleChiave.toLowerCase().includes("descrizione"))
          // Da finire
          dati = [];
        else dati = this.risultatiRicerca.filter((f) => f.contFiltri > 0);
      }




      // --------------------------------------------------
      // Controllo spunte obsoleto/eliminato/fuori cat
      // --------------------------------------------------
      let filtriTipologia = this.filtroCheck;

      if (!!filtriTipologia) {
        let swap = [];

        for (let k = 0; k <= dati.length - 1; k++) {
          if (
            filtriTipologia[4] == true &&
            filtriTipologia[0] == false &&
            filtriTipologia[1] == false &&
            filtriTipologia[2] == false &&
            filtriTipologia[3] == false
          ) {
            if (dati[k].articoli_NonStampCatalogo) continue;

            if (dati[k].articoli_Obsoleti) continue;

            if (dati[k].articoli_Eliminati) continue;

            if (dati[k].disattivaInternet) continue;
          } else {
            // Controllo spunta verde
            if (filtriTipologia[4] == false) {
              if (
                dati[k].articoli_NonStampCatalogo == false &&
                dati[k].articoli_Obsoleti == false &&
                dati[k].articoli_Eliminati == false &&
                dati[k].disattivaInternet == false
              )
                continue;

              if (
                dati[k].articoli_NonStampCatalogo &&
                filtriTipologia[0] == false
              )
                continue;

              if (dati[k].articoli_Obsoleti && filtriTipologia[1] == false)
                continue;

              if (dati[k].articoli_Eliminati && filtriTipologia[2] == false)
                continue;

              if (dati[k].disattivaInternet && filtriTipologia[3] == false)
                continue;
            } else {
              if (
                dati[k].articoli_NonStampCatalogo &&
                filtriTipologia[0] == false
              )
                continue;

              if (dati[k].articoli_Obsoleti && filtriTipologia[1] == false)
                continue;

              if (dati[k].articoli_Eliminati && filtriTipologia[2] == false)
                continue;

              if (dati[k].disattivaInternet && filtriTipologia[3] == false)
                continue;
            }
          }

          swap.push(dati[k]);
        }

        dati = swap;
      }



      // --------------------------------------------------
      // Controllo spunte terzista
      // --------------------------------------------------
      if (this.role == 3) {
        if (this.filtroCheckTerzista[2]) {
          dati = dati.filter(
            (f) => f.ubicazione.toString().toLowerCase() == "simapac"
          );
        }
      }



      // --------------------------------------------------
      // Controllo spunte filtri laterali
      // --------------------------------------------------
      // X DEBUG SENZA FILTRI
      /*  this.filtroRisultati = dati;
       return; */

      let items = [];

      // Controllo presenza filtri checkbox selezionate
      if (this.check_selected.length == 0) {
        // ---- NESSUN FILTRO APPLICATO ----
        items = dati;
      } else {
        // ---- APPLICO I FILTRI DELLE CHECKBOX ----
        // Ciclo sui risultati della ricerca
        for (let k = 0; k <= dati.length - 1; k++) {
          let valoriArr = dati[k].filtriRicerca.split("|");
          let contTrovato = 0;

          // Ciclo sui valori di filtro in ricerca
          for (let i = 0; i <= valoriArr.length - 1; i++) {
            if (valoriArr[i] == null) continue;
            if (valoriArr[i].length == 0) continue;

            // Ciclo sui filtri selezionati (spunta)
            for (var j = 0; j <= this.check_selected.length - 1; j++) {
              if (this.check_selected[j].length == 0) continue;

              let valore = "";
              let filtro = "";

              let arr = valoriArr[i].split("#");

              // Controllo filtro tutti
              if (this.check_selected[j].valore.toLowerCase().includes("Tutti".toLowerCase())) {
                valore = arr[j];
                filtro = this.check_selected[j].filtro.replace("Tutti", "");
              }
              else {
                valore = arr[1];
                filtro = this.check_selected[j].valore;
              }

              let f = arr[0];
              let f2 = this.check_selected[j].filtro;

              if (this.confronta(f, f2) && this.confronta(valore, filtro)) {
                contTrovato++;
              }
            }
          }


          // Controllo inserimento voce
          if (this.opzioniFiltriRicerca.filtroMultiplo) {
            if (contTrovato > 0) {
              items.push(dati[k]);
            }
          }
          else {
            if (contTrovato == this.check_selected.length) {
              items.push(dati[k]);
            }
          }



        }
      }

      this.filtroRisultati = items;



      if (this.opzioniFiltriRicerca.filtroRiconteggia) {
        // -------------------------------------------------------------------------------
        // CONTEGGIA LE OCCORRENZE PRESENTI DEI VALORI DEI FILTRI
        // ES. SPUNTO FINITURA BIANCO, RICONTEGGIA GLI ALTRI FILTRI DISPONIBILI CON QUELLO SPUNTATO
        // -------------------------------------------------------------------------------        

        // Clono l'array
        let filtriSwap = JSON.parse(JSON.stringify(this.filtri));

        //console.log(filtriSwap);
        //console.log(items);

        // Ciclo sui filtri ricerca
        for (let j = 0; j <= filtriSwap.length - 1; j++) {
          for (let k = 0; k <= filtriSwap[j].valoriFiltri.length - 1; k++) {
            // DA CANC
            /*    if(this.opzioniFiltriRicerca.multiFiltroAzienda && filtriSwap[j].valoriFiltri[k].filtro=="Marca")
                 continue */

            filtriSwap[j].valoriFiltri[k].nrValori = 0;

            // Ciclo sui risultati di ricerca filtrati
            for (let z = 0; z < items.length; z++) {
              let valoriArr = items[z].filtro;

              // Ciclo sui valori di filtro in ricerca
              for (let i = 0; i <= valoriArr.length - 1; i++) {
                if (!!valoriArr[i] == false) continue;
                if (valoriArr[i].length == 0) continue;
                if (valoriArr[i] == "#") continue;
                if (valoriArr[i].indexOf("#") == -1) continue;

                // NOTA: non utilizzo la split perchè  rallenta
                let filtro = valoriArr[i].substring(0, valoriArr[i].indexOf("#"));
                let valore = valoriArr[i].substring(
                  valoriArr[i].indexOf("#") + 1,
                  valoriArr[i].length
                );

                if (
                  this.confronta(filtro, this.filtri[j].valoriFiltri[k].filtro) &&
                  this.confronta(valore, this.filtri[j].valoriFiltri[k].valore)
                ) {
                  filtriSwap[j].valoriFiltri[k].nrValori++;
                }
              }
            }
          }
        }

        // Memorizzo in vuex
        this.filtri = filtriSwap;
      }
      // -------------------------------------------------------------------------------



      this.attesa = false;
    },




    setpSelect(arr) {
      this.pSelect = arr;
    },


    popstateHandler(event) {
      // NOTA: si attiva sul bottone indietro\avanti
      //console.log('popstateHandler')

      this.attivaFiltroQuerystring();
    },

    getChipFilter(item) {
      return item.valore.toLowerCase().includes("Tutti".toLowerCase())
        ? item.filtro
        : item.valore;
    },

    // checkbox  filtri
    styleCheckbox(index) {
      let style =
        "border-color: " + this.getColoreCheckbox(index) + " !important;";

      if (this.filtroCheck[index])
        style +=
          " background-color: " +
          this.getColoreCheckbox(index) +
          " !important;";
      return style;
    },

    getColoreCheckbox(index) {
      //Fuori catalogo (GIALLO)
      //Obsoleto (ARANCIONE)
      //Eliminato (BLU)
      //Fuori internet (FUCSIA)
      if (index == 0) return "#f0e130";
      else if (index == 1) return "orange";
      else if (index == 2) return "blue";
      else if (index == 3) return "#FF1493";
      else if (index == 4) return "green";
    },

    getDescrizione(index) {
      switch (index) {
        case 0:
          return "Fuori catalogo";
        case 1:
          return "Obsoleto";
        case 2:
          return "Eliminato";
        case 3:
          return "Fuori internet";
        case 4:
          return "";
        default:
          return "";
      }
    },

    setFiltroCheck(valore, i) {

      this.filtroCheck[i] = valore;

      this.$store.commit(
        "ricerca/setFiltroObsoletoEliminatoFuoriCat",
        this.filtroCheck
      );

      // NOTA: sulla visualizzazione prodotti devo fare il filtro per vedere le testata obsolete\fuoricat\eliminate
      //       sulla pagina prodotto non devo filtrare le testate, ma solo gli articoli
      let url = this.$store.getters["app/getUrlPage"];
      if (url.includes("prodotti"))
        this.filtra();
    },

    setFiltroTerzistaCheck(valore, i) {
      this.filtroCheckTerzista[i] = valore;

      this.filtra();
    },

    // checkbox  filtri
    styleCheckboxTerzista(index) {
      let style =
        "border-color: " +
        this.getColoreCheckboxTerzista(index) +
        " !important;";

      if (this.filtroCheckTerzista[index])
        style +=
          " background-color: " +
          this.getColoreCheckboxTerzista(index) +
          " !important;";
      return style;
    },

    getColoreCheckboxTerzista(index) {
      if (index == 0) return "#f0e130";
      else if (index == 1) return "orange";
      else if (index == 2) return "blue";
    },

    getDescrizioneTerzista(index) {
      switch (index) {
        case 0:
          return "Acquisto";
        case 1:
          return "Conto deposito";
        case 2:
          return "Conto lavoro";
        default:
          return "";
      }
    },

    apriManutenzione(e) {

      e.preventDefault();
      this.menuManutenzione.show = false;
      this.menuManutenzione.x = e.clientX;
      this.menuManutenzione.y = e.clientY + 10;
      this.$nextTick(() => {
        this.menuManutenzione.show = true;
      });
    },

    closeChip(item) {

      this.avviaFiltro(item)

    }
  },

  mounted() {
    this.caricaDati();

    this.impostaFiltriObsoletoEliminatoFuoriCat();
  },

  created() {
    window.addEventListener("popstate", this.popstateHandler);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.popstateHandler);
  },

  computed: {
    risultatiRicerca() {
      return this.$store.getters["ricerca/risultatiRicerca"];
    },
    filtri: {
      set(valore) {
        this.$store.commit("ricerca/setFiltri", valore);
      },
      get() {
        return this.$store.getters["ricerca/filtriRicerca"];
      },
    },
    find() {
      return this.$store.getters["ricerca/find"];
    },
    role() {
      return this.$store.getters["user/getRole"];
    },
    group() {
      return this.$store.getters["user/getGroup"];
    },

    valoreFiltro() {
      return this.$store.getters["ricerca/valoreFiltro"];
    },

    tipoRicerca() {
      return this.$store.getters["ricerca/tipoRicerca"];
    },

    selezionaTutti: {
      set(valore) {
        if (valore && this.manutenzione == false)
          this.manutenzione = true

        this.$store.commit("ricerca/setSelTutti", valore);
      },
      get() {
        return this.$store.getters["ricerca/selTutti"];
      },
    },

    manutenzione: {
      set(valore) {
        this.$store.commit("ricerca/setManutenzione", valore);
      },
      get() {
        return this.$store.getters["ricerca/manutenzione"];
      },
    },

    multiSelezione: {
      set(valore) {
        this.$store.commit("ricerca/setMultiSelezione", valore);
      },
      get() {
        return this.$store.getters["ricerca/multiSelezione"];
      },
    },

    noResult() {
      return this.filtroRisultati.length == 0 || false;
    },
  },
  watch: {
    find() {
      this.caricaDati();
    },
  },
};
</script>

<style scoped>
.active-filter {
  color: #fff;
  background-color: #e40520;
}

.active-filter:hover,
.active-filter:active,
.active-filter:focus {
  background-color: #c2041b;
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  /* border-radius: 50%; */
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-right: 2px;
  border: 3px solid;
}
</style>
