<template>
  <v-dialog v-model="getDialog" max-width="500">
    <v-data-table
      :items="tipiSerramento"
      no-data-text=""
      disable-pagination
      hide-default-footer
      fixed-header
      height="400"
      item-key="tipoSerramento"
      :headers="headersTipoSerr"
      @click:row="rowTipoSerrClick"
      style="cursor: pointer"
      disable-sort
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.descrizione="{ item }">
        <div
          v-html="$functionsApp.convertiTestoGrassetto(item.descrizione)"
        ></div>
      </template>
    </v-data-table>
  </v-dialog>
</template>

<script>
import { get_tipiSerrDistinta } from "@/api/api.catalogo.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    cdArticolo: { type: String, default: "" },
    codCliente: { type: String, default: "" },
  },

  data: () => ({
    tipiSerramento: [],

    attesa: false,

    headersTipoSerr: [{ text: "Tipologia", value: "descrizione" }],
  }),

  methods: {
    async caricaDati() {
      if (this.dialog == false) return;

      // Reset
      this.tipiSerramento = [];
      this.attesa = true;

      let res = await get_tipiSerrDistinta(
        this.cdArticolo + "/" + this.codCliente
      );
      this.tipiSerramento = res.data;

      this.attesa = false;
    },

    rowTipoSerrClick(item) {
      this.chiudi(item);
    },

    chiudi(row) {
      this.$emit("chiudi", row);
    },
  },

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    dialog() {
      this.caricaDati();
    },
  },
};
</script>