<template>
    <v-dialog v-model="dialogInfo" width="50%" style="min-height: 100% !important" persistent>
        <v-card>
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn text icon elevation="0" @click="vediInfo">
                    <v-icon> close </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-row v-if="testata != null" no-gutters>
                    <v-col cols="1" class="pa-1">
                        <v-text-field hide-details label="CodTestata" :readonly="true" style="font-weight: bold"
                            v-model="codTestata" />
                    </v-col>
                    <v-col cols="1" class="pa-1">
                        <v-text-field hide-details label="Suffisso" type="number" :readonly="true"
                            style="font-weight: bold" v-model="testata.suffisso" />
                    </v-col>
                    <v-col cols="10" class="pa-1">
                        <v-text-field hide-details label="Descrizione" :readonly="true" style="font-weight: bold"
                            v-model="testata.descrizione" />
                    </v-col>
                </v-row>

                <v-row no-gutters>
                    <v-col cols="12" class="ma-0 pa-0">
                        <vue-editor class="mt-6 mb-10" style="height: 60vh" :editorToolbar="customToolbar"
                            placeholder="Scrivi qui..." v-model="informazioni" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <div class="mr-0">
                    <v-btn class="mr-2" v-on:click="vediInfo">ANNULLA</v-btn>
                    <v-btn class="btn-conferma" v-on:click="salvaDatiInfo">CONFERMA</v-btn>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { get_infoTestata, set_infoTestata } from "@/api/api.archivi.js";

import { VueEditor } from "vue2-editor";

export default {
    props: {
        dialogInfo: { type: Boolean, default: false },
        testata: { type: Object, default: null },
    },

    components: {
        VueEditor,
    },

    data: () => ({
        //campo Informazioni
        customToolbar: [
            [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ["bold", "italic", "underline", "strike"],
            [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
            ["blockquote", "code-block"],
            [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            [{ color: [] }, { background: [] }],
            ["link", "image", "video"],
        ],

        codTestata: "",
        informazioni: "",
    }),

    methods: {
        vediInfo() {
            this.$emit("vediInfo");
        },

        async info() {
            let par = {
                CodTestata: this.testata.codTestata,
                Suffisso: this.testata.suffisso,
            };
            let res = await get_infoTestata(par);
            this.informazioni = res.data;
        },

        async salvaDatiInfo() {
            let par = {
                CodTestata: this.testata.codTestata,
                Suffisso: this.testata.suffisso,
                Informazioni: this.informazioni,
            };
            let res = await set_infoTestata(par);
            if (res.data == "OK") {
                this.$toast.info("Informazioni salvate");
                this.dialogInfo = false;
            } else {
                this.$toast.alert("Errore nel salvataggio. " + res.data);
            }
        },
    },

    watch: {
        dialogInfo(newVal) {
            if (newVal) {
                this.codTestata = this.testata.codTestata.substring(0, 3) + " " + this.testata.codTestata.substring(2, 8);
                this.info();
            }
        }
    },
};
</script>