var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.listaQuery,"item-text":"nomeFile","item-value":"nomeFile","outlined":"","hide-details":""},on:{"input":function($event){return _vm.fileSelected()}},model:{value:(_vm.fileQuery),callback:function ($$v) {_vm.fileQuery=$$v},expression:"fileQuery"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"no-resize":"","outlined":"","label":"Query SQL"},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),_c('v-btn',{attrs:{"loading":_vm.attesa},on:{"click":function($event){return _vm.eseguiQuery()}}},[_vm._v("Esegui")]),_c('v-btn',{staticClass:"ml-4",on:{"click":function($event){return _vm.generaExcel()}}},[_vm._v("Excel")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{ref:"tabella",attrs:{"headers":_vm.getHeaders,"hide-default-header":"","items":_vm.datiWithIndex,"no-data-text":'',"no-results-text":'Nessun dato trovato',"options":_vm.pagination,"item-key":"idPrimary"},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(head,i){return _c('th',{key:i,class:[
                'column sortable',
                _vm.pagination.descending ? 'desc' : 'asc',
                head.value === _vm.pagination.sortBy ? 'active' : '' ]},[_c('span',{domProps:{"innerHTML":_vm._s(head.text)}}),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(head.sortable == true),expression:"head.sortable == true"}],attrs:{"small":"","color":"#FFF"}},[_vm._v("arrow_upward")])],1)}),0)])]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('tr',_vm._l((_vm.getHeaders),function(header,i){return _c('td',{key:i,staticStyle:{"padding":"2px !important"}},[_c('span',{domProps:{"innerHTML":_vm._s(item[header.value])}})])}),0)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }