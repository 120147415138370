<template>
    <v-container fluid class="pt-0">
        <v-row class="ma-0 pa-0 mt-2">
            <v-col style="border-bottom: 1px solid black;" class="ma-0 pa-0">
                <div style="height: 26px;">
                    <v-row class="ma-0 pa-0" v-if="elencoDati && elencoDati.length > 0">
                        <span>
                            {{ elencoDati.length }} record</span>
                        <!-- <v-checkbox class="mt-0 pt-0 ml-4" label="Seleziona tutto" hide-details v-model="selezionaTutto"
                            @change="spuntaLavorazioni()" /> -->
                    </v-row>
                </div>
                <v-data-table dense mobile-breakpoint="0" :items="elencoDati" :headers="headersTabella"
                    :items-per-page="999" hide-default-footer :search="search" height="60vh" fixed-header
                    no-data-text="Nessun elemento in lavorazione" class="table-vertical-divider">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="mr-0 pr-0">
                                <v-checkbox v-if="!item.Ricevimento" v-model="item.selezionato" color="primary"
                                    hide-details @change="spuntaLavorazione()" :key=inputKey />
                            </td>
                            <td class="pl-2">{{ item.NrBolla }}</td>
                            <td class="pl-2">{{ item.NrDocuTerzista == 0 ? '' : item.NrDocuTerzista }} </td>
                            <td class="pl-2">{{ item.NrRigaDocu }}</td>
                            <td class="pl-2">{{ $functionsApp.getCodArticoloFormat(item.CdArticolo) }}</td>
                            <td class="pl-2">{{ item.DescrizioneProdotto }}</td>
                            <td class="pl-2" style="min-width: 130px;">{{ $i2kFunctions.formatISODate(item.DataInvio) }}
                                <!-- Pallino colorato per lo stato di avanzamento (giallo se meno di 7 gg, arancio altrimenti) -->
                                <span v-if="item.GiorniRitardo >= 1 && item.GiorniRitardo <= 7">
                                    <span class="dot yellow"
                                        v-tooltip.top="'Giorni di ritardo:' + item.GiorniRitardo"></span>
                                </span>
                                <span v-if="item.GiorniRitardo > 7">
                                    <span class="dot dark-orange"
                                        v-tooltip.top="'Giorni di ritardo:' + item.GiorniRitardo"></span>
                                </span>
                            </td>
                            <td class="pl-2">{{ item.Lavorazione }}</td>
                            <td style="min-width: 70px; align-items: end; text-align: end;"><strong>
                                    {{ item.Qta }}</strong></td>
                            <td class="pl-2">{{ item.LeadTime }}</td>
                            <td v-if="!soloOrdiniAperti" class="pl-2">{{ item.Scarto }}</td>
                            <td v-if="!soloOrdiniAperti" class="pl-2">
                                <span v-if="item.Ricevimento">
                                    {{ $i2kFunctions.formatISODate(item.DataRicevimento) }}
                                </span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-2">
            <v-btn v-if="showBtnConferma" color="primary" v-on:click="dialogConferma()">
                Conferma ricezione lavorazione</v-btn>

            <!-- <v-btn v-if="showBtnConferma" class="ml-4" color="primary" v-on:click="generaSollecito()">
                <v-icon class="mr-1">warning</v-icon> Genera sollecito</v-btn> -->
        </v-row>

        <modal name="confermaLavorazione" :adaptive="true" :width="1200" :resizable="true" :draggable="true"
            :clickToClose="true" :minWidth="1000" :minHeight="600" :reset="true">
            <v-card>
                <v-card-title>Riepilogo articoli
                    <v-spacer />
                    <v-btn icon small @click="$modal.hide('confermaLavorazione')">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text v-if="getLavorazioniSpuntate[0]">
                    <v-data-table :items="getLavorazioniSpuntate" :headers="headersDettaglio" height="465" fixed-header
                        hide-default-footer :items-per-page="999">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.NrBolla }}</td>
                                <td>{{ item.NrDocuTerzista == 0 ? '' : item.NrDocuTerzista }} </td>
                                <td>{{ item.NrRigaDocu }}</td>
                                <td>{{ $functionsApp.getCodArticoloFormat(item.CdArticolo) }}</td>
                                <td>{{ $i2kFunctions.formatISODate(item.DataInvio) }}</td>
                                <td>{{ item.Lavorazione }}</td>
                                <td>{{ item.Qta }}</td>
                                <td>
                                    <v-text-field v-model.number="item.Scarto" label="Scarto" hide-details single-line
                                        @focus="$event.target.select()" style="width:60px; color:#E40520 !important"
                                        type="number" />
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn v-on:click="$modal.hide('confermaLavorazione')">Annulla</v-btn>
                    <v-btn color="primary" v-on:click="confermaRicezione()">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </modal>
    </v-container>
</template>

<script>
import { get_terzisti, post_terzisti } from "@/api/api.lottiProd.js"

export default {
    name: 'LavorazioniInviate',
    props: {
        search: { type: String, default: "" },
        clear: { type: Boolean, default: false },
        calendar: { type: Object, default: () => { return {} } },
        cicloSel: { type: Object, default: () => { return {} } },
        lavorazioneSel: { type: Object, default: () => { return {} } },
        soloOrdiniAperti: { type: Boolean, default: false },
    },

    data: () => ({
        elencoDati: [],
        headersTabella: [
            { text: "Seleziona", value: "", class: "mr-0 pr-0" },
            { text: "Numero DDT", value: "NrBolla" },
            { text: "Numero doc interno", value: "NrDocuTerzista" },
            { text: "Riga", value: "NrRigaDocu", class: "mr-0 pr-0" },
            { text: "Codice articolo", value: "CdArticolo", class: "mr-0 pr-0" },
            { text: "Descrizione articolo", value: "descrizioneProdotto", class: "mr-0 pr-0" },
            { text: "Data invio", value: "DataInvio", class: "mr-0 pr-0" },
            { text: "Lavorazione", value: "Lavorazione", class: "mr-0 pr-0" },
            { text: "Quantita", value: "Qta", class: "pr-1" },
            { text: "Lead time (giorni)", value: "LeadTime", class: "mr-0 pr-0" },
        ],

        headersDettaglio: [
            { text: "Numero DDT", value: "NrBolla", class: "mr-0 pr-0" },
            { text: "Numero doc interno", value: "NrDocuTerzista", class: "mr-0 pr-0" },
            { text: "Riga", value: "NrRigaDocu", class: "mr-0 pr-0" },
            { text: "Codice articolo", value: "CdArticolo", class: "mr-0 pr-0" },
            { text: "Data invio", value: "DataInvio", class: "mr-0 pr-0" },
            { text: "Lavorazione", value: "Lavorazione", class: "mr-0 pr-0" },
            { text: "Quantita", value: "Qta", class: "mr-0 pr-0" },
            { text: "Scarto", value: "Scarto", class: "mr-0 pr-0" },],
        showBtnConferma: false,

        selezionaTutto: false,
        inputKey: 0,
    }),

    methods: {
        async caricaDati() {
            this.showBtnConferma = false;
            let res = await get_terzisti("lavorazioni_inviate/elenco?inCorso=" + this.soloOrdiniAperti);
            this.elencoDati = res.data;
        },

        async confermaRicezione() {
            if (!this.checkScarti()) return;

            let par = this.getLavorazioniSpuntate;
            par.forEach(el => {
                el.QtaRicevuta = el.Qta - el.Scarto;
            });
            let res = await post_terzisti("lavorazioni_inviate/conferma", par);
            if (res.data == "OK") {
                this.$toast.info("Lavorazioni confermate");
                this.$modal.hide("confermaLavorazione");
                this.caricaDati();
            }
            else this.$toast.error("Si è verificato un errore nella ricezione della lavorazione.");
        },

        checkScarti() {
            this.getLavorazioniSpuntate.forEach(el => {
                if (el.Scarto < 0) {
                    this.$toast.alert("Errore. Scarto negativo per l'articolo " + this.$functionsApp.getCodArticoloFormat(el.CdArticolo));
                    return false;
                }
                if (el.Scarto > el.Qta) {
                    this.$toast.alert("Errore. Scarto maggiore della quantità inviata per l'articolo " + this.$functionsApp.getCodArticoloFormat(el.CdArticolo))
                    return false;
                }
            });

            return true;
        },

        spuntaLavorazioni() {
            this.inputKey += 1;
            if (!this.elencoDati || this.elencoDati.length <= 0) return;

            if (this.selezionaTutto) {
                this.elencoDati.forEach(el => {
                    el.selezionato = true;
                });
                this.showBtnConferma = true;
            }
            else {
                this.elencoDati.forEach(el => {
                    el.selezionato = false;
                });
                this.showBtnConferma = false;
            }
        },

        spuntaLavorazione() {
            let spunta = false;
            let tuttiSelezionati = true;

            this.elencoDati.forEach(el => {
                if (!el.selezionato) {
                    this.selezionaTutto = false;
                    tuttiSelezionati = false;
                } else {
                    el.Scarto = 0;
                    spunta = true;
                }
            });
            this.showBtnConferma = spunta;
            if (tuttiSelezionati) this.selezionaTutto = true;
        },

        addHeadersTabella() {
            this.headersTabella.push({ text: "Scarto", value: "Scarto", class: "mr-0 pr-0" });
            this.headersTabella.push({ text: "Data ricevimento", value: "DataRicevimento", class: "mr-0 pr-0" });
        },

        removeHeadersTabella() {
            this.headersTabella.pop();
            this.headersTabella.pop();
        },

        dialogConferma() {
            this.$modal.show("confermaLavorazione");
        },

        generaSollecito() {
            let elencoSelezionati = this.elencoDati.filter((f) => (f.selezionato == true && f.GiorniRitardo > 0));
            console.log(elencoSelezionati);
            //TODO: generare excel con l'elenco delle lavorazioni da sollecitare
            //colonne: numero ddt, numero doc interno, riga, descrizione articolo, data invio, lavorazione, giorni ritardo
        }
    },

    mounted() {
        this.caricaDati();
    },

    computed: {
        getLavorazioniSpuntate() {
            return this.elencoDati.filter(el => el.selezionato);
        },
    },

    watch: {
        clear: function () {
            this.caricaDati();
        },

        soloOrdiniAperti: function (newVal, oldVal) {
            if (!newVal) this.addHeadersTabella();
            else this.removeHeadersTabella();
        },
    }
}
</script>

<style scoped>
.dot {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 5px;
}

.yellow {
    background-color: yellow;
}

.dark-orange {
    background-color: rgb(255, 106, 0);
}
</style>
