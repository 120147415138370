import request from '@/services/interceptor.js'

/* LOTTI PRODUZIONE */
export const get_lotti = (data) => {
    return request({
        url: '/lottiProduzione',
        method: 'post',
        data: data
    })
};

export const get_dettaglio_lotto = (data) => {
    return request({
        url: '/lottiProduzione/dettaglio',
        method: 'post',
        data: data
    })
};

export const get_elenco_stampanti = () => {
    return request({
        url: "/lottiProduzione/stampanti",
        method: 'get'
    })
}

export const get_etichetta_stampa = (data, par) => {
    return request({
        url: "/lottiProduzione/get/stampa/" + par,
        method: 'post',
        data: data
    })
}

export const get_terzisti = (par) => {
    return request({
        url: "/lottiProduzione/terzisti/" + par,
        method: 'get',
    })
}


export const post_terzisti = (par, data) => {
    return request({
        url: "/lottiProduzione/terzisti/" + par,
        method: 'post',
        data: data,
    })
}

export const invia_lavorazioni = (data) => {
    return request({
        url: "/lottiProduzione/lavorazione/invio",
        method: 'post',
        data: data,
    })
}

export const set_esporta_lavorazioni = (data) => {
    return request({
        url: "/lottiProduzione/lavorazione/esporta",
        method: 'post',
        data: data,
    })
}