<template>
  <v-dialog
    v-if="!auth"
    v-model="getDialog"
    transition="slide-y-transition"
    width="320"
  >
    <!-- Login -->
    <v-card light v-if="!recuperaPwd" tile width="320" height="380">
      <v-system-bar window color="white" class="pl-0">
        <v-img
          :src="require('@/assets/img/favicon.png')"
          contain
          height="35"
          max-width="50"
        ></v-img>
        <v-spacer></v-spacer>
        <v-icon v-on:click="chiudi">close</v-icon>
      </v-system-bar>

      <v-card-title class="pb-0 justify-center">
        <h4>Accedi all’area riservata</h4>
      </v-card-title>

      <v-card-text class="pt-0">
        <v-form ref="formLogin" v-on:submit.prevent="login()">
          <v-text-field
            label="E-Mail"
            required
            v-model="cred.username"
            :rules="[
              () =>
                cred.username.length > 0 || 'Il campo utente è obbligatorio',
            ]"
            ref="inputMail"
            autofocus
          >
          </v-text-field>
          <v-text-field
            label="Password"
            required
            :type="showText ? 'text' : 'password'"
            :append-icon="showText ? 'visibility' : 'visibility_off'"
            @click:append="showText = !showText"
            v-model="cred.password"
            :rules="[
              () =>
                cred.password.length > 0 || 'Il campo password è obbligatorio',
            ]"
          ></v-text-field>

          <v-btn
            block
            type="submit"
            :disabled="attesaLogin"
            :loading="attesaLogin"
            color="primary"
            class="mt-4"
          >
            Accedi
          </v-btn>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-container class="pa-0">
          <v-row>
            <v-col class="pa-0">
              <v-btn text @click="recuperaPwd = true"
                >Hai dimenticato la password?
              </v-btn>
            </v-col>

            <!--   <v-col class="pa-0">
                        <v-btn text to="/registrazione" @click="chiudi">Registra un nuovo account</v-btn>
                    </v-col> -->
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>

    <!-- Recupera password -->
    <v-card light v-else>
      <v-card-title>
        <h4>Recupera la password</h4>
      </v-card-title>

      <v-card-title primary-title class="pa-0">
        <v-alert
          :value="!!alertPwd"
          :type="tipoAlert"
          transition="scale-transition"
          >{{ alertPwd }}
        </v-alert>
      </v-card-title>

      <v-form ref="resetPwd" v-on:submit.prevent="pwdDimenticata()">
        <v-card-text class="pb-0">
          Inserisci il tuo username o l'indirizzo e-mail utilizzato per la
          registrazione. Ti invieremo una e-mail con il link per reimpostare la
          password.
        </v-card-text>

        <v-card-text>
          <v-text-field
            label="E-Mail"
            required
            v-model="cred.username"
            :rules="[
              () =>
                cred.username.length > 0 || 'Il campo e-mail è obbligatorio',
            ]"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-layout column>
            <v-flex xs12>
              <v-btn
                block
                type="submit"
                :disabled="attesaMail"
                :loading="attesaMail"
                v-if="mailInvio == false"
                color="primary"
                >Invia
              </v-btn>
            </v-flex>
            <v-flex xs12>
              <v-btn block @click="recuperaPwd = false" class="mt-2">
                <v-icon>navigate_before</v-icon>Torna al login
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { pwdForgot_mail } from "@/api/api.account.js";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    auth: { type: Boolean, default: false },
  },

  data: () => ({
    cred: {
      username: "",
      password: "",
    },

    attesaLogin: false,
    showText: false,
    autofocus: false,

    recuperaPwd: false,
    alertPwd: "",
    tipoAlert: "success",
    mailInvio: false,
    attesaMail: false,
  }),

  methods: {
    async login() {
      // Validazione
      if (this.$refs.formLogin.validate() == false) {
        return;
      }

      // Attesa  login
      this.attesaLogin = true;

      // Reset messaggi
      this.$toast.close();

      try {
        // Login
        await this.$store.dispatch("auth/login", this.cred);

        // Reset dati
        this.cred.username = "";
        this.cred.password = "";

        this.$store.commit("app/setCliente", null);

        // Chiudo la dialog
        this.chiudi();

        // Aggiorno i dati cliente
        this.$emit("userInfo");
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      // Fine attesa
      this.attesaLogin = false;

      return false;
    },

    chiudi() {
      this.$emit("chiudi");
    },

    async pwdDimenticata() {
      // Validazione
      if (this.$refs.resetPwd.validate() == false) {
        return;
      }

      this.attesaMail = true;
      this.alertPwd = "";

      try {
        await pwdForgot_mail(this.cred);

        this.mailInvio = true;
        this.alertPwd = "";

        this.tipoAlert = "success";
        this.alertPwd =
          "Abbiamo inviato un messaggio al tuo indirizzo e-mail con il link per la reimpostazione della password.";
      } catch (error) {
        this.tipoAlert = "warning";
        this.alertPwd = this.$i2kFunctions.getErrorMsg(error);
      }

      this.attesaMail = false;
    },
  },

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },

  watch: {
    dialog(val) {
      // NOTA: quando apro si attiva la validazione
      if (val) {
        // Reset
        this.cred.username = "";
        this.cred.password = "";
        this.recuperaPwd = false;
        this.alertPwd = "";
        this.tipoAlert = "success";
        this.mailInvio = false;

        this.$nextTick(() => this.$refs.formLogin.resetValidation());

        // NOTA: il focus sulla dialog viene attivato solo la prima volta
        this.$nextTick(() => this.$refs.inputMail.focus());
      }
    },
  },
};
</script>
