<template>
  <v-dialog v-model="dialog" persistent max-width="50%">
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn class="mr-5 mt-2" hide-details @click="refresh" text fab small v-on="on" elevation="0">
                <v-icon color="primary" large>refresh</v-icon>
              </v-btn>
            </template>
            <span>Ricarica</span>
          </v-tooltip>
          <div>
            <v-text-field hide-details style="width: auto !important" color="primary" v-model="search"
              append-icon="search" label="Cerca"></v-text-field>
          </div>
          <v-spacer></v-spacer>
          <v-btn text icon elevation="0" class="mt-2" v-on:click="caricaCatMer">
            <v-icon large> close </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col>
            <v-data-table dense height="40vh" :headers="headersTabella" :items="listaMerceologica"
              :items-per-page="listaMerceologica.length" class="elevation-0" :search="search" :loading="loadingTabella"
              loading-text="Caricamento dati..." no-data-text="Nessun dato disponibile" mobile-breakpoint="0" fixed-header
              hide-default-header hide-default-footer :page.sync="page" @page-count="pageCount = $event"
              style="cursor: pointer; border-bottom: 1px solid black">
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th v-for="(head, i) in headers" :key="i">
                      <span v-html="head.text"></span>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item="{ item }">
                <tr v-on:click.prevent="selectRow(item)" v-on:dblclick.prevent="selectRowDouble(item)"
                  :class="{ 'row-selected': item === merceologica }">
                  <td>
                    <b>{{ item.ctMer }}</b>
                  </td>
                  <td>
                    {{ item.cmDes }}
                  </td>
                  <!-- <td>
                    {{ item.livellamento }}
                  </td>
                  <td>
                    {{ item.obsoleto }}
                  </td>
                  <td>
                    {{ item.eliminato }}
                  </td>
                  <td>
                    {{ item.gruppo }}
                  </td>
                  <td>
                    {{ item.ordinamento }}
                  </td>
                  <td>
                    {{ item.noStampaCatalogo }}
                  </td>
                  <td>
                    {{ item.disattivaInternet }}
                  </td>
                  <td>
                    {{ item.catProgOrdine }}
                  </td>
                  <td>
                    {{ item.parola }}
                  </td>
                  <td>
                    {{ item.filtro }}
                  </td>
                  <td>
                    {{ item.valore }}
                  </td>
                  <td>
                    {{ item.ctStSet }}
                  </td>
                  <td>
                    {{ item.macroCat }}
                  </td>
                  <td>
                    {{ item.noRicalcolaDBase }}
                  </td> -->
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-layout justify-center column>
          <v-row no-gutters class="ml-3">
            <div class="mt-5">
              <span>
                Tot. Record: <b>{{ listaMerceologica.length }}</b></span>
            </div>
            <v-spacer />
            <div class="pa-1 mt-2">
              <v-btn class="btn-conferma" v-on:click="conferma">CONFERMA</v-btn>
            </div>
          </v-row>
        </v-layout>
      </v-card-text>

      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_ListaCatMer } from "@/api/api.archivi.js";

import objectArchivi from "@/views/intranet/archivi/objectArchivi.js";
import storage from "@/services/storage.js";

export default {
  props: {
    dialog: { type: Boolean, default: null },
    cerca: { type: String, default: "" },
  },

  components: {},

  mixins: [objectArchivi],
  data: () => ({
    headerProps: {
      sortByText: "Ordina per",
    },
    loadingTabella: false,
    page: 1,
    pageCount: 0,
    search: "",
    headersTabella: [],
    listaMerceologica: [],
    filtri: {},

    merceologica: {},
  }),

  methods: {
    caricaCatMer() {
      //this.search = "";
      this.$emit("caricaCatMer", this.merceologica);
      this.CaricaDati();
    },

    refresh() {
      this.search = "";
      this.filtri = this.filtriTabellaAziende();
      this.CaricaDati();
    },

    async CaricaDati() {
      // Controllo login
      if (storage.checkAuth() == false) {
        return;
      }
      this.loadingTabella = true;

      this.listaMerceologica = [];
      /* let par = {
        CtMer: this.filtri.CtMer,
      }; */
      let res = await get_ListaCatMer(); //par
      this.listaMerceologica = res.data;

      this.setCodiciLista();

      this.loadingTabella = false;
    },

    setCodiciLista() {
      this.listaMerceologica.forEach(element => {
        element.ctMer = element.ctMer.substring(0, 3) + " " + element.ctMer.substring(3, 6);
      });
    },

    selectRow(i) {
      this.merceologica = i;
    },

    selectRowDouble(i) {
      this.merceologica = i;
      this.caricaCatMer();
    },

    conferma() {
      this.caricaCatMer();
    },
  },

  created() { },

  mounted() {
    this.CaricaDati();
    this.headersTabella = this.headerTabellaMerceologica();
    this.filtri = this.filtriTabellaAziende();
  },

  computed: {
    GetLoading() {
      return this.$store.getters["app/getLoading"];
    },
  },

  watch: {
    cerca(newVal) {
      if (newVal) this.search = this.cerca;
    },
  },
};
</script>

<style scoped></style>
