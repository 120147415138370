<template src="./main.html"></template>

<script>
import { mapState } from "vuex";

import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import Carrello from "@/components/dialog/CarrelloDialog.vue";
import Login from "@/components/dialog/Login.vue";
import ZoomClienti from "@/components/dialog/ZoomClienti";

import storage from "@/services/storage.js";

export default {
  components: {
    Header,
    Footer,
    Carrello,
    Login,
    ZoomClienti,
  },

  data: () => ({
    dialogCarrello: false,
    dialogLogin: false,
  }),

  methods: {
    cercaValore(valore) {
      this.$functionsApp.cercaValore(valore, this.$router);
    },

    showLeftBar() {
      this.$store.commit("app/showLeftbar");
    },

    async userInfo() {
      try {
        // Controllo login
        if (storage.checkAuth() == false) {
          return;
        }

        // Controllo se è in corso il login da OrdiniClienti.vue, non proseguo
        // La watch controlla la fine
        if (this.waitLoginAzienda) {
          return;
        }

        // User info
        await this.$store.dispatch("user/userInfo");

        // Controllo intranet
        if (this.group != "local") return;

        // Clienti
        if (this.role == 2 || this.role == 4 || this.role == 5) {
          await this.$store.dispatch("app/getClienti");
        }

        // Update
        let resUpd = await this.$i2kFunctions.checkUpdate(this.$load.getUrl_Update());
        if (resUpd) {
          this.$toast.update("E' disponibile una nuova versione");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async logout() {
      try {
        let res = await this.$store.dispatch("auth/logout");
      } catch (error) {
        console.log(error);
      }

      // Aggiorno la pagina (attualmente non usato)
      //window.location.reload();

      this.$store.commit("user/RESET_USER");
    },

    apriLogin() {
      this.dialogLogin = true;
    },
    apriCarrello() {
      this.dialogCarrello = true;
    },
    chiudiLogin() {
      this.dialogLogin = false;
    },
    chiudiCarrello() {
      this.dialogCarrello = false;
    },
  },

  mounted() {
    this.userInfo();

    this.$store.commit("app/setUrlPage", window.location.pathname);
  },

  computed: {
    ...mapState({
      loading: (state) => state.app.loading,
      utente: (state) => state.user.descUser,
      attesaRicerca: (state) => state.ricerca.attesaRicerca,
      role: (state) => state.user.role,
      group: (state) => state.user.group,
      message: (state) => state.app.message,
      waitLoginAzienda: (state) => state.app.waitLoginAzienda,
    }),

    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },

    carrello() {
      return this.$store.getters["user/getCarrello"];
    },

    dialogZoomClienti() {
      return this.$store.getters["app/getDialog"]("clienti");
    },

    dialogInfoAzienda() {
      return this.$route.query && this.$route.query.comando == "dialogParoleChiave";
    },
  },

  watch: {
    message() {
      this.$toast.alert(this.message);
    },

    $route(to, from) {
      this.$store.commit("app/setUrlPage", window.location.pathname);
    },

    waitLoginAzienda() {
      this.userInfo();
    },
  },
};
</script>
