<template>
    <v-dialog v-model="getDialog" width="900" @keydown.esc="chiudi()" persistent>
        <v-card width="900" height="650" :loading="attesa" class="px-1">
            <v-toolbar class="pa-0 pl-2">
                <v-toolbar-title>Dati</v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn text icon @click="chiudi()">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-tabs v-model="tab" slider-color="primary" grow class="mt-3">
                <v-tab ripple class="no-print" href="#desc">Descrizioni tecniche</v-tab>
                <v-tab-item class="py-0 no-print" value="desc">
                    <div style="height: 460px; overflow-y: auto;">
                        <vue-editor :editorToolbar="customToolbar" placeholder="Scrivi qui..." v-model="desc"
                            style="height: 80%;"></vue-editor>

                        <!--  <v-textarea v-text="desc" readonly auto-grow></v-textarea> -->
                    </div>

                </v-tab-item>

                <v-tab ripple class="no-print" href="#valori">Valori tecnici</v-tab>
                <v-tab-item class="py-0 no-print" value="valori">
                    <div style="height: 460px; overflow-y: auto;">
                        <v-data-table :headers="headersValTec" :items="valoriTecnici" disable-pagination hide-default-footer
                            fixed-header height="460">

                            <!-- items -->
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td v-for="(header, i) in headersValTec" style="padding: 2px !important" :key="i"
                                        @click="rowClickTecnici(header.value, item)">
                                        <span v-if="header.value != 'delete'" v-html="item[header.value]"
                                            style=" cursor: pointer;"></span>
                                        <v-btn v-else fab small text @click="deleteValTecnico(item)">
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>

                    </div>
                </v-tab-item>

                <v-tab ripple class="no-print" href="#schede">Schede prodotto</v-tab>
                <v-tab-item class="py-0 no-print" value="schede">
                    <div style="height: 460px; overflow-y: auto;">


                    </div>
                </v-tab-item>

                <v-tab ripple class="no-print" href="#correlati">Correlati</v-tab>
                <v-tab-item class="py-0 no-print" value="correlati">
                    <div style="height: 460px; overflow-y: auto;">
                        <v-data-table :headers="headersCorrelati" :items="correlati" disable-pagination hide-default-footer
                            fixed-header height="460">

                            <!-- items -->
                            <template v-slot:item="{ item }">
                                <tr>
                                    <td v-for="(header, i) in headersCorrelati" style="padding: 2px !important" :key="i">

                                        <v-btn v-if="header.value == 'delete'" fab small text
                                            @click="deleteCorrelato(item)">
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                        <v-img v-if="header.value == 'img'" :src="item.img" width="100"></v-img>
                                        <span v-else v-html="item[header.value]" style=" cursor: pointer;"></span>
                                    </td>
                                </tr>
                            </template>

                        </v-data-table>

                        <!--        <iframe src="http://localhost:8080/ricerca" width="100%" height="100%">

                        </iframe> -->

                        <!-- //document.getElementById('myframe1').contentWindow.document.getElementById('x')
 -->
                    </div>
                </v-tab-item>
            </v-tabs>

            <v-card-actions style="background-color: lightgrey;">
                <v-btn @click="nuovo()" v-if="tab == 'valori' || tab == 'correlati'">
                    <v-icon>add</v-icon>Nuovo
                </v-btn>
                <v-btn @click="salva()" v-if="tab == 'desc'">
                    <v-icon>save</v-icon>Salva
                </v-btn>
                <v-btn @click="areaTecnica" v-if="tab == 'schede'"><v-icon>folder</v-icon> Apri</v-btn>
            </v-card-actions>

        </v-card>


        <DialogInput :dialog="dialogInput" :valorePar="rowTecnico[rowTecnico.col]" :titolo="rowTecnico.col"
            @chiudi="chiudiDialogInput">
        </DialogInput>

        <DialogInputCorrelati :dialog="dialogInputCorr" @chiudi="chiudiDialogCorrelati">
        </DialogInputCorrelati>
    </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { get_datiTecnici, salva_datiTecnici, get_valoriTecnici, salva_valoriTecnici, del_valoriTecnici, get_correlati, salva_correlati, del_correlati } from "@/api/api.catalogo.js";
import DialogInput from "@/components/dialog/catalogo/DialogInput";
import DialogInputCorrelati from "@/components/dialog/catalogo/DialogInput";

export default {

    props: {
        dialog: { type: Boolean, default: false },
        testata: { type: String, default: "" },
        suffisso: { type: Number, default: 0 },
        tabDefault: { type: String, default: "" },
    },

    components: {
        VueEditor,
        DialogInput,
        DialogInputCorrelati
    },

    data: () => ({
        desc: "",

        customToolbar: [
            ["bold"],
            [{ list: "bullet" }],
        ],

        tab: "desc",

        valoriTecnici: [],
        headersValTec: [
            {
                text: "Descrizione",
                value: "etichettaTecnica",
                sortable: false,
            },
            {
                text: "ValoreTecnico1",
                value: "valoreTecnico1",
                sortable: false,
            },
            {
                text: "ValoreTecnico2",
                value: "valoreTecnico2",
                sortable: false,
            },
            {
                text: "ValoreTecnico3",
                value: "valoreTecnico3",
                sortable: false,
            },
            {
                text: "ValoreTecnico4",
                value: "valoreTecnico4",
                sortable: false,
            },
            { text: "", value: "delete", width: "50px", sortable: false },
        ],
        rowTecnico: { EtichettaTecnica: '', ValoreTecnico1: '', ValoreTecnico2: '', ValoreTecnico3: '', ValoreTecnico4: '' },

        correlati: [],
        headersCorrelati: [
            {
                text: "",
                value: "img",
                sortable: false,
            },
            {
                text: "CodTestata",
                value: "codTestata",
                sortable: false,
            },
            {
                text: "Suffisso",
                value: "suffisso",
                sortable: false,
            },
            {
                text: "Descrizione",
                value: "descrizione",
                sortable: false,
            },

            { text: "", value: "delete", width: "50px", sortable: false },
        ],
        rowCorrelati: {},

        dialogInput: false,
        dialogInputCorr: false,

        attesa: false
    }),

    methods: {


        async caricaDati() {

            if (this.dialog == false)
                return


            this.attesa = true

            // Reset
            this.desc = ""
            this.valoriTecnici = []
            this.correlati = []

            try {
                if (this.tab == "desc") {

                    let res = await get_datiTecnici(this.testata + "/" + this.suffisso)
                    let swap = res.data

                    this.desc = this.$functionsApp.getDescrizioneTecniche(swap)

                }
                else if (this.tab == "valori") {
                    let res = await get_valoriTecnici(this.testata + "/" + this.suffisso)
                    this.valoriTecnici = res.data
                }
                else if (this.tab == "correlati") {
                    let res = await get_correlati(this.testata + "/" + this.suffisso)
                    this.correlati = res.data
                }
            } catch (error) {
                console.log(error);
                this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
            }


            this.attesa = false

        },

        async salva() {

            try {
                if (this.tab == "desc") {
                    let datiSwap = { descrizioneHTML: this.desc, codiceScheda: this.testata, suffisso: this.suffisso }

                    let res = await salva_datiTecnici(datiSwap);

                    this.chiudi()
                }
                else if (this.tab == "valori") {

                    let res = await salva_valoriTecnici(this.rowTecnico);

                    this.caricaDati()
                }
                else if (this.tab == "correlati") {

                    let res = await salva_correlati(this.testata + "/" + this.suffisso, this.rowCorrelati);

                    this.caricaDati()
                }


            } catch (error) {
                console.log(error);
                this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
            }
        },

        chiudi() {

            this.$emit("chiudi");
        },

        nuovo() {
            if (this.tab == 'valori')
                this.newValTecnico()
            else if (this.tab == 'correlati')
                this.dialogInputCorr = true
        },


        /* --------- VALORI TECNICI  --------- */
        rowClickTecnici(col, row) {

            /*  console.log(col);*/
            /*  console.log(row) */

            if (col == 'delete')
                return

            this.rowTecnico = row
            this.rowTecnico.col = col

            this.dialogInput = true
        },

        chiudiDialogInput(salva, valore) {
            this.dialogInput = false

            if (salva) {
                this.rowTecnico[this.rowTecnico.col] = valore

                this.salva()
            }
        },

        async deleteValTecnico(row) {

            let confirm = await this.$confirm.open(
                "Elimina",
                "Vuoi eliminare il dato ?"
            );

            if (!confirm) return;

            try {

                let res = await (del_valoriTecnici(row))


            } catch (error) {
                console.log(error);
                this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
            }

            this.caricaDati()
        },

        newValTecnico() {
            this.rowTecnico.codiceScheda = this.testata
            this.rowTecnico.suffisso = this.suffisso
            this.rowTecnico.numeroRiga = -1

            this.salva()
        },


        /* ---- AREA TECNICA ---*/
        areaTecnica() {

            this.$functionsApp.webViewCommand(
                "areaTecnica|" +
                this.testata + "|" + this.suffisso
            );
        },


        /* ----- CORRELATI ---- */
        chiudiDialogCorrelati(salva, valore) {
            this.dialogInputCorr = false

            if (salva) {
                if (valore.indexOf("-") == -1) {
                    this.$toast.alert("Dati non validi, inserire Testata-Suffisso");
                    return
                }

                let v = valore.split("-")
                this.rowCorrelati.id = -1
                this.rowCorrelati.codTestata = v[0]
                this.rowCorrelati.suffisso = v[1]
                this.salva()
            }
        },


        async deleteCorrelato(item) {
            let confirm = await this.$confirm.open(
                "Elimina",
                "Vuoi eliminare il dato ?"
            );

            if (!confirm) return;

            this.rowCorrelati = item
            let res = await del_correlati(this.testata + "/" + this.suffisso, this.rowCorrelati)

            this.caricaDati()
        }


    },

    mounted() {
        this.caricaDati()
    },
    computed: {

        getDialog: {
            get: function () {
                return this.dialog;
            },
            set: function (value) {
                if (!value) {
                    this.chiudi();
                }
            },
        },

    },

    watch: {
        dialog() {
            this.tab = this.tabDefault

            this.caricaDati();
        },

        tab() {
            this.caricaDati()
        }
    },
}

</script>