<template>
    <v-container fluid class="pt-0">
        <v-row class="ma-0 pa-0 mt-2">
            <v-col class="ma-0 pa-0">
                <div style="height: 26px;">
                    <v-row class="ma-0 pa-0 mb-2" v-if="righeDettaglio && righeDettaglio.length > 0">
                        <span>
                            {{ righeDettaglio.length }} record</span>
                        <!-- <v-checkbox class="mt-0 pt-0 ml-4" label="Seleziona tutto" hide-details v-model="selezionaTutto"
                            @change="spuntaLavorazioni()" /> -->
                    </v-row>
                </div>

                <v-data-table dense mobile-breakpoint="0" :items="righeDettaglio" :search="search"
                    :headers="headersDettaglio" hide-default-footer :items-per-page="999" height="60vh" fixed-header
                    no-data-text="Nessun dato disponibile per la lavorazione selezionata" class="table-vertical-divider"
                    style="border-bottom: 1px solid black;">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="pr-0">
                                <v-checkbox v-if="item.tipo == 'PRODOTTO'" v-model="item.selezionato" color="primary"
                                    hide-details @change="spuntaLavorazione()" :key="inputKey" />
                            </td>
                            <!-- <td class="mr-0 pr-0"> {{ item.nrDocuStampato }} </td> -->
                            <td class="pr-0"> {{ item.nrBolla }} </td>
                            <td class="pr-0"> {{ item.nrDocuTerzista == 0 ? '' : item.nrDocuTerzista }} </td>
                            <td class="pr-0"> {{ item.nrRigaDocu }} </td>
                            <td class="pr-1"> {{ item.descrTpDocu }} </td>
                            <td class="pr-1"> {{ $i2kFunctions.formatISODate(item.dataDocu) }} </td>
                            <td class="pl-2" style="min-width: 170px;">
                                {{ $functionsApp.getCodArticoloFormat(item.cdArticolo) }}
                            </td>
                            <td class="pl-2"> {{ item.descrizioneProdotto }} </td>
                            <td> {{ item.descrizioneAggiuntiva }} </td>
                            <td class="pr-2 pl-2" style="min-width: 110px; align-items: end; text-align: end;">
                                <span v-if="item.qtordinata != 0" style="text-align: end !important;">
                                    <strong>{{ item.qtordinata }}</strong> {{ item.unMisura }}</span>
                            </td>
                            <td class="pl-2 pr-2">
                                {{ getNomeLavorazione(item.lavorazioneDaFare) }}
                            </td>
                            <td class="pl-2 pr-2" style="min-width: 170px;">
                                {{ $functionsApp.getCodArticoloFormat(item.codLavorazione) }} </td>
                        </tr>
                    </template>
                </v-data-table>
                <v-btn v-if="showBtnInvia" color="primary" v-on:click="inviaLavorazione()">Invia lavorazione</v-btn>
            </v-col>
        </v-row>

        <modal name="invioLavorazione" :adaptive="true" :width="1200" :resizable="true" :draggable="true"
            :clickToClose="true" :minWidth="1000" :minHeight="600" :reset="true">
            <v-card>
                <v-card-title>Invia lavorazione
                    <v-spacer />
                    <v-btn icon small @click="$modal.hide('invioLavorazione')">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text v-if="getLavorazioniSpuntate()[0]">
                    <span style="font-size: 1.2em;">Invio lavorazione articoli:
                    </span>

                    <v-data-table :items="getLavorazioniSpuntate()" :headers="headersInvio" height="445" fixed-header
                        hide-default-footer :items-per-page="999">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td style="min-width: 180px;" class="pl-1">
                                    {{ $functionsApp.getCodArticoloFormat(item.cdArticolo) }}
                                </td>
                                <td>{{ item.descrizioneProdotto }}</td>
                                <td style="min-width: 170px;" class="pl-1">
                                    {{ $functionsApp.getCodArticoloFormat(item.codLavorazione) }}
                                </td>
                                <td>{{ item.descrizioneAggiuntiva }}</td>
                                <td>{{ item.qtordinata }}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-btn color="primary" v-on:click="generaExcel()"> Esporta EXCEL </v-btn>
                    <v-spacer />
                    <v-btn v-on:click="$modal.hide('invioLavorazione')">Annulla</v-btn>
                    <v-btn color="primary" v-on:click="confermaInvio()">Conferma</v-btn>
                </v-card-actions>
            </v-card>
        </modal>

    </v-container>
</template>

<script>
import { post_terzisti, invia_lavorazioni } from "@/api/api.lottiProd.js";
import XLSX from "xlsx";

export default {
    name: 'DettaglioLavorazioni',
    props: {
        search: { type: String, default: "" },
        clear: { type: Boolean, default: false },
        calendar: { type: Object, default: () => { return {} } },
        cicloSel: { type: Object, default: () => { return {} } },
        lavorazioneSel: { type: Object, default: () => { return {} } },
        elencoLavorazioni: { type: Array, default: () => { return [] } },
        soloOrdiniAperti: { type: Boolean, default: false },
        filtroDoc: { type: Number, default: 0 }, // 0: DDT inviati, 1: DDT in attesa, 2: TUTTI
    },

    data: () => ({
        headersTestate: [
            { text: "Descrizione", value: "descrizione" },
            { text: "Tipo invio", value: "tipoInvio" },
            { text: "Base", value: "materialeBase" },
            { text: "Lavorazioni", value: "descrizioneLavorazione" }],

        righeDettaglio: [],
        headersDettaglio: [
            { text: "Seleziona", value: "", class: "mr-0 pr-0" },
            { text: "DDT", value: "nrBolla", class: "mr-0 pr-0" },
            { text: "Nr. doc interno", value: "nrDocuTerzista", class: "mr-0 pr-0" },
            { text: "Riga", value: "nrRigaDocu", class: "mr-0 pr-0" },
            { text: "Tipo documento", value: "descrTpDocu", class: "mr-0 pr-0" },
            { text: "Data", value: "dataDocu", class: "mr-0 pr-0" },
            { text: "Articolo", value: "cdArticolo", class: "mr-0 pr-0" },
            { text: "Descrizione prodotto", value: "descrizioneProdotto" },
            { text: "Lavorazione", value: "descrizioneAggiuntiva" },
            { text: "Quantità", value: "qtordinata", class: "pr-1" },
            { text: "Lav. da fare", value: "lavorazioneDaFare", class: "mr-0 pr-0" },
            { text: "Codice lavorazione", value: "codLavorazione", class: "mrl-0 pl-0" }
        ],

        headersInvio: [
            { text: "Articolo", value: "cdArticolo", class: "mr-0 pr-0" },
            { text: "Descrizione prodotto", value: "descrizioneProdotto" },
            { text: "Codice lavorazione", value: "codLavorazione", class: "mrl-0 pl-0" },
            { text: "Lavorazione", value: "descrizioneAggiuntiva" },
            { text: "Quantità", value: "qtordinata", class: "mr-0 pr-0" },
        ],
        showBtnInvia: false,
        inputKey: 0,
        selezionaTutto: false,
    }),

    methods: {
        async caricaDettaglio() {
            this.showBtnInvia = false;

            let res = await post_terzisti("lavorazioni/documenti" + this.getParamsCiclo(), this.calendar);
            this.righeDettaglio = res.data;
        },

        getParamsCiclo() {
            let params = "?finitura=";
            if (this.cicloSel == null || this.cicloSel.codiceFinitura == undefined)
                params += "&tipo=";
            else
                params += this.cicloSel.codiceFinitura + "&tipo=" + this.cicloSel.tipoInvio;

            if (this.lavorazioneSel == null || this.lavorazioneSel.codice == undefined)
                params += "&lavorazione=";
            else
                params += "&lavorazione=" + this.lavorazioneSel.codice;
            params += "&ordiniAperti=" + (this.soloOrdiniAperti ? '1' : '0')
            params += "&documenti=" + this.filtroDoc
            return params;
        },

        spuntaLavorazione() {
            let spunta = false;
            let tuttiSelezionati = true;

            this.righeDettaglio.forEach(el => {
                if (!el.selezionato) {
                    this.selezionaTutto = false;
                    tuttiSelezionati = false;
                } else {
                    spunta = true;
                }
            });
            this.showBtnInvia = spunta;
            if (tuttiSelezionati) this.selezionaTutto = true;
        },

        spuntaLavorazioni() {
            if (!this.righeDettaglio || this.righeDettaglio.length <= 0) return;

            if (this.selezionaTutto) {
                this.righeDettaglio.forEach(el => {
                    el.selezionato = true;
                });
                this.showBtnInvia = true;
            }
            else {
                this.righeDettaglio.forEach(el => {
                    el.selezionato = false;
                });
                this.showBtnInvia = false;
            }
            this.inputKey += 1;
        },

        inviaLavorazione() {
            this.$modal.show("invioLavorazione");
        },

        async confermaInvio() {
            let res = await invia_lavorazioni(this.getLavorazioniSpuntate());
            if (res.data != "OK") this.$toast.alert(res.data);
            else {
                this.$toast.info("Confermato l'invio delle lavorazioni");
                this.$modal.hide("invioLavorazione");
                this.caricaDettaglio();
            }
        },

        getNomeLavorazione(cod) {
            let lav = this.elencoLavorazioni.filter((el) => { return el.codice == cod });
            if (lav) {
                if (lav[0])
                    return lav[0].descrizione
            }
            return "";
        },

        generaExcel() {
            const datiEsportazione = [];
            this.getLavorazioniSpuntate().forEach(item => {
                datiEsportazione.push({
                    "DDT": item.nrBolla,
                    "Nr docu interno": item.nrDocuTerzista,
                    "Cod": item.cdArticolo,
                    "Descrizione": item.descrizioneProdotto,
                    "Q.tà": item.qtordinata,
                    "U.m.": item.unMisura
                });
                datiEsportazione.push({
                    "DDT": "",
                    "Nr docu interno": "",
                    "Cod": "",
                    "Descrizione": item.descrizioneAggiuntiva,
                    "Q.tà": "",
                    "U.m.": ""
                })
            });

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(datiEsportazione);
            const columnWidths = [
                { wch: 8 },
                { wch: 14 },
                { wch: 16 },
                { wch: 74 },
                { wch: 8 },
                { wch: 8 },
            ];
            ws['!cols'] = columnWidths;
            XLSX.utils.book_append_sheet(wb, ws, 'Dati');

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([wbout], { type: 'application/octet-stream' });

            const fileName = 'dati_invio_lavorazione.xlsx';
            if (window.navigator.msSaveOrOpenBlob) {
                // Per Internet Explorer
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }
        },

        getLavorazioniSpuntate() {
            return this.righeDettaglio.filter(el => el.selezionato == true);
        }
    },

    mounted() {
        this.caricaDettaglio();
    },

    watch: {
        clear: function () {
            this.caricaDettaglio();
        },

        cicloSel: function () {
            this.caricaDettaglio();
        },

        lavorazioneSel: function () {
            this.caricaDettaglio();
        }
    },
}
</script>