<template>
  <div>
    <v-text-field hide-details v-model="inputText" placeholder="Cerca" @keyup.13="cercaValore()" @focus="
      isFocused = true;" append-icon="search" dense style="width:500px;" @click:append="cercaValore()" filled rounded
      clearable>
    </v-text-field>

  </div>
</template>

<script>

export default {
  props: {
    disabled: { type: Boolean, defualt: false },
    role: { type: Number, default: 0 },
  },

  data: () => ({
    isFocused: false,
  }),

  methods: {

    cercaValore() {
      // Controllo presenza valore
      if (this.inputText.length > 0) {
        this.$functionsApp.cercaValore(this.inputText, this.$router);
      }
    },

  },

  mounted() { },



  computed: {
    inputText: {
      set(valore) {
        this.$store.commit("ricerca/setValoreRicerca", valore);
      },
      get() {
        let res = this.$store.getters["ricerca/valoreRicerca"];

        if (res.toLowerCase() == "preferiti") return "";
        if (res.toLowerCase() == "prodotti") return "";
        else return res;
      },
    },
  },
};
</script>

<style scoped>
/* Search bar */
/* .search-bar {
  background-color: white;
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  z-index: 2;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 1px solid #ccc;
}
 */
/*Riga rossa */
/*.search-bar:after {
  content: "";
  transform: scaleX(0);
  bottom: -1px;
   border-bottom: 2px solid #ff0017; 
  position: absolute;
  left: 0;
  width: 100%;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}
.search-bar.is-focused:after {
  transform: scaleX(1);
}*/

/* .search-bar__input {
  max-width: 100%;
  width: 100%;
}

.search-bar__input input {
  caret-color: #ff0017;
  max-width: 100%;
  width: 100%;
  padding: 12px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-border-before-color: transparent;
  outline: none !important;
}

.search-bar__icon {
  padding: 12px;
  display: flex;
  user-select: none;
}

.v-input__icon.v-input__icon--append * {
  cursor: pointer;
  display: inline-flex;
}

.no-userinput {
  background-color: #efefef !important;
  color: #ccc;
  pointer-events: none;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Dropdown marchi */
/* .dropdown-ci {
  user-select: none;
  background-color: #eee;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 12px;
  width: 210px;
  text-align: center;
  border-radius: 0.25rem 0.25rem 0px 0px;
  margin-right: 10px
}

.dropdown-container {
  transform: translateY(-50px);
  opacity: 0;
  position: absolute;
  visibility: collapse;
  z-index: -1 !important;
  background-color: white;
  padding: 16px;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), z-index 0s;
  overflow: hidden;
  top: 60px;
  height: calc(100vh - 128px);
  pointer-events: none;
} */

/* .dropdown-container.active {
  pointer-events: all;
  opacity: 1;
  z-index: auto;
  overflow: auto;
  visibility: visible;
  transform: none;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.05),
    0px 10px 30px rgba(0, 0, 0, 0.1);
} */

/* @media screen and (min-width: 960px) {
  .dropdown-container {
    max-height: calc(100vh - 64px);
    height: 420px;
    width: 850px;
    left: auto;
    right: auto;
  }

  .dropdown-container.active {
    width: 850px;
  }

  .labelCombo {
    font-size: 20px;
    color: black;
  } 
/* } */
</style>
