<template>
  <!-- <router-link :to="href" class="unstyle-link"> -->
    <div class="news-card" :class="imageOrientation == 'landscape' ? 'portrait' : 'landscape'">
      <div
        class="news-card__image"
        :class="imageOrientation"
        :style="'background-image: url(\''+src+'\')'"
      ></div>
      <div class="news-card__body">
        <h1>
          <slot name="titolo"></slot>
        </h1>
        <h2 class="news-subheading">
          <slot name="sottotitolo"></slot>
        </h2>
        <p class="desc-preview">
          <slot name="descrizione"></slot>
        </p>
      </div>
      <v-btn :to="href"  color="primary" class="read-more">
        Leggi
        <v-icon right small>chevron_right</v-icon>
      </v-btn>
    </div>
  <!-- </router-link> -->
</template>

<script>
export default {
  name: "news-card",
  props: {
    src: String,
    imageOrientation: {
      type: String,
      default: "landscape"
    },
    href: {
      type: String,
      required: true
    }
  },

  mounted() {}
};
</script>


<style scoped>
.news-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.news-card.portrait {
  height: 400px;
  border-bottom: 1px solid #ccc;
}
.news-card.landscape {
  height: 100%;
  border-bottom: 1px solid #ccc;
}

h2.news-subheading {
  font-weight: normal;
  font-style: italic;
  font-size: 1.14286em;
}
div.news-card__image {
  background-color: #ccc;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin-bottom: 16px;
}
p.desc-preview {
  max-height: 120px;
  overflow: hidden;
}
p.desc-preview:after {
  content: "";
  background-image: linear-gradient(to top, white 50%, transparent);
  height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.news-card.landscape p.desc-preview {
  position: relative;
}
.news-card.landscape p.desc-preview:after {
  height: 60px;
  transform: translateY(30px)
}
div.news-card__image.landscape {
  width: 100%;
  height: 0px;
  padding-top: calc(300% / 4);
}
div.news-card__image.portrait {
  width: 30%;
  height: 0px;
  padding-top: calc(120% / 3);
}
div.news-card.landscape div.news-card__body {
  width: 70%;
  padding-left: 16px;
}
.read-more,
.read-more:hover,
.read-more:active {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
