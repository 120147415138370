import request from '@/services/interceptor.js'

/* TESTATA PRODOTTO */
export const get_prodotti = (par) => {
    return request({
        url: '/testataProdotto/prodotti',
        method: 'post',
        data: par
    })
};

export const add_testata = (par) => {
    return request({
        url: '/testataProdotto/prodotti/add/testata',
        method: 'post',
        data: par
    })
};

export const edit_testata = (par) => {
    return request({
        url: '/testataProdotto/prodotti/edit/testata',
        method: 'post',
        data: par
    })
};

export const delete_testata = (par) => {
    return request({
        url: '/testataProdotto/prodotti/delete/testata',
        method: 'post',
        data: par
    })
};

export const get_listaAziende = () => {
    return request({
        url: '/testataProdotto/prodotti/codiceAzienda',
        method: 'get'
    })
};

export const get_ListaCatMer = () => {
    return request({
        url: '/testataProdotto/prodotti/catMerceologica',
        method: 'get'
    })
};

export const getTestata = (par) => {
    return request({
        url: '/testataProdotto/prodotti/getTestata',
        method: 'post',
        data: par
    })
};

export const set_infoTestata = (par) => {
    return request({
        url: '/testataProdotto/prodotti/info/testata',
        method: 'post',
        data: par
    })
};

export const get_infoTestata = (par) => {
    return request({
        url: '/testataProdotto/info',
        method: 'post',
        data: par
    })
};

export const get_logOperazioni = (par) => {
    return request({
        url: '/testataProdotto/prodotti/logOperazioni',
        method: 'post',
        data: par
    })
};

export const set_logOperazioni = (par) => {
    return request({
        url: '/testataProdotto/prodotti/logOperazioni/operazione',
        method: 'post',
        data: par
    })
};

export const confermaPsw = (par) => {
    return request({
        url: '/testataProdotto/prodotti/conferma/password',
        method: 'post',
        data: par
    })
};

export const set_suffissoTestataDuplicata = (par) => {
    return request({
        url: '/testataProdotto/prodotti/set/suffisso',
        method: 'post',
        data: par
    })
};



/* GESTIONALE FILTRI */
export const get_filtriParoleChiaveArchivi = () => {
    return request({
        url: '/testataProdotto/prodotti/parolechiave/filtri',
        method: 'get',
        noLoadingBar: true
    })
};

export const get_numeroFiltriParoleChiaveArchivi = () => {
    return request({
        url: '/testataProdotto/prodotti/parolechiave/numero/filtri',
        method: 'get',
        noLoadingBar: true
    })
};

export const get_numeroNomiFiltri = () => {
    return request({
        url: '/testataProdotto/prodotti/parolechiave/numero/nomi/filtri',
        method: 'get',
        noLoadingBar: true
    })
};

export const get_numeroValoriFiltri = (param) => {
    return request({
        url: '/testataProdotto/prodotti/parolechiave/numero/valori/filtri/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_valoriFiltri = (param) => {
    return request({
        url: '/testataProdotto/prodotti/parolechiave/valori/filtri/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_pswUser = (par) => {
    return request({
        url: '/testataProdotto/prodotti/get/user/password',
        method: 'post',
        data: par
    })
};

export const add_filtro = (num, param) => {
    return request({
        url: '/testataProdotto/prodotti/aggiungi/filtro/' + num + '/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const delete_filtro = (param) => {
    return request({
        url: '/testataProdotto/prodotti/elimina/filtro/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const edit_filtro = (param) => {
    return request({
        url: '/testataProdotto/prodotti/modifica/filtro/' + param,
        method: 'get',
        noLoadingBar: true
    })
};





/* MARCHI AZIENDA */
export const get_aziende = (par) => {
    return request({
        url: '/marchiAzienda/marchi',
        method: 'post',
        data: par
    })
};

export const edit_marchio = (par) => {
    return request({
        url: '/marchiAzienda/marchi/edit/marchio',
        method: 'post',
        data: par
    })
};

export const add_marchio = (par) => {
    return request({
        url: '/marchiAzienda/marchi/add/marchio',
        method: 'post',
        data: par
    })
};

export const duplica_marchio = (par) => {
    return request({
        url: '/marchiAzienda/marchi/duplica/marchio',
        method: 'post',
        data: par
    })
};

export const delete_marchio = (par) => {
    return request({
        url: '/marchiAzienda/marchi/delete/marchio',
        method: 'post',
        data: par
    })
};

export const get_listaLineeProdotto = () => {
    return request({
        url: '/marchiAzienda/marchi/lineeProdotto',
        method: 'get'
    })
};

export const getMarchio = (par) => {
    return request({
        url: '/marchiAzienda/marchi/getTestata',
        method: 'post',
        data: par
    })
};

export const get_logOperazione = (par) => {
    return request({
        url: '/marchiAzienda/marchi/logOperazioni',
        method: 'post',
        data: par
    })
};

/* CATALOGO */
export const get_listaCataloghi = () => {
    return request({
        url: '/testataProdotto/prodotti/cataloghi',
        method: 'get'
    })
};

export const get_listaCategorieCataloghi = (par) => {
    return request({
        url: '/testataProdotto/prodotti/categorie/cataloghi',
        method: 'post',
        data: par
    })
};

export const get_listaSottoCategorieCataloghi = (par) => {
    return request({
        url: '/testataProdotto/prodotti/sotto/categorie/cataloghi',
        method: 'post',
        data: par
    })
};

export const set_cataloghi = (par) => {
    return request({
        url: '/testataProdotto/prodotti/set/categorie/sottoCategorie',
        method: 'post',
        data: par
    })
};

export const get_codiceLinea = (par) => {
    return request({
        url: '/testataProdotto/prodotti/get/codiceLinea',
        method: 'post',
        data: par
    })
};

/* FILTRI EXCEL */
//carica file
export const carica_file_filtri = (data) => {
    return request({
        url: '/testataProdotto/filtri/upload',
        method: 'post',
        data: data,
    })
}