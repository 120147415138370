<template>
    <v-dialog v-model="dialog" persistent max-width="20%">
        <v-card>
            <v-card-title>
                <v-row no-gutters>
                    <h4>Modifica filtro</h4>
                    <v-spacer></v-spacer>
                    <v-btn hide-details small text icon elevation="0" class="mt-0" @click="rinominaEliminaFiltro">
                        <v-icon> close </v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>

            <v-card-text class="ma-0 pa-0">
                <v-row no-gutters class="ma-2 pa-2">
                    <v-text-field hide-details style="width: auto !important" color="primary" v-model="EditFiltro.nome"
                        label="Filtro" append-icon="delete" @click:append="eliminaFiltro"
                        v-on:keyup.enter="salvaDati"></v-text-field>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <div class="mr-0">
                        <v-btn class="mr-2" small v-on:click="rinominaEliminaFiltro">ANNULLA</v-btn>
                        <v-btn class="btn-conferma" small v-on:click="salvaDati">CONFERMA</v-btn>
                    </div>
                </v-row></v-card-actions>
        </v-card>

        <!-- DIALOG PASSWORD -->
        <v-dialog v-model="dialogPsw" @keydown.esc="dialogPsw = !dialogPsw" persistent max-width="400px">
            <v-system-bar dark dense color="#e40520">
                <span class="white--text">Elimina filtro</span>
            </v-system-bar>

            <v-card>
                <v-card-text style="font-size: 15px" class="pa-3">
                    <v-text-field v-model="password" autocomplete="new-password"
                        :append-icon="show1 ? 'visibility' : 'visibility_off'" :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'" name="input-10-1" label="Password" hint="Minimo 8 caratteri"
                        counter @click:append="show1 = !show1" ref="inputPsw" autofocus></v-text-field>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn small class="mr-2" v-on:click="dialogPsw = !dialogPsw">ANNULLA</v-btn>
                    <v-btn small class="btn-conferma" v-on:click="confermaPsw">CONFERMA</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>
  
<script>
import { get_pswUser, delete_filtro, edit_filtro } from "@/api/api.archivi.js";

export default {
    props: {
        dialog: { type: Boolean, default: null },
        Filtro: { type: Object, default: null },
    },

    components: {},

    data: () => ({
        EditFiltro: {
            nome: "",
            numero: 0,
        },

        dialogPsw: false,
        show1: false,
        password: "",
        rules: {
            required: (value) => !!value || "Campo obbligatorio.",
            min: (v) => v.length >= 8 || "Minimo 8 caratteri",
        },
        autofocus: false,
    }),

    methods: {
        rinominaEliminaFiltro(e, item, num) {
            this.password = "";
            this.dialogPsw = false;
            this.$emit("rinominaEliminaFiltro", e, item, num);
        },

        async salvaDati() {
            //update query filtro
            let res = await edit_filtro(this.EditFiltro.nome);
            if (res.data == "OK") this.rinominaEliminaFiltro(undefined, undefined, -9999);
        },

        eliminaFiltro() {
            if (this.EditFiltro.numero > 0) {
                this.$toast.alert(
                    "Impossibile eliminare! Questo filtro è presente in almeno una testata."
                );
            } else {
                this.dialogPsw = true;
            }
        },

        async confermaPsw() {
            //query controllo password utente loggato
            let par = {
                UserName: this.getUser,
                Password: this.password,
            }
            let res = await get_pswUser(par);
            if (res.data == "OK") {
                //delete query filtro
                let res = await delete_filtro(this.EditFiltro.nome);
                if (res.data == "OK") this.rinominaEliminaFiltro(undefined, undefined, -9999);
            } else {
                this.$toast.alert(
                    "Password errata! Riprovare."
                );
            }
        },
    },

    created() { },

    mounted() {
    },

    computed: {
        GetLoading() {
            return this.$store.getters["app/getLoading"];
        },
        getUser() {
            return this.$store.getters["user/getUser"];
        },
    },

    watch: {
        dialog(newVal) {
            if (newVal) this.EditFiltro = this.Filtro;
        },
    },
};
</script>
  
<style scoped></style>  