<template>
    <v-container fluid class="pt-0">
        <v-row class="ma-0 pa-0 mt-2">
            <v-col style="border-bottom: 1px solid black;" class="ma-0 pa-0">
                <div style="height: 26px;">
                    <v-row class="ma-0 pa-0 mb-2">
                        <span>
                            {{ elencoDati.length }} record</span>
                        <v-checkbox class="mt-0 pt-0 ml-4" label="Seleziona tutto" hide-details v-model="selezionaTutto"
                            @change="spuntaLavorazioni()" />
                        <v-spacer />
                        <v-switch label="Mostra storico" v-model="storico" hide-details class="mt-0 pt-0" />
                    </v-row>
                </div>

                <v-data-table dense mobile-breakpoint="0" :items="elencoDati" :headers="headersTabella"
                    :items-per-page="999" hide-default-footer :search="search" no-data-text="Nessun dato disponibile"
                    fixed-header height="60vh" class="table-vertical-divider"
                    v-if="elencoDati && elencoDati.length > 0">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="mr-0 pr-0">
                                <v-checkbox v-model="item.selezionato" color="primary" hide-details small dense
                                    @change="spuntaLavorazione()" :key="inputKey" />
                            </td>
                            <td class="pl-2">{{ item.nrBolla }}</td>
                            <td class="pl-2">{{ item.nrDocuTerzista == 0 ? '' : item.nrDocuTerzista }} </td>
                            <td class="pl-2">{{ item.nrRigaDocu }}</td>
                            <td style="min-width: 170px;" class="pl-2">
                                {{ $functionsApp.getCodArticoloFormat(item.cdArticolo) }}</td>
                            <td class="pl-2">{{ item.descrizioneProdotto }}</td>
                            <td class="pl-2">{{ $i2kFunctions.formatISODate(item.dataRicevimento) }}</td>
                            <td class="pl-2">{{ item.lavorazione }}</td>
                            <td style="min-width: 70px; align-items: end; text-align: end;">{{ item.qtaRicevuta }}</td>
                            <td style="min-width: 70px; align-items: end; text-align: end;">{{ item.scarto }}</td>
                            <!-- <td>{{ item.leadTime }}</td> -->
                            <!-- <td v-if="!soloOrdiniAperti">
                                <span v-if="item.Ricevimento">
                                    {{ $i2kFunctions.formatISODate(item.DataRicevimento) }}
                                </span>
                            </td> -->
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 mt-2">
            <v-btn v-if="showBtnEsporta" color="primary" v-on:click="esportaExcel()">
                Esporta EXCEL</v-btn>
        </v-row>
    </v-container>
</template>

<script>
import { get_terzisti, set_esporta_lavorazioni } from "@/api/api.lottiProd.js"
import XLSX from "xlsx";

export default {
    name: 'LavorazioniCompletate',
    props: {
        search: { type: String, default: "" },
        clear: { type: Boolean, default: false },
        calendar: { type: Object, default: () => { return {} } },
        cicloSel: { type: Object, default: () => { return {} } },
        lavorazioneSel: { type: Object, default: () => { return {} } },
    },

    data: () => ({
        elencoDati: [],
        headersTabella: [
            { text: "Seleziona", value: "", class: "mr-0 pr-0" },
            { text: "Numero DDT", value: "nrBolla", class: "mr-0 pr-0" },
            { text: "Numero doc interno", value: "nrDocuTerzista", class: "mr-0 pr-0" },
            { text: "Riga", value: "nrRigaDocu", class: "mr-0 pr-0" },
            { text: "Codice articolo", value: "cdArticolo", class: "mr-0 pr-0" },
            { text: "Descrizione articolo", value: "descrizioneProdotto", class: "mr-0 pr-0" },
            { text: "Data invio", value: "dataRicevimento", class: "mr-0 pr-0" },
            { text: "Lavorazione", value: "lavorazione", class: "mr-0 pr-0" },
            { text: "Quantita inviata", value: "qta", class: "pr-1" },
            { text: "Scarto", value: "scarto", class: "pr-1" },
        ],
        showBtnEsporta: true,
        selezionaTutto: false,
        storico: false,

        inputKey: 0,
    }),

    methods: {
        async caricaDati() {
            this.showBtnEsporta = false;
            let par = "lavorazioni_completate/elenco/" + (this.storico ? "1" : "0");
            let res = await get_terzisti(par);
            this.elencoDati = res.data;
        },

        spuntaLavorazione() {
            let spunta = false;
            let tuttiSelezionati = true;

            this.elencoDati.forEach(el => {
                if (!el.selezionato) {
                    this.selezionaTutto = false;
                    tuttiSelezionati = false;
                } else {
                    spunta = true;
                }
            });
            this.showBtnEsporta = spunta;
            if (tuttiSelezionati) this.selezionaTutto = true;
        },

        addHeadersTabella() {
            this.headersTabella.push({ text: "Data ricevimento", value: "DataRicevimento" });
        },

        removeHeadersTabella() {
            this.headersTabella.pop();
        },

        spuntaLavorazioni() {
            this.inputKey += 1;
            if (!this.elencoDati || this.elencoDati.length <= 0) return;

            if (this.selezionaTutto) {
                this.elencoDati.forEach(el => {
                    el.selezionato = true;
                });
                this.showBtnEsporta = true;
            }
            else {
                this.elencoDati.forEach(el => {
                    el.selezionato = false;
                });
                this.showBtnEsporta = false;
            }
        },

        async esportaExcel() {
            let arr = this.elencoDati.filter(el => el.selezionato);
            const datiEsportazione = arr.map(item => ({
                "Nr docu interno": item.nrDocuTerzista,
                "Cod": item.cdArticolo,
                "Descrizione": item.descrizioneAggiuntiva,
                "Q.tà": item.qtaRicevuta,
                "Scarto": item.scarto,
                "U.m.": item.unMisura
            }));

            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(datiEsportazione);
            const columnWidths = [
                { wch: 12 },
                { wch: 16 },
                { wch: 70 },
                { wch: 8 },
                { wch: 8 },
                { wch: 8 },
            ];
            ws['!cols'] = columnWidths;
            XLSX.utils.book_append_sheet(wb, ws, 'Dati');

            const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([wbout], { type: 'application/octet-stream' });

            const fileName = 'dati.xlsx';
            if (window.navigator.msSaveOrOpenBlob) {
                // Per Internet Explorer
                window.navigator.msSaveOrOpenBlob(blob, fileName);
            } else {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }

            let res = await set_esporta_lavorazioni(arr);
            if (res.data != "OK") {
                this.$toast.alert("Errore nell'esportazione dei dati");
            } else this.caricaDati();
        }

    },

    mounted() {
        this.caricaDati();
    },

    watch: {
        clear: function () {
            this.caricaDati();
        },

        storico() {
            if (!this.elencoDati) return;
            this.selezionaTutto = false;
            this.spuntaLavorazioni();
            this.caricaDati();
        }

        /* soloOrdiniAperti: function (newVal, oldVal) {
            if (!newVal) this.addHeadersTabella();
            else this.removeHeadersTabella();
        } */
    }
}
</script>