<template>
  <v-dialog v-model="dialog" width="400" persistent @keydown.esc="chiudi(null)">
    <v-card>
      <v-card-text>
        <v-col v-if="datiDocu != null">
          <v-row class="ma-0 pa-0">
            <v-col cols="5">
              <span class="descrizione">Scatole</span>
            </v-col>
            <v-col cols="7">
              <!-- sull'invio sposto il focus sui fasci -->
              <v-text-field type="number" v-model="datiDocu.colli_Scatole" @focus="$event.target.select()"
                class="valoreInput" tabindex="1" v-on:keyup.enter="$refs.colli_Fasci.focus()" ref="colli_Scatole" />
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col cols="5">
              <span class="descrizione">Fasci</span>
            </v-col>
            <v-col cols="7">
              <!-- sull'invio sposto il focus sulle barre -->
              <v-text-field type="number" v-model="datiDocu.colli_Fasci" @focus="$event.target.select()"
                class="valoreInput" tabindex="2" v-on:keyup.enter="$refs.colli_Barre.focus()" ref="colli_Fasci" />
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col cols="5">
              <span class="descrizione">Barre</span>
            </v-col>
            <v-col cols="7">
              <v-text-field type="number" v-model="datiDocu.colli_Barre" @focus="$event.target.select()"
                class="valoreInput" tabindex="3" v-on:keyup.enter="$refs.colli_Latte.focus()" ref="colli_Barre" />
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col cols="5">
              <span class="descrizione">Latte</span>
            </v-col>
            <v-col cols="7">
              <!-- sull'invio sposto il focus sui pallet -->
              <v-text-field type="number" v-model="datiDocu.colli_Latte" @focus="$event.target.select()"
                class="valoreInput" tabindex="4" v-on:keyup.enter="$refs.colli_Pallet.focus()" ref="colli_Latte" />
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col cols="5">
              <span class="descrizione">Pallet</span>
            </v-col>
            <v-col cols="7">
              <!-- sull'invio sposto il focus sulle barre -->
              <v-text-field type="number" v-model="datiDocu.colli_Pallet" @focus="$event.target.select()"
                class="valoreInput" tabindex="5" v-on:keyup.enter="$refs.colli_Altro.focus()" ref="colli_Pallet" />
            </v-col>
          </v-row>

          <v-row class="ma-0 pa-0">
            <v-col cols="5">
              <span class="descrizione">Altro</span>
            </v-col>
            <v-col cols="7">
              <!-- sull'invio confermo -->
              <v-text-field type="number" v-model="datiDocu.colli_Altro" @focus="$event.target.select()"
                class="valoreInput" tabindex="6" v-on:keyup.enter="conferma()" ref="colli_Altro" />
            </v-col>
          </v-row>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn @click="chiudi(null)" tabindex="8"> annulla </v-btn>
        <v-btn @click="conferma()" tabindex="7" class="btn-conferma"> Conferma </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    datiDocu: { type: Object, default: null },
  },

  methods: {
    conferma() {
      this.chiudi(this.datiDocu);
    },

    chiudi(dati) {
      this.$emit("chiudi", dati);
    },

    getSommaColli() {
      return (
        this.datiDocu.colli_Altro +
        this.datiDocu.colli_Barre +
        this.datiDocu.colli_Fasci +
        this.datiDocu.colli_Latte +
        this.datiDocu.colli_Pallet +
        this.datiDocu.colli_Scatole
      );
    },
  },

  watch: {
    async dialog() {
      if (this.dialog) {
        let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
        await wait(100);
        this.$refs.colli_Scatole.focus();
      }
    },
  },
};
</script>

<style scoped>
.descrizione {
  font-size: 1.3em;
  text-align: center;
  display: flex;
  align-items: center;
  height: 100% !important;
}

.valoreInput {
  font-size: 1.5em;
}
</style>