<template>
  <v-row no-gutters>
    <v-col cols="12" class="ma-0 pa-0">
      <vue-editor
        class="mt-6 mb-10"
        style="height: 65vh"
        :editorToolbar="customToolbar"
        placeholder="Scrivi qui..."
        v-model="testata.informazioni"
      ></vue-editor>
    </v-col>
  </v-row>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: {
    testata: { type: Object, default: null },
  },

  components: {
    VueEditor,
  },

  data: () => ({
    //campo Informazioni
    customToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"],
      [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      //[{ indent: "-1" }, { indent: "+1" }],
      [{ color: [] }, { background: [] }],
      ["link", "image", "video"],
      //["clean"],
    ],
  }),

  methods: {},

  mounted() {},

  watch: {},
};
</script>
