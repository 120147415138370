<template src="./mapDispositivi.html"></template>

<script>
import {
  get_dispositivi,
  get_dispositivo,
  set_dispositivo,
  del_dispositivo,
  get_immagine_dispositivo,
  save_immagine_dispositivo,
  del_immagine_dispositivo
} from "@/api/api.intranet.js";

import mixinDispositivi from "./mixinDispositivi";

import ImageInput from "@/components/intranet/ImageInput.vue";

export default {
  components: {
    ImageInput
  },
  mixins: [mixinDispositivi],
  data() {
    return {
      dialogAdd: false,
      headers: [],
      elencoDati: [],

      editedItem: {},
      filtro: {
        tipo: "",
        nome: "",
      },
      search: "",
      page: 1,
      pageCount: 0,
      itemsPerPage: 20,
      selectedRow: [],
      rowIsSelected: false,
      azione: "nuovo",

      elencoTipi: [],

      expandSped: [],

      avatar: { imageURL: "" },
      saving: false,
      saved: false,
      show: false,

    };
  },

  methods: {
    caricaDati() {
      this.caricaDatiTabella();
    },

    async caricaDatiTabella() {
      let res = await get_dispositivi();
      this.elencoDati = res.data;
    },

    pulisci() {
      this.rowIsSelected = false;
      this.headers = [];
      this.elencoDati = [];
    },

    close() {
      this.dialogAdd = false;
    },

    ValidateIPaddress(ipaddress) {
      if (
        this.$functionsApp.validateIP(ipaddress)
      ) {
        return true;
      }
      this.$toast.alert("Indirizzo IP non valido!");
      return false;
    },

    selectRow(props) {
      // Dettaglio
      this.selectedRow = props;
      this.rowIsSelected = true;

      this.elencoDati.forEach((el) => {
        if (el != props) el.expanded = false;
      });

      this.expandSped = [];
      this.expandSped.push(props);
      props.expanded = !props.expanded;

      if (!props.expanded) {
        this.selectedRow = {};
      }
    },

    async save() {
      this.loading = true;
      //await this.uploadImage();
      if (this.editedItem.nome == "" || this.editedItem.nome == null) {
        this.$toast.alert("Il nome non è valido!");
        return;
      } else {
        if (this.editedItem.ip != null && this.editedItem.ip != "") {
          if (!this.ValidateIPaddress(this.editedItem.ip)) {
            this.$toast.alert("L'indirizzo IP non è valido!");
            return;
          }
        }
        let res = await set_dispositivo(this.editedItem);

        if (res.data == "NO")
          this.$toast.alert("Impossibile aggiungere l'elemento!");
        else this.caricaDati();

        this.pulisciCampi();
        this.close();
      }


    },

    avviaComando(tipo, item) {
      this.selectedRow = item;
      switch (tipo) {
        case "modifica":
          this.azione = "modifica";
          this.editItem();
          break;
        case "elimina":
          this.deleteItem();
          break;
      }
      //this.azione = "nuovo";
    },

    deleteItem() {
      if (
        this.selectedRow == null ||
        this.selectedRow.id == undefined ||
        this.selectedRow.id <= 0
      )
        return;
      this.loading = true;
      this.$confirm
        .open("Elimina", "Vuoi eliminare l'elemento ?")
        .then((confirm) => {
          if (!confirm) return;
          del_dispositivo(this.selectedRow.id)
            .then((res) => {
              this.loading = false;
              if (res.data == "NO") {
                this.$toast.alert("Impossibile eliminare l'elemento!");
              } else {
                this.caricaDati();
              }
            })
            .catch((err) => {
              this.$toast.alert("Impossibile eliminare l'elemento!");
              this.loading = false;
            });
        });
    },

    async editItem() {
      if (
        this.selectedRow == null ||
        this.selectedRow.id == undefined ||
        this.selectedRow.id <= 0
      )
        return;
      let res = await get_dispositivo(this.selectedRow.id);
      this.editedItem = res.data;
      this.dialogAdd = true;
      await this.caricaImmagini();
    },

    refresh() {
      this.rowIsSelected = false;
      this.selectedRow = [];
      this.caricaDati();
    },

    pulisciCampi() {
      this.selectedRow = [];
      this.editedItem = {};
      this.rowIsSelected = false;
    },

    openDialogAdd() {
      this.editedItem = {};
      this.azione = "nuovo";
      this.dialogAdd = true;
    },

    async caricaImmagini() {
      let res = await get_immagine_dispositivo(this.selectedRow.id);

      if (res.data == "") this.avatar = null;
      else {
        this.avatar = { imageURL: res.data };
      }
      this.saved = true;
      this.saving = false;
    },

    async wait(millisecs) {
      let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
      await wait(millisecs);
    },

    async uploadImage() {
      this.saving = true;
      await save_immagine_dispositivo(this.selectedRow.id, this.avatar.formData);
      this.saving = false;
      this.saved = true;

      await this.wait(50);
      this.saved = true;
    },

    async cancellaImmagine() {
      let res = await del_immagine_dispositivo(this.selectedRow.id);
      if (res.data != "OK") {
        this.$toast.alert("Errore. Impossibile cancellare l'immagine");
        return;
      }
      this.caricaImmagini();
      this.saved = false;
    },

    showOverlay() {
      this.show = true;
    },

    hideOverlay() {
      this.show = false;
    },
  },

  mounted() {
    this.headers = this.getHeadersTabella();
    this.editedItem = this.getEditItem();
    this.elencoTipi = this.getElencoTipi();
  },

  created() {
    this.caricaDati();
  },

  computed: {
    headersDettaglioDisp() {
      return this.getHeadersTabellaDettaglio()
    }
  },
  watch: {
    "avatar.imageURL": function (newVal, oldVal) {
      if (oldVal != "" && oldVal != null) this.saved = false;
      //if (newVal != "" && newVal != null) this.imageRows.push(newVal);
    }
  }
};
</script>


<style scoped>
.container {
  position: relative;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  max-width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.overlay:hover {
  opacity: 1;
}

.overlay h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.overlay p {
  font-size: 16px;
  margin-left: 5px;
}
</style>