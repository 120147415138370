import store from "@/vuex/index.js"

export default {


  getCodArticoloFormat(codArticolo) {

    if (!!codArticolo == false)
      return ""

    let str = ""
    str = codArticolo.substring(0, 3)
    str += " "
    str += codArticolo.substring(3, 8)
    str += " "
    str += codArticolo.substring(8, 10)
    str += " "
    str += codArticolo.substring(10, 12)
    if (codArticolo.length >= 12) {
      str += " "
      str += codArticolo.substring(12, 14)
    }

    return str
  },

  getSconti(sc1, sc2, sc3) {
    let res = "";

    if (sc1 != 0) res += sc1.toString() + "+";
    if (sc2 != 0) res += sc2.toString() + "+";
    if (sc3 != 0) res += sc3.toString() + "+";

    if (res.length > 1) res = this.remove_last_character(res);

    return res;
  },



  convertiTestoGrassetto(valore) {

    let firstvariable = '"';
    let secondvariable = '"';

    // NOTA: ig ->  Case insensitive match
    let regExString = new RegExp(
      "(?:" + firstvariable + ")(.*?)(?:" + secondvariable + ")",
      "ig"
    );


    let testRE = regExString.exec(valore);

    if (testRE && testRE.length > 1) {

      valore = valore.replace(
        firstvariable + testRE[1] + secondvariable,
        "<b>" + testRE[1] + "</b>"
      );

      // Controllo ricorsione
      if (valore.includes(firstvariable))
        valore = this.convertiTestoGrassetto(valore)
    }


    // Sostituisce underscore
    if (valore)
      valore = valore.replaceAll("_", "&nbsp;").replaceAll("#", "&nbsp;")

    return valore;
  },

  convertiTestoMinuscolo(valore) {

    valore = valore[0] + valore.slice(1).toLowerCase();

    return valore;
  },



  getTimeFromDate(data) {
    return new Date(data).toLocaleTimeString('it-IT', {
      hour: '2-digit',
      minute: '2-digit'
    })
  },

  getColoreCatalogo(obsoleto, eliminato, noStampaCataglogo, disattivaInternet) {
    if (disattivaInternet) return "#FF1493"
    else if (eliminato) return "blue"
    else if (obsoleto) return "orange"
    else if (noStampaCataglogo) return "#f0e130"

    return "";
  },

  getTooltipPallinoCatalogo(obsoleto, eliminato, noStampaCataglogo, disattivaInternet) {
    if (obsoleto) return "Obsoleto"
    else if (eliminato) return "Eliminato"
    else if (noStampaCataglogo) return "Fuori catalogo"
    else if (disattivaInternet) return "Internet disattivo"

    return "";
  },

  addParamsToLocation(route, arrFiltro) {

    let url = route.path

    let urlQs = ""

    // Querystring filtri
    let qs = route.query;
    /*     let urlParams = new URLSearchParams(window.location.search);
        console.log(urlParams.has('yourParam')); // true
        console.log(urlParams.get('yourParam')); // "MyParam" */

    // NOTA: con substring tolgo il carattere iniziale ?
    //console.log(window.location.search.substring(1))

    // Ciclo sui filtri querystring
    if (arrFiltro.length > 0) {
      for (let item in qs) {
        urlQs += encodeURIComponent(item) + "=" + encodeURIComponent(qs[item]) + "&"
      }
    }


    // Controllo se termina con /
    if (url.endsWith("/"))
      url = url.substr(0, url.length - 1)


    if (urlQs || arrFiltro.length > 0)
      url += "?" + urlQs
    //  url += "/?" + urlQs

    if (urlQs && arrFiltro.length > 0)
      url += "&"


    for (let i = 0; i <= arrFiltro.length - 1; i++) {
      url += encodeURIComponent(arrFiltro[i].filtro)
      url += "="
      url += encodeURIComponent(arrFiltro[i].valore)
      url += "&"
    }

    if (url.endsWith("&"))
      url = url.substr(0, url.length - 1)

    // NOTA: se torno alla versione iniziale del path, non devo fare il pushState,
    //       altrimenti aggiungerebbe in cronologia un valore indentico 
    if (route.path == url && urlQs.length == 0) {
      history.replaceState(null, "", url);
      return
    }

    // Inserisco nel'url per gestire la cronologia
    history.pushState(null, "", url);

  },




  cercaValore(valore, router) {
    // Controllo presenza valore
    if (valore.trim().length == 0) return;

    // Vado alla pagina di ricerca
    router
      .push({
        path: "/ricerca/" + valore + "/prodotti"
      })
      .catch((err) => {
        // Ignoro l'errore della ricerca sulla stessa pagina
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        )
          logError(err);
      });

    // Avvio la ricerca
    store.commit("ricerca/setFind");
  },


  webViewCommand(msg, hideMsg) {

    try {
      window.chrome.webview.postMessage(msg)
    } catch (error) {
      if (!!hideMsg == false) store.commit('app/showMessage', "Comunicazione con Azienda fallita")
    }

  },

  splitBooleanArray(arr) {

    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i] == 'true' ? true : false;
    }

    return arr
  },


  formatMonthData(data) {
    // Converto yyyy-MM-dd => MM/yyyy
    // NOTA: la data deve essere passata in formato ISO (.toISOString())
    if (!data) return null;

    // Ricavo solo la data (primi 10 caratteri)
    data = data.substr(0, 10)

    const [year, month, day] = data.split("-");
    return `${month}/${year}`;
  },

  getNextGiornoLav(d) {
    d.setDate(d.getDate() + 1); // tomorrow
    if (d.getDay() == 0) d.setDate(d.getDate() + 1);
    else if (d.getDay() == 6) d.setDate(d.getDate() + 2);
    return d;
  },

  getClienteSelezionato(campo) {
    let cliente = store.getters["app/getCliente"];
    if (!!cliente && Object.keys(cliente).length > 0)
      return cliente[campo];

    return "";
  },


  getDescrizioneTecniche(descTecniche) {

    let res = ""

    // Controllo presenza campo HTML
    if (descTecniche.length > 0 && descTecniche[0].descrizioneHTML && descTecniche[0].descrizioneHTML.length > 0) {
      res = descTecniche[0].descrizioneHTML
    }
    else {
      // Ciclo sulle descrizioni tecniche
      for (let i = 0; i < descTecniche.length; i++) {

        let desc = descTecniche[i].descrizione.trim()
        if (desc.length == 0)
          continue

        // Converto in grassetto
        desc = this.convertiTestoGrassetto(desc);

        if (descTecniche[i].tipoDescrizione == "T") {
          // -> TITOLO
          // NOTA: il titolo potrebbe anche non contenere i doppi apici, forzo il bold
          if (desc.indexOf("\"") == -1)
            desc = "<strong>" + desc + "</strong>"
        } else if (descTecniche[i].elencoPuntato) {
          // -> ELENCO PUNTATO
          desc = "<ul><li>" + desc + "</li></ul>";
        }
        else
          desc = "<p>" + desc + "</p>";

        res += desc

      }
    }

    return res
  },

  validateIP(ip) {
    return (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ip
      ) ||
      ip == "" ||
      ip == null)
  }

}