<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-select :items="listaQuery" item-text="nomeFile" item-value="nomeFile" v-model="fileQuery" outlined
          @input="fileSelected()" hide-details></v-select>
      </v-col>
      <v-col cols="12">
        <v-textarea no-resize outlined v-model="query" label="Query SQL"></v-textarea>
        <v-btn @click="eseguiQuery()" :loading="attesa">Esegui</v-btn>
        <v-btn @click="generaExcel()" class="ml-4">Excel</v-btn></v-col>

      <v-col cols="12">
        <v-data-table :headers="getHeaders" hide-default-header :items="datiWithIndex" :no-data-text="''"
          :no-results-text="'Nessun dato trovato'" :options.sync="pagination" item-key="idPrimary" ref="tabella">
          <!-- header -->
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th v-for="(head, i) in headers" :key="i" :class="[
                  'column sortable',
                  pagination.descending ? 'desc' : 'asc',
                  head.value === pagination.sortBy ? 'active' : '',
                ]">
                  <span v-html="head.text"></span>

                  <v-icon small v-show="head.sortable == true" color="#FFF">arrow_upward</v-icon>
                </th>
              </tr>
            </thead>
          </template>

          <!-- items -->
          <template v-slot:item="{ item }">
            <tr>
              <td v-for="(header, i) in getHeaders" style="padding: 2px !important" :key="i">
                <span v-html="item[header.value]"></span>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get_query, get_filesQuery } from "@/api/api.utilita.js";
import XLSX from "xlsx";

export default {
  data: () => ({
    headers: [],

    pagination: {
      rowsPerPage: 200,
      sortBy: new Array("prog"),
      sortDesc: new Array(false),
    },

    dati: [],

    search: "",

    query: "",
    listaQuery: [],
    fileQuery: "",

    attesa: false,
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.listaQuery = [];

      try {
        let res = await get_filesQuery();

        this.listaQuery = res.data;

      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async eseguiQuery() {
      if (this.query.length == 0) return;

      // Reset
      this.dati = [];
      this.attesa = true

      try {
        let res = await get_query({ sql: this.query, nomeFile: this.fileQuery });

        this.dati = res.data.dati;
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false
    },

    fileSelected() {
      this.query = this.listaQuery.find(f => f.nomeFile == this.fileQuery).sql;
    },

    generaExcel() {
      /* generate worksheet and workbook */
      const worksheet = XLSX.utils.json_to_sheet(this.dati);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Dates");

      /* create an XLSX file and try to save to Presidents.xlsx */
      XLSX.writeFile(workbook, "Tabella.xlsx");
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getHeaders() {
      let res = [];

      this.dati.forEach((value) => {
        // Ciclo sulle colonne
        for (let key in value) {
          let item = { text: key, value: key };
          if (res.findIndex((k) => k.text == key && k.value == key) != -1)
            continue;
          res.push(item);
        }
      });

      return res;
    },

    // NOTA: serve per mettere un indice univoco alla riga "item-key" (nel caso di espansione è richiesto)
    datiWithIndex() {
      return this.dati.map((item, index) => ({
        idPrimary: index,
        ...item,
      }));
    },
  },
};
</script>