<template>
  <v-toolbar color="#F5F5F5" class="elevation-0">
    <span class="ml-0">
      <v-menu
        :close-on-content-click="false"
        v-model="calendar"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            v-model="dataConsegnaComputed"
            label="Data iniziale"
            prepend-icon="event"
            hide-details
            style="max-width: 150px !important"
            clearable
            readonly
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datiFiltro.dataInizio"
          locale="it-it"
          first-day-of-week="1"
          event-color="black"
          class="b-date-picker"
          @input="
            calendar = false;
            aggiornaDati();
          "
          hide-details
          type="month"
        ></v-date-picker>
      </v-menu>
    </span>

    <span class="ml-8">
      <v-menu
        :close-on-content-click="false"
        v-model="calendar2"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-on="on"
            v-model="dataConsegna2Computed"
            label="Data finale"
            prepend-icon="event"
            hide-details
            style="max-width: 150px !important"
            clearable
            readonly
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datiFiltro.dataFine"
          locale="it-it"
          first-day-of-week="1"
          event-color="black"
          class="b-date-picker"
          @input="
            calendar2 = false;
            aggiornaDati();
          "
          hide-details
          type="month"
        ></v-date-picker>
      </v-menu>
    </span>

    <v-text-field
      label="Filtro"
      hide-details
      @input="aggiornaDati()"
      class="ml-8"
      style="max-width: 250px"
      v-model="datiFiltro.filtro"
      prepend-icon="filter_alt"
      clearable
    ></v-text-field>
  </v-toolbar>
</template>

<script>
export default {
  data: () => ({
    calendar: false,
    calendar2: false,

    datiFiltro: {
      dataInizio: "",
      dataFine: "",
      filtro: "",
    },
  }),

  methods: {
    aggiornaDati() {
      this.$emit("aggiornaDati", this.datiFiltro);
    },
  },

  computed: {
    dataConsegnaComputed: {
      get: function () {
        if (!!this.datiFiltro.dataInizio == false) return "";

        return this.$functionsApp.formatMonthData(this.datiFiltro.dataInizio);
      },
      set: function (val) {
        this.datiFiltro.dataInizio = val;
      },
    },

    dataConsegna2Computed: {
      get: function () {
        if (!!this.datiFiltro.dataFine == false) return "";

        return this.$functionsApp.formatMonthData(this.datiFiltro.dataFine);
      },
      set: function (val) {
        this.datiFiltro.dataFine = val;
      },
    },
  },
};
</script>