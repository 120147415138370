<template src="./passwordReset.html"></template>

<script>
import { pwdForgot_reset } from "@/api/api.account.js";

export default {
  title: "Recupero password",
  data: () => ({
    e1: false,
    disabilitaBtn: false,
    alertElaborazione: "",
    tipo: "success",
    cred: {
      Password: "",
      ConfermaPassword: "",
      Code: ""
    },
    OK: false
  }),

  methods: {
    resetPwd() {
      // Validazione
      if (this.$refs.formPwd.validate() == false) {
        return;
      }

      // Controllo presenza codice
      if (!this.checkCode()) return;

      // Reset
      this.alertElaborazione = "";
      this.disabilitaBtn = true;

      this.cred.Code = this.$route.query.code;

      // Reset pwd
      pwdForgot_reset(this.cred)
        .then(res => {
          this.tipo = "success";
          this.alertElaborazione =
            "Operazione completata. Accedi per effettuare il login.";

          this.disabilitaBtn = false;
          this.OK = true;
        })
        .catch(err => {
          console.log(this.$i2kFunctions.getErrorMsg(err));

          this.disabilitaBtn = false;
          this.tipo = "warning";
          this.alertElaborazione = this.$i2kFunctions.getErrorMsg(err);
        });
    },

    checkCode() {
      if (this.$route.query.code && this.$route.query.code.length > 0) {
        return true;
      }

      return false;
    }
  },

  mounted() {
    if (this.checkCode() == false) {
      this.tipo = "warning";
      this.alertElaborazione = "Token non valido.";
    }
  }
};
</script>
