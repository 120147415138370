import request from '@/services/interceptor.js'


// --------------------
// Account
// --------------------
export const pwdForgot_mail = (par) => {
    return request({
        url: '/account/password/mail',
        data: par,
        method: 'post',
    })
}

export const pwdForgot_reset = (par) => {
    return request({
        url: '/account/password/reset',
        data: par,
        method: 'post',
    })
}

export const get_categorie = () => {
    return request({
        url: '/account/categorie',
        method: 'get',
    })
}

export const new_register = (par) => {
    return request({
        url: '/account/register',
        data: par,
        method: 'post',
    })
}

export const account_activation = (par) => {
    return request({
        url: '/account/activation/user',
        data: par,
        method: 'post',
    })
}


