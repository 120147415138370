
import request from '@/services/interceptor.js'




// --------------------
// User
// --------------------
export const get_datiUserInfo = () => {
    return request({
        url: '/user/info',
        method: 'get',
    })
}



// --------------------
// Carrello
// --------------------
export const get_carrello = (par) => {
    return request({
        url: '/user/carrello',
        method: 'get',
    })
}


export const ins_carrello = (par) => {
    return request({
        url: '/user/carrello',
        data: par,
        method: 'post',
    })
}
export const del_carrello = (par) => {
    return request({
        url: '/user/carrello/' + par,
        method: 'delete',
    })
}

export const del_svuotaCarrello = () => {
    return request({
        url: '/user/carrello/svuota',
        method: 'delete',
    })
}
export const conferma_carrello = (par) => {
    return request({
        url: '/user/carrello/conferma',
        data: par,
        method: 'post',
    })
}

export const get_totaleCarrello = (par) => {
    return request({
        url: '/user/carrello/totale',
        method: 'get',
    })
}
export const update_carrello = (data) => {
    return request({
        url: '/user/carrello/edit',
        method: 'put',
        data: data
    })
}


// --------------------
// Documenti
// --------------------
export const get_documentiTestata = (par) => {
    return request({
        url: '/user/documenti/' + par,
        method: 'get',
    })
}
export const get_documentiDettaglio = (par) => {
    return request({
        url: '/user/documenti/' + par,
        method: 'get',
    })
}




// --------------------
// Profilo
// --------------------
export const get_profilo = () => {
    return request({
        url: '/user/profilo',
        method: 'get',
    })
}

export const ins_profilo = (par) => {
    return request({
        url: '/user/profilo',
        data: par,
        method: 'put',
    })
}



// --------------------
// Giorni consegna cliente
// --------------------
export const dateConsegne_cliente = () => {
    return request({
        url: '/user/carrello/consegna',
        method: 'get',
    })
}

export const salva_indirizzo = (par) => {
    return request({
        url: '/user/carrello/consegna/nuovo/indirizzo',
        data: par,
        method: 'post',
    })
}

export const listaIndirizziConsegne_cliente = () => {
    return request({
        url: '/user/carrello/consegna/indirizzi',
        method: 'get',
    })
}

export const delete_indirizzo = (par) => {
    return request({
        url: '/user/carrello/consegna/elimina/indirizzo',
        data: par,
        method: 'post',
    })
}

export const edit_indirizzo = (par) => {
    return request({
        url: '/user/carrello/consegna/modifica/indirizzo',
        data: par,
        method: 'post',
    })
}

export const province_cliente = () => {
    return request({
        url: '/user/carrello/province',
        method: 'get',
    })
}

export const datiCliente_carrello = () => {
    return request({
        url: '/user/carrello/datiCliente/carrello',
        method: 'get',
    })
}

export const GetInfoUtenteCarrello = () => {
    return request({
        url: '/user/info/utente/carrello',
        method: 'get',
    })
}




// --------------------
// Help
// --------------------
export const get_help = (par) => {
    return request({
        url: '/user/help' + par,
        method: 'get',
    })
}

export const set_help = (par) => {
    return request({
        url: '/user/help',
        data: par,
        method: 'post',
    })
}


