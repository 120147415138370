<template>
    <v-dialog v-model="dialog" persistent max-width="50%">
        <v-card>
            <v-card-title>
                <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-btn hide-details text icon elevation="0" class="mt-2" @click="chiudiDialogSuffisso(false)">
                        <v-icon> close </v-icon>
                    </v-btn>
                </v-row>
            </v-card-title>

            <v-card-text>
                <v-row no-gutters v-if="Testata != null">
                    <v-col cols="2" class="pa-1">
                        <v-text-field style="font-weight: bold" hide-details label="CodTestata" required
                            :rules="[rules.required]" :readonly="true" v-model="Testata.codTestataCompleto" />
                    </v-col>
                    <v-col cols="1" class="pa-1">
                        <v-text-field style="font-weight: bold" hide-details label="Suffisso" type="number"
                            v-model="Testata.suffisso" :rules="[rules.suffisso]" />
                    </v-col>
                    <v-col cols="9" class="pa-1">
                        <v-text-field style="font-weight: bold" hide-details label="Descrizione"
                            v-model="Testata.descrizione" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-col cols="12">
                    <v-row no-gutters>
                        <v-spacer />
                        <div class="mr-0">
                            <v-btn class="mr-2" v-on:click="chiudiDialogSuffisso(false)">ANNULLA</v-btn>
                            <v-btn class="btn-conferma" v-on:click="salvaDati()">CONFERMA</v-btn>
                        </div>
                    </v-row>
                </v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {
    set_suffissoTestataDuplicata, add_testata
} from "@/api/api.archivi.js";

export default {
    props: {
        dialog: { type: Boolean, default: null },
        testata: { type: Object, default: null },
        suffisso: { type: Number, default: 0 },
        cambiaSuffisso: { type: String, default: "" },
    },

    components: {},

    data: () => ({
        rules: {
            required: (value) => !!value || "Campo obbligatorio.",
            suffisso: (value) => value >= 0 || "Camo obbligatorio.",
        },

        Testata: null,
    }),

    methods: {
        chiudiDialogSuffisso(refresh) {
            this.Testata = null;
            this.$emit("chiudi", refresh);
        },

        async salvaDati() {
            this.Testata.suffisso_Old = this.suffisso;

            if (this.cambiaSuffisso != 'cambio') {
                //cambio suffisso + duplico testata
                let res = await add_testata(this.Testata);
                if (res.data == "OK") {
                    this.chiudiDialogSuffisso(true);
                    this.aggiungi = false;
                } else this.$toast.error("Errore nel salvataggio dei dati. " + res.data);
            } else {
                //cambio suffisso
                let res = await set_suffissoTestataDuplicata(this.Testata);
                if (res.data == "OK") {
                    this.$toast.info("Dati aggiornati");
                    this.chiudiDialogSuffisso(true);
                }
                else this.$toast.error(res.data);
            }
        }
    },

    watch: {
        testata(newVal) {
            if (newVal) this.Testata = this.testata;
        }
    },
};
</script>