<template>
  <v-dialog v-model="dialogEsportaPdf" width="400" persistent>
    <v-card style="max-width: 400px">
      <v-card-title>
        <span>Esporta borderò BRT</span>
        <v-spacer />
        <v-btn icon @click.prevent="chiudi()">
          <v-icon large>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-menu :close-on-content-click="false" v-model="calendarPdf" :nudge-right="40" transition="scale-transition"
          offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field class="mt-0 pt-0 mr-4" v-on="on" readonly v-model="dataEsportaPdf"
              label="Data partenza spedizione" prepend-icon="event" hide-details style="max-width: 255px !important"
              ref="txtDataEsportaPdf" />
          </template>
          <v-date-picker v-model="dataEsportaPdf" locale="it-it" first-day-of-week="1" event-color="black"
            class="b-date-picker" @input="calendarPdf = false" hide-details />
        </v-menu>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.prevent="chiudi()">Annulla</v-btn>
        <v-btn @click.prevent="esportaPdf()" class="btn-conferma">Esporta</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_pdfBordero } from "@/api/api.etichette.js";
export default {
  props: {
    dialogEsportaPdf: { type: Boolean, default: false },
  },

  data: () => ({
    calendarPdf: false,
    dataEsportaPdf: "",
  }),

  methods: {
    async initialize() {
      await this.wait(300);
      this.$refs.txtDataEsportaPdf.focus();
      this.calendarPdf = true;
    },

    chiudi() {
      this.dataEsportaPdf = "";
      this.$emit("chiudiEsportaPdf");
    },

    async esportaPdf() {
      if (this.dataEsportaPdf == null || this.dataEsportaPdf == "") {
        this.$toast.alert("Data non valida");
        return;
      }
      let par = {
        DtPartenza: this.dataEsportaPdf,
      };
      let res = await get_pdfBordero(par);
      this.$i2kFunctions.createBlobPdf(res.data);
      this.chiudi();
    },

    async wait(millisecs) {
      let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
      await wait(millisecs);
    },
  },

  watch: {
    dialogEsportaPdf(newVal) {
      if (newVal) {
        this.initialize();
      }
    },
  },
};
</script>