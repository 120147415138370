<template>
  <v-dialog v-model="dialog" width="950" persistent>
    <v-card>
      <v-card-title>
        Nuova spedizione
        <v-spacer />
        <v-btn icon @click="chiudi()">
          <v-icon large>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-row class="ma-0 pa-0">
        <v-select :items="getAnni()" v-model="corriere.datiDocu.anno" style="width: 50px" class="ml-4 mr-4"
          label="Anno" />

        <v-text-field v-model="datiCorriere.datiDocu.nrDocuStampato" ref="txtNrDocu" id="txtNrDocu" clearable
          class="mr-4 ml-4" label="Numero documento" append-icon="search" @click:append="cercaDocumento()"
          style="max-width: 220px" @keydown.enter="caricaDatiDocumento()" required />

        <v-text-field class="mr-4 ml-4" v-model="datiCorriere.codiceSpedizione" readonly label="Codice spedizione"
          style="max-width: 150px" />

        <v-text-field class="ml-4 mr-4" :value="datiCorriere.descrCodiceSpedizione"
          label="Descrizione codice spedizione" readonly />
      </v-row>

      <v-row class="ma-0 pa-0">
        <v-text-field v-model="datiCorriere.vettore" readonly label="Vettore" class="mr-4 ml-4"
          style="width: 100px !important" required />

        <v-text-field v-model="datiCorriere.tipoPorto" readonly label="Porto" class="mr-4 ml-4" style="width: 100px"
          required />

        <v-text-field v-model="datiCorriere.destinatario" readonly label="Destinatario" class="mr-4 ml-4" required />
      </v-row>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.prevent="chiudi()"> Annulla </v-btn>
        <v-btn @click.prevent="salva()" class="btn-conferma"> Aggiungi </v-btn>
      </v-card-actions>
    </v-card>

    <cerca-documento :dialog="dialogCercaDocu" @chiudi="chiudiCercaDocumento" />
  </v-dialog>
</template>

<script>
import {
  get_datiDocumento,
  get_datiCliente,
  get_codiceSpedizione,
} from "@/api/api.etichette.js";

import CercaDocumento from "./CercaDocumento.vue";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    corriere: { type: Object, default: null },
  },

  components: { CercaDocumento },

  data: () => ({
    datiCorriere: {
      datiDocu: {
        nrDocuStampato: "",
        nrDocu: "",
        cdContatto: "",
        tpDocu: "",
        dtDocuStampato: "1900-01-01",
        anno: new Date().getFullYear(),
        numColli: 0,
        peso: 0,
      },
    },

    dialogCercaDocu: false,
  }),

  methods: {
    salva() {
      this.$emit("salvaDati", this.datiCorriere);
    },

    chiudi() {
      this.$emit("chiudi");
    },

    async caricaDatiDocumento() {
      if (!this.dialog) return;
      if (
        this.datiCorriere.datiDocu.nrDocuStampato == null ||
        this.datiCorriere.datiDocu.nrDocuStampato == ""
      ) {
        this.$toast.alert("Numero documento non valido");
        return;
      }

      let codiceSp = this.datiCorriere.codiceSpedizione;
      this.datiCorriere.datiDocu.cdContatto = null;

      let res = await get_datiDocumento(this.datiCorriere.datiDocu);
      if (res.status == 200 && res.data != null && res.data != "") {
        this.datiCorriere = res.data;
        this.datiCorriere.codiceSpedizione = codiceSp;
        let anno = new Date().getFullYear();
        this.datiCorriere.descrCodiceSpedizione =
          anno.toString().substring(2, 4) +
          " " +
          this.datiCorriere.datiDocu.cdContatto +
          " " +
          this.datiCorriere.codiceSpedizione;

        //se non ci sono i dati della destinazione alternativa recupero i dati del cliente
        if (this.datiCorriere.altraDestinazione == "") {
          res = await get_datiCliente(this.datiCorriere.datiDocu.cdContatto);
          this.datiCorriere.destinatario = res.data.destinatario;
          this.datiCorriere.indirizzoDestinatario =
            res.data.indirizzoDestinatario;
          this.datiCorriere.cap = res.data.cap;
          this.datiCorriere.localita = res.data.localita;
          this.datiCorriere.provincia = res.data.provincia;
          this.datiCorriere.nazione = res.data.nazione;
        }
      } else
        this.$toast.alert(
          "Numero documento non valido per il cliente " +
          this.datiCorriere.datiDocu.cdContatto
        );
    },

    async caricaDati() {
      if (!this.dialog) return;
      this.datiCorriere = this.corriere;
      this.datiCorriere.datiDocu.nrDocuStampato = "";
      this.datiCorriere.datiDocu.nrDocu = "";
      this.datiCorriere.descrCodiceSpedizione = "";
      this.datiCorriere.vettore = "";
      this.datiCorriere.tipoPorto = "";
      this.datiCorriere.destinatario = "";
      let anno = new Date().getFullYear();
      let res = await get_codiceSpedizione(anno);
      if (res.data != "") this.datiCorriere.codiceSpedizione = res.data;

      await this.wait(300);
      this.$refs.txtNrDocu.focus();
    },

    async wait(millisecs) {
      let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
      await wait(millisecs);
    },

    getAnni() {
      if (this.corriere.datiDocu == undefined) return [];
      let anno = new Date().getFullYear();
      return [anno - 1, anno];
    },

    cercaDocumento() {
      this.dialogCercaDocu = true;
    },

    chiudiCercaDocumento(nrDocuStampato, tpDocu, nrDocu) {
      this.dialogCercaDocu = false;
      if (nrDocuStampato) {
        this.datiCorriere.datiDocu.nrDocuStampato = nrDocuStampato;
        this.datiCorriere.datiDocu.tpDocu = tpDocu;
        this.datiCorriere.datiDocu.nrDocu = nrDocu;
        this.caricaDatiDocumento();
      }
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal) this.caricaDati();
    },
  },
};
</script>