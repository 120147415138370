<template>
  <v-dialog scrollable v-model="dialogComputed" width="80%">
    <v-card tile dark>
      <v-toolbar dense class="primary">
        <v-toolbar-title v-html="titolo" style="line-height: 18px;"></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <!-- Pdf -->
      <v-card-text v-if="type === 'pdf'" style="height: 1600px; width: 100%">
        <div style="width: 100%; height: 100%; text-align: center">
          <iframe v-if="src" :src="src" width="100%" height="100%">Caricamento....</iframe>
        </div>
      </v-card-text>

      <!-- Video -->
      <v-card-text v-if="type === 'video'">
        <div style="
            width: 100%;
            padding-top: calc(100% * 9 / 16);
            position: relative;
          ">
          <iframe style="
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              width: 100%;
            " :src="'https://www.youtube.com/embed/' + src" frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </v-card-text>

      <!-- Img -->
      <v-card-text v-if="type === 'img'">
        <v-img :src="src" style="background-color: #fff;"> </v-img>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean },
    src: { type: String },
    type: { type: String },
    titolo: { type: String, default: "" },
  },
  data() {
    return {
      page: 1,
      pageNo: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: 1, // 'page-width'
      isLoading: true,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("chiudi");
    },
  },
  mounted() { },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },
};
</script>
