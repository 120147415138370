<template>
  <v-dialog scrollable v-model="dialogComputed" width="400px">
    <v-card tile>
      <v-toolbar light dense flat>
        <v-toolbar-title>Autisti</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-select v-model="autistaSel" :items="autisti" label="Autisti" :loading="attesa" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="closeDialog()">Annulla</v-btn>
        <v-btn class="btn-conferma" @click.native="conferma()">Conferma</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import { get_autisti } from "@/api/api.intranet.js";
export default {
  props: {
    dialog: { type: Boolean },
  },
  data() {
    return {
      autistaSel: "",
      autisti: [],

      attesa: false,
    };
  },

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      this.attesa = true;

      let res = await get_autisti();

      this.autisti = res.data;

      this.attesa = false;
    },

    conferma() {
      if (!!this.autistaSel == false) return;

      this.$emit("impostaAutista", this.autistaSel);

      this.closeDialog();
    },

    closeDialog() {
      this.$emit("chiudiDialog");
    },
  },
  mounted() {
    this.caricaDati();
  },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },

  watch: {
    dialog(v) {
      if (v) {
        this.caricaDati();
      }
    },
  },
};
</script>