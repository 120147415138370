<template src="./lavorazioniTerzisti.html"></template>

<script>
import DettaglioDocumenti from "./DettaglioDocumenti.vue";
import GestioneLavorazioni from "./GestioneLavorazioni.vue";
import DettaglioLavorazioni from "./DettaglioLavorazioni.vue";
import LavorazioniInviate from "./LavorazioniInviate.vue";
import LavorazioniCompletate from "./LavorazioniCompletate.vue";
import { post_terzisti, get_etichetta_stampa, get_elenco_stampanti, get_terzisti } from "@/api/api.lottiProd.js"

export default {
    components: {
        DettaglioDocumenti, GestioneLavorazioni, DettaglioLavorazioni, LavorazioniInviate, LavorazioniCompletate
    },

    data: () => ({
        elencoTestate: [],
        cicliLavorazioni: [],
        cicloSel: {},

        elencoLavorazioni: [],
        lavorazioneSel: {},

        activeTab: 0,
        search: "",
        stampanti: [],
        stampanteSel: "",
        calendar: {
            calendarStart: false,
            calendarEnd: false,
            dataInizio: "",
            dataFine: "",
            dataStartFormat: "",
            dataEndFormat: "",
        },
        soloOrdiniAperti: true,

        refreshDocumenti: false,
        refreshLavorazioni: false,
        refreshLavorazioniInviate: false,
        refreshLavorazioniCompletate: false,

        filtriDocumenti: [{ label: "inviati", id: 0 }, { label: "in attesa", id: 1 }, { label: "tutti", id: 2 }],
        filtroDoc: 0,

        socket: null,
        isConnected: false,

        elencoHeaders: [],  //headers tabella (mi servono per popolare la select per la barra di ricerca)
        colonnaSel: "",

    }),

    methods: {
        async caricaDati(tab) {
            switch (tab) {
                case 1: case 0: case undefined: case null:
                    this.caricaDocumenti();
                    break;
                case 2:
                    this.caricaDatiLavorazioni();
                    break;
                case 3:
                    this.caricaDatiLavorazioniInviate();
                    break;
                case 4:
                    this.caricaDatiLavorazioniCompletate();
                    break;
                default:
                    break;
            }
        },

        caricaDatiLavorazioniInviate() {
            this.refreshLavorazioniInviate = !this.refreshLavorazioniInviate;
        },

        caricaDatiLavorazioni() {
            this.refreshLavorazioni = !this.refreshLavorazioni;
        },
        caricaDatiLavorazioniCompletate() {
            this.refreshLavorazioniCompletate = !this.refreshLavorazioniCompletate;
        },

        // elenco documenti lavorazioni tab "DOCUMENTI"
        async caricaDocumenti() {
            this.testataSel = {};
            if (this.calendar.dataStartFormat == null) this.calendar.dataInizio = "";
            if (this.calendar.dataEndFormat == null) this.calendar.dataFine = "";
            if (this.search == null) this.search = "";

            let res = await post_terzisti("testata/elenco/" + (this.soloOrdiniAperti ? "1" : "0") + "?documenti=" + this.filtroDoc + "&cerca=" + this.search + "&colonna=" + this.colonnaSel.value, this.calendar);
            this.elencoTestate = res.data;

            this.testataSel = {};
            this.righeDettaglio = [];
            this.refreshDocumenti = !this.refreshDocumenti;
        },

        async caricaCicliLavorazioni() {
            let res = await get_terzisti("lavorazioni/cicli/elenco");
            this.cicliLavorazioni = res.data;
            this.cicloSel = {};
        },

        salvaHeaders(data) {
            this.elencoHeaders = data;
        },

        async caricaLavorazioni() {
            let res = await get_terzisti("lavorazioni/elenco");
            this.elencoLavorazioni = res.data;
            this.lavorazioneSel = {};
        },

        handleNumEtichette(el) {
            this.righeDettaglio = el;
        },

        handleTestataSel(el) {
            this.testataSel = el;
        },

        async stampaEtichetta() {
            if (this.testataSel == null || this.testataSel == {} || this.testataSel.nrDocu == undefined) {
                this.$toast.alert("Nessun ordine selezionato.");
                return;
            }

            let msg = "";
            for (let el of this.righeDettaglio) {
                if (el.stampaEtichette == true && (el.numeroEtichette == undefined || el.numeroEtichette == null || el.numeroEtichette <= 0)) {
                    msg += "\r\nNumero etichette non valide per l'articolo " + el.cdArticoloPadre
                }
            }
            if (msg != "") {
                this.$toast.alert(msg);
                return;
            }
            msg = "";
            let etichetteError = [];
            for (let el of this.righeDettaglio) {
                if (el.stampaEtichette == undefined || el.stampaEtichette === null || el.stampaEtichette != true)
                    continue;

                if (el.cdArticoloPadre != "" && el.cdArticoloPadre != null) {
                    let res = await get_etichetta_stampa(null, "articolo/" + el.cdArticoloPadre + "/" + this.testataSel.nrDocu + "/" + el.nrRigaDocu + "/2");
                    if (res.data.messaggio == undefined || res.data.messaggio == null) {
                        this.$toast.alert("Errore. Impossibile stampare l'etichetta articolo " + el.cdArticoloPadre);
                    }
                    if (res.data.messaggio != "OK") {
                        this.$toast.alert(res.data.messaggio);
                        etichetteError.push(res.data.nrRigaDocu);
                        msg += "\nErrore stampa riga " + res.data.nrRigaDocu + " lavorazione: " + res.data.codLavorazione;
                        continue;
                    }

                    for (let i = 0; i < el.numeroEtichette; i++) {
                        this.printBarcode(res.data.label);
                    }

                    let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
                    await wait(500);
                }
            }
            if (msg != "") this.$toast.alert(msg);

            for (let el of this.righeDettaglio) {
                if (etichetteError.indexOf(el.nrRigaDocu) <= 0) {
                    el.numeroEtichette = 0;
                    el.stampaEtichette = false;
                }
            }
        },

        printBarcode(zpl) {
            let stpte = this.stampanteSel.split(" | ")[0];
            this.$functionsApp.webViewCommand("stampaSimapac" + "|" + stpte + "|" + zpl);
            /*       try {
                      let stpte = this.stampanteSel.split(" | ")[0];
                      if (!this.$functionsApp.validateIP(stpte)) return;
                      const axios = require('axios');
                      let url = "http://" + stpte + "/pstprnt";
                      const response = await axios.post(url, zpl);
                      console.log('API call response:', response.data);
                  } catch (error) {
                      console.error(error);
                  } */
        },

        async connectWebSocket() {
            let stpte = this.stampanteSel.split(" | ")[0];
            const url = `ws://${stpte}:9100`; // Modifica il path secondo le specifiche della stampante
            this.socket = new WebSocket(url);

            this.socket.onopen = () => {
                console.log('WebSocket connection established');
                this.isConnected = true;
            };

            this.socket.onmessage = (event) => {
                console.log('Message from server:', event.data);
            };

            this.socket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            this.socket.onclose = () => {
                console.log('WebSocket connection closed');
                this.isConnected = false;
            };
        },

        async printBarcode_(zpl) {
            /* if (!this.isConnected) {
                await this.connectWebSocket();
            }

            if (this.isConnected) {
                //this.socket.send(zpl);
                console.log('Sent ZPL to printer:', zpl);
            } else {
                console.error('WebSocket is not connected');
            } */
            var printWindow = window.open();
            printWindow.document.open('text/plain')
            printWindow.document.write(zpl);
            printWindow.document.close();
            printWindow.focus();
            printWindow.print();
            printWindow.close();
        },

        async caricaStampanti() {
            let res = await get_elenco_stampanti();
            if (res.data == null || res.data == undefined) {
                this.$toast.alert("Errore. Impossibile recuperare l'elenco delle stampanti.");
                return;
            }
            this.stampanti = res.data;
            this.stampanteSel = this.stampanti[0];
        },
    },

    created() {
        this.caricaStampanti();
        this.caricaDati();
        this.caricaLavorazioni();
        this.caricaCicliLavorazioni();
    },

    watch: {
        "calendar.dataInizio": function () {
            this.calendar.dataStartFormat = this.$i2kFunctions.formatISODate(
                this.calendar.dataInizio
            );
        },
        "calendar.dataFine": function () {
            this.calendar.dataEndFormat = this.$i2kFunctions.formatISODate(
                this.calendar.dataFine
            );
        },
    }
}
</script>