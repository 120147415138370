<template src="./registrazione.html"></template>

<script>
import { new_register, get_categorie } from "@/api/api.account.js";

import { get_profilo, ins_profilo } from "@/api/api.user.js";

export default {
  name: "registrazione",
  title: "Registrazione",
  data: () => ({
    dati: {
      ragioneSociale: "",
      indirizzo: "",
      localita: "",
      provincia: "",
      cap: "",
      telefono: "",
      fax: "",
      categoria: "",
      sottocategoria: "",
      piva: "",
      userName: "",
      password: "",
      repeatPassword: "",
      nome: "",
      cognome: "",
      privacy: false,
      mailContatto: "",
      comunicazioni: false,
      newsLetter: false,
    },

    itemsCategoria: [],
    itemsSottocategoria: [],
    itemsSottocategoriaFiltro: [],

    alertElaborazione: "",
    tipo: "success",

    rules: {
      required: (value) => !!value || "Richiesto.",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
        return pattern.test(value) || "Indirizzo email non valido.";
      },
      emailOptional: (value) => {
        if (value == undefined || value.length == 0) return true;
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
        return pattern.test(value) || "Indirizzo email non valido.";
      },
      phone: (value) => {
        // eslint-disable-next-line
        const pattern = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
        return (
          (pattern.test(value) && (value.length == 0 || value.length > 5)) ||
          "Numero di telefono non valido."
        );
      },
      number: (value) => {
        return (
          (/^\d+$/g.test(value) && parseInt(value) > 0) ||
          "Numero di partecipanti non valido."
        );
      },
      optional: (value) => {
        if (value == undefined) return true;
        if (value.length == 0) return true;
        if (!/^\d+$/g.test(value) || parseInt(value) < 0)
          return "Numero di partecipanti non valido.";
      },
      password: (value) => {
        if (value.length < 8) return "La password è troppo breve.";
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/;
        return (
          pattern.test(value) ||
          "La password deve contenere almeno una lettera minuscola, una maiuscola e un numero."
        );
      },
      CAP: (value) => {
        return (
          (/\d{5}/.test(value) && value.length == 5) ||
          "Il CAP inserito non è valido"
        );
      },
      piva: (value) => {
        return (
          (/\d{11}/.test(value) && value.length == 11) ||
          "La partita IVA inserita non è valida"
        );
      },
    },
  }),

  methods: {
    isSameMail() {
      if (this.repeatPassword.length == 0) return true;
      return (
        this.password === this.repeatPassword || "Le password non coincidono."
      );
    },

    formSubmit() {
      // Controllo utente loggato
      if (this.auth) this.salvaDati();
      else this.registrazione();
    },

    registrazione() {
      // Validazione
      if (this.$refs.formRegistrazione.validate() == false) {
        return;
      }

      this.alertElaborazione = "";

      new_register(this.dati)
        .then((res) => {
          console.log(res);

          this.alertElaborazione =
            "Grazie per esserti registrato, a breve riceverai una mail di accettazione per effettuare il login.";
          this.tipo = "success";
        })
        .catch((err) => {
          console.log(err);

          this.alertElaborazione = this.$i2kFunctions.getErrorMsg(err);
          this.tipo = "warning";
        });
    },

    caricaCategorie() {
      get_categorie(this.dati)
        .then((res) => {
          this.itemsCategoria = res.data.categorie;
          this.itemsSottocategoria = res.data.sottoCategorie;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    caricaSottocategoria() {
      let _this = this;
      this.itemsSottocategoriaFiltro = [];

      let arr = this.itemsSottocategoria.filter(function (val) {
        return val.split("|")[0] == _this.dati.categoria;
      });

      for (let i in arr) {
        this.itemsSottocategoriaFiltro.push(arr[i].split("|")[1]);
      }
    },

    caricaDati() {
      // Controllo utente loggato
      if (this.auth == false) return;

      get_profilo()
        .then((res) => {
          //console.log(res);

          this.dati = res.data;
          this.dati.privacy = true;

          // Carico le sotto categorie
          this.caricaSottocategoria();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    salvaDati() {
      this.$confirm
        .open("Salva", "Vuoi salvare i dati ?")
        .then((confirm) => {
          if (!confirm) return;

          ins_profilo(this.dati)
            .then((res) => {
              console.log(res);
            })
            .catch((err) => {
              console.log(this.$i2kFunctions.getErrorMsg(err));
            });
        })
        .catch((err) => {
          console.log(this.$i2kFunctions.getErrorMsg(err));
        });
    },
  },

  mounted() {
    this.caricaCategorie();

    this.caricaDati();
  },

  computed: {
    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },

  watch: {
    auth() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

