<template>
  <div style="height: 100%">
    <FilterScansioni @caricaDati="caricaDati" @anteprima="apriAnteprima()" @salva="salvaDoc" @elimina="eliminaDoc"
      ref="filtroComponent" />

    <v-container xs fluid>
      <v-row wrap>
        <v-col v-for="(item, i) in dati" :key="i" xs6>
          <v-card tile class="ma-2" @dblclick="apriAnteprima(item)" style="max-width: 200px">
            <v-checkbox v-model="listaDocSel" :value="item.documento" @click.native="setDocumento(item, $event.target)"
              style="position: absolute; top: -5px; margin: 0; z-index: 1" />

            <v-img :src="item.urlAnteprima" height="300px" />
            {{ getData(item.dataOra) }}
          </v-card>
        </v-col>
      </v-row>

      <div v-show="attesa" style="position: absolute; top: 50%; left: 50%; margin: auto">
        <v-progress-circular indeterminate color="primary" />
      </div>
    </v-container>

    <pdf-viewer :dialog="previewDialog.show" :src="previewDialog.src" :type="previewDialog.type" @chiudi="closeDialog" />

  </div>
</template>

<script>
import {
  get_documenti,
  get_scansioni,
  save_documenti,
  del_documenti,
} from "@/api/api.intranet.js";

import FilterScansioni from "@/components/intranet/FilterScansioni.vue";
import PdfViewer from "@/components/dialog/Viewer.vue";

export default {
  components: {
    FilterScansioni,
    PdfViewer
  },

  data: () => ({
    dati: [],
    listaDocSel: [],
    docSel: {},

    previewDialog: {
      show: false,
      type: "",
      src: "",
    },

    origine: "",
    paramSwap: null,

    attesa: false,
  }),

  methods: {
    async caricaDati(origine, par) {
      // Reset
      this.dati = [];
      this.listaDocSel = [];
      this.docSel = {};

      // Scroll top pagina
      window.scrollTo(0, 0);

      this.origine = origine;
      this.paramSwap = par;

      // Controllo input
      if (this.origine == "Archivio") {
        if (this.controllaInputArchivio(par) == false) return;
      } else if (this.origine == "CodBarError") {
        if (this.controllaInputCodeBar(par) == false) return;
      }

      this.attesa = true;

      try {
        let res = [];

        if (this.origine == "Archivio") res = await get_documenti(par);
        else res = await get_scansioni(par);

        this.dati = res.data;
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    apriAnteprima(par) {
      this.previewDialog.type = "pdf";
      this.previewDialog.src =
        par.urlDoc + "?timeStamp=" + new Date().getTime();
      this.previewDialog.show = true;
    },

    closeDialog() {
      this.previewDialog.show = false;
    },

    setDocumento(doc, ev) {
      if (this.listaDocSel == false) return;
      // Reset e inserimento singola voce
      this.listaDocSel = [];
      this.listaDocSel.push(doc.documento);
      this.docSel = doc;
    },

    controllaInputArchivio(val) {
      if (
        val.Area.length == 0 &&
        (!!val.TipoDocumento == false || val.TipoDocumento.length == 0) &&
        !!val.NrDocumento == false &&
        val.CdAnagrafica.length == 0
      ) {
        this.$toast.info("Filtro non valido");
        return false;
      }

      return true;
    },

    controllaInputCodeBar(val) {
      if (val.nrRimanenza == 0) {
        this.$toast.info("Rimanenza non valida");
        return false;
      }

      if (val.nrPagina == 0) {
        this.$toast.info("Pagina non valida");
        return false;
      }

      return true;
    },

    validaInput(origine, val) {
      if (this.listaDocSel.length == 0) {
        this.$toast.info("Nessun documento selezionato");
        return;
      }

      if (val.Area.length == 0) {
        this.$toast.info("Area non valida");
        return;
      }

      if (val.TipoDocumento.length == 0) {
        this.$toast.info("Tipo documento non valido");
        return;
      }

      if (val.NrDocAuto == false && !!val.NrDocumento == false) {
        this.$toast.info("Numero non valido");
        return;
      }

      if (val.CdAnagrafica.length == 0) {
        this.$toast.info("Cliente non valido");
        return;
      }

      if (origine == "CodBarError" && !!val.NrRimanenza == false) {
        this.$toast.info("Rimanenza non valida");
        return;
      }

      if (origine == "CodBarError" && !!val.NrPagina == false) {
        this.$toast.info("Nr Pagina non valida");
        return;
      }

      return true;
    },

    getPar(val) {
      val.IdProg = this.docSel.idProg;
      val.Documento = this.docSel.documento;

      return val;
    },

    salvaDoc(origine, val) {
      if (!this.validaInput(origine, val)) return;

      let par = this.getPar(val);

      if (origine == "Scansioni") this.salvaScansione(par);
      else if (origine == "CodBarError") this.salvaCodeBarError(par);
    },

    async salvaScansione(par) {
      try {
        await save_documenti("scansioni", par);

        // Ricarico i dati
        this.caricaDati(this.origine, this.paramSwap);

        // Pulizia filtro
        this.pulisciFiltro();
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
        console.log(error);
      }
    },

    async salvaCodeBarError(par) {
      try {
        await save_documenti("codbarerror", par);

        // Attesa registrazione
        let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
        await wait(1500);

        // Ricarico i dati
        this.caricaDati(this.origine, this.paramSwap);

        // Pulizia filtro
        this.pulisciFiltro();
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
        console.log(error);
      }
    },

    pulisciFiltro() {
      this.$refs.filtroComponent.pulisciFiltro();
    },

    async eliminaDoc(origine, val) {
      if (this.listaDocSel.length == 0) {
        this.$toast.info("Nessun documento selezionato");
        return;
      }

      let conf = await this.$confirm.open(
        "Eliminare",
        "Vuoi eliminare il documento ?"
      );
      if (!conf) return;

      try {
        let par = this.getPar(val);

        await del_documenti(origine, par);

        // Ricarico i dati
        this.caricaDati(this.origine, this.paramSwap);
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
        console.log(error);
      }
    },

    async scroll() {
      if (this.origine != "Archivio") return;
      if (this.dati.length == 0) return;

      let bottom =
        window.scrollY >= document.body.scrollHeight - window.innerHeight - 1;
      if (bottom == false) return;

      let idProg = this.dati[this.dati.length - 1].idProg;

      this.paramSwap.IdProg = idProg;

      this.attesa = true;

      let res = await get_documenti(this.paramSwap);

      let arr = JSON.parse(JSON.stringify(res.data));
      for (let key in arr) {
        this.dati.push(arr[key]);
      }

      this.attesa = false;

      return;
    },

    getData(data) {
      if (!!data == false) return "";

      let d = new Date(data);

      let res = d.toLocaleDateString("it-IT");
      res += " " + d.toLocaleTimeString("it-IT");

      return res;
    },
  },

  mounted() {
  },

  created() {
    window.addEventListener("scroll", this.scroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
  },
};
</script>