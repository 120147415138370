<template>
  <v-navigation-drawer width="380" fixed app mobile-breakpoint="400" :clipped="$vuetify.breakpoint.mdAndUp" right>
    <v-card-title class="pa-0 ml-1">
      <v-menu :close-on-content-click="false" v-model="calendar" :nudge-right="40" transition="scale-transition" offset-y
        style="width: 130px">
        <template v-slot:activator="{ on }">
          <v-text-field v-on="on" v-model="dataConsegnaFormat" label="Data" prepend-icon="event" hide-details
            style="max-width:150px;" />
        </template>
        <v-date-picker v-model="dataConsegna" locale="it-it" first-day-of-week="1" event-color="primary"
          class="b-date-picker" @input="calendar = false" hide-details />
      </v-menu>

      <v-select v-model="autistaSel" :items="autisti" label="Autista" style="max-width: 110px" hide-details
        class="ml-2" />

      <v-btn icon dark small color="red" @click="caricaDati()" :loading="attesa" class="mr-0 mt-3">
        <v-icon>arrow_forward</v-icon>
      </v-btn>

      <v-btn icon small dark color="red" class="mr-0 mt-3" v-on:click="stampa()" :loading="attesaStampa">
        <v-icon>print</v-icon>
      </v-btn>

      <v-card-title class="pa-0 ml-1 mt-3">
        <v-text-field v-model="kmInizComputed" label="Km Iniziali" outline hide-details style="width: 100px"
          class="mr-1" />

        <v-text-field v-model="kmCalcComputed" label="Km calcolati" filled hide-details style="width: 100px" class="mr-1"
          append-icon="save" @click:append="salvaKmCalcolato" />

        <v-btn class="mr-0 mt-3 btn-conferma" @click="calcola" :loading="attesaCalcola">
          <v-icon>refresh</v-icon>Calcola
        </v-btn>
      </v-card-title>

      <v-card-title class="pa-0 ml-1 mt-3">
        <v-text-field v-model="kmCostiComputed" label="€/Km" outline hide-details style="max-width: 100px"
          append-icon="save" @click:append="salvaKmCosto" />

        <comboClienti v-model="clienteSel" label="Aggiungi destinazione" :items="getClienti" itemText="ragioneSociale"
          style="width: 85%" class="mt-4" outline />

        <v-btn icon dark small color="red" class="mr-0 mt-1" @click="nuovaDestinazione">
          <v-icon>add</v-icon>
        </v-btn>
      </v-card-title>

      <v-data-table :headers="headers" :items="getListaConsegne()" class="elevation-1" no-data-text="" hide-default-footer
        hide-default-header item-key="prog" disable-pagination>
        <template v-slot:item="{ item }">
          <tr>
            <td style="max-width: 25px !important" class="pa-0">
              <v-checkbox primary hide-details :input-value="item.selectedRows"
                @change="setRigaSel(item, $event)"></v-checkbox>
            </td>
            <td style="width: 23px !important">{{ item.prog }}</td>
            <td style="height: 28px">{{ item.ragioneSociale }}</td>
            <td style="height: 28px">{{ item.localita }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card-title>

    <div>
      <v-btn icon dark small color="red" class="mr-0 mt-1" @click="eliminaRighe">
        <v-icon>delete</v-icon>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import {
  save_rimborsi,
  del_rimborsi,
  calcola_rimborsi,
  salva_rimborsiCosti,
  stampa_rimborsi,
  save_kmCalcolati,
} from "@/api/api.intranet.js";

import comboClienti from "@/components/intranet/ComboClienti";

export default {
  components: { comboClienti },

  props: {
    dati: { type: Object, default: () => ({}) },
    attesa: { type: Boolean, default: false },
    attesaSalva: { type: Boolean, default: false },
    autisti: { type: Array, default: () => [] },
  },

  data: () => ({
    calendar: false,
    dataConsegna: null,
    dataConsegnaFormat: null,

    autistaSel: false,

    clienteSel: { cdAnagrafica: "", ragioneSociale: "" },

    kmCalcolati: 0,
    attesaStampa: false,
    attesaCalcola: false,

    selectedRows: [],
    headers: [
      {
        text: "Prog",
        align: "left",
        sortable: false,
        value: "prog",
      },
      {
        text: "RagioneSociale",
        align: "left",
        sortable: false,
        value: "ragioneSociale",
      },
      {
        text: "Localita",
        align: "left",
        sortable: false,
        value: "localita",
      },
    ],
  }),

  methods: {
    async init() {
      // Data consegna giorno successivo
      this.dataConsegna = new Date(new Date().setDate(new Date().getDate()))
        .toISOString()
        .substr(0, 10);

      this.dataConsegnaFormat = this.$i2kFunctions.formatISODate(
        new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
      );
    },

    getData() {
      return this.$i2kFunctions.formatITDateToISO(this.dataConsegnaFormat);
    },

    caricaDati() {
      if (!!this.autistaSel == false) {
        this.$toast.info("Autista non selezionato");
        return;
      }

      // Reset
      this.selectedRows = [];
      //this.kmInit = 0;

      let data = this.getData();

      this.$emit("caricaDati", data, this.autistaSel);
    },

    async nuovaDestinazione() {
      if (this.clienteSel.cdAnagrafica == "") {
        this.$toast.info("Cliente non selezionato");
        return;
      }

      let data = {
        DtRimborso: this.dataConsegna,
        Utente: this.autistaSel,
        Prog: 0,
        CdAnagrafica: this.clienteSel.cdAnagrafica,
      };

      let res = await save_rimborsi(data);

      // Ricarico i dati
      this.caricaDati();
    },

    async stampa() {
      if (!this.controllaAutista()) return;

      this.attesaStampa = true;
      try {
        let res = await stampa_rimborsi(
          this.dataConsegna + "/" + this.autistaSel
        );

        // Visualizzo il pdf
        this.$i2kFunctions.createBlobPdf(res.data);
      } catch (error) {
        console.log(error);
      }

      this.attesaStampa = false;
    },

    async eliminaRighe() {
      if (this.selectedRows.length == 0) {
        this.$toast.info("Nessuna riga  selezionata");
        return;
      }

      let conf = await this.$confirm.open(
        "Eliminare",
        "Vuoi eliminare le righe selezionate ?"
      );
      if (!conf) return;

      let res = await del_rimborsi(this.selectedRows);

      // Ricarico i dati
      this.caricaDati();
    },

    async calcola() {
      if (!this.controllaAutista()) return;
      if (!this.controllaRighe()) {
        this.$toast.info("Rimborsi non presenti");
        return;
      }

      this.attesaCalcola = true;

      try {
        let res = await calcola_rimborsi(
          this.dataConsegna + "/" + this.autistaSel + "/" + this.kmInizComputed
        );
      } catch (error) {
        console.log(error);
      }

      this.attesaCalcola = false;

      // Ricarico i dati
      this.caricaDati();
    },

    controllaRighe() {
      return Object.keys(this.dati).length > 0 && this.dati.rimborsi.length > 0;
    },

    getListaConsegne() {
      if (!this.controllaRighe()) return;

      let res = [];

      // Copio l'array
      let arr = JSON.parse(JSON.stringify(this.dati.rimborsi));
      for (let key in arr) {
        res.push(arr[key]);
      }

      return arr;
    },

    async salvaKmCosto() {
      let res = await salva_rimborsiCosti(
        this.dataConsegna + "/" + this.autistaSel + "/" + this.kmCostiComputed
      );
    },

    async salvaKmCalcolato() {
      let resConf = await this.$confirm.open("Vuoi salvare i km percorsi ? ");
      if (resConf == false) return false;

      let res = await save_kmCalcolati(
        this.dataConsegna + "/" + this.autistaSel + "/" + this.kmCalcolati
      );
    },

    controllaAutista() {
      if (!!this.autistaSel == false) {
        this.$toast.info("Autista non selezionato");
        return false;
      }

      return true;
    },

    toTimeString(seconds) {
      return new Date(seconds * 1000)
        .toUTCString()
        .match(/(\d\d:\d\d:\d\d)/)[0];
    },

    setRigaSel(riga, ev) {
      if (ev) this.selectedRows.push(riga);
      else this.selectedRows.splice(this.selectedRows.indexOf(riga), 1);
    },
  },

  mounted() {
    this.init();
  },

  watch: {
    dataConsegna(val) {
      this.dataConsegnaFormat = this.$i2kFunctions.formatISODate(
        this.dataConsegna
      );
    },
  },

  computed: {
    getClienti() {
      return this.$store.getters["app/getClienti"];
    },

    kmInizComputed: {
      get: function () {
        return this.dati.kmIniziali;
      },
      set: function (val) {
        this.$emit("setKmInit", Number(val));
      },
    },

    kmCostiComputed: {
      get: function () {
        return this.dati.kmCosto;
      },
      set: function (val) {
        this.$emit("setKmCosto", Number(val));
      },
    },

    kmCalcComputed: {
      get: function () {
        return this.dati.kmCalcolati;
      },
      set: function (val) {
        this.kmCalcolati = val;
      },
    },
  },
};
</script>

<style scoped>
.drawer {
  z-index: 6 !important;
  border-left: 1px solid #ccc !important;
}

@media screen and (min-width: 960px) {
  .drawer {
    z-index: 2 !important;
  }
}
</style>


