<template src="./gestioneRicerca2.html"></template>


<script>
import { get_paroleChiaveRicerca, get_parolechiaveIncluseEscluse, salva_parole_chiave } from "@/api/api.catalogo.js"

export default {
    props: {
        articoloSel: { type: Object, default: null },
        testataSel: { type: Object, default: null },
    },

    data: () => ({
        elencoParole: [],
        paroleChiaveTestata: [],
        parolaNew: "",
        parolaSel: "",

        filtriTestata: [],
        filtroNew: "",

        sottoCategoria: [],
        sottoCategoriaNew: "",

        paroleIncluse: [],
        parolaInclusaNew: "",
        parolaInclusaSel: "",

        paroleEscluse: [],
        parolaEsclusaNew: "",
        parolaEsclusaSel: "",

        valoriRicerca: [],
        valoriRicercaTestata: [],

        dataContainer: 1,   // id che mi serve per forzare il refresh dei componenti
    }),

    methods: {
        //#region dati generici catalogo

        // funzione per caricare l'elenco di TUTTI i valori filtri e parole chiavi presenti nel catalogo
        async caricaListeDati() {
            let res = await get_paroleChiaveRicerca("");
            this.elencoParole = res.data;
        },

        //#endregion


        async caricaDati() {
            await this.caricaParoleChiaveTestata();
            await this.caricaFiltriTestata();
            await this.caricaSottoCategoria();
            this.caricaParoleIncluseEscluse();
            await this.caricaValoriRicerca();
            await this.caricaValoriRicercaTestata();
        },


        //#region dati testata selezionata

        async caricaParoleChiaveTestata() {
            let par = this.testataSel.codTestata + "/" + this.testataSel.suffisso;
            let res = await get_paroleChiaveRicerca(par);
            if (res.data == null || res.data == undefined) {
                this.$toast.alert("Nessuna parola chiave trovata");
                return;
            }
            this.paroleChiaveTestata = res.data;
            // imposto la prima parola chiave per caricare i dati
            this.parolaSel = this.paroleChiaveTestata[0];
        },

        // in base alla parola chiave selezionata carico i relativi filtri
        async caricaFiltriTestata() {
            if (this.parolaSel == null || this.parolaSel == "") return;
            let par = "filtri/" + this.parolaSel + "/" + this.testataSel.codTestata + "/" + this.testataSel.suffisso;
            let res = await get_paroleChiaveRicerca(par);
            this.filtriTestata = res.data;
        },

        async caricaSottoCategoria() {
            if (this.parolaSel == null || this.parolaSel == "") return;
            let par = "sotto-categoria/" + this.testataSel.codTestata + "/" + this.testataSel.suffisso;
            let res = await get_paroleChiaveRicerca(par);
            this.sottoCategoria = res.data;
        },

        async caricaParoleIncluseEscluse() {
            let res = await get_parolechiaveIncluseEscluse(this.testataSel);
            if (res.data != null && res.data != undefined) {
                this.paroleIncluse = res.data.paroleIncluse;
                this.paroleEscluse = res.data.paroleEscluse;
            }
        },

        // carico tutti i valori di ricerca presenti nei vari filtri della parola chiave selezionata
        async caricaValoriRicerca() {
            if (this.parolaSel == null || this.parolaSel == "") return;
            let i = 0;
            for (let el of this.filtriTestata) {
                let par = "filtri/valori/" + this.parolaSel + "/" + el;
                let res = await get_paroleChiaveRicerca(par);
                this.valoriRicerca[i] = res.data;
                i++;
                this.dataContainer++;
            }
        },

        // carico tutti i valori di ricerca per la testata selezionata
        async caricaValoriRicercaTestata() {
            if (this.parolaSel == null || this.parolaSel == "") return;
            let i = 0;
            for (let el of this.filtriTestata) {
                let par = "filtri/valori/" + this.parolaSel + "/" + el + "/" + this.testataSel.codTestata + "/" + this.testataSel.suffisso;
                let res = await get_paroleChiaveRicerca(par);
                this.valoriRicercaTestata[i] = res.data;
                i++;
                this.dataContainer++;
            }
        },

        //#endregion

        // evento sul cambiamento della prima parola chiave
        setParolaSel(item) {
            this.parolaSel = item;
        },

        async salvaDati() {
            let par = {
                ValoreRicerca: this.parolaSel,
                fitriTestata: this.filtriTestata,
                valoriFiltri: this.valoriRicercaTestata
            }
            let res = await salva_parole_chiave(par, this.testataSel.codTestata + "/" + this.testataSel.suffisso);
            if (res.data == "OK") this.$toast.info("Dati salvati");
            else this.$toast.alert(res.data);
        },

        /* Click pulsante deseleziona tutto per i valori dei filtri */
        deseleziona(i) {
            this.valoriRicercaTestata[i] = [];
            this.dataContainer++;
        }
    },

    created() {
        this.caricaListeDati();
    },

    mounted() {
        this.caricaDati();
    },

    watch: {
        parolaSel() {
            this.caricaFiltriTestata();
        }
    }
}
</script>

<style scoped>
.v-row.flex-nowrap {
    flex-wrap: nowrap;
}
</style>