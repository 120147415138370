<template>
  <v-container fluid>
    <!-- TESTATA PRODOTTO -->
    <v-row class="mt-0" no-gutters
      ><!-- v-if="testataSel === true" -->
      <v-col cols="1" class="pa-1">
        <v-text-field
          hide-details
          label="CodTestata"
          :readonly="true"
          style="font-weight: bold"
          v-model="testata.codTestataCompleto"
        ></v-text-field>
      </v-col>
      <v-col cols="1" class="pa-1">
        <v-text-field
          hide-details
          label="Suffisso"
          type="number"
          :readonly="true"
          style="font-weight: bold"
          v-model="testata.suffisso"
        ></v-text-field>
      </v-col>
      <v-col cols="10" class="pa-1">
        <v-text-field
          hide-details
          label="Descrizione"
          :readonly="true"
          style="font-weight: bold"
          v-model="testata.descrizione"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-layout class="mt-3 mb-3" justify-center column>
      <v-row no-gutters>
        <div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                hide-details
                @click="refreshLog"
                text
                fab
                small
                v-on="on"
                elevation="0"
                class="mr-2 mt-2"
              >
                <v-icon color="primary" large>refresh</v-icon>
              </v-btn>
            </template>
            <span>Ricarica</span>
          </v-tooltip>
        </div>
        <div class="ml-5">
          <v-select
            :disabled="loadingTabella"
            hide-details
            style="width: 50% !important"
            color="primary"
            :items="listaSelect"
            label="Filtro Operazioni"
            v-model="tipoFiltro"
            @change="caricaLogOperazioni"
          ></v-select>
        </div>
        <v-spacer></v-spacer>
        <div style="width: 30%">
          <v-text-field
            :disabled="loadingTabella"
            hide-details
            color="primary"
            v-model="searchLog"
            append-icon="search"
            label="Cerca"
          ></v-text-field>
        </div>
      </v-row>
    </v-layout>

    <v-row no-gutters class="mb-0 mt-0">
      <v-col>
        <v-data-table
          id="wheel"
          dense
          :headers="headerLog"
          :items="listaLog"
          :items-per-page="50"
          class="elevation-0 mt-0"
          :search="searchLog"
          :loading="loadingTabella"
          loading-text="Caricamento dati..."
          no-data-text="Nessun dato disponibile"
          mobile-breakpoint="0"
          fixed-header
          hide-default-header
          :page.sync="page"
          @page-count="pageCount = $event"
          style="border-bottom: 1px solid black"
          height="calc(92vh - 320px)"
        >
          <template v-slot:header="{ props: { headers } }">
            <thead>
              <tr>
                <th v-for="(head, i) in headers" :key="i">
                  <span v-html="head.text"></span>
                </th>
              </tr>
            </thead>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.tabella }}</td>
              <td>{{ item.colonna }}</td>
              <td>{{ convertData(item.dataOra) }}</td>
              <td>{{ item.note }}</td>
              <td>{{ item.utente }}</td>
              <td>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" v-if="item.operazione === 'add'" color="green">{{
                      item.operazione
                    }}</v-icon>
                  </template>
                  <span>Aggiunta</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" v-if="item.operazione === 'delete'" color="red">{{
                      item.operazione
                    }}</v-icon>
                  </template>
                  <span>Elimina</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" v-if="item.operazione === 'edit'">{{
                      item.operazione
                    }}</v-icon>
                  </template>
                  <span>Modifica</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- TOT RECORD -->
    <v-col cols="12" class="ma-0 pa-0">
      <v-row no-gutters>
        <div class="mt-5">
          <span>
            Tot. Record: <b>{{ listaLog.length }}</b></span
          >
        </div>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
/* import { get_logOperazioni } from "@/api/api.log.js"; */
import { get_logOperazioni } from "@/api/api.archivi.js"; //testata prodotto
import { get_logOperazione } from "@/api/api.archivi.js"; //marchio azienda

import objectArchivi from "@/views/intranet/archivi/objectArchivi.js";

export default {
  props: {
    testataSel: { type: Boolean, default: false },
    testata: { type: Object, default: null },
    tabs: { type: Number, default: null },
    storico: { type: Boolean, default: false },
  },

  mixins: [objectArchivi],
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    loadingTabella: false,
    listaLog: [],
    searchLog: "",
    headerLog: [],

    listaSelect: ["Tutto", "Campi Testata", "Filtri Ricerca"],
    tipoFiltro: "",
  }),

  methods: {
    async caricaLogOperazioni() {
      //if (this.testataSel != false) {
      this.loadingTabella = true;
      this.listaLog = [];
      let res = "";

      this.testata.tipoFiltro = this.tipoFiltro; //setto il tipo di filtro
      if (this.storico == true) {
        res = await get_logOperazioni(this.testata); //testata prodotto
        this.listaLog = res.data;
      } else {
        res = await get_logOperazione(this.testata); //marchio azienda
        this.listaLog = res.data;
      }

      this.loadingTabella = false;
      //}
    },

    /* CONVERTO DATA-ORA Log_Operazioni */
    convertData(data) {
      let dataTemp =
        data.substring(4, 0) + "-" + data.substring(7, 5) + "-" + data.substring(8, 10);
      data = this.$i2kFunctions.formatISODateTime(
        dataTemp.substring(0, 10) + "T" + data.substring(11, 20)
      );
      return data;
    },

    refreshLog() {
      this.caricaLogOperazioni();
    },
  },

  mounted() {
    this.tipoFiltro = "Tutto"; //setto default filtro
    this.headerLog = this.headerTabellaLog();
    this.refreshLog(); //carico log
  },

  watch: {
    tabs(newVal) {
      if (newVal && this.tabs == 1) this.caricaLogOperazioni();
    },
    /* tipoFiltro(newVal) {
      if (newVal) this.caricaLogOperazioni();
    }, */
  },
};
</script>
