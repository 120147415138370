<template >
  <v-container>
    <v-row class="ml-0 mr-0 mb-4 mt-4">
      <v-col class="ma-0 pa-0">
        <v-row class="ma-0 pa-0 mt-2">
          <v-text-field type="number" style="max-width: 120px" v-model="cassetto" label="Cassetto" class="pa-1" required
            @focus="$event.target.select()">
          </v-text-field>

          <v-text-field type="number" style="max-width: 120px" v-model="baia" label="Baia" class="pa-1" required
            @focus="$event.target.select()">
          </v-text-field>
        </v-row>
        <v-btn color="primary" @click="chiamaCassetto(cassetto)">
          Estrai cassetto
        </v-btn>
      </v-col>

      <v-col cols="3" style="align-items: end; display: flex; text-align: end" class="ma-0 pa-0">
        <v-spacer></v-spacer>
        <v-btn class="ml-2" large color="primary" @click="riponiCassetto()" style="font-size: large">
          Riponi cassetto
        </v-btn>
      </v-col>
    </v-row>

    <v-divider />
    <v-row class="mt-2 pr-0 mb-4">
      <v-col cols="1" v-for="(item, i) in cassetti" :key="i">
        <v-btn large style="
            font-size: 1.2em;
            padding: 0 !important;
            min-width: 100% !important;
            height: 48px !important;
          " @click="chiamaCassetto(item.cassetto)">
          {{ item.cassetto }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row class="ma-0 pa-0 mt-4">
      <v-menu bottom right offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon class="pr-2">
            <v-icon>more_vert</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="pulisciCodaCassetti()">
            <v-list-item-title>
              Cancella coda cassetti
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn @click="infoCassetto()" icon>
        <v-icon>refresh</v-icon>
      </v-btn>
    </v-row>

    <v-row v-for="(item, i) in infoMagazzino" :key="i" class="ma-0 pa-0 ml-2">
      <span v-html="item"></span>
    </v-row>

    <!-- <v-btn @click="caricaAltreInfo()"> altre info</v-btn>
    <div>{{ altreInfo }}</div> -->

    <div>
      {{ cassettiInCoda }}
    </div>
  </v-container>
</template>


<script>
import {
  get_connessionePlc,
  get_struttura_magazzino,
} from "@/api/api.intranet.js";

import mixinMagazVerticale from "@/views/intranet/magazzino/mixinMagVerticale.js";

export default {
  mixins: [mixinMagazVerticale],

  data: () => ({
    cassetto: 1,
    baia: 1,

    socket: null,
    infoMagazzino: [],

    cassetti: [],
    timer: null,

    altreInfo: {},
    timerId: null,

    cassettiInCoda: [],
    timerQueue: null,
  }),

  methods: {
    async ping() {
      let res = await get_connessionePlc();
      if (res.data == "OK")
        this.$toast.info("Connessione effettuata correttamente");
      else this.$toast.alert(res.data);
    },

    async caricaStrutturaMag() {
      let res = await get_struttura_magazzino();
      this.cassetti = res.data;
    },

    async infoCassetto() {
      let resp = await this.info();
      if (resp != null && resp.Message != undefined)
        if (resp.Message != "" && !resp.Message.includes("NESSUNA")) {
          this.infoMagazzino = resp.Message.split(";");
        }
      if (this.timerId != null) this.cancellaTimer();
    },

    async caricaAltreInfo() {
      let resp = await this.altre_info(2);
      this.altreInfo = resp;
    },

    async chiamaCassetto(c) {
      // se il cassetto non c'è nella coda lo inserisco
      if (this.cassettiInCoda.indexOf(c) == -1) this.cassettiInCoda.push(c);

      // se ci sono più di due cassetti esco dalla funzione e aspetto che uno venga riposto
      if (this.cassettiInCoda.length > 2) {
        this.$toast.info("Cassetto inserito nella coda");
        return;
      }
      let res = await this.estrai(c);
      if (res == "OK") this.$toast.info("Comando inviato");
      else this.$toast.alert(res);
      this.avviaTimer(5000);
    },

    async riponiCassetto() {
      let res = await this.riponi();
      if (res == "OK") this.$toast.info("Comando inviato");
      else this.$toast.alert(res);
      this.avviaTimer(10000);

      // tolgo il primo cassetto dalla coda
      if (this.cassettiInCoda.length > 0) this.cassettiInCoda.splice(0, 1);
      // se c'è un terzo cassetto nella coda lo chiamo (il secondo cassetto è già stato portato in baia)
      if (this.cassettiInCoda.length > 1)
        this.chiamaCassetto(this.cassettiInCoda[1]);
    },

    avviaTimer(sec) {
      this.timerId = setTimeout(this.infoCassetto, sec);
    },

    cancellaTimer() {
      try {
        clearTimeout(this.timerId);
        // eslint-disable-next-line no-empty
      } catch (error) { }
    },

    pulisciCodaCassetti() {
      this.cassettiInCoda = [];
      this.$toast.info("Coda cassetti cancellata. Nota: se il commando è già stato inviato al magazzino le operazioni sui cassetti verranno eseguite in ogni caso");
    }
  },

  mounted() {
    this.caricaStrutturaMag();
    this.infoCassetto();
    // timer di refresh info cassetto in baia
    this.timer = setInterval(() => {
      this.infoCassetto();
    }, 15000);

    // time di pulizia coda cassetti ogni 10 minuti
    this.timerQueue = setInterval(() => {
      this.cassettiInCoda = [];
    }, 600000);
  },

  beforeDestroy() {
    //this.closeStream();
    clearInterval(this.timer);
    clearInterval(this.timerQueue);
  },

  computed: {
    descUtente() {
      return this.$store.getters["user/getDescUser"];
    },
  },
};
</script>