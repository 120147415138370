import request from '@/services/interceptor.js'


// --------------------
// Ricerca
// --------------------
export const get_datiRicerca = (par) => {
    return request({
        url: '/catalogo/ricerca',
        method: 'post',
        data: par
    })
};

export const get_datiFiltriRicerca = (par) => {
    return request({
        url: '/catalogo/ricerca/filtri',
        method: 'post',
        data: par
    })
};

export const get_imgRicerca = (par) => {
    return request({
        url: '/catalogo/ricerca/immagini',
        method: 'post',
        data: par
    })
};

export const get_aziende = () => {
    return request({
        url: '/catalogo/aziende',
        method: 'get',
    })
};

export const get_catmerc = (par) => {
    return request({
        url: '/catalogo/aziende/catmerc/' + par,
        method: 'get',
    })
};




// --------------------
// Pagina catalogo
// --------------------
export const get_datiPaginaCatalogo = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/' + par,
        method: 'get',
    })
};

export const get_datiTecnici = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/datiTecnici/' + par,
        method: 'get',
    })
};

export const salva_datiTecnici = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/datiTecnici',
        method: 'post',
        data: par
    })
};

export const get_datiTecniciArticolo = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/datiTecnici/articolo/' + par,
        method: 'get',
    })
};

export const salva_datiTecniciArticolo = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/datiTecnici/articolo',
        method: 'post',
        data: par
    })
};

export const get_valoriTecnici = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/valoriTecnici/' + par,
        method: 'get',
    })
};

export const salva_valoriTecnici = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/valoriTecnici',
        method: 'post',
        data: par
    })
};

export const del_valoriTecnici = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/valoriTecnici',
        method: 'delete',
        data: par
    })
};

export const get_correlati = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/correlati/' + par,
        method: 'get',
    })
};

export const salva_correlati = (par, data) => {
    return request({
        url: '/catalogo/paginacatalogo/correlati/' + par,
        method: 'post',
        data: data
    })
};

export const del_correlati = (par, data) => {
    return request({
        url: '/catalogo/paginacatalogo/correlati/' + par,
        method: 'delete',
        data: data
    })
};

export const get_datiDettaglioPrezzi = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/dettaglio/' + par,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_datiDettaglioOrdinato = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/dettaglio/ordinato/' + par,
        method: 'get',
        noLoadingBar: true
    })
};


export const get_ordinamentoCatalogo = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/ordinamento/' + par,
        method: 'get',
    })
};
export const set_ordinamentoCatalogo = (data) => {
    return request({
        url: '/catalogo/paginacatalogo/ordinamento',
        method: 'post',
        data: data
    })
};

export const update_descrizioneCatalogo = (par, data) => {
    return request({
        url: '/catalogo/paginacatalogo/descrizione/' + par,
        method: 'put',
        data: data,
    })
};

export const set_nuovaQuantita = (data) => {
    return request({
        url: 'catalogo/paginacatalogo/quantita',
        method: 'post',
        data: data,
    })
}

export const ins_vuoto = (data) => {
    return request({
        url: '/catalogo/paginacatalogo/inseriscivuoto',
        method: 'post',
        data: data,
    })
};

export const del_rigoCatalogo = (data) => {
    return request({
        url: '/catalogo/paginacatalogo',
        method: 'delete',
        data: data,
    })
};

export const ins_preferito = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/preferiti/' + par,
        method: 'get',
    })
};

export const get_areaTecnicaCatalogo = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/areatecnica/' + par,
        method: 'get',
    })
};

export const set_testataPreferita = (data) => {
    return request({
        url: '/catalogo/paginacatalogo/testata/preferiti',
        method: 'post',
        data: data,
    })
}

export const del_testataCatalogo = (par, data) => {
    return request({
        url: '/catalogo/paginacatalogo/' + par + '/elimina',
        method: 'delete',
        data: data,
    })
};

export const set_nuovoSuffisso = (data) => {
    return request({
        url: '/catalogo/paginacatalogo/cambiosuffisso',
        method: 'post',
        data: data,
    })
}

export const set_nuovoSuffissoTestata = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/cambiosuffisso/' + par,
        method: 'get',
    })
}

export const set_nuovoTipo = (par, data) => {
    return request({
        url: '/catalogo/paginacatalogo/tipologia/' + par,
        method: 'post',
        data: data,
    })
}

export const get_datiArticolo = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/articolo/' + par,
        method: 'get',
    })
}


export const set_hImg = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/img/altezza/' + par,
        method: 'get',
    })
}



export const get_testata = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/testata/' + par,
        method: 'get',
    })
}

export const salva_catMerceologica = (data) => {
    return request({
        url: '/catalogo/paginacatalogo/testata/categoriaMerceologica',
        method: 'post',
        data: data,
    })
}







// --------------------
// Terzisti
// --------------------
export const get_ordiniForTerzisti = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/terzisti/ordinifornitori/' + par,
        method: 'get',
    })
};

export const get_mastrinoForTerzisti = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/terzisti/mastrino/' + par,
        method: 'get',
    })
};

export const get_articoloGrezzoTerzisti = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/terzisti/articologrezzo/' + par,
        method: 'get',
    })
};


export const set_dataConsegnaConf = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/terzisti/consegna/modifica',
        method: 'post',
        data: par
    })
};

export const get_scartiTerzisti = (par) => {
    return request({
        url: '/catalogo/paginacatalogo/terzisti/scarti/' + par,
        method: 'get',
    })
};

// --------------------
// Parole chiave
// --------------------
// elenco delle parole chiave abbinate alla testata e al suffisso (url: parolechiave/{testata}/{suffisso})
export const get_paroleChiaveRicerca = (param) => {
    return request({
        url: '/catalogo/parolechiave/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_filtriParoleChiave = (param) => {
    return request({
        url: '/catalogo/parolechiave/filtri/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_parolechiaveIncluseEscluse = (data) => {
    return request({
        url: '/catalogo/parolechiave/incluseescluse',
        method: 'post',
        data: data,
    })
}

// salvataggio di tutte le parole chiave (url: salva/{testata}/{suffisso})
export const salva_parole_chiave = (data, par) => {
    return request({
        url: '/catalogo/parolechiave/salva/' + par,
        method: 'post',
        data: data,
    })
}

// --------------------
// Distinta base
// --------------------
export const get_tipiSerrDistinta = (param) => {
    return request({
        url: '/catalogo/distintabase/dinamica/tipiserramento/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_elaboraDistinta = (param) => {
    return request({
        url: '/catalogo/distintabase/dinamica/elabora',
        data: param,
        method: 'post',
        noLoadingBar: true
    })
};

export const get_distintaBaseDinamica = (param) => {
    return request({
        url: '/catalogo/distintabase/dinamica/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_componentiDistinta = (param) => {
    return request({
        url: '/catalogo/distintabase/dinamica/componenti/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_opzioniiDistinta = (param) => {
    return request({
        url: '/catalogo/distintabase/dinamica/opzioni/' + param,
        method: 'get',
        noLoadingBar: true
    })
};

export const get_distintaBaseSemplice = (param) => {
    return request({
        url: '/catalogo/distintabase/semplice/' + param,
        method: 'get',
        noLoadingBar: true
    })
};