var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-layout',{attrs:{"justify-center":"","column":""}},[_c('v-card',[_c('v-row',{staticClass:"mt-1"},[_c('div',{staticClass:"ml-5"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-5 mt-2",attrs:{"text":"","fab":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.caricaDati()}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Ricarica")])])],1),_c('div',{attrs:{"id":"dataPicker"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"primary","label":"Data Inizio","append-icon":"edit"},on:{"blur":function($event){_vm.dateInizio = _vm.parseDate(_vm.dateFormattedInizio)}},model:{value:(_vm.dateFormattedInizio),callback:function ($$v) {_vm.dateFormattedInizio=$$v},expression:"dateFormattedInizio"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.dateInizio),callback:function ($$v) {_vm.dateInizio=$$v},expression:"dateInizio"}})],1)],1),_c('div',{staticClass:"ml-5",attrs:{"id":"dataPicker"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"primary","label":"Data Fine","append-icon":"edit"},on:{"blur":function($event){_vm.dateFine = _vm.parseDate(_vm.dateFormattedFine)}},model:{value:(_vm.dateFormattedFine),callback:function ($$v) {_vm.dateFormattedFine=$$v},expression:"dateFormattedFine"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"color":"primary"},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dateFine),callback:function ($$v) {_vm.dateFine=$$v},expression:"dateFine"}})],1)],1),_c('v-spacer'),_c('div',{staticClass:"mr-5"},[_c('v-text-field',{staticStyle:{"width":"500px !important"},attrs:{"color":"primary","append-icon":"search","label":"Cerca"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"search":_vm.search,"dense":"","headers":_vm.headersTabella,"fixed-header":"","items":_vm.pagineScorri,"page":_vm.page,"items-per-page":_vm.pagineScorri.length,"hide-default-header":"","hide-default-footer":"","loading-text":"Caricamento dati...","height":"calc(100vh - 200px)","no-data-text":"Nessun dato disponibile"},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(head,i){return _c('th',{key:i,attrs:{"id":"headerTabella"}},[_c('span',[_vm._v(_vm._s(head.text))])])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',{attrs:{"id":"allineamentoTestoColonna"}},[_vm._v(_vm._s(item.progressivo))]),_c('td',[_vm._v(_vm._s(item.dataDocumento.substr(0, 10)))]),_c('td',[_vm._v(_vm._s(item.tipoDocumento))]),_c('td',{attrs:{"id":"allineamentoTestoColonna"}},[_vm._v(_vm._s(item.numeroDocumento))]),_c('td',{attrs:{"id":"allineamentoTestoColonna"}},[_vm._v(_vm._s(item.cdAnagrafica))]),_c('td',[_vm._v(_vm._s(item.ragioneSociale))]),_c('td',[_vm._v(_vm._s(item.eMail))]),_c('td',[(item.stato == 'OK')?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("done")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("close")])],1)])]}},{key:"footer",fn:function(){return [_c('div',[_vm._v(" Tot. Record: "),_c('b',[_vm._v(_vm._s(_vm.pagineScorri.length))])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }