import Vue from 'vue'
import Vuex from 'vuex'
import appModule from './modules/app'
import userModule from './modules/user'
import authModule from './modules/auth'
import ricercaModule from './modules/ricerca'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app: appModule,
    user: userModule,
    auth: authModule,
    ricerca: ricercaModule
  }
});

export default store;
