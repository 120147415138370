import request from '@/services/interceptor.js'

// --------------------
// Query
// --------------------
export const get_query = (par) => {
    return request({
        url: '/utilita/query',
        method: 'post',
        data: par
    })
}

export const get_filesQuery = () => {
    return request({
        url: '/utilita/query',
        method: 'get',
    })
}