import { get_clienti } from "@/api/api.intranet.js"

const app = {
    namespaced: true,

    state: {
        loading: false,
        message: '',
        leftDrawer: null,
        carrello: [],
        filterDrawer: null,
        dialog: "",
        dialogResult: null,
        selectedPage: null,
        selectedSubMenu: null,
        callingNumber: null,
        urlPage: null,
        clienti: [],
        selectedCliente: null,
        waitLoginAzienda: null,
        spedizioneBRT: "",
    },

    mutations: {

        setLoading(state, vis) {
            state.loading = vis
        },

        showMessage(state, data) {
            state.message = data
        },

        showLeftDrawer(state, data) {
            state.leftDrawer = !state.leftDrawer
        },

        setLeftDrawer(state, data) {
            state.leftDrawer = data
        },

        showFilterDrawer(state, data) {
            state.filterDrawer = !state.filterDrawer;
        },

        setFilterDrawer(state, data) {
            state.filterDrawer = data
        },

        setCarrello() {

        },

        setDialog: (state, data) => {
            state.dialog = data
        },

        setDialogResult: (state, data) => {
            state.dialogResult = data
        },

        setSelectedPage(state, data) {
            state.selectedPage = data
        },

        setSelectedSubMenu(state, data) {
            state.selectedSubMenu = data
        },

        setCallingNumber(state, data) {
            state.callingNumber = data;
        },

        setUrlPage(state, data) {
            state.urlPage = data;
        },

        setListaClienti: (state, items) => {
            state.clienti = items
        },

        setCliente: (state, data) => {
            state.selectedCliente = data
        },

        setWaitLoginAzienda: (state, data) => {
            state.waitLoginAzienda = data
        },

        setSpedizioneBRT: (state, data) => {
            state.spedizioneBRT = data
        },
    },

    actions: {

        getClienti({
            commit,
            dispatch
        }) {

            return new Promise((resolve, reject) => {

                get_clienti().then(resp => {
                    commit("setListaClienti", resp.data)
                    resolve(resp)
                }).catch(err => {
                    reject(err)
                })
            })

        },

    },

    getters: {
        leftDrawerVisibility: state => state.leftDrawer,
        filterDrawerVisibility: state => state.filterDrawer,
        getDialog: state => tipo => {
            return state.dialog == tipo
        },
        getDialogResult: state => state.dialogResult,

        //ritorna la pagina corrente
        getSelectedPage: state => state.selectedPage,
        //ritorna l'elenco dei menu abilitati per l'utente
        getSelectedSubMenu: state => state.selectedSubMenu,
        getCallingNumber: state => state.callingNumber,
        getUrlPage: state => state.urlPage,

        getClienti: state => state.clienti,
        getClientiToolbar: state => state.clienti.filter(f => f.cliente == true),
        getCliente: state => state.selectedCliente,
        getSpedizioneBRT: state => state.spedizioneBRT,
    }

}

export default app