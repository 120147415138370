import Vue from 'vue'
import Router from 'vue-router'

import Main from '@/views/Main'
import NotFound from '@/views/404.vue'

import Home from '@/views/Home'
import Extranet from '@/views/Extranet.vue'
import Ricerca from '@/views/Ricerca.vue'
import News from '@/views/News.vue'

import Prodotti from '@/views/ricerca/Prodotti.vue'
import Catalogo from '@/views/ricerca/Catalogo.vue'
import Sommario from '@/views/ricerca/Sommario.vue'
import Indice from '@/views/ricerca/Indice.vue'

import Ordini from '@/views/extranet/Doc.vue'
import DDT from '@/views/extranet/Doc.vue'
import Fatture from '@/views/extranet/Doc.vue'
import Web from '@/views/extranet/Doc.vue'

import Azienda from '@/views/home/Azienda.vue'
import NewsDettaglio from '@/views/news/NewsDettaglio.vue'

import PwdReset from '@/views/account/PasswordReset.vue'
import AttivaAccount from '@/views/account/ActivateAccount.vue'
import Registrazione from '@/views/account/Registrazione.vue'
import Profilo from '@/views/account/Registrazione.vue'
import Carrello from '@/views/Carrello.vue'

// Intranet
import Consegne from '@/views/intranet/mappe/Consegne.vue'
import HomeIntranet from '@/views/intranet/Home.vue'
import Scansioni from '@/views/intranet/Scansioni.vue'
import Rimborsi from '@/views/intranet/mappe/Rimborsi.vue'
import Magazzino from "@/views/intranet/magazzino/Magazzino.vue"
import Laser from "@/views/intranet/magazzino/Laser.vue"
import CentroLavoro from "@/views/intranet/magazzino/CentroLavoro.vue"
import OrdiniClienti from "@/views/intranet/OrdiniClienti.vue"
import EMailInviate from "@/views/intranet/EMailInviate.vue"
import Test from "@/views/intranet/Test.vue"
import VisDocumenti from "@/views/intranet/documenti/VisualizzaDocumenti.vue"
import InfoAziendaDialog from "@/views/intranet/InfoAziendaDialog.vue"
import OrdiniPreparazione from "@/views/intranet/mappe/OrdiniPreparazione.vue"
import Comuni from "@/views/intranet/mappe/Comuni.vue"

//ARCHIVI
import TestataProdotto from "@/views/intranet/archivi/TestataProdotto.vue"
import MarchiAzienda from "@/views/intranet/archivi/MarchiAzienda.vue"
import LottiProduzione from "@/views/intranet/archivi/LottiProduzione.vue"
import LavorazioniTerzisti from "@/views/intranet/terzisti/LavorazioniTerzisti.vue"

// Telefonia
import MappaPorte from '@/views/intranet/dispositivi/MapDispositivi.vue'

import SpedizioniBRT from '@/views/intranet/etichette/EtichetteBRT'

import Query from '@/views/utilita/Query.vue'

import Template from '@/views/utilita/Template.vue'

import storage from '@/services/storage.js'
import FunctionsApp from "@/utils/functions.js";

Vue.use(Router)



const routerOptions = [{
  path: '/',
  component: Main,
  children: [{
    path: '/',
    component: Home,
    meta: {
      title: "Home"
    }
  },
  {
    name: 'ricerca',
    path: 'ricerca/:valore',
    component: Ricerca,
    props: true,
    children: [{
      name: 'prodotti',
      path: 'prodotti',
      component: Prodotti,
      props: true,
      meta: {
        title: "Ricerca"
      },
    },
    {
      name: 'catalogo',
      path: 'catalogo/:testata/:suffisso/:codArt?',
      component: Catalogo,
      props: true,
      meta: {
        title: "Ricerca"
      },
    },
    {
      name: 'sommario',
      path: 'sommario/:codcategoria',
      component: Sommario,
      props: true,
      meta: {
        title: "Ricerca"
      },
    },
    {
      name: 'indice',
      path: 'indice/:codcategoria',
      component: Indice,
      props: true,
      meta: {
        title: "Ricerca"
      },
    },
    ],

  },
  {
    path: 'documenti/',
    component: Extranet,
    props: true,
    meta: {
      checkAuth: true,
    },
    children: [{
      path: '',
      redirect: 'ordini',
      meta: {
        checkAuth: true
      },
    },
    {
      path: 'ordini',
      component: Ordini,
      meta: {
        checkAuth: true,
        title: "Extranet"
      },
    },
    {
      path: 'ddt',
      component: DDT,
      meta: {
        checkAuth: true,
        title: "Extranet"
      },
    },
    {
      path: 'fatture',
      component: Fatture,
      meta: {
        checkAuth: true,
        title: "Extranet"
      },
    },
    {
      path: 'web',
      component: Web,
      meta: {
        checkAuth: true,
        title: "Extranet"
      },
    }
    ]
  },
  {
    path: '/passwordreset',
    component: PwdReset,
    meta: {
      title: "Password reset"
    }
  },
  {
    path: '/activateaccount',
    component: AttivaAccount,
    meta: {
      title: "Attiva account"
    }
  },
  {
    name: 'news',
    path: 'news',
    component: News,
    meta: {
      title: "News"
    },
  },
  {
    name: 'news-article',
    path: 'news/:azienda/:prog',
    component: NewsDettaglio,
    props: true,
    meta: {
      title: "News"
    },
  },
  {
    name: 'registrazione',
    path: 'registrazione',
    component: Registrazione,
    meta: {
      title: "Registrazione"
    }
  },
  {
    name: 'profilo',
    path: 'profilo',
    component: Profilo,
    meta: {
      title: "Profilo"
    }
  },
  {
    name: 'azienda',
    path: 'azienda',
    component: Azienda,
    meta: {
      title: "Azienda"
    },
  },
  {
    name: 'consegne',
    path: 'consegne',
    component: Consegne,
    meta: {
      title: "Consegne",
      checkAuth: true
    },
  },
  {
    name: 'rimborsi',
    path: 'rimborsi',
    component: Rimborsi,
    meta: {
      title: "Rimborsi",
      checkAuth: true,
    },
  },

  {
    name: 'mappatura',
    path: 'mappatura',
    component: MappaPorte,
    meta: {
      title: "Mappatura Porte",
      checkAuth: true,
    },
  },

  {
    name: 'homeintranet',
    path: 'homeintranet',
    component: HomeIntranet,
    meta: {
      title: "Intranet",
      checkAuth: true,
    },
  },

  {
    name: 'scansioni',
    path: 'scansioni',
    component: Scansioni,
    meta: {
      title: "Scansioni",
      checkAuth: true,
    },
  },



  {
    name: 'magzzino',
    path: 'magazzino',
    component: Magazzino,
    meta: {
      title: "Magazzino",
      checkAuth: true,
    }
  },

  {
    name: 'laser',
    path: 'laser',
    component: Laser,
    meta: {
      title: "laser",
      checkAuth: true,
    }
  },

  {
    name: 'centrolavoro',
    path: 'centrolavoro',
    component: CentroLavoro,
    meta: {
      title: "Centro Lavoro",
      checkAuth: true,
    }
  },
  {
    name: 'carrello',
    path: 'carrello',
    component: Carrello,
    meta: {
      title: "carrello",
      checkAuth: true,
    }
  },

  {
    name: 'etichette',
    path: 'etichette',
    component: SpedizioniBRT,
    meta: {
      title: "etichette",
      checkAuth: true,
    }
  },

  {
    name: 'query',
    path: 'utilita/query',
    component: Query,
    meta: {
      title: "query",
      checkAuth: true,
    }
  },

  {
    name: 'ordiniclienti',
    path: 'ordiniclienti',
    component: OrdiniClienti,
    meta: {
      title: "ordiniclienti"
    }
  },

  {
    name: 'ordinipreparazione',
    path: 'ordinipreparazione',
    component: OrdiniPreparazione,
    meta: {
      title: "ordinipreparazione"
    }
  },
  {
    name: 'comuni',
    path: 'comuni',
    component: Comuni,
    meta: {
      title: "comuni"
    }
  },
  {
    name: 'dialog',
    path: 'dialog',
    component: InfoAziendaDialog,
    meta: {
      title: "dialog"
    }
  },

  {
    name: 'email',
    path: 'utilita/emailinviate',
    component: EMailInviate,
    meta: {
      title: "email"
    }
  },

  {
    name: 'testataProdotto',
    path: 'testataProdotto',
    component: TestataProdotto,
    meta: {
      title: "Testate",
      checkAuth: true,
    }
  },
  {
    name: 'marchiAzienda',
    path: 'marchiAzienda',
    component: MarchiAzienda,
    meta: {
      title: "Marchi",
      checkAuth: true,
    }
  },
  {
    name: 'lottiProduzione',
    path: 'lottiProduzione',
    component: LottiProduzione,
    meta: {
      title: "Lotti",
      checkAuth: true,
    }
  },
  {
    name: 'template',
    path: 'template',
    component: Template,
    meta: {
      title: "template",
      checkAuth: true,
    }
  },
  {
    name: 'visualizzadocumenti',
    path: '/visualizzadocumenti',
    component: VisDocumenti,
    meta: {
      title: "Documenti",
      checkAuth: true,
    },
  },
  {
    name: 'test_simapac',
    path: '/test_simapac',
    component: LavorazioniTerzisti,
    meta: {
      title: "test_simapac",
      checkAuth: true,
    },
  },

  ],
},
{
  name: '404',
  path: '*',
  component: NotFound,
  props: false
},

]


const rou = new Router({
  mode: 'history',
  routes: routerOptions
})

rou.beforeEach((to, from, next) => {

  // Controllo se è richiesta l'autenticazione
  if (to.meta.checkAuth && !storage.checkAuth()) {

    next({
      path: '/' // Ritorno alla home
    })

    return
  }

  next();

})


rou.afterEach((to, from) => {

  let title = to.meta.title || ''

  // Imposto il nome della pagina
  window.document.title = "Battisti - " + title

  // Nome scheda su azienda
  FunctionsApp.webViewCommand("setTitle|" + title, true)
})

export default rou;