<template src="./prodotti.html"></template>

<script>
import ProductChip from "@/components/ricerca/ProductChip.vue";
import ParoleChiave from "@/components/dialog/catalogo/ParoleChiave.vue";
import DialogMerceologica from "@/components/dialog/archivi/DialogMerceologica.vue";

import modificaRigo from "@/components/dialog/catalogo/ModificaDescRiga";
import eliminaTestata from "@/components/dialog/catalogo/EliminaTestata";
import aggiungiPreferiti from "@/components/dialog/catalogo/DialogPreferiti";
import cambiaSuffisso from "@/components/dialog/catalogo/CambiaSuffisso";

import {
  get_imgRicerca,
  set_nuovoTipo,
  salva_catMerceologica,
} from "@/api/api.catalogo.js";

export default {
  // NOTA: serve per keep-alive dentro ricerca.html
  name: "prodotti",

  components: {
    ProductChip,
    ParoleChiave,
    DialogMerceologica,
    modificaRigo,
    eliminaTestata,
    aggiungiPreferiti,
    cambiaSuffisso,
  },

  props: {
    filtriOrdine: {
      type: Array,
      default: () => {
        return [];
      },
    },

    filtroRisultati: {
      type: Array,
      default: () => {
        return [];
      },
    },

    ordinamento: { type: String },
  },

  data: () => ({
    testata: "",
    suffisso: 0,
    descTestata: "",
    testateSelez: [],
    testataSingolaSelez: {},
    step: 24,
    resLimit: 0,

    dialogParoleChiave: false,
    dialogEliminaTestata: false,
    dialogPreferiti: false,
    dialogCambiaSuffisso: false,

    infoDlg: {
      show: false,
      info: "",
    },

    showMenu: false,
    x: 0,
    y: 0,

    datiArticoloDettPrezzi: {},

    elencoTipologie: [
      {
        width: 20,
        height: 20,
        nome: "obsoleto",
        testo: "Obsoleto",
        stile: "fill: orange; stroke-width: 3",
      },
      {
        width: 20,
        height: 20,
        nome: "fuoricatalogo",
        testo: "Fuori catalogo",
        stile: "fill: yellow; stroke-width: 3",
      },
      {
        width: 20,
        height: 20,
        nome: "eliminato",
        testo: "Eliminato",
        stile: "fill: blue; stroke-width: 3",
      },
      {
        width: 20,
        height: 20,
        nome: "disattivainternet",
        testo: "Disattiva Internet",
        stile: "fill: #FF1493; stroke-width: 3",
      },
    ],

    dialogMerceologica: false,
    search: "",

    tipoModificaMenuDx: "",
    dialogDescRiga: false,
  }),

  methods: {
    gotoPageCatalogo(testata, suffisso, desc) {
      this.testata = testata;
      this.suffisso = suffisso;
      this.descTestata = desc;

      let valore = this.$store.getters["ricerca/valoreRicerca"];

      // Pagina catalogo
      return "/ricerca/" + valore + "/catalogo/" + testata + "/" + suffisso;
    },

    scroll() {
      let nearBottomOfWindow =
        window.scrollY + window.innerHeight >=
        document.documentElement.offsetHeight - 300;

      if (nearBottomOfWindow == false) return;

      // Controllo limite
      if (this.resLimit < this.filtroRisultati.length) {
        // Quando scrollo devo ricavare le immagini
        this.getImmagini(this.resLimit);

        // Aumento il limite dello step
        this.resLimit += this.step;
      }
    },

    async getImmagini(startIndex) {
      // --- Ricavo le immagini
      let lista = [];
      let fine = this.resLimit;
      if (startIndex == this.resLimit) fine += this.step;

      for (let i = startIndex; i < fine; i++) {
        if (!!this.filtroRisultati[i] == false) break;

        // Controllo se è già stata caricata l'immagine
        if (this.filtroRisultati[i].pathImg) continue;

        lista.push(this.filtroRisultati[i]);
      }

      if (lista.length == 0) return;

      let res = await get_imgRicerca(lista);

      let testate = res.data;

      for (let i in testate) {
        if (!!testate[i] == false || Object.keys(testate[i]).length == 0) continue;

        let item = this.filtroRisultati.find(
          (f) =>
            f.codTestata == testate[i].codTestata && f.suffisso == testate[i].suffisso
        );
        if (item) item.pathImg = testate[i].pathImg;
      }
    },

    infoDialog(e, cod) {
      this.infoDlg.info = cod;
      this.infoDlg.show = true;
    },

    checkDivisione(item, index) {
      let res = index == 0 ? item[index].catDescOrdine : item[index - 1].catDescOrdine;

      return res != item[index].catDescOrdine || index == 0;
    },

    /*  checkDivisione(item, index) {
      // DEVO DIVIDERE X ORDINAMENTO IMPOSTATO
      console.log(this.ordinamento);
      if (this.ordinamento.length == 0) return false;

      //debugger;

      //console.log(item[index]);

      console.log(item[index]);

      let itemCheck = index == 0 ? item[index] : item[index - 1];

      if (itemCheck.filtriRicerca.includes(this.ordinamento) == false)
        return false;

      let filtri = item[index].filtriRicerca.split("|");

      for (let i = 0; i < filtri.length; i++) {
        let s = filtri[i].split("#");
        if (s[0] == this.ordinamento) {
          if (s[1] == this.ordinamento) {
            return false;
          }
        }
      }

   filtri = itemCheck.filtriRicerca.split("|");
      console.log(filtri);

      for (let i = 0; i < filtri.length; i++) {
        let s = filtri[i].split("#");
        if (s[0] == this.ordinamento) {
          if (s[1] != item[this.ordinamento]) {
            return false;
          }
        }
      }
      return true;
    }, */

    chiudiParoleChiave() {
      this.dialogParoleChiave = false;
      this.testateSelez = []; //resetto testata selezionata
      this.$functionsApp.cercaValore(this.inputText, this.$router); //ricarico prodotti
    },

    filtriRicerca() {
      window.open("/testataProdotto", "_blank");
    },

    async tastoDestro(e, testata) {
      // Controllo intranet
      if (this.role != 2) return;

      e.preventDefault();

      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });

      let valoreRicerca = testata.paroleChiave.split(",");
      testata.valoreRicerca = valoreRicerca[0];

      // Se non è stata selezionata la testata, la seleziono
      if (this.testateSelez.length == 0) {
        this.testateSelez.push(testata);
      }

      this.testataSingolaSelez = testata;
    },

    apriImgIndice() {
      if (this.testateSelez.length == 0) return;

      this.$functionsApp.webViewCommand(
        "apriImgIndice|" + this.testataSingolaSelez.pathImg
      );
    },



    apriDialogElimina() {
      this.dialogEliminaTestata = true;
    },

    chiudidialogElimina(aggiorna) {
      this.dialogEliminaTestata = false;

      if (aggiorna) this.caricaDati();
    },

    apriDialogPreferiti() {
      this.dialogPreferiti = true;
    },

    chiudiDialogPreferiti() {
      this.dialogPreferiti = false;
    },

    async cambiaTipo(nuovoTipo) {
      let par = null;
      let url = "testata/" + nuovoTipo;

      par = {
        CodTestata: this.testataSingolaSelez.codTestata,
        ctnumSUFFISSO: this.testataSingolaSelez.suffisso,
      };

      try {
        let res = await set_nuovoTipo(url, par);
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    selectionTestateClick(item) {
      if (this.testateSelez.length == 0) return;

      if (this.multiSelezione == true) return;

      this.testateSelez = [];

      if (this.testateSelez.indexOf(item) == -1) this.testateSelez.push(item);
      else this.testateSelez.splice(this.testateSelez.indexOf(item) - 1, 1);
    },

    async caricaCatMer(categoria) {
      if (this.dialogMerceologica === false) {
        this.dialogMerceologica = true;
        this.search = this.testateSelez[0].catMerceologica.substring(0, 3); //setto filtro ricerca categoria merceologica
      } else this.dialogMerceologica = false;
      if (categoria.ctMer != "" && categoria.ctMer) {
        categoria.ctMer =
          categoria.ctMer.substring(0, 3) + categoria.ctMer.substring(4, 7);
        this.testateSelez[0].catMerceologica = categoria.ctMer;
        let res = await salva_catMerceologica(this.testateSelez[0]);

        if (res.data != "OK") this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
        else this.$toast.info("Dati aggiornati con successo");
      }
    },

    apriDialogSuffisso() {
      this.dialogCambiaSuffisso = true;
    },

    chiudiDialogSuffisso() {
      this.dialogCambiaSuffisso = false;
    },

    apriModificaRiga(tipo) {
      this.tipoModificaMenuDx = tipo;
      this.dialogDescRiga = true;
    },

    chiudiModificaRiga(aggiorna) {
      this.dialogDescRiga = false;

      if (aggiorna) this.chiudiParoleChiave();
    },
  },

  computed: {
    pSelectEmit: function () {
      let j = this.testateSelez;
      return this.$emit("itemSelezionati", j);
    },

    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },
    role() {
      return this.$store.getters["user/getRole"];
    },

    group() {
      return this.$store.getters["user/getGroup"];
    },

    selTutti() {
      return this.$store.getters["ricerca/selTutti"];
    },

    multiSelezione() {
      return this.$store.getters["ricerca/multiSelezione"];
    },

    manutenzione() {
      return this.$store.getters["ricerca/manutenzione"];
    },

    getDati() {
      let res = this.filtroRisultati.slice(0, this.resLimit);
      return res;
    },

    inputText: {
      set(valore) {
        this.$store.commit("ricerca/setValoreRicerca", valore);
      },
      get() {
        let res = this.$store.getters["ricerca/valoreRicerca"];
        if (res.toLowerCase() == "preferiti") return "";
        if (res.toLowerCase() == "prodotti") return "";
        if (res.toLowerCase() == "promo") return "";
        else return res;
      },
    },
  },

  // -------------------------------------------------------------
  // N.B.
  // Usati gli hook lifecycle activated() e deactivated()
  // anziché created() e beforeDestroy() perché il componente
  // rimane inattivo a causa di <keep-alive> anziché essere
  // distrutto, e di conseguenza le funzioni che sarebbero
  // invocate da created(), mounted() ecc rimangono attive.
  // Questo comporta che l'event listener "scroll" rimane
  // attivo anche quando è mostrata la pagina catalogo, e la
  // funzione incrementa il limite di caricamento di conseguenza.

  activated() {
    this.resLimit = this.step;

    window.addEventListener("scroll", this.scroll);
  },

  deactivated() {
    window.removeEventListener("scroll", this.scroll);
  },

  watch: {
    filtroRisultati: function (a, b) {
      // NOTA: Si attiva quando viene effettuata una ricerca

      // Reimposta il limite di caricamento e riporta in alto
      this.resLimit = this.step;
      window.scrollTo(0, 0);

      // NOTA: se viene applicato un filtro devo caricare le immagini
      this.getImmagini(0);

      // Reset
      this.testateSelez = [];
    },

    testateSelez: function (a, b) {
      this.pSelectEmit;
    },

    selTutti(val) {
      // Reset
      this.testateSelez = [];

      for (let i = this.filtroRisultati.length - 1; i >= 0; i--) {
        if (val) this.testateSelez.push(this.filtroRisultati[i]);
      }
    },
  },

  mounted() {
    //console.log("mount");
  },
};
</script>

