// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './vuex'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'

import FunctionsApp from "@/utils/functions.js";
import i2kFunctions from "i2kjslibrary"

import Toast from 'i2kjslibrary/plugins/vuetify/toast/toast.js';
import Confirm from 'i2kjslibrary/plugins/vuetify/confirm/confirm.js';
import Wait from 'i2kjslibrary/plugins/vuetify/wait/wait.js';

import VueGtag from "vue-gtag";

import Load from "./LOAD.js"

import './assets/fonts/campton.css'
import './assets/css/style.css'

import {
  // Directives
  VTooltip,
} from 'floating-vue'
import 'floating-vue/dist/style.css'



const isProd = process.env.NODE_ENV === 'production'

// Controllo se sono in produzione 
if (isProd) {
  // Google analytics
  Vue.use(VueGtag, {
    config: {
      id: "UA-169886429-2"
    }
  }, router);
}

// Floating-vue
Vue.directive('tooltip', VTooltip)


// Vuetify
Vue.use(Vuetify)

//VModal
Vue.use(VModal)

const opts = {
  customProperties: true,
  theme: {
    themes: {
      light: {
        primary: '#E40520'
      },
      dark: {
        primary: '#E40520',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
}


// Plugins
Vue.use(Toast, {
  version: 2
})
Vue.use(Confirm, {
  version: 2,
  titleBackground: 'primary'
})
Vue.use(Wait, {
  version: 2
})

Vue.use(VModal)

// Prototype library
Vue.prototype.$functionsApp = FunctionsApp;
Vue.prototype.$i2kFunctions = i2kFunctions;
Vue.prototype.$load = Load;

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
  router,
  store,
  vuetify: new Vuetify(opts)
}).$mount('#app')