<template src="./azienda.html"></template>
<script>
export default {
  name: "azienda",
  title: "L’azienda",
  data: () => ({
    dialog: "",
    valori: [
      {
        colore: "#1e90b3",
        titolo: "Famiglia",
        testo:
          "Un’azienda, una famiglia, una piccola bottega di paese, giunta alla soglia dei 60 anni di attività",
        testolungo:
          "Un’azienda, una famiglia: un tipico modello italiano, da alcuni definito superato ma che continua tuttora \
        a rappresentare l’asse portante dell’economia nazionale.\
        La competitività delle aziende familiari è legata alle tipiche cifre di efficacia e di flessibilità, ma subiscono anche le criticità del passaggio generazionale: una sfida delicata che deve essere gestita con un approccio rivolto\
        al miglioramento continuo.\
        L’obiettivo non è semplicemente mantenere la propria posizione nel mercato, ma sfruttare le nuove conoscenze e attitudini dei giovani per far crescere l’azienda.",
        approfondimenti: [
          {
            icona: "icon-storia",
            titolo: "Storia",
            testo:
              "Da più di 50 anni ci occupiamo di ferramenta. \
              Nel 1962 Francesco Battisti apre una bottega, passata ai figli Franco e Cristina e giunta oggi alla soglia dei 60 anni\
              di attività, con la recente introduzione della\
              terza generazione."
          },
          {
            icona: "icon-filosofia",
            titolo: "Filosofia",
            testo:
              "Dove prima c’era una piccola bottega di paese, è nato uno spazio moderno, innovativo e attrezzato che supporta le esigenze professionali di oltre 1500 clienti. Non ci accontentiamo, lavoriamo da allora con lo stesso coraggio di cambiare e rinnovarci."
          },
          {
            icona: "icon-futuro",
            titolo: "Futuro",
            testo:
              "Siamo proiettati alla crescita continua, mantenendo inalterati i valori di un tempo."
          }
        ]
      },
      {
        colore: "#594c32",
        titolo: "Competenza",
        testo:
          "Competenza quale conoscenza del proprio ruolo, dei prodotti e delle loro applicazioni",
        testolungo:
          "Competenza come conoscenza del proprio ruolo, dei prodotti e delle \
        loro applicazioni, capacità di saperli consigliare per adattarli alle varie situazioni, contesti e all’applicazione sul campo.\
        La parola competenza, come il verbo da cui deriva, significa\
        anche competere e questo vale per qualsiasi percorso si scelga nella vita. La competizione è inevitabile, poiché solo attraverso di essa si raggiungono i risultati migliori.\
        I successi sono tra i piaceri più interessanti dell’esperienza umana, questi però non cadono dal cielo, richiedono appunto competenza\
        e competizione.",
        approfondimenti: [
          {
            icona: "icon-confronto",
            titolo: "Confronto",
            testo:
              "Ci confrontiamo ogni giorno in un mercato difficile, con esigenze in continuo mutamento, per creare nuove competenze."
          },
          {
            icona: "icon-competizione",
            titolo: "Competizione",
            testo:
              "Battere sul tempo la concorrenza, acquisire un cliente, sviluppare con esso nuove soluzioni, sono gli obiettivi che ci prefiggiamo giornalmente."
          }
        ]
      },
      {
        colore: "#3ba697",
        titolo: "Persone",
        testo:
          "Le persone che fanno parte dell’organizzazione aziendale sono il vero fulcro su cui si muove tutto il resto ",
        testolungo:
          "Le persone che fanno parte dell’organizzazione aziendale sono il vero fulcro su cui si basa tutto il resto. Uno dei principali punti di forza è senza dubbio il valore della squadra: collaboratori capaci, che mettono a disposizione le proprie competenze, non solo nell’attività tecnica a loro affidata, ma anche e soprattutto in un’ottica di valorizzazione comune, fatta di costante confronto e collaborazione reciproca.",
        approfondimenti: [
          {
            icona: "icon-continuita",
            titolo: "Continuità",
            testo:
              "La maggior parte del nostro personale ha costruito la propria carriera lavorativa interamente nella nostra azienda."
          },
          {
            icona: "icon-motivazione",
            titolo: "Motivazione",
            testo:
              "L’ascolto reciproco è fondamentale per l’andamento positivo dell’azienda. Quando i collaboratori vengono coinvolti attivamente, si sviluppa un senso di appartenenza che incentiva anche proposte di miglioramento."
          },
          {
            icona: "icon-relazioni",
            titolo: "Relazioni",
            testo:
              "Pensiamo che oltre all’organizzazione, alla tecnologia e alle soluzioni, sia ancora il rapporto umano a fare la differenza."
          }
        ]
      },
      {
        colore: "#00669b",
        titolo: "Collaborazione",
        testo:
          "La collaborazione produce una qualità migliore del lavoro, facilita l’esecuzione dei progetti e migliora l’efficienza I nostri valori",
        testolungo:
          "Una buona collaborazione produce una qualità migliore, facilita l’esecuzione dei progetti, migliora l’effi cienza del team, crea ambienti di lavoro sereni e fa crescere l’organizzazione. La collaborazione consiste nel creare una relazione sinergica tra due o più entità (persone, reparti, uffi ci, dipartimenti, fornitori), che interagiscono per produrre qualcosa di meglio rispetto all’agire da soli.\
        Negli anni abbiamo coltivato e mantenuto diverse collaborazioni, ormai storiche, con fornitori leader nel nostro settore.",
        approfondimenti: [
          {
            icona: "icon-valore",
            titolo: "Valore",
            testo:
              "Instaurare collaborazioni nel nostro lavoro è fondamentale, aiuta l’azienda a mantenere standard elevati e i nostri clienti a creare valore aggiunto."
          },
          {
            icona: "icon-clienti",
            titolo: "Clienti",
            testo:
              "Competenza, innovazione e qualità del servizio ci permettono di essere un partner affidabile, accompagnando i clienti dallo studio alla realizzazione dei progetti."
          },
          {
            icona: "icon-fornitori",
            titolo: "Fornitori",
            testo:
              "Abbiamo scelto da sempre di collaborare con le migliori aziende del settore per diventare nel tempo loro punto di riferimento sul territorio."
          }
        ]
      },
      {
        colore: "#7b619e",
        titolo: "Innovazione",
        testo:
          "Innovazione è comprendere i bisogni dei clienti e, soddisfacendoli, creare nuove azioni",
        testolungo:
          "Per una definizione di organizzazione bisogna guardare l’azienda come a un complesso organico. L’azienda non è infatti un’entità isolata, ma un insieme di interazioni che si definiscono attraverso i propri componenti\
        e la realtà circostante.\
        Il concetto di organizzazione aziendale è racchiuso nella serie dei processi, dei materiali e delle persone che li operano e li gestiscono, coordinati per il raggiungimento di uno scopo comune.",
        approfondimenti: [
          {
            icona: "icon-struttura",
            titolo: "Struttura",
            testo:
              "La nostra struttura è un’area polifunzionale open space di oltre 2000 mq, in grado di far interagire al meglio le tre anime dell’azienda (amministrativa, operativa e logistica)."
          },
          {
            icona: "icon-logistica",
            titolo: "Logistica",
            testo:
              "Magazzini automatici e tracciatura informatizzata della movimentazione interna dei prodotti.\
              Oltre il 90% delle consegne viene effettuato con personale e mezzi aziendali, assistiti da software che ottimizzano automaticamente i percorsi."
          }
        ]
      },
      {
        colore: "#a64776",
        titolo: "Organizzazione",
        testo:
          "Per una definizione di organizzazione bisogna guardare all’azienda come a un complesso organico ",
        testolungo:
          "Il mondo delle vendite ha un costante bisogno di innovazione e di comprendere i sempre nuovi bisogni dei clienti per soddisfarli, creando nuove azioni, processi, servizi, prodotti e soluzioni. Ricercare soluzioni innovative per rispondere con efficienza a tutte le sfide, è la qualità di chi ha capacità di adattamento ai mutamenti del mercato. Mantenere la massima flessibilità, e aprirsi alle novità significa approcciarsi attivamente al proprio lavoro e non sottomettersi alle trasformazioni.",
        approfondimenti: [
          {
            icona: "icon-tecnologia",
            titolo: "Tecnologia",
            testo:
              "L’innovazione ha sempre fatto parte del nostro DNA. A chi ci diceva “si è sempre fatto così”, abbiamo risposto con un’innovazione continua in ogni contesto o situazione."
          },
          {
            icona: "icon-informatica",
            titolo: "Informatica",
            testo:
              "Dagli anni ‘90 sviluppiamo internamente i nostri software, dalla gestione degli ordini, alla programmazione della consegna, dal sito, alla generazione automatica dei cataloghi."
          },
          {
            icona: "icon-prodotto",
            titolo: "Prodotto",
            testo:
              "Siamo alla continua ricerca di prodotti che si distinguono per l’alta qualità, tecnologicamente avanzati, che soddisfino le esigenze progettuali dei nostri clienti."
          }
        ]
      },
      {
        colore: "#be9638",
        titolo: "Informazione",
        testo:
          "Persone, procedure, strumenti e applicazioni per mettere a disposizione le informazioni al posto e al momento giusto ",
        testolungo:
          "In un mondo sempre più veloce\
        e saturo di informazioni,\
        trovare quella corretta risulta spesso complesso e dispersivo.\
        Il sistema informativo che rappresenta quell’insieme di persone, procedure, strumenti e applicazioni che permettono all’azienda di mettere a disposizione le informazioni, deve essere in grado di farlo sempre,\
        al posto e al momento giusto.",
        approfondimenti: [
          {
            icona: "icon-catalogo",
            titolo: "Catalogo",
            testo:
              "Impegniamo energie e tecnologie per la distribuzione delle informazioni digitali, ma crediamo che la carta stampata sia ancora uno strumento essenziale. A questo scopo investiamo molto nei nostri cataloghi."
          },
          {
            icona: "icon-web",
            titolo: "Web",
            testo:
              "Abbiamo trasformato il nostro sito web da vetrina virtuale a piattaforma multifunzionale, orientata soprattutto ai dispositivi mobili, con nuovi strumenti per una migliore fruibilità delle informazioni."
          },
          {
            icona: "icon-social",
            titolo: "Social",
            testo:
              "Le abitudini dei consumatori sono cambiate, e le strategie di comunicazione devono procedere di pari passo. Attraverso i social network è possibile raggiungere direttamente il proprio target e allargare il bacino di utenza per conquistare nuovi potenziali acquirenti."
          }
        ]
      },
      {
        colore: "#0b776a",
        titolo: "Qualità",
        testo:
          "La qualità come un modus operandi, non imposta dai vertici ma condivisa da tutto lo staff ",
        testolungo:
          "La qualità come un modus operandi, vivo e operativo,\
        e documenti fini a se stessi, e non imposto dai vertici, ma condiviso da tutto lo staff aziendale.\
        Qualità come insieme, o meglio, come sistema di elementi e processi, tra loro correlati e interagenti, che in modo circolare partono e tornano alla figura del cliente.\
        Ogni richiesta del cliente deve essere elaborata da una serie di processi che permetta di rispondere alle esigenze del mercato e che sia misurabile\
        e analizzabile.",
        approfondimenti: [
          {
            icona: "icon-soddisfazione",
            titolo: "Soddisfazione",
            testo:
              "Il fine del nostro sistema di qualità è la soddisfazione dei requisiti del cliente."
          },
          {
            icona: "icon-soluzioni",
            titolo: "Soluzioni",
            testo:
              "I problemi sono all’ordine del giorno nel nostro lavoro, per questo abbiamo sviluppato un sistema di gestione della qualità volto ad anticipare le sempre maggiori esigenze dei clienti."
          },
          {
            icona: "icon-procedure",
            titolo: "Procedure",
            testo:
              "Il nostro sistema di qualità ISO 9001-2015 è stato completamente riscritto, integrandolo nel flusso dei dati gestionali e sostituendo i moduli cartacei con informazioni digitali in tempo reale."
          }
        ]
      }
    ]
  }),

  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>
<style scoped>
#inst-header {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
  width: 100%;
  height: 100vw;
  max-height: 85.71vw;
  background-image: url("/img/azienda/famiglia.jpg");
}
@media screen and (min-width: 600px) {
  #inst-header {
    height: 100vw;
    max-height: 75vw;
  }
}
@media screen and (min-width: 900px) {
  #inst-header {
    height: 100vw;
    max-height: 35vw;
  }
}
.bigger {
  font-size: 1.2em;
}
.schedina-valori {
  cursor: pointer;
  color: white;
  height: 0;
  padding-top: 100%;
  width: 100%;
  position: relative;
}
.schedina-valori-testo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
}
.schedina-valori h4 {
  text-transform: uppercase;
}
.schedina-valori p {
  margin-bottom: 0;
}
.schedina-valori hr {
  width: 50%;
  border: none;
  border-bottom: solid 2px white;
  margin: 5px 0;
}
.custom-icon {
  font-size: 36px;
}
</style>
