<template src="./centroLavoro.html"></template>

<script>
import {
  esegui_comando,
  get_elencoProgrammi,
  ping_cnc,
} from "@/api/api.intranet.js";

export default {
  name: "CentroLavoro",

  data: () => ({
    info: {}, //axes.info
    cnc_info: {}, //cnc.info
    cnc_system: {}, //system.info

    elencoProg: [],
    mdiCommand: "",

    timer: null,
    message: null,

    camera: null,
    scene: null,
    renderer: null,
    mesh: null,
    loader: null,

    tab: 0,

    search: "",
    active: [],
    socket: null,

    selection: null,
    stlUrl: "",
  }),

  methods: {
    async caricaDati() {
      if (!(await this.checkConnessione())) {
        return;
      }
      await this.caricaInfoLavoro();
      await this.eseguiComando("system");
    },

    async caricaInfoLavoro() {
      if (!(await this.checkConnessione())) {
        return;
      }
      await this.eseguiComando("info");
      await this.eseguiComando("cnc_info");
    },

    async caricaElencoProgrammi() {
      let res = await get_elencoProgrammi();
      if (res.data != null) this.elencoProg = res.data;
    },

    async checkConnessione() {
      let res = await ping_cnc();
      return res.data == "OK";
    },

    async selezionaProgramma(prog) {
      if (!prog.file && prog.file != "ngc") return;
      if (this.selection != null && this.selection.name == prog.name) {
        this.selection = null;
        return;
      }
      this.selection = prog;
      this.stlUrl = this.selection.folderName;
    },

    async eseguiComando(comando, prog) {
      if (comando == "mdi" && (prog == null || prog == "")) {
        this.$toast.alert("Errore. Comando non trovato");
        return;
      }

      let par = {
        Command: comando,
        Programma: prog,
      };

      let res = await esegui_comando(par);

      if (comando == "info") this.info = res.data;
      else if (comando == "cnc_info" && typeof res.data === "object")
        this.cnc_info = res.data;
      else if (comando == "system" && typeof res.data === "object")
        this.cnc_system = res.data;
      else if (res.data == "OK" || res.data.res == true)
        this.$toast.info("Comando inviato");
      else this.$toast.alert(res.data);
    },

    caricaProgramma() {
      if (this.selection != null && this.selection != "")
        this.eseguiComando("programma", this.selection.name);
      else this.$toast.alert("Errore. Programma non trovato");
    },

    cercaNodoProgramma(root, id) {
      //ritorno il valore che sto cercando
      if (root.file == "ngc" && root === id) return root;

      if (root.children) {
        //ciclo per ogni nodo figlio
        for (let node of root.children) {
          let trovato = this.cercaNodoProgramma(node, id);

          if (trovato) return trovato;
        }
      }
      //se non trovo nulla ritorno null
      return null;
    },

    /* chiudiSocket() {
      try {
        this.socket.close();
      } catch (error) {
        console.log(error);
      }
    }, */

    resetTimer() {
      clearInterval(this.timer);
    },
  },

  watch: {
    tab(newVal) {
      if (newVal != 0) {
        this.resetTimer();
      } else {
        this.timer = setInterval(() => {
          this.caricaInfoLavoro();
        }, 2000);
      }
    },
  },

  computed: {},

  mounted() {
    this.caricaDati();
    /* this.timer = setInterval(() => {
      this.caricaInfoLavoro();
    }, 5000); */
  },

  beforeDestroy() {
    this.resetTimer();
    //this.chiudiSocket();
  },
};
</script>
