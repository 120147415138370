<template >
  <v-dialog
    v-model="getDialog"
    @keydown.esc="chiudi()"
    width="90%"
    style="height: 100%"
    persistent
  >
    <v-system-bar window color="white" class="pl-0">
      <!-- <span class="ml-3">{{ utenteNomeCarrello }}</span> -->
      <v-spacer></v-spacer>
      <v-icon size="24px" v-on:click="chiudi">close</v-icon>
    </v-system-bar>

    <car :openFromDialog="dialog"></car>
  </v-dialog>
</template>

<script>
import car from "@/views/Carrello.vue";
export default {
  name: "carrello",

  components: {
    car,
  },

  props: {
    dialog: { type: Boolean, default: false },
  },

  data: () => ({
    attesa: false,
  }),

  methods: {
    caricaDati() {
      if (this.dialog == false) return;
    },
    chiudi() {
      this.$emit("chiudi");
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog(val) {
      if (val) {
        this.caricaDati();
      }
    },
  },

  computed: {
    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },
};
</script>
