<template>
  <v-layout row wrap pa-0 mt-2>
    <!-- Filtri -->
    <v-container>
      <v-layout row wrap justify-fill>
        <v-flex xs6 md3>
          <v-menu
            class="b-datepicker-range"
            :close-on-content-click="true"
            v-model="selettoreDataIn"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            @blur="dataIn = parseDate(dataInF)"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="dataInF"
                label="Inizio periodo"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataIn"
              locale="it-it"
              first-day-of-week="1"
              :max="dataOut"
              event-color="primary"
              class="b-date-picker-start"
            ></v-date-picker>
            <!-- :events="functionRange" -->
          </v-menu>
        </v-flex>
        <v-flex xs6 md3>
          <v-menu
            class="b-datepicker-range"
            :close-on-content-click="false"
            v-model="selettoreDataOut"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
            @blur="dataOut = parseDate(dataOutF)"
            ><template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="dataOutF"
                label="Fine periodo"
                prepend-icon="event"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dataOut"
              locale="it-it"
              first-day-of-week="1"
              :min="dataIn"
              :max="new Date().toISOString().substr(0, 10)"
              event-color="primary"
              class="b-date-picker-end"
            ></v-date-picker>
            <!-- :events="functionRange" -->
          </v-menu>
        </v-flex>
        <v-flex xs12 md3 class="px-2">
          <!-- <v-select :items="stati" v-model="filtroStato" label="Stato"></v-select> -->
        </v-flex>
        <v-flex xs12 md3 class="px-2">
          <!-- <v-text-field label="Cerca"></v-text-field> -->
        </v-flex>
      </v-layout>
    </v-container>

    <v-container :class="{ 'b-container__table-and-image': true }">
      <v-col>
        <v-expansion-panels>
          <v-expansion-panel
            v-model="pannelliEspansi"
            @click.native="caricaDettaglio()"
            focusable
          >
            <v-expansion-panel-content
              v-for="(item, i) in datiTestata"
              :key="i"
            >
              <div slot="header">
                {{ item.descTipo }} n° {{ item.numero }} del
                {{ $i2kFunctions.formatISODate(item.data) }}
                <!-- <v-icon small>
                 {{ (documento.Stato == "in attesa") ? "panorama_fish_eye" : "" }}{{ (documento.Stato == "parziale") ? "timelapse" : "" }}{{ (documento.Stato == "pronto") ? "check_circle" : "" }}
              </v-icon>-->
              </div>

              <v-card>
                <v-card-text class="pt-1 pl-4">
                  <p style="margin: 0">
                    <v-icon small>archive</v-icon>
                    {{
                      tab == "ordini" && item.rfTpOrdine.length > 0
                        ? "Ordine via " + item.rfTpOrdine + " - "
                        : ""
                    }}
                    {{
                      tab == "ordini" && item.rfCliente.length > 0
                        ? "Riferimento: " + item.rfCliente + " - "
                        : ""
                    }}
                    {{
                      tab == "ordini" && item.rfOrdCliente.length > 0
                        ? "Vostro numero: " + item.rfOrdCliente + " - "
                        : " "
                    }}
                    {{
                      tab != "ordini" && item.trasporto.length > 0
                        ? "Trasporto: " + item.trasporto + " - "
                        : ""
                    }}
                    {{
                      tab != "ordini" && item.porto.length > 0
                        ? "Porto: " + item.porto
                        : ""
                    }}
                  </p>
                  <p style="margin: 0">
                    <v-icon small>today</v-icon>
                    Consegna richiesta:
                    {{ $i2kFunctions.formatISODate(item.dtConsRichiesta) }}
                    <!-- <v-icon small>event</v-icon>
                  Consegna prevista:-->
                  </p>
                  <!-- <p>
                  <v-icon small>local_shipping</v-icon>
                  Mezzo: {{ documento.Mezzo }} {{ (documento.Addetto.length > 0) ? "- addetto " + documento.Addetto : "" }}
                </p>-->

                  <!-- Dettaglio -->
                  <v-data-table
                    class="
                      data-table data-table-config data-table-document
                      pt-3
                    "
                    :items="datiDettaglio.filter((x) => x.tpRiga == 'PRODOTTO')"
                    hide-actions
                    :no-results-text="'Nessun dato trovato.'"
                  >
                    <template v-slot:header>
                      <tr>
                        <th>Codice</th>
                        <th>Descrizione</th>
                        <th>Cod. F.</th>
                        <th>Um</th>
                        <th>Q.tà<br />evasa</th>
                        <th>Q.tà<br />ordine</th>
                        <th>Prezzo</th>
                        <th>Sconto</th>
                        <th>Importo</th>
                      </tr>
                    </template>

                    <template v-slot:item="{ item }">
                      <tr>
                        <td title="Codice Articolo">
                          {{
                            $functionsApp.getCodArticoloFormat(
                              item.cdArticoloPadre
                            )
                          }}
                        </td>
                        <td title="Descrizione">
                          {{
                            $i2kFunctions.replaceAll(
                              item.descrizioneProdotto,
                              '"',
                              ""
                            )
                          }}
                        </td>
                        <td title="Codice Articolo Fornitore"></td>
                        <td title="Unità di misura">{{ item.unMisura }}</td>
                        <td title="Quantità Evasa">{{ item.qtOrdinata }}</td>
                        <td title="Quantità Ordine">
                          {{ item.qtEvasaStoccata }}
                        </td>
                        <td title="Prezzo unitario">{{ item.prUnitario }}</td>
                        <td title="Sconto">
                          {{
                            $functionsApp.getSconti(
                              item.sconto1,
                              item.sconto2,
                              item.sconto3
                            )
                          }}
                        </td>
                        <td title="Totale Riga">{{ "€" + item.impRigo }}</td>
                      </tr>
                    </template>
                  </v-data-table>

                  <!-- Totali -->
                  <table class="data-table-report" v-show="tab != 'ddt'">
                    <tr style="text-align: right">
                      <td>
                        <span class="helper">Totale merce</span>
                        <span class="data">{{ item.totaleMerce }}</span>
                      </td>
                      <td>
                        <span class="helper">Totale Omaggi</span>
                        <span class="data">{{ item.omaggi }}</span>
                      </td>
                      <td>
                        <span class="helper">Spese incasso</span>
                        <span class="data">{{ item.speseIncasso }}</span>
                      </td>
                      <td>
                        <span class="helper">Trasporto</span>
                        <span class="data">{{ item.trasporto }}</span>
                      </td>
                      <td>
                        <span class="helper">Imponibile</span>
                        <span class="data">{{ item.imponibile }}</span>
                      </td>
                      <td>
                        <span class="helper">IVA</span>
                        <span class="data">{{ item.cdIva }}</span>
                      </td>
                      <td>
                        <span class="helper">Importo IVA</span>
                        <span class="data">{{ item.imposta }}</span>
                      </td>
                      <td>
                        <span class="helper">Totale</span>
                        <span
                          class="data"
                          style="font-weight: bold; color: #da291c"
                          >€ {{ item.totaleDocumento }}</span
                        >
                      </td>
                    </tr>
                  </table>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-container>
  </v-layout>
</template>

<script>
import {
  get_documentiTestata,
  get_documentiDettaglio,
} from "@/api/api.user.js";

export default {
  props: { tab: { type: String, default: "" } },

  data: (vm) => ({
    pannelliEspansi: -1,
    datiTestata: [],
    datiDettaglio: [],
    attesa: false,
    pannelloAperto: -1,

    dataIn: new Date(new Date().getFullYear(), new Date().getMonth(), 2)
      .toISOString()
      .substr(0, 10),
    dataInF: vm.$i2kFunctions.formatISODate(
      new Date(new Date().getFullYear(), new Date().getMonth(), 2).toISOString()
    ),
    selettoreDataIn: false,

    dataOut: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
      .toISOString()
      .substr(0, 10),
    dataOutF: vm.$i2kFunctions.formatISODate(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        1
      ).toISOString()
    ),
    selettoreDataOut: false,
  }),

  methods: {
    caricaDati() {
      // Controllo tab selezionata
      if (!this.tab) return;

      //console.log("carica");

      // Reset
      this.datiTestata = [];
      this.datiDettaglio = [];
      this.pannelliEspansi = -1;
      this.pannelloAperto = -1;

      this.attesa = true;

      let mese = this.$i2kFunctions.getMonth(this.dataIn);
      let anno = this.$i2kFunctions.getYear(this.dataIn);

      get_documentiTestata(this.tab + "/" + anno + "/" + mese)
        .then((res) => {
          this.datiTestata = res.data;

          this.attesa = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    caricaDettaglio() {
      if (this.pannelliEspansi == null) return;

      // NOTA: ho messo questo controllo perchè cliccando nel dettaglio aperto, viene attivato l'evento click
      if (this.pannelloAperto == this.pannelliEspansi) return;

      this.pannelloAperto = this.pannelliEspansi;

      //console.log("aperto");

      // Reset
      this.datiDettaglio = [];

      this.attesa = true;

      let tipo = this.datiTestata[this.pannelliEspansi].tipo;
      let numero = this.datiTestata[this.pannelliEspansi].numero;
      let suff = this.datiTestata[this.pannelliEspansi].suffisso;
      let data = this.datiTestata[this.pannelliEspansi].data.substr(0, 10);

      get_documentiDettaglio(
        "dettaglio?documento=" +
          this.tab +
          "&tipo=" +
          tipo +
          "&numero=" +
          numero +
          "&suffisso=" +
          suff +
          "&data=" +
          data
      )
        .then((res) => {
          this.datiDettaglio = res.data;

          this.attesa = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    functionRange(date) {
      if (!date) return null;

      if (
        new Date(this.dataIn) <= new Date(date) &&
        new Date(date) <= new Date(this.dataOut)
      )
        return ["accent", "#ED2A2B50"];
    },
  },

  mounted() {
    //console.log("mount");

    this.caricaDati();
  },

  watch: {
    dataIn(val) {
      this.dataInF = this.$i2kFunctions.formatISODate(this.dataIn);

      this.caricaDati();
    },
    dataOut(val) {
      this.dataOutF = this.$i2kFunctions.formatISODate(this.dataOut);

      this.caricaDati();
    },
    tab(val) {
      //console.log(val);
      this.caricaDati();
    },
  },
};
</script>


