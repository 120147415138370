<template src="./elencoSpedizioni.html"></template>

<script>
import {
  get_spedizioni,
  get_spedizione,
  set_spedizione,
  del_spedizione,
} from "@/api/api.etichette.js";

import TrackingSpedizione from "@/components/dialog/etichette/TrackingSpedizione.vue";
import EsportaBordero from "@/components/dialog/etichette/EsportaBordero.vue";
import PdfViewer from "@/components/dialog/Viewer.vue";

import mixinSped from "@/views/intranet/etichette/mixinEtichette.js";
export default {
  props: {
    refresh: { type: Boolean, default: false },
  },

  mixins: [mixinSped],
  components: {
    PdfViewer,
    TrackingSpedizione,
    EsportaBordero,
  },

  data: () => ({
    dialogEsportaPdf: false,
    dialogTracking: false,

    elencoDatiSped: [],
    elencoSped: [],
    headersSped: [],

    expandSped: [],
    headersDettaglioSped: [],
    selectedRow: {},

    elencoAnni: [],
    filtroAnnullato: false,
    filtroConfermato: false,
    calendarFilter: false,
    filtroMese: "",
    filtroAnno: "",

    search: "",
    previewDialog: {
      show: false,
      src: "",
      type: "",
    },

    spedTracking: "",
  }),

  methods: {
    async cancellaSpedizione(item) {
      let msg = "Vuoi cancellare la spedizione ";
      msg += item.descrCodiceSpedizione;
      msg += "?<br>L'eliminazione è permanente. Continuare?";
      let confirm = await this.$confirm.open("Elimina", msg);
      if (!confirm) return;
      if (item.dtPartenza == null || item.dtPartenza == "")
        item.dtPartenza = "1970-01-01";
      let res = await del_spedizione(item);
      if (res.data == "OK") {
        this.$toast.info("Spedizione cancellata");
        this.annulla();
      } else this.$toast.alert(res.data);
    },

    annulla() {
      this.info = "";
      this.elencoDatiSped = [];
      this.caricaElencoSpedizioni();
    },

    selectRow(item) {
      this.selectedRow = item;
    },

    async caricaDati() {
      this.info = "";
      let par = {
        annullato: this.filtroAnnullato,
        confermato: this.filtroConfermato,
        anno: this.filtroAnno,
        mese:
          this.filtroMese != "" && this.filtroMese != null
            ? this.filtroMese.split("-")[1]
            : "",
      };
      let res = await get_spedizioni(par);
      this.elencoSped = res.data;
    },

    async espandiSped(props) {
      this.elencoSped.forEach((el) => {
        if (el != props) el.expanded = false;
      });

      this.expandSped = [];
      this.expandSped.push(props);

      props.expanded = !props.expanded;

      if (!props.expanded) {
        this.elencoDatiSped = [];
        this.selectedRow = {};
        return;
      }
      let res = await get_spedizione(props.descrCodiceSpedizione);
      this.elencoDatiSped = res.data;
    },

    async confermaSpedizioni() {
      let arr = [];
      this.elencoSped.forEach((el) => {
        if (el.selezionato)
          arr.push({
            CodiceSpedizione: el.codiceSpedizione,
            DescrCodiceSpedizione: el.descrCodiceSpedizione,
          });
      });
      if (arr.length <= 0) return;

      let res = await set_spedizione("conferma", arr);

      if (res.data == "OK") {
        this.$toast.info("Spedizione confermata");
        this.caricaDati();
      } else
        this.$toast.alert("Errore conferma spedizione: " + res.data.Message);
    },

    async annullaSpedizione(item) {
      let confirm = await this.$confirm.open(
        "Conferma annullamento",
        "Vuoi annullare la spedizione " + item.descrCodiceSpedizione + "?"
      );

      if (!confirm) return;
      let par = {
        CodiceSpedizione: item.codiceSpedizione,
        DescrCodiceSpedizione: item.descrCodiceSpedizione,
      };
      let res = await set_spedizione("annulla", par);
      if (res.data == "OK") {
        this.$toast.info("Spedizione annullata");
        this.caricaDati();
      } else
        this.$toast.alert("Errore annulla spedizione: " + res.data.Message);
    },

    getBackground(item) {
      let classe = "background-color: ";
      if (item.annullata) classe += "red ";
      else if (item.confermata) classe += "green ";

      return classe;
    },

    selezionaSpedizione(item) {
      if (!item.selezionato) return;
    },

    apriDocumento(item) {
      if (item.datiDocu.pathBolla != "") {
        this.previewDialog.show = true;
        this.previewDialog.src = item.datiDocu.pathBolla;
        this.previewDialog.type = "pdf";
      } else if (item.datiDocu.pathFattura != "") {
        this.previewDialog.show = true;
        this.previewDialog.src = item.datiDocu.pathFattura;
        this.previewDialog.type = "pdf";
      }
    },

    closePdfDialog() {
      this.previewDialog.show = false;
    },

    apriEsportaPdf() {
      this.dialogEsportaPdf = true;
    },

    chiudiEsportaPdf() {
      this.dialogEsportaPdf = false;
    },

    tracciaSpedizione(item) {
      this.spedTracking = item.descrCodiceSpedizione;
      this.dialogTracking = true;
    },

    chiudiTracking() {
      this.spedTracking = "";
      this.dialogTracking = false;
    },
  },

  mounted() {
    this.elencoAnni = this.getElencoAnni();
    this.filtroAnno = new Date().getFullYear();
    this.headersSped = this.getHeadersSped();
    this.headersDettaglioSped = this.getHeadersDettaglioSped();
  },

  watch: {
    refresh(newVal, oldVal) {
      if (newVal != oldVal) this.caricaDati();
    },

    filtroAnno: function () {
      this.caricaDati();
    },

    filtroMese: function () {
      this.caricaDati();
    },

    filtroConfermato: function () {
      this.caricaDati();
    },

    filtroAnnullato: function () {
      this.caricaDati();
    },
  },
};
</script>