<template>
  <div style="background-color: #fff; overflow-x: hidden !important">
    <h1 class="pa-1 ml-5">Dati utente</h1>
    <v-row class="ml-5" style="margin-top: -2em">
      <v-col cols="6">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="datiUtente.ragioneSociale" label="Ragione sociale" validate-on-blur readonly
                style="min-width: 35em"></v-text-field>
              <v-text-field v-model="datiUtente.indirizzo" label="Indirizzo" validate-on-blur readonly
                style="min-width: 35em; margin-top: -1em"></v-text-field>
            </v-col>
          </v-row>
          <v-container>
            <v-row style="margin-top: -2em">
              <v-text-field v-model="datiUtente.localita" label="Località" validate-on-blur readonly
                style="max-width: 17em"></v-text-field>
              <v-text-field v-model="datiUtente.stato" label="Stato" validate-on-blur readonly class="ml-4"
                style="max-width: 17em"></v-text-field>
            </v-row>
          </v-container>
        </v-container>
      </v-col>

      <v-col cols="6">
        <v-container>
          <v-container>
            <v-row>
              <v-text-field v-model="datiUtente.provincia" label="Provincia" validate-on-blur readonly
                style="max-width: 17em"></v-text-field>
              <v-text-field v-model="datiUtente.cap" label="Cap" validate-on-blur readonly class="ml-4"
                style="max-width: 17em"></v-text-field>
            </v-row>
          </v-container>
          <v-container>
            <v-row style="margin-top: -2em">
              <v-text-field v-model="datiUtente.mail_Internet" label="Mail (utilizzata per il login)" validate-on-blur
                readonly style="max-width: 17em"></v-text-field>
              <v-text-field v-model="datiUtente.telefono" label="Telefono" validate-on-blur readonly class="ml-4"
                style="max-width: 17em"></v-text-field>
            </v-row>
          </v-container>
          <v-container>
            <v-row style="margin-top: -2em">
              <v-text-field v-model="datiUtente.nome_Internet" label="Nome" validate-on-blur readonly
                style="max-width: 17em"></v-text-field>
              <v-text-field v-model="datiUtente.cognome_Internet" label="Cognome" validate-on-blur readonly class="ml-4"
                style="max-width: 17em"></v-text-field>
            </v-row>
          </v-container>
        </v-container>
      </v-col>
    </v-row>

    <v-stepper v-model="step" style="border-radius: 0px; margin-top: -3%">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" editable step="1">Carrello</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="step > 2" editable step="2">Dettagli spedizione</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" editable>Conferma</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items style="width: 100%">
        <!-- Righe carrello -->
        <v-stepper-content style="margin-top: -5px" step="1">
          <v-card tile style="min-height: 450px !important">
            <v-data-table :page.sync="page" @page-count="pageCount = $event" :items="datiCarrello" hide-default-footer
              hide-default-header dense class="elevation-1" disable-pagination :no-results-text="'Nessun dato trovato'"
              no-data-text="Carrello vuoto" loading-text="Caricamento dati..." :headers="headersDati" :loading="attesa"
              style="width: 100% !important; min-height: 450px !important" fixed-header>
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th v-for="(head, i) in headers" :key="i" style="
                        height: 50px !important;
                        font-size: 14px !important;
                        background-color: #e40520 !important;
                        color: #fff !important;
                      ">
                      <span v-html="head.text"></span>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item="{ item }"><!-- , expand, isExpanded -->
                <tr :style="{
                backgroundColor:
                  item.tpRiga == 'RIFERIMENTO' ? 'whitesmoke' : '',
              }">
                  <td style="width: 150px; cursor: pointer" :style="{
                color: item.tpRiga == 'RIFERIMENTO' ? 'black' : '',
              }" @click="showPermanent(item)" @mouseover="showPermanent(item)" @mouseout="showOver(item)"
                    class="primary--text">
                    <b>{{
                item.tpRiga == "PRODOTTO"
                  ? $functionsApp.getCodArticoloFormat(item.cdArticolo)
                  : ""
              }}</b>
                  </td>

                  <td style="width: 300px" v-html="getDescrizione(item).substring(0, 100)"></td>

                  <td style="width: 150px">
                    {{ item.tpRiga == "PRODOTTO" ? item.cdFornitore : "" }}
                  </td>

                  <td style="width: 50px; text-align: center" title="Codice Articolo Fornitore">
                    {{ item.tpRiga == "PRODOTTO" ? item.um : "" }}
                  </td>

                  <td style="width: 50px; text-align: center">
                    <v-edit-dialog :return-value.sync="item.qta" @save="saveEditQta(item)" @cancel="cancelEditQta"
                      @open="openEditQta" @close="closeEditQta" large cancel-text="Annulla" save-text="Salva">
                      {{ item.tpRiga == "PRODOTTO" ? item.qta : "" }}
                      <template v-slot:input>
                        <v-text-field style="width: auto; text-align: center" v-model="item.qta"
                          v-show="item.tpRiga == 'PRODOTTO'" type="number" label="Edit" single-line>
                        </v-text-field>
                      </template>
                    </v-edit-dialog>
                  </td>

                  <td class="primary--text" style="width: 50px; text-align: center">
                    <b>{{
                item.tpRiga == "PRODOTTO" ? getPrezzoUnitario(item) : ""
              }}</b>
                  </td>

                  <td style="width: 50px; text-align: center">
                    {{ item.tpRiga == "PRODOTTO" ? getSconto(item) : "" }}
                  </td>

                  <td :style="[
                item.tpRiga == 'RIFERIMENTO'
                  ? {
                    width: '50px',
                    'background-color': 'whitesmoke',
                    'text-align': 'center',
                  }
                  : { width: '50px', 'text-align': 'center' },
              ]">
                    <v-icon v-if="item.tpRiga == 'PRODOTTO'" size="20" @click="eliminaRigoCarrello(item.prog)">
                      delete
                    </v-icon>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-stepper-content>

        <!-- Spedizione -->
        <v-stepper-content step="2" class="pa-1" style="max-width: 1200px">
          <v-card tile style="min-height: 500px !important">
            <h1>Preferenze di consegna</h1>

            <v-container>
              <v-row row wrap>
                <v-col cols="4" style="margin-top: -1vh">
                  <v-card text class="pa-3 my-3" style="width: 100vh; height: 53vh; margin-left: 0px">
                    <v-container>
                      <v-col>
                        <h2>Indirizzo di consegna</h2>

                        <template>
                          <v-container style="margin-top: 10px">
                            <v-row>
                              <v-col cols="5">
                                <span style="font-size: 20px"><b>Indirizzo:</b></span>
                              </v-col>
                              <v-col cols="7" style="margin-top: 1%">
                                {{ indirizzoDefault.indirizzo }}
                              </v-col>
                            </v-row>
                            <v-row style="margin-top: -20px">
                              <v-col cols="5">
                                <span style="font-size: 20px"><b>Località:</b></span>
                              </v-col>
                              <v-col cols="7" style="margin-top: 1%">
                                {{ indirizzoDefault.localita }}
                              </v-col>
                            </v-row>
                            <v-row style="margin-top: -20px">
                              <v-col cols="5">
                                <span style="font-size: 20px"><b>Stato:</b></span>
                              </v-col>
                              <v-col cols="7" style="margin-top: 1%">
                                {{ indirizzoDefault.stato }}
                              </v-col>
                            </v-row>
                            <v-row style="margin-top: -20px">
                              <v-col cols="5">
                                <span style="font-size: 20px"><b>Provincia:</b></span>
                              </v-col>
                              <v-col cols="7" style="margin-top: 1%">
                                {{ indirizzoDefault.provincia }}
                              </v-col>
                            </v-row>
                            <v-row style="margin-top: -20px">
                              <v-col cols="5">
                                <span style="font-size: 20px"><b>Cap:</b></span>
                              </v-col>
                              <v-col cols="7" style="margin-top: 1%">
                                {{ indirizzoDefault.cap }}
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                      </v-col>
                    </v-container>

                    <v-row style="
                        justify-content: center;
                        margin-right: 0px;
                        margin-top: 0px;
                      ">
                      <!-- Tabella indirizzi -->
                      <v-dialog v-model="dialogIndirizzi" width="70%" v-if="listaIndirizzi.length > 0" persistent>
                        <!-- max-width="500px" -->
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn style="margin-top: 0px" color="primary" dark v-bind="attrs" v-on="on">Indirizzi di
                            conesgna
                          </v-btn>
                        </template>

                        <v-card>
                          <h2 style="margin-left: 20px; font-size: 26px">
                            Indirizzi di consegna
                            <v-btn style="margin-left: 70%" icon v-on:click="chiudiDialogIndirizzi()">
                              <v-icon size="24px">close</v-icon>
                            </v-btn>
                          </h2>

                          <v-card-text>
                            <v-container fluid>
                              <v-row>
                                <v-col>
                                  <!-- Tabella indirizzi cliente -->
                                  <v-data-table :headers="tabellaIndirizzi" :items="listaIndirizzi" class="elevation-1"
                                    hide-default-footer hide-default-header mobile-breakpoint="0" item-key="prog"
                                    no-data-text="Nessun dato disponibile" loading-text="Caricamento dati..."
                                    height="auto" style="
                                      min-width: 1000px !important;
                                      background: rgb(238, 238, 238);
                                    " single-select show-select v-model="selectedRow" :item-class="itemRowBackground">
                                    <template v-slot:header="{ props: { headers } }">
                                      <thead>
                                        <tr>
                                          <th v-for="(head, i) in headers" :key="i" style="
                                              height: 50px;
                                              font-size: 15px;
                                              background-color: #e40520;
                                              color: #fff;
                                            ">
                                            <span v-html="head.text"></span>
                                          </th>
                                        </tr>
                                      </thead>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                            </v-container>

                            <v-container fluid>
                              <v-row>
                                <!-- Elimina indirizzo cliente -->
                                <v-col cols="9" style="height: 35px">
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" style="margin-top: -10px" class="ml-2"
                                        color="primary" small fab dark v-on:click="eliminaIndirizzo">
                                        <v-icon v-bind="attrs" v-on="on" dark>delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Elimina</span>
                                  </v-tooltip>

                                  <!-- Modifica indirizzo cliente -->
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn style="margin-top: -10px" class="ml-2" color="primary" small fab dark
                                        v-bind="attrs" v-on="on" v-on:click="modificaIndirizzo">
                                        <v-icon v-bind="attrs" v-on="on" dark>edit</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Modifica</span>
                                  </v-tooltip>

                                  <!-- Nuovo indirizzo cliente -->
                                  <v-dialog v-model="dialogIndirizzo" persistent max-width="500px">
                                    <template v-slot:activator="{ on: attrs }">
                                      <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                          <v-btn style="margin-top: -10px" class="ml-2" color="primary" small fab dark
                                            v-bind="attrs" v-on="attrs">
                                            <v-icon v-bind="tooltip" v-on="tooltip" dark>playlist_add</v-icon>
                                          </v-btn>
                                        </template>
                                        <span>Aggiungi</span>
                                      </v-tooltip>
                                    </template>

                                    <v-card>
                                      <h2 style="
                                          margin-left: 20px;
                                          font-size: 26px;
                                        ">
                                        Nuovo indirizzo di consegna
                                        <v-btn style="margin-left: 70px" icon v-on:click="chiudiDialogIndirizzo()">
                                          <v-icon size="24px">close</v-icon>
                                        </v-btn>
                                      </h2>

                                      <v-card-text>
                                        <v-form ref="form">
                                          <v-container fluid>
                                            <v-row>
                                              <v-col cols="12">
                                                <label>Indirizzo:</label>
                                                <v-text-field class="pa-1" placeholder="Via Roma, 1" v-model="nuovoIndirizzo.Indirizzo
                " style="
                                                    width: 500px;
                                                    color: primary;
                                                    margin-top: 0px;
                                                  " clearable required :rules="[rules.required]" type="text">
                                                </v-text-field>
                                              </v-col>
                                              <v-col style="margin-top: -30px" cols="12">
                                                <v-row>
                                                  <v-col cols="8">
                                                    <label>Località:</label>
                                                    <v-text-field placeholder="Roma" v-model="nuovoIndirizzo.Localita
                " style="
                                                        width: 300px;
                                                        color: primary;
                                                        margin-top: -10px;
                                                      " clearable required :rules="[rules.required]" type="text">
                                                    </v-text-field>
                                                  </v-col>
                                                  <v-col cols="4">
                                                    <label>Stato:</label>
                                                    <v-text-field placeholder="Italia" v-model="nuovoIndirizzo.Stato
                " style="
                                                        color: primary;
                                                        margin-top: -10px;
                                                      " clearable required :rules="[rules.required]" type="text">
                                                    </v-text-field>
                                                  </v-col>
                                                </v-row>
                                              </v-col>
                                              <v-col style="margin-top: -30px" cols="12">
                                                <v-row>
                                                  <v-col cols="6">
                                                    <label>Provincia:</label>
                                                    <v-select outlined color="primary" v-model="nuovoIndirizzo.Provincia
                " style="margin-top: 0px" :items="listaProvince" placeholder="RO" required :rules="[rules.required]"
                                                      type="text">
                                                    </v-select>
                                                  </v-col>
                                                  <v-col style="margin-top: 0px" cols="6">
                                                    <label>Cap:</label>
                                                    <v-text-field outlined placeholder="00100" v-model="nuovoIndirizzo.Cap
                " style="
                                                        width: 200px;
                                                        color: primary;
                                                        margin-top: 0px;
                                                      " clearable required :rules="[rules.required]"
                                                      type="number"></v-text-field>
                                                  </v-col>
                                                </v-row>
                                              </v-col>
                                              <v-col style="margin-top: -30px" cols="12">
                                                <label>Ragione sociale:</label>
                                                <v-text-field placeholder="RAG SOC" v-model="nuovoIndirizzo.RagSoc
                " style="
                                                    width: 500px;
                                                    color: primary;
                                                    margin-top: -10px;
                                                  " clearable type="text" required :rules="[rules.required]">
                                                </v-text-field>
                                              </v-col>
                                              <v-col style="margin-top: -30px" cols="12">
                                                <v-row>
                                                  <v-col cols="6">
                                                    <label>Telefono:</label>
                                                    <v-text-field outlined placeholder="3498877665" v-model="nuovoIndirizzo.Telefono
                " style="
                                                        width: 200px;
                                                        color: primary;
                                                        margin-top: 0px;
                                                      " clearable required :rules="[rules.required]" type="number">
                                                    </v-text-field>
                                                  </v-col>
                                                  <v-col cols="6">
                                                    <label>Fax:</label>
                                                    <v-text-field outlined placeholder="123456" v-model="nuovoIndirizzo.Fax
                " style="
                                                        color: primary;
                                                        margin-top: 0px;
                                                      " clearable type="number" required :rules="[rules.required]">
                                                    </v-text-field>
                                                  </v-col>
                                                </v-row>
                                              </v-col>
                                              <v-col style="margin-top: -30px" cols="12">
                                                <label>Mail:</label>
                                                <v-text-field placeholder="mario.rossi@outlook.it"
                                                  v-model="nuovoIndirizzo.Mail" style="
                                                    width: 500px;
                                                    color: primary;
                                                    margin-top: -10px;
                                                  " clearable required :rules="[rules.required]" type="text">
                                                </v-text-field>
                                              </v-col>
                                              <v-col style="margin-top: -30px" cols="12">
                                                <label>Note:</label>
                                                <v-text-field placeholder="Nota di avvertenza"
                                                  v-model="nuovoIndirizzo.Note" style="
                                                    width: 500px;
                                                    color: primary;
                                                    margin-top: -10px;
                                                  " clearable type="text">
                                                </v-text-field>
                                              </v-col>
                                            </v-row>
                                          </v-container>
                                        </v-form>
                                      </v-card-text>
                                      <v-card-actions style="margin-top: -40px">
                                        <v-spacer></v-spacer>
                                        <v-btn color="primary" text v-on:click="salvaIndirizzo()">
                                          Salva
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>

                                  <!-- Help -->
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn v-bind="attrs" v-on="on" style="margin-top: -10px" class="ml-2"
                                        color="primary" small fab dark v-on:click="help">
                                        <v-icon v-bind="attrs" v-on="on" dark>live_help</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Help</span>
                                  </v-tooltip>
                                </v-col>

                                <v-col cols="3" style="height: 35px">
                                  <!-- Conferma indirizzo cliente -->
                                  <v-btn style="margin-top: -10px" text class="ml-6" color="primary" dark
                                    v-on:click="confermaIndirizzo">Conferma indirizzo
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col cols="4" style="margin-top: -1vh">
                  <v-card text class="pa-3 my-3" style="width: 400px; height: 53vh; margin-left: 0px">
                    <v-container>
                      <v-col>
                        <h2>Mezzi di trasporto</h2>
                        <template>
                          <v-container fluid style="margin-top: 0px">
                            <v-row>
                              <v-col cols="11" style="margin-left: 0px; margin-top: -10px">
                                <v-radio-group v-model="preferenzeTrasporto">
                                  <v-radio label="Mezzo Battisti" value="radio1"></v-radio>
                                  <v-radio label="Corriere BRT" value="radio2" style="margin-top: 5px"></v-radio>
                                  <v-radio label="Corriere GLS" value="radio3" style="margin-top: 5px"></v-radio>
                                  <v-radio label="Ritiro in azienda" value="radio4" style="margin-top: 5px"></v-radio>
                                </v-radio-group>
                              </v-col>

                              <v-col cols="1" style="margin-left: -4em; margin-top: 10px">
                                <img src="~@/assets/img/battisti.svg" alt="Battisti" class="mb-2" style="width: 80px" />
                                <img src="~@/assets/img/bartolini.svg" alt="Bartolini" style="width: 30px" />
                                <img src="~@/assets/img/gls.png" alt="Gls" style="width: 70px" />
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>

                        <h2 v-if="preferenzeTrasporto != 'radio4'" style="margin-top: 0px">
                          Spese di trasporto
                        </h2>
                        <template>
                          <v-container style="margin-top: 0px" fluid>
                            <label v-if="preferenzeTrasporto == 'radio1'">Da definire (+50)</label>
                            <label v-if="preferenzeTrasporto == 'radio2'">Da definire (+150)</label>
                            <label v-if="preferenzeTrasporto == 'radio3'">Da definire (+150)</label>
                          </v-container>
                        </template>
                      </v-col>
                    </v-container>
                  </v-card>
                </v-col>

                <v-col cols="4" style="margin-top: -1vh">
                  <v-card text class="pa-3 my-3" style="width: 400px; height: 53vh; margin-left: 0px">
                    <v-container v-if="preferenzeTrasporto != 'radio4'">
                      <v-col>
                        <h2>Data di spedizione</h2>

                        <template>
                          <v-container fluid style="margin-top: 0px; text-align: center">
                            <v-menu :nudge-right="40" transition="scale-transition" offset-y min-width="190px"
                              @blur="dataConsegna = parseDate(dataConsegnaF)">
                              <template v-slot:activator="{ on }">
                                <v-text-field v-on="on" v-model="getDataPicker" label="Data di spedizione"
                                  prepend-icon="event" readonly style="width: 150px">
                                </v-text-field>
                              </template>

                              <v-date-picker v-if="preferenzeTrasporto == 'radio1'" v-model="listaDate" range no-title
                                locale="it-it" first-day-of-week="1" color="#e40520" class="b-date-picker"
                                @input="controlloDate()">
                                <v-row style="margin-top: -20px">
                                  <v-col cols="11">
                                    <h4>Date disponibili:</h4>
                                  </v-col>
                                  <v-col cols="1" style="margin-left: -140px">
                                    <div>
                                      <svg width="20" height="20">
                                        <rect width="20" height="20" rx="100" ry="100"
                                          style="fill: red; stroke-width: 3"></rect>
                                      </svg>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-date-picker>

                              <v-date-picker v-if="preferenzeTrasporto != 'radio1'" v-model="listaDateCorriere" range
                                no-title locale="it-it" first-day-of-week="1" color="#e40520" class="b-date-picker"
                                @input="controlloDate()">
                                <v-row style="margin-top: -20px">
                                  <v-col cols="11">
                                    <h4>Date disponibili:</h4>
                                  </v-col>
                                  <v-col cols="1" style="margin-left: -140px">
                                    <div>
                                      <svg width="20" height="20">
                                        <rect width="20" height="20" rx="100" ry="100"
                                          style="fill: red; stroke-width: 3"></rect>
                                      </svg>
                                    </div>
                                  </v-col>
                                </v-row>
                              </v-date-picker>
                            </v-menu>
                          </v-container>
                        </template>
                      </v-col>
                    </v-container>

                    <v-container :style="[
                preferenzeTrasporto != 'radio4'
                  ? { 'margin-top': '0px' }
                  : {},
              ]">
                      <template>
                        <v-col>
                          <div v-if="preferenzeTrasporto != 'radio4'">
                            <h2>Consegna prevista</h2>
                            <template>
                              <v-container>
                                {{ getDataGiorno }}
                              </v-container>
                            </template>
                          </div>

                          <div v-if="preferenzeTrasporto == 'radio4'">
                            <h2>Ritiro disponibile da</h2>
                            <template>
                              <v-container>
                                {{ getPicker }}
                              </v-container>
                            </template>
                          </div>
                        </v-col>
                      </template>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-stepper-content>

        <!-- Concludi ordine -->
        <v-stepper-content step="3" class="pa-1" style="max-width: 1200px">
          <v-card tile style="min-height: 500px !important">
            <h1>Riepilogo carrello</h1>

            <v-container>
              <v-row row wrap>
                <v-col cols="4" style="margin-top: -1vh">
                  <v-card text class="pa-3 my-3" style="width: 400px; height: 53vh; margin-left: 0px">
                    <v-container>
                      <v-col>
                        <h2>Dati documento</h2>
                        <template>
                          <v-row class="mt-1">
                            <v-col cols="9" style="margin-left: 0px">
                              <h3>Imponibile:</h3>
                              <h3>IVA:</h3>
                              <h3>Importo IVA:</h3>
                              <h3>Totale merce:</h3>
                              <h3>Tot. documento:</h3>
                              <h3 v-if="totaliCarrello.totOmaggi != 0">
                                Totale omaggi:
                              </h3>
                            </v-col>

                            <v-col style="margin-left: -40px">
                              <h3>
                                <span style="color: red">{{ totaliCarrello.totImponibile }}€</span>
                              </h3>
                              <h3>
                                <span style="color: red">{{ totaliCarrello.percentualeIva }}%</span>
                              </h3>
                              <h3>
                                <span style="color: red">{{ totaliCarrello.totIVA }}€</span>
                              </h3>
                              <h3>
                                <span style="color: red">{{ totaliCarrello.totMerce }}€</span>
                              </h3>
                              <h3>
                                <span style="color: red">{{ totaliCarrello.totDocumento }}€</span>
                              </h3>
                              <h3 v-if="totaliCarrello.totOmaggi != 0">
                                <span style="color: red">{{ totaliCarrello.totOmaggi }}€</span>
                              </h3>
                            </v-col>
                          </v-row>
                        </template>
                      </v-col>
                    </v-container>
                  </v-card>
                </v-col>

                <v-col cols="4" style="margin-top: -1vh">
                  <v-card text class="pa-3 my-3" style="width: 400px; height: 53vh; margin-left: 0px">
                    <v-container>
                      <v-col>
                        <h2>Tipo documento</h2>
                        <template>
                          <v-container fluid style="margin-top: 0px">
                            <v-row>
                              <v-col cols="12" style="margin-left: 0px; margin-top: -10px">
                                <v-radio-group v-model="opzioniDocumento">
                                  <v-radio label="Ordine" value="radio1"></v-radio>
                                  <v-radio label="Preventivo" value="radio2" style="margin-top: 5px"></v-radio>
                                </v-radio-group>
                                <label v-if="opzioniDocumento == 'radio2'">Numero preventivo:</label>
                                <v-text-field v-if="opzioniDocumento == 'radio2'" solo v-model="numPreventivo"
                                  placeholder="123456" style="width: 400px" clearable required :rules="[rules.required]"
                                  type="number">
                                </v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </template>
                      </v-col>
                    </v-container>
                  </v-card>
                </v-col>

                <v-col v-if="opzioniDocumento == 'radio1' && Cliente.iban != ''" cols="4" style="margin-top: -1vh">
                  <v-card text class="pa-3 my-3" style="width: 400px; height: 53vh; margin-left: 0px">
                    <v-container>
                      <v-col>
                        <h2>Pagamento</h2>
                        <v-container fluid>
                          <label>Iban del conto:</label>
                          <v-text-field placeholder="Cliente.iban" solo v-model="Cliente.iban"
                            style="margin-left: 0px; min-width: 300px" disabled>
                          </v-text-field>
                        </v-container>
                      </v-col>
                    </v-container>
                  </v-card>
                </v-col>

                <v-col v-if="opzioniDocumento == 'radio1' && Cliente.iban == ''" cols="4" style="margin-top: -1vh">
                  <v-card text class="pa-3 my-3" style="width: 400px; height: 53vh; margin-left: 0px">
                    <v-container>
                      <v-col>
                        <h2>Pagamento</h2>
                        <v-container fluid>
                          <img src="~@/assets/img/nexi/f2_mastercard-logo.png" style="width: 70px" />
                          <!-- <img src="~@/assets/img/nexi/f2_maestro-logo.png" style="margin-left: 30px;">
                        <img src="~@/assets/img/nexi/f2_americanexpress-logo.png" style="margin-left: 30px;"> -->
                          <img src="~@/assets/img/nexi/logo-visa.png" style="height: 70px" />
                          <!-- <img src="~@/assets/img/nexi/f2_mybank-logo.png" style="width: 110px;">
                        <img src="~@/assets/img/nexi/f2_paypal-logo.png" style="margin-top: 20px;">
                        <img src="~@/assets/img/nexi/bancomat-BPay.png"
                          style="margin-top: 20px; margin-left: 30px; height: 50px; width: 140px;"> -->
                        </v-container>
                      </v-col>

                      <v-row style="justify-content: center; margin-top: 50px">
                        <v-btn width="auto" color="primary" @click="pagamentoNexi" :loading="attesaConferma">Vai al
                          pagamento
                        </v-btn>
                      </v-row>

                      <!-- <h2 v-if="getPagamentoAbituale != 'Paypal'">Pagamento</h2>

                      <v-container fluid
                        :style="[getPagamentoAbituale == 'Paypal' ? {'margin-top':'-25px'} : {'margin-top':'-10px'}]">
                        <v-select :items="abituale" v-model="pagamentoAbituale" label="Seleziona pagamento"></v-select>
                      </v-container>
                      <v-container style="margin-top: -40px;" v-if="getPagamentoAbituale == 'Carta credito'">

                        <h2>Dati carta</h2>

                        <v-col>

                          <div style="margin-top: -10px;">
                            <label>Numero della carta:</label>
                          </div>
                          <v-text-field label="Numero" solo v-model="Pagamento.numeroCarta" style="width: 200px"
                            clearable required :rules="[rules.required]" type="number"></v-text-field>
                          <div style="margin-top: -10px;">
                            <label>Nome della carta:</label>
                          </div>
                          <v-text-field label="Nome" solo v-model="Pagamento.nomeCarta" style="width: 200px" clearable
                            required :rules="[rules.required]" type="text"></v-text-field>

                          <v-container style="margin-top: -200px; margin-left: 230px;">
                            <div style="margin-top: -5px;">
                              <label>Data di scadenza:</label>
                            </div>
                            <div style="margin-top: -10px;">
                              <v-menu :nudge-right="40" transition="scale-transition" offset-y min-width="190px">

                                <template v-slot:activator="{ on }">
                                  <v-text-field v-on="on" v-model="Pagamento.dataScadenza" prepend-icon="event" readonly
                                    style="width: 140px;"></v-text-field>
                                </template>

                                <v-date-picker v-model="Pagamento.dataScadenza" locale="it-it" first-day-of-week="1"
                                  color="#e40520"></v-date-picker>

                              </v-menu>
                            </div>
                          </v-container>

                        </v-col>

                      </v-container>

                      <v-container style="margin-top: -40px;" v-if="getPagamentoAbituale == 'Bonifico bancario'">

                        <h2>Dati conto bancario</h2>

                        <v-col>

                          <div style="margin-top: -10px;">
                            <label>IBAN del conto:</label>
                          </div>
                          <v-text-field label="IBAN" solo v-model="Pagamento.ibanConto" style="width: 400px" clearable
                            required :rules="[rules.required]" type="text"></v-text-field>
                        </v-col>

                      </v-container>

                      <v-container style="margin-top: -40px;" v-if="getPagamentoAbituale == 'Paypal'">

                        <v-col>
                          <dialogPaypal></dialogPaypal>
                        </v-col>

                        da commentare
                        <v-col>
                          <dialogNexi></dialogNexi>
                        </v-col>

                      </v-container> -->

                      <!-- </v-col> -->
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>

    <v-container style="background-color: #fff; margin-top: -5px; justify-content: end" class="ma-0 text-right" fluid>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col>
              <h3 class="mt-1" v-if="step < 3" style="text-align: left">
                Totale imponibile:
                <span style="color: red">{{ totaliCarrello.totMerce }} €</span>
              </h3>
            </v-col>
            <v-col>
              <v-btn class="mr-3" @click="svuota" v-if="datiCarrello.length > 0 && step == 1">Svuota
                <v-icon> delete_forever</v-icon>
              </v-btn>
              <v-btn v-if="step > 1" @click="step--" class="mr-3" width="115">
                <v-icon color="rgb(0,0,0,.87);">chevron_left</v-icon>Indietro
              </v-btn>

              <v-btn color="primary" width="130" v-if="step < 3" @click="step++" :disabled="datiCarrello.length == 0">
                Continua
                <v-icon>chevron_right</v-icon>
              </v-btn>
              <v-btn color="primary" width="130" v-else @click="confermaCarrello" :loading="attesaConferma">Conferma
                <v-icon> check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <dialogDettaglioPrezzi :auth="auth" :role="role" :floatingModal="floatingModal" :cdArticolo="cdArticolo"
      origine="carrello" @floatingModalPermanent="floatingModalPermanent">
    </dialogDettaglioPrezzi>

    <dialogHelpCarrello :dialogHelp="dialogHelp" @chiudiHelp="chiudiHelp"></dialogHelpCarrello>
  </div>
</template>

<script>
import dialogDettaglioPrezzi from "@/components/dialog/catalogo/DettaglioPrezzi.vue";
import dialogHelpCarrello from "@/components/dialog/Help.vue";
//import dialogNexi from "@/views/ricerca/nexi.vue";
//import dialogPaypal from "@/views/ricerca/paypal.vue";

import Holidays from "date-holidays";

import {
  get_carrello,
  del_carrello,
  conferma_carrello,
  del_svuotaCarrello,
  get_totaleCarrello,
  update_carrello,
  dateConsegne_cliente,
  salva_indirizzo,
  listaIndirizziConsegne_cliente,
  delete_indirizzo,
  edit_indirizzo,
  province_cliente,
  datiCliente_carrello,
  GetInfoUtenteCarrello,
} from "@/api/api.user.js";

export default {
  props: {
    openFromDialog: { type: Boolean, default: false },
  },

  components: {
    //dialogPaypal,
    //dialogNexi,
    dialogDettaglioPrezzi,
    dialogHelpCarrello,
  },

  name: "carrello",

  data: () => ({
    tabellaIndirizzi: [
      { text: "Indirizzo", value: "indirizzo", sortable: false },
      { text: "Località", value: "localita", sortable: false },
      { text: "Stato", value: "stato", sortable: false },
      { text: "Provincia", value: "provincia", sortable: false },
      { text: "Cap", value: "cap", sortable: false },
    ],
    selectedRow: [],
    listaIndirizzi: [],
    modifica: false,
    falso: false,
    dialogIndirizzo: false,
    dialogIndirizzi: false,
    picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    controlloData: "",
    listaDate: [],
    listaSupporto: [],
    listaDateCorriere: [],
    listaSupportoCorriere: [],
    dataFinale: "",
    listaProvince: [],

    floatingModal: {
      transformStyle: { transform: "" },
      isPermanent: false,
      isHover: false,
    },
    datiArticolo: {},
    cdArticolo: "",

    previewDialog: {
      show: false,
      type: "",
      src: "",
      pdfOptions: {
        scale: "page-width",
        numPages: 0,
      },
    },

    imgSearched: false,
    headersDati: [
      { text: "Cod. Articolo", value: "Cod. Articolo", sortable: false },
      { text: "Descrizione", value: "Descrizione", sortable: false },
      { text: "Cod. Fornitore", value: "Cod. Fornitore", sortable: false },
      { text: "UM", value: "UM", sortable: false },
      { text: "Qta.", value: "Qta.", sortable: false },
      {
        text: "Prz. Unitario",
        value: "Prz. Unitario",
        sortable: false,
      },
      { text: "Sconto", value: "Sconto", sortable: false },
      { text: "Azioni", value: "Azioni", sortable: false },
    ],

    itemsPerPage: 15,
    page: 1,
    pageCount: 0,
    selected: [],
    datiCarrello: [],
    totaliCarrello: {},
    abituale: ["Carta credito", "Paypal", "Bonifico bancario"],
    pagamentoAbituale: "",
    preferenzeTrasporto: "radio1",
    opzioniDocumento: "radio1",
    numPreventivo: null,
    //riepilogoCarrello: 0,
    nuovoIndirizzo: {
      RagSoc: "",
      Indirizzo: "",
      Cap: "",
      Localita: "",
      Provincia: "",
      Stato: "",
      Telefono: "",
      Note: "",
      Fax: "",
      Mail: "",
    },
    indirizzoModificato: {
      Prog: 0,
      RagSoc: "",
      Indirizzo: "",
      Cap: "",
      Localita: "",
      Provincia: "",
      Stato: "",
      Telefono: "",
      Note: "",
      Fax: "",
      Mail: "",
    },
    indirizzoDefault: {
      cap: "",
      codAnagrafica: "",
      fax: "",
      indirizzo: "",
      localita: "",
      mail: "",
      note: "",
      prog: "",
      provincia: "",
      ragSoc: "",
      stato: "",
      telefono: "",
    },
    Cliente: {
      CdAnagrafica: "",
      Prog: 0,
      RagioneSociale: "",
      Indirizzo: "",
      Cap: "",
      Localita: "",
      Provincia: "",
      CdPagamento: "",
      Banca: "",
      Iban: "",
      ContoCorrente: "",
      DescrizionePagamento: "",
    },

    rules: {
      required: (value) => !!value || "Campo obbligatorio.",
    },

    listaDateConsegna: [],

    giorniConsegna: "lmv",

    numOrdineCliente: "",

    attesa: false,
    attesaConferma: false,

    // stepper
    step: 0,

    dialogHelp: false,
    //utenteNomeCarrello: "",
    datiUtente: {},
    flag: false,
  }),

  methods: {
    async datiUtenteCarrello() {
      this.datiUtente = {};

      let res = await GetInfoUtenteCarrello();
      this.datiUtente = res.data;
    },

    /* async nomeUtente() {
      let res = await get_datiUserInfo();
      let dati = res.data;
      this.$store.commit("user/SET_USER", dati);
      this.utenteNomeCarrello = dati.ragioneSociale;
    }, */

    itemRowBackground(item) {
      return this.selectedRow.indexOf(item) != -1
        ? "grey white--text"
        : "white";
    },

    async saveEditQta(item) {
      let res = await update_carrello(item);

      this.caricaDati();
      this.caricaIndirizzi();
    },

    cancelEditQta() { },
    openEditQta() { },
    closeEditQta() { },

    showOver(item) {
      if (!!item.cdArticolo == false) return;

      if (item === false) {
        this.floatingModal.isHover = false;
        return;
      }
      if (this.floatingModal.isPermanent) return;

      this.floatingModal.isHover = true;

      this.cdArticolo = item.cdArticolo;
      this.datiArticolo = { cdArticoloParam: item.cdArticolo };
    },

    showPermanent(item) {
      if (!!item.cdArticolo == false) return;

      if (this.floatingModal.isPermanent) {
        this.floatingModal.isPermanent = false;
      }

      this.floatingModal.isPermanent = true;

      this.cdArticolo = item.cdArticolo;
      this.datiArticolo = { cdArticoloParam: item.cdArticolo };
    },

    /* ------------------- HELP ------------------- */
    help() {
      if (this.dialogHelp == false) {
        this.dialogHelp = true;
      }
    },

    chiudiHelp() {
      if (this.dialogHelp == true) {
        this.dialogHelp = false;
      }
    },

    salva() {
      this.$emit("salva");
    },
    /* ------------------- HELP ------------------- */

    async caricaDati() {
      // Reset
      this.datiCarrello = [];
      this.step = 1;

      this.attesa = true;

      try {
        let res = await get_carrello();

        this.datiCarrello = res.data;

        this.getTotaleCarrello();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.pannelliEspansi = [];
      this.attesa = false;

      this.caricaIndirizzi();
      this.dateClienteConsegna();
      this.Province();
      this.datiCliente();
      this.$emit("caricaInformazioni");
    },

    async confermaIndirizzo() {
      if (this.selectedRow[0] != undefined) {
        /* let confirm = await this.$confirm.open(
          "Conferma",
          "Vuoi confermare questo indirizzo?"
        );

        if (!confirm) return; */

        try {
          this.indirizzoDefault = {
            cap: this.selectedRow[0].indirizzo,
            codAnagrafica: this.selectedRow[0].codAnagrafica,
            fax: this.selectedRow[0].fax,
            indirizzo: this.selectedRow[0].indirizzo,
            localita: this.selectedRow[0].localita,
            mail: this.selectedRow[0].mail,
            note: this.selectedRow[0].note,
            prog: this.selectedRow[0].prog,
            provincia: this.selectedRow[0].provincia,
            ragSoc: this.selectedRow[0].ragSoc,
            stato: this.selectedRow[0].stato,
            telefono: this.selectedRow[0].telefono,
          };
          this.$toast.info("Indirizzo confermato con successo!!!");
          this.caricaDati();
          this.caricaIndirizzi();
          this.chiudiDialogIndirizzi();
        } catch (error) {
          console.log(this.$i2kFunctions.getErrorMsg(error));
        }
      } else {
        this.$toast.alert("Selezionare l'indirizzo da confermare!!!");
      }
    },

    async eliminaIndirizzo() {
      if (this.selectedRow[0] != undefined) {
        let confirm = await this.$confirm.open(
          "Elimina",
          "Vuoi eliminare questo indirizzo?"
        );

        if (!confirm) return;

        try {
          let res = await delete_indirizzo(this.selectedRow[0]);

          if (res.data == "OK") {
            this.selectedRow[0] = [];
            this.$toast.info("Indirizzo eliminato");
            this.caricaDati();
            this.caricaIndirizzi();
          } else {
            this.$toast.alert("Errore. Impossibile eliminare l'indirizzo");
          }
        } catch (error) {
          console.log(this.$i2kFunctions.getErrorMsg(error));
        }
      } else {
        this.$toast.alert("Errore. Nessun indirizzo selezionato");
      }
    },

    modificaIndirizzo() {
      if (this.selectedRow[0] != undefined) {
        this.dialogIndirizzo = true;
        this.modifica = true;
        this.nuovoIndirizzo = {
          RagSoc: this.selectedRow[0].ragSoc,
          Indirizzo: this.selectedRow[0].indirizzo,
          Cap: this.selectedRow[0].cap,
          Localita: this.selectedRow[0].localita,
          Provincia: this.selectedRow[0].provincia,
          Stato: this.selectedRow[0].stato,
          Telefono: this.selectedRow[0].telefono,
          Note: this.selectedRow[0].note,
          Fax: this.selectedRow[0].fax,
          Mail: this.selectedRow[0].mail,
        };
      } else {
        this.$toast.alert("Selezionare l'indirizzo da modificare!!!");
      }
    },

    async caricaIndirizzi() {
      this.listaIndirizzi = [];

      if (this.indirizzi == false) return;

      this.attesa = true;

      try {
        let res = await listaIndirizziConsegne_cliente();

        this.listaIndirizzi = res.data;

        if (this.selectedRow[0] != undefined) {
          this.indirizzoDefault = {
            cap: this.selectedRow[0].cap,
            codAnagrafica: this.selectedRow[0].codAnagrafica,
            fax: this.selectedRow[0].fax,
            indirizzo: this.selectedRow[0].indirizzo,
            localita: this.selectedRow[0].localita,
            mail: this.selectedRow[0].mail,
            note: this.selectedRow[0].note,
            prog: this.selectedRow[0].prog,
            provincia: this.selectedRow[0].provincia,
            ragSoc: this.selectedRow[0].ragSoc,
            stato: this.selectedRow[0].stato,
            telefono: this.selectedRow[0].telefono,
          };
        } else {
          this.indirizzoDefault = {
            cap: this.listaIndirizzi[0].cap,
            codAnagrafica: this.listaIndirizzi[0].codAnagrafica,
            fax: this.listaIndirizzi[0].fax,
            indirizzo: this.listaIndirizzi[0].indirizzo,
            localita: this.listaIndirizzi[0].localita,
            mail: this.listaIndirizzi[0].mail,
            note: this.listaIndirizzi[0].note,
            prog: this.listaIndirizzi[0].prog,
            provincia: this.listaIndirizzi[0].provincia,
            ragSoc: this.listaIndirizzi[0].ragSoc,
            stato: this.listaIndirizzi[0].stato,
            telefono: this.listaIndirizzi[0].telefono,
          };
        }
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    getDescrizione(item) {
      let res = "";
      if (item.tpRiga == "PRODOTTO") {
        res = this.$functionsApp.convertiTestoGrassetto(item.descrizione);
      } else {
        res =
          "<b>" +
          this.$functionsApp.convertiTestoMinuscolo(item.tpRiga) +
          ": " +
          "</b>" +
          item.riferimento;
      }

      return res;
    },

    async eliminaRigoCarrello(prog) {
      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare l'ordine?"
      );
      if (!confirm) return;
      try {
        let res = await del_carrello(prog);

        this.datiCarrello = res.data;
        this.getTotaleCarrello();
        this.$store.commit("user/SET_CARRELLO", res.data);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async modificaRigoCarrello(prog) {
      try {
        /* let res = await del_carrello(prog);

        this.datiCarrello = res.data;
        this.$store.commit("user/SET_CARRELLO", res.data); */
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    chiudiDialogIndirizzi() {
      this.dialogIndirizzi = false;
    },

    chiudiDialogIndirizzo() {
      this.dialogIndirizzo = false;
      this.nuovoIndirizzo = {};
      this.indirizzoModificato = {};
    },

    async salvaIndirizzo() {
      if (!this.$refs.form.validate()) return;

      /* let confirm = await this.$confirm.open(
        "Salva",
        "Vuoi confermare l'indirizzo ?"
      );

      if (!confirm) return; */

      try {
        if (this.modifica == false) {
          let res = await salva_indirizzo(this.nuovoIndirizzo);

          if (res.data == "OK") {
            this.listaIndirizzi.push(this.nuovoIndirizzo);

            this.$toast.info("Indirizzo salvato con successo!!!");
            this.caricaIndirizzi();
            this.chiudiDialogIndirizzo();
          } else {
            this.$toast.alert(
              "L'inserimento non è andato a buon fine. Riprovare!!!"
            );
            this.chiudiDialogIndirizzo();
          }
        } else {
          this.indirizzoModificato = {
            Prog: this.selectedRow[0].prog,
            RagSoc: this.nuovoIndirizzo.RagSoc,
            Indirizzo: this.nuovoIndirizzo.Indirizzo,
            Cap: this.nuovoIndirizzo.Cap,
            Localita: this.nuovoIndirizzo.Localita,
            Provincia: this.nuovoIndirizzo.Provincia,
            Stato: this.nuovoIndirizzo.Stato,
            Telefono: this.nuovoIndirizzo.Telefono,
            Note: this.nuovoIndirizzo.Note,
            Fax: this.nuovoIndirizzo.Fax,
            Mail: this.nuovoIndirizzo.Mail,
          };

          let res = await edit_indirizzo(this.indirizzoModificato);

          if (res.data == "OK") {
            this.$toast.info("Indirizzo modificato con successo.");
            this.caricaIndirizzi();
            this.chiudiDialogIndirizzo();
          } else {
            this.$toast.alert("Errore. Impossibile modificare l'indirizzo");
            this.chiudiDialogIndirizzo();
          }

          this.modifica = false;
        }
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async confermaCarrello() {
      if (this.selectedRow.length == 0) {
        this.$toast.alert("Selezionare l'indirizzo per la consegna!!!");
        return;
      }

      if (this.datiUtente.iban == "" || this.datiUtente.banca == "") {
        this.$toast.alert("Selezionare il metodo di pagamento!!!");
        return;
      }

      /* if (this.opzioniDocumento != 'radio1') {
        this.$toast.alert("Scegliere 'Ordine' come tipo documento!");
        return;
      } */

      let confirm = await this.$confirm.open(
        "Salva",
        "Vuoi confermare l'ordine ?"
      );

      if (!confirm) return;

      this.attesaConferma = true;

      try {
        let res = await conferma_carrello({
          numeroOrdineCliente: this.numOrdineCliente,
          dataConsegna: this.dataFinale, //this.dates
        });

        this.$store.commit("user/SET_CARRELLO", res.data);

        // Chiudo la videata
        this.chiudi();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesaConferma = false;
    },

    async svuota() {
      let conf = await this.$confirm.open(
        "Svuota",
        "Vuoi svuotare il carrello  ?"
      );

      if (conf == false) return;

      try {
        let res = await del_svuotaCarrello();

        this.getTotaleCarrello();
        this.$store.commit("user/SET_CARRELLO", res.data);

        this.caricaDati();
        this.caricaIndirizzi();
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async getTotaleCarrello() {
      let res = await get_totaleCarrello();
      this.totaliCarrello = res.data;
    },

    pagamentoNexi() {
      console.log("pagamento nexi");
    },

    async Province() {
      try {
        this.listaProvince = [];

        let res = await province_cliente();

        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.listaProvince.push(res.data[i].sigla);
          }
        } else {
          this.$toast.alert(
            "Nessuna data disponibile per la consegna. Ci scusiamo per il disagio."
          );
        }

        return res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async dateClienteConsegna() {
      try {
        this.listaDate = [];

        let res = await dateConsegne_cliente();

        if (res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            this.listaDate.push(res.data[i].dataconsegna.substr(0, 10));
          }

          this.listaSupporto = this.listaDate;

          let oggi = new Date();
          oggi.setDate(new Date(oggi).getDate() + 1); //formato system
          oggi = this.formatDate_IT(oggi); //dd/MM//YYYY
          oggi = this.formatITDateToISO(oggi); //YYYY/MM/dd

          this.listaDateCorriere = [oggi, "2022-06-30"];
          this.listaSupportoCorriere = this.listaDateCorriere;
        } else {
          this.$toast.alert(
            "Nessuna data disponibile per la consegna. Ci scusiamo per il disagio."
          );
        }

        return res.data;
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async datiCliente() {
      let res = await datiCliente_carrello();

      if (res.data != "") {
        this.Cliente = {
          codAnagrafica: res.data[0].codAnagrafica,
          prog: res.data[0].prog,
          ragioneSociale: res.data[0].ragioneSociale,
          indirizzo: res.data[0].indirizzo,
          cap: res.data[0].cap,
          localita: res.data[0].localita,
          provincia: res.data[0].provincia,
          cdPagamento: res.data[0].cdPagamento,
          banca: res.data[0].banca,
          iban: res.data[0].iban,
          descrizionePagamento: res.data[0].descrizionePagamento,
        };
      } else {
        this.$toast.alert(
          "Dati cliente non acquisiti. Ci scusiamo per il disagio."
        );
      }
    },

    // funzioni prezzi
    sumNumbers(q, p, s) {
      var quantity = parseInt(q),
        price = parseFloat(p.replace(",", ".")),
        discount = Math.abs(
          (parseInt(s.split("+")[0]) + parseInt(s.split("+")[1])) / 100 - 1
        );
      return (quantity * price * discount).toFixed(3);
    },

    parseNumberField(n) {
      if (isNaN(parseInt(n))) {
        n = 0;
      }
      n = parseInt(n);
      n = n < 0 ? 0 : n;
      return n;
    },

    // funzioni data
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    formatISODate(data) {
      // Converto yyyy-MM-dd => dd/MM/yyyy
      // NOTA: la data deve essere passata in formato ISO (.toISOString())
      if (!data) return null;

      // Ricavo solo la data (primi 10 caratteri)
      data = data.substr(0, 10);

      const [year, month, day] = data.split("-");
      return `${day}/${month}/${year}`;
    },

    formatDate_IT(d) {
      // Converto Tue Jul 02 2019 17:17:35 GMT+0200 => dd/MM/yyyy

      let year = "" + d.getFullYear();

      let month = "" + (d.getMonth() + 1);
      if (month.length == 1) {
        month = "0" + month;
      }

      let day = "" + d.getDate();
      if (day.length == 1) {
        day = "0" + day;
      }

      return day + "/" + month + "/" + year;
    },

    formatITDateToISO(date) {
      // Converto dd/MM/yyyy => yyyy-MM-dd
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    controlloDate() {
      let hd = new Holidays("IT");
      let tomorrow = new Date();
      tomorrow.setDate(new Date().getDate());
      this.controlloData = tomorrow.toISOString().substring(0, 10);
      let data = new Date(this.listaDate.join(" ~ ").substr(0, 10));

      if (this.preferenzeTrasporto == "radio1") {
        if (
          this.listaDate.join(" ~ ").substr(0, 10) <= this.controlloData ||
          data.getDay() == 0 ||
          data.getDay() == 6 ||
          hd.isHoliday(this.listaDate.join(" ~ ").substr(0, 10)) != false
        ) {
          this.$toast.alert("Data inserita non valida!");
          this.falso = true;
        } else if (
          this.listaSupporto.includes(!this.dataFinale) ||
          data.getDay() == 0 ||
          data.getDay() == 6 ||
          hd.isHoliday(this.listaDate.join(" ~ ").substr(0, 10)) != false
        ) {
          this.$toast.alert("Data inserita non valida!");
          this.falso = true;
        } else {
          this.falso = false;
        }
      } else {
        if (
          this.listaDateCorriere.join(" ~ ").substr(0, 10) <=
          this.controlloData ||
          hd.isHoliday(this.listaDateCorriere.join(" ~ ").substr(0, 10)) !=
          false
        ) {
          this.$toast.alert("Data inserita non valida!");
          this.falso = true;
        } else if (
          this.listaSupportoCorriere.includes(!this.dataFinale) ||
          hd.isHoliday(this.listaDateCorriere.join(" ~ ").substr(0, 10)) !=
          false
        ) {
          this.$toast.alert("Data inserita non valida!");
          this.falso = true;
        } else {
          this.falso = false;
        }
      }

      if (this.falso == false) {
        if (this.preferenzeTrasporto == "radio1") {
          return (this.dataFinale = this.listaDate.join(" ~ ").substr(0, 10));
        } else {
          return (this.dataFinale = this.listaDateCorriere[0]);
        }
      } else {
        this.falso = false;
        this.$toast.alert("Data inserita non valida!");
        return;
      }
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    allowedDates: function (val) {
      //let d = new Date(val).getDay();
      if (new Date(val) <= new Date()) {
        return false;
      } else {
        return true;
      }
    },

    chiudi() {
      this.floatingModal.isPermanent = false;
      this.floatingModal.isHover = false;
      this.$emit("chiudi");
    },

    getSconto(item) {
      let res = "";
      if (item.sconto2 != 0) {
        res = item.sconto1 + "+" + item.sconto2 + "%";
      } else if (item.sconto1 != 0) {
        res = item.sconto1 + " %";
      } else {
        res = "";
      }

      return res;
    },

    getPrezzoUnitario(item) {
      let res = "";

      if (item.prezzoUnitario != 0) {
        res = item.prezzoUnitario + " €";
      } else {
        res = "";
      }

      return res;
    },

    /* getRiferimentoTab(item) {
      let res = "";
      if(item.tpRiga != "PRODOTTO"){
        res = item.tpRiga + '\n\r' + item.riferimento;
      }else{
        res = item.cdArticolo;
      }

      return res;
    } */

    floatingModalPermanent(vis) {
      this.floatingModal.isPermanent = vis;
    },
  },

  mounted() {
    this.caricaDati();
    //this.nomeUtente();
    this.datiUtenteCarrello();

    // seleziona prima data disponibile in base a Allowed Dates
    while (!this.allowedDates(this.dataConsegna)) {
      this.dataConsegna = new Date(
        new Date().setDate(new Date(this.dataConsegna).getDate())
      )
        .toISOString()
        .substr(0, 10);
    }

    // data-picker consegna +1
    let tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    this.picker = tomorrow.toISOString().substring(0, 10);
  },

  created() {
    window.addEventListener("scroll", this.scroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scroll);
  },

  watch: {
    dataConsegna(val) {
      this.dataConsegnaF = this.$i2kFunctions.getDateIso(this.dataConsegna);
    },

    indirizzi(val) {
      if (val) {
        this.caricaIndirizzi();
      }
    },
    listaDate(val) {
      if (this.listaDate.length == 1) {
        this.listaDate = this.listaSupporto;
      }
    },
    listaDateCorriere(val) {
      if (this.listaDateCorriere.length == 1) {
        this.listaDateCorriere = this.listaSupportoCorriere;
      }
    },
    controlloDate(val) {
      if (val) {
        this.controlloDate();
      }
    },

    openFromDialog: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          this.caricaDati();
        }
      },
    },
  },

  computed: {
    // ---------- Consegna prevista ----------
    getDataGiorno() {
      let tomorrow1 = new Date();
      tomorrow1.setDate(
        new Date(this.listaDate.join(" ~ ").substr(0, 10)).getDate() + 1
      ); //formato system
      tomorrow1 = this.formatDate_IT(tomorrow1); //dd/MM//YYYY

      let corriere1 = new Date();
      corriere1.setDate(new Date(this.listaDateCorriere[0]).getDate() + 1); //formato system
      corriere1 = this.formatDate_IT(corriere1); //dd/MM//YYYY

      let tomorrow2 = new Date();
      tomorrow2.setDate(
        new Date(this.listaDate.join(" ~ ").substr(0, 10)).getDate() + 2
      ); //formato system
      tomorrow2 = this.formatDate_IT(tomorrow2); //dd/MM//YYYY

      let corriere2 = new Date();
      corriere2.setDate(new Date(this.listaDateCorriere[0]).getDate() + 2); //formato system
      corriere2 = this.formatDate_IT(corriere2); //dd/MM//YYYY

      if (this.dataFinale == "") {
        if (
          this.preferenzeTrasporto == "radio1" ||
          this.preferenzeTrasporto == "radio4"
        ) {
          return tomorrow1;
        } else {
          return corriere1 + " - " + corriere2;
        }
      }

      let riscontro = "";
      if (this.preferenzeTrasporto == "radio1") {
        riscontro = this.listaSupporto.includes(this.dataFinale); //controllo data presente in listaDate per consegna
      } else {
        riscontro = this.listaSupportoCorriere.includes(this.dataFinale); //controllo data presente in listaDate per consegna
      }

      if (riscontro == true) {
        let data1 = new Date(this.dataFinale);
        data1.setDate(data1.getDate() + 1); //formato system
        data1 = this.formatDate_IT(data1); //dd/MM//YYYY;

        let data2 = new Date(this.dataFinale);
        data2.setDate(data2.getDate() + 2); //formato system
        data2 = this.formatDate_IT(data2); //dd/MM//YYYY;

        riscontro = "";

        if (
          this.preferenzeTrasporto == "radio1" ||
          this.preferenzeTrasporto == "radio4"
        ) {
          return data1;
        } else {
          return data1 + " - " + data2;
        }
      } else {
        let data3 = new Date(this.dataFinale);
        data3.setDate(data3.getDate() + 1); //formato system
        data3 = this.formatDate_IT(data3); //dd/MM//YYYY

        let data4 = new Date(this.dataFinale);
        data4.setDate(data4.getDate() + 2); //formato system
        data4 = this.formatDate_IT(data4); //dd/MM//YYYY

        riscontro = "";

        if (
          this.preferenzeTrasporto == "radio1" ||
          this.preferenzeTrasporto == "radio4"
        ) {
          return data3;
        } else {
          return data3 + " - " + data4;
        }
      }
    },

    // ---------- Data di spedizione ----------
    getDataPicker() {
      if (this.dataFinale == "") {
        if (this.preferenzeTrasporto == "radio1") {
          return this.formatISODate(this.listaDate.join(" ~ ").substr(0, 10));
        } else {
          return this.formatISODate(this.listaDateCorriere[0]);
        }
      }

      if (this.preferenzeTrasporto == "radio1") {
        for (let i = 0; i < this.listaSupporto.length; i++) {
          if (this.dataFinale == this.listaSupporto[i]) {
            let temp = "";
            temp = this.dataFinale;
            temp = this.formatISODate(temp);

            return temp;
          }
        }

        let riscontro = "";
        riscontro = this.listaSupportoCorriere.includes(this.dataFinale);
        if (riscontro == true) {
          return this.formatISODate(this.dataFinale);
        } else {
          this.$toast.alert("Data inserita non valida!");
          return this.formatISODate(this.dataFinale);
        }
      } else {
        for (let i = 0; i < this.listaSupportoCorriere.length; i++) {
          if (this.dataFinale == this.listaSupportoCorriere[i]) {
            let temp = "";
            temp = this.dataFinale;
            temp = this.formatISODate(temp);

            return temp;
          }
        }

        return this.formatISODate(this.dataFinale);
      }
    },

    // ---------- Ritiro in azienda ----------
    getPicker() {
      let data = new Date();
      data.setDate(data.getDate() + 1); //formato system
      data = this.formatDate_IT(data); //dd/MM//YYYY

      return data;
    },

    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },

    role() {
      return this.$store.getters["user/getRole"];
    },

    getPagamentoAbituale() {
      return this.pagamentoAbituale;
    },
  },
};
</script>

<style scoped>
.total {
  font-weight: bold;
  color: white;
  background-color: #ff0017;
  padding: 1em;
  display: block;
  width: 100%;
  text-align: center;
  margin: 0 0 8px 0;
  border-radius: 4px;
}

.total-price {
  font-size: 36px;
  font-variant-numeric: tabular-nums;
}

.checkout-summary {
  width: 100%;
  border-collapse: collapse;
}

.checkout-summary tr td {
  padding: 4px 0;
}

.checkout-summary tr td:last-child {
  min-width: 20%;
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.checkout-summary tr:last-child {
  border-top: 1px solid #ccc;
}

.checkout-summary tr:last-child td {
  border: inherit;
  padding: 12px 0 0;
  font-weight: bold;
}
</style>
