<template>
  <v-app-bar color="white" :clipped-left="true" app :clipped-right="true" class="ma-0">
    <!--Da gestire -->
    <!--     <v-app-bar-nav-icon @click.stop="$store.commit('app/showLeftDrawer')" v-if="auth && role != 6">
    </v-app-bar-nav-icon> -->

    <!-- Logo -->
    <div style="display: block; cursor: pointer; width: 250px;">
      <v-img :src="require('@/assets/img/battisti.svg')" v-on:click="$router.push({ path: '/' })"
        alt="Battisti – Ferramenta per l’architettura" class="logo" width="200" />
    </div>

    <v-spacer />

    <!-- Campo ricerca -->
    <InputRicerca :disabled="loadingRicerca" append-icon="search" :role="role">
    </InputRicerca>

    <v-spacer />

    <!-- Novità / Outlet-->
    <div v-if="role == 2">
      <v-btn class="mr-1" text style="background-color: green !important; color: #fff !important;"
        @click="apriPromo()">Promo</v-btn>
      <!--   <v-btn class="mr-1" text style="background-color: red !important; color: #fff !important;">Novità</v-btn>
      <v-btn text style="background-color: orange !important; color: #fff !important;">Outlet</v-btn> -->
    </div>

    <span v-if="role == 5">
      <v-btn @click="riponiCassetto()" color="primary"> Riponi cassetto </v-btn>
    </span>

    <v-spacer />

    <!-- Combo clienti -->
    <comboClienti label="Clienti" itemText="ragioneSociale" v-if="group == 'local' && role != 6">
    </comboClienti>

    <!-- Cliente infoazienda carico ordini  -->
    <span v-if="role == 6" class="username">{{ getNomeClienteSelezionato }}</span>

    <!-- Help -->
    <v-btn large icon @click="help()" color="black" v-if="auth && role != 6" v-tooltip.bottom="'Help'">
      <v-icon large dark>help</v-icon>
    </v-btn>

    <!-- Bottone Carrello -->
    <v-btn large icon @click="apriCarrello()" color="black" v-if="auth && role != 3 && role != 6"
      v-tooltip.bottom="'Carrello'">
      <v-badge left overlap color="red" v-model="getCarrello">
        <span slot="badge">{{ getCarrello }}</span>

        <v-icon large>shopping_cart</v-icon>
      </v-badge>
    </v-btn>

    <!-- Bottone login -->
    <v-btn v-if="!auth" @click="login()" color="#EEEEEE" class="elevation-0">Accedi</v-btn>

    <!-- Menu utente -->
    <v-menu bottom left offset-y v-model="showUserMenu" :nudge-width="200" :close-on-click="true"
      :close-on-content-click="auth ? true : false" v-if="auth">
      <template v-slot:activator="{ on: attrs }">
        <v-btn v-bind="attrs" v-on="attrs" v-tooltip.bottom="'Profilo'" icon color="black">
          <v-icon large>account_circle</v-icon>
        </v-btn>
      </template>

      <v-card light>
        <v-list class="pa-0">
          <v-list-item to="/profilo" class="unstyle-link">
            <v-list-item-avatar>
              <v-icon :x-large="true">account_circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Utente:</v-list-item-title>
              <v-list-item-subtitle>{{ utente }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" v-on:click="esci()">Esci</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <dialogHelpCarrello :dialogHelp="dialogHelp" @chiudiHelp="chiudiHelp"></dialogHelpCarrello>

    <!-- Menu -->
    <template v-slot:extension v-if="role == 1 || role == 2 || role == 4 || role == 5">
      <v-toolbar dense class="ma-0" flat dark color="grey darken-3">
        <v-toolbar-items v-for="(item, i) in menuTestata" :key="i">

          <!-- Menu dropdown -->
          <v-menu offset-y style="z-index: 5000;" v-if="item.dropDown">
            <template v-slot:activator="{ on }">
              <v-btn color="#fff" text v-on="on">
                {{ item.descrizione }}
              </v-btn>
            </template>
            <v-list color="grey darken-3" dark>
              <v-list-item link v-for="(subMenu, j) in menu.menuDettaglio.filter((f) =>
                f.menuIdList.includes('-' + item.menuId.toString() + '-'))" :key="j" @click="apriLink(subMenu, true)">
                <v-list-item-title>
                  {{ subMenu.descrizione }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- Button -->
          <v-btn color="#fff" text v-else @click="apriLink(item, false)"> {{ item.descrizione }}</v-btn>

        </v-toolbar-items>
      </v-toolbar>

    </template>

  </v-app-bar>
</template>

<script>


import comboClienti from "@/components/intranet/ComboClienti";
import InputRicerca from "../ricerca/InputRicerca.vue";
import dialogHelpCarrello from "@/components/dialog/Help.vue";
import mixinMagazVerticale from "@/views/intranet/magazzino/mixinMagVerticale.js";

export default {
  mixins: [mixinMagazVerticale],

  components: {
    comboClienti,
    InputRicerca,
    dialogHelpCarrello
  },

  props: {
    utente: { type: String, default: "" },
    loadingRicerca: { type: Boolean, default: false },
    group: { type: String, default: "" },

    carrello: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  data: () => ({
    ricerca: "",
    hasScrolled: false,
    prevScrollY: 250,
    hideHeader: false,
    hideTrade: false,
    oneCatOpen: false,
    oneColumn: false,
    currentSelects: "",
    showUserMenu: false,
    leftMenu: false,
    dialogHelp: false,

  }),

  methods: {
    leftBar() {
      this.$emit("showLeftBar");
    },

    updateScroll() {
      if (window.scrollY < document.body.scrollHeight - 250) {
        // mostra sempre se lo scroll è < 250
        document.body.classList.remove("short-header");
        document.body.classList.add("tall-header");
      }
      if (!this.hasScrolled) {
        document.body.classList.remove("short-header");
        document.body.classList.add("tall-header");
      } else {
        document.body.classList.add("short-header");
        document.body.classList.remove("tall-header");
      }
      if (
        window.scrollY > document.body.scrollHeight - window.innerHeight - 250 &&
        document.body.scrollHeight >= window.innerHeight + 300
      ) {
        // non mostra mai se al fondo della pagina e se la pagina è più alta della finestra.
        document.body.classList.add("short-header");
        document.body.classList.remove("tall-header");
      }
      if (document.body.scrollHeight == window.innerHeight) {
        document.body.classList.remove("short-header");
        document.body.classList.add("tall-header");
      }
      if (this.prevScrollY > window.scrollY || window.scrollY < 100) {
        this.prevScrollY = window.scrollY;
        this.hasScrolled = false;
      } else if (this.prevScrollY < window.scrollY) {
        this.prevScrollY = window.scrollY;
        this.hasScrolled = true;
      }
      return;
    },

    login() {
      this.$emit("apriLogin");
    },

    esci() {
      this.showUserMenu = false;
      this.$emit("esci");
    },

    apriCarrello() {
      this.$emit("apriCarrello");
    },

    /* ------------------- HELP ------------------- */
    help() {
      this.dialogHelp = true;
    },

    chiudiHelp() {
      this.dialogHelp = false;
    },
    /* ------------------- HELP ------------------- */

    riponiCassetto() {
      // Mixin
      this.riponi();
    },




    menuShow() {
      this.$emit("menuShow", true);
    },

    apriLink(item, dropDown) {

      if (dropDown) {
        if (item.programType == "vue") {
          this.$router.push({ path: "/" + item.programPath });
          this.$store.commit("app/setDialog", "");
        } else if (item.programType == "dll" || item.programType == "exe")
          this.$functionsApp.webViewCommand("menu|" + item.menuDettaglioID);
        else if (item.programType == "link") {
          this.finestra = window.open(item.programPath, "_blank");
          this.finestra.focus();
        } else if (item.programType == "js") {
          if (item.programPath == "update") window.location.reload();
          else if (item.programPath == "riponiCassetto") this.riponiCassetto();
        } else if (item.programType == "dialog") {
          this.$store.commit("app/setDialog", item.programPath);
        }
      }
      else {
        let dett = this.getMenuDettaglio(item.menuId);
        if (!!dett == false) return;

        if (dett.programType == "vue")
          this.$router.push({ path: "/" + dett.programPath });
        if (dett.programPath == "cercapreferiti")
          this.$functionsApp.cercaValore("preferiti", this.$router);
      }

    },


    getClassMenu(item) {
      let dett = this.getMenuDettaglio(item.menuId);
      if (!!dett == false) return;

      let res = false;

      if (dett.programPath == "cercapreferiti") {
        if (this.$route.path.includes("preferiti")) res = true;
      } else if (this.$route.path.includes(dett.programPath)) res = true;

      if (res) return "primary white--text";
      else "";
    },

    getMenuDettaglio(menuId) {
      let dett = this.menu.menuDettaglio.find((f) =>
        f.menuIdList.includes("-" + menuId + "-")
      );
      return dett;
    },

    getVersione() {
      return "Versione: " + (localStorage.getItem("version") || "");
    },

    checkRoute(item) {
      return "/" + item.programPath === this.$route.path;
    },

    apriPromo() {
      this.$functionsApp.cercaValore("promo", this.$router);
    }

  },

  computed: {
    getCarrello: {
      get: function () {
        return this.carrello.length;
      },
      set: function (value) { },
    },

    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    },

    role() {
      return this.$store.getters["user/getRole"];
    },

    clienteSelezionato() {
      return this.$store.getters["app/getCliente"];
    },

    getNomeClienteSelezionato() {
      let cliente = this.clienteSelezionato;
      if (!!cliente && Object.keys(cliente).length > 0)
        return cliente.ragioneSociale.substring(0, 20);

      return "";
    },


    menu() {
      return this.$store.getters["user/getMenu"];
    },
    menuTestata() {
      return this.menu.menuTestata;
    },

  },
};
</script>

<style scoped>
@media only screen and (max-width: 992px) {
  .logo {
    display: none;
  }
}

.username {
  visibility: hidden;
  max-width: 120px;
  width: 0;
}

@media screen and (min-width: 960px) {
  .username {
    width: auto;
    visibility: visible;
    flex: 1 1 auto;
    margin: 0 5px;
  }
}
</style>
