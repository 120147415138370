import { render, staticRenderFns } from "./gestioneRicerca2.html?vue&type=template&id=4a33e8cb&scoped=true&"
import script from "./GestioneRicerca2.vue?vue&type=script&lang=js&"
export * from "./GestioneRicerca2.vue?vue&type=script&lang=js&"
import style0 from "./GestioneRicerca2.vue?vue&type=style&index=0&id=4a33e8cb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a33e8cb",
  null
  
)

export default component.exports