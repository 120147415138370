<template>
  
  <div v-if="auth" :class="[
    { 'data-table-detail': true },
    { 'active-permanent': floatingModal.isPermanent },
    { 'active-hover': floatingModal.isHover && !floatingModal.isPermanent },
    { hidden: !floatingModal.isHover && !floatingModal.isPermanent },]" :style="floatingModal.transformStyle" style="background-color: #fff;
      border-radius: 10px;
      width: calc(72%) !important;
      max-width: 1200px !important;
      height: 90%;
    " id="floating-div">
    <!--max-height: 800px;-->

    <!-- Testata -->
    
    <v-container class="ma-0 pa-2" fluid>
      <v-row>
        <v-col cols="9">
          <div class="data-table-detail__code">
            {{ $functionsApp.getCodArticoloFormat(datiArticolo.cdArticolo) }}

            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <div v-on="{ ...tooltip }" class="pallinoInfo" :style="getColore()" v-if="group == 'local'"
                  style="margin-top:5px;">
                </div>
              </template>
              <span>{{ getTooltipPallino() }}</span>
            </v-tooltip>
          </div>

          <div style="width: 100%; padding: 2px" v-html="$functionsApp.convertiTestoGrassetto(datiArticolo.descrizione)
            "></div>
        </v-col>

        <!-- Cliente intranet -->
        <v-col cols="2" class="text-right">
          <!-- l'ho messo nell header principale -->
          <!--  {{
            group == "local" && !!cliente && Object.keys(cliente).length > 0
              ? cliente.ragioneSociale.substring(0,20) 
              : ""
          }} -->
        </v-col>

        <v-col cols="1" class="text-right pa-1">
          <v-icon @click="floatingModalPermanent(false)">close</v-icon></v-col>
      </v-row>
    </v-container>

    <!-- Linea divisione -->
    <div style="clear: both; width: 100%">
      <v-divider></v-divider>
      <div style="height: 10px">
        <v-progress-linear indeterminate v-show="attesa"> </v-progress-linear>
      </div>
    </div>

    <!-- Tab intestazione -->
    <v-tabs v-model="activeTab" slider-color="primary" style="min-width: 100%" height="30" @change="onTabChange"
      fixed-tabs>
      <v-tab href="#info"> Informazioni </v-tab>
      <v-tab href="#ricevimento" v-if="role == 3"> Ricevimenti </v-tab>
      <v-tab href="#lavorazione" v-if="role == 3"> In lavorazione </v-tab>
      <v-tab href="#scarti" v-if="role == 3"> Scarti </v-tab>
      <v-tab href="#venduto" v-if="group == 'local'"> Clienti </v-tab>
      <v-tab href="#acquistato" v-if="group == 'local'"> Fornitori </v-tab>
      <v-tab href="#listini" v-if="group == 'local'"> Listini </v-tab>
      <v-tab href="#ordiniClienti" v-if="role == 1"> Ordini </v-tab>
      <v-tab href="#distintaBaseSemplice" v-if="visDistintaSemplice">
        Distinta base
      </v-tab>
      <v-tab href="#distintaBaseDinamica" v-if="visDistintaDinamica" :disabled="attesa">
        Distinta base
      </v-tab>
    </v-tabs>

    <!-- Tabs item -->
    <v-tabs-items :value="activeTab" style="min-width: 100% !important; height: 100%">
      <!-- Informazioni -->
      <v-tab-item value="info">
        <div style="height: 60px; background-color: #f5f5f5">
          <v-container class="pa-0 mt-2">
            <v-row>
              <v-col cols="2">
                <v-btn v-on:click="insPreferiti()" :class="{ primary: preferito }" small class="mt-2 ml-1" text>
                  <v-icon class="mr-2">{{ preferito ? "star" : "star_outline" }} </v-icon>Preferito
                </v-btn>
              </v-col>

              <v-col cols="6"> </v-col>

              <v-col cols="4" class="ivaEsclusa" style="font-size: 12px">
                **Prezzi iva esclusa
              </v-col>
            </v-row>
          </v-container>
        </div>

        <v-container>
          <v-row>
            <!-- Immagine laterale -->
            <v-col cols="6" class="mt-0 pt-0 d-flex flex-column justify-center align-center">
              <v-img :src="pathImg" class="data-table-detail__image" :alt="datiArticolo.descrizione" contain
                height="100px">
              </v-img>
              <!-- Attesa -->
              <div v-show="attesaDisegnoDistinta" style="position: absolute; margin: auto">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
              <span v-if="dettaglioPrezzi.imgNonPresente" style="
                  color: red;
                  font-size: 12px;
                  position: absolute;
                  margin-top: -50px;
                ">Immagine generica <br />
                riferita al gruppo prodotti.</span>
            </v-col>
            <v-spacer></v-spacer>

            <!-- Terzisti -->
            <v-col cols="6" v-if="role == 3">
              <v-card flat class="mt-4">
                <div class="mt-4">
                  <b>Magazzino {{ descUtente }}</b>
                </div>

                <table class="tableDett">
                  <tr>
                    <td>Scorta totale</td>
                    <td>
                      {{
                        dettaglioPrezzi.dettaglioTerzisti.qtaMagazzinoTerzista
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>In lavorazione</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioTerzisti.qtaContoProduzione }}
                    </td>
                  </tr>
                  <tr>
                    <td>Disponibilità</td>
                    <td>
                      {{
                        dettaglioPrezzi.dettaglioTerzisti.disponibilitaTerzista
                      }}
                    </td>
                  </tr>
                </table>

                <div class="mt-4"><b>Ordinato fornitore</b></div>

                <div style="height: 200px; overflow: auto">
                  <table class="tableDett" style="width: 100%" v-for="(
                      item, i
                    ) in dettaglioPrezzi.ordinatoArticoloFornitori" :key="i">
                    <tr>
                      <td>
                        {{ item.ragioneSociale }}
                      </td>

                      <td style="width: 20%; text-align: left">
                        {{ item.nomeMese }}
                      </td>

                      <td style="width: 20%">
                        <b> {{ item.qtOrdFor }}</b>
                      </td>
                    </tr>
                  </table>
                </div>
              </v-card>
            </v-col>

            <!-- Intranet locale -->
            <v-col cols="6" v-if="group == 'local'" style="font-size: 15px; overflow-y: auto" class="py-0">
              <!-- Prezzi -->
              <v-card flat class="mt-4">
                <table class="tableDett">
                  <tr>
                    <td style="width: 60%">Codice fornitore</td>
                    <td>
                      {{ datiArticolo.cdArticoloFornitoreCatalogo }}
                    </td>
                  </tr>

                  <tr>
                    <td style="width: 60%">
                      Prezzo {{ datiArticolo.descUmConversione }}
                    </td>
                    <td>{{ getPrezzo(dettaglioPrezzi.prezzoListino) }} €</td>
                  </tr>
                  <tr>
                    <td>Sconto %</td>
                    <td>
                      {{ dettaglioPrezzi.sconto1 }}
                      {{ getSecondoSconto(dettaglioPrezzi.sconto2) }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 60%">Prezzo scontato</td>
                    <td>{{ dettaglioPrezzi.prezzoScontato }} €</td>
                  </tr>

                  <tr>
                    <td style="width: 60%">Data validità</td>
                    <td>
                      {{
                        $i2kFunctions.formatISODate(dettaglioPrezzi.dtValidita)
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td style="width: 60%">Cat.merce</td>
                    <td>
                      {{
                        datiArticolo.catMerceologica
                        ? datiArticolo.catMerceologica.substring(0, 3) +
                        " " +
                        datiArticolo.catMerceologica.substring(3, 6)
                        : ""
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td style="width: 60%">Ubicazione</td>
                    <td>{{ datiArticolo.ubicazione }}</td>
                  </tr>

                  <tr v-show="datiArticolo.ubicazione2">
                    <td style="width: 60%">Ubicazione2</td>
                    <td>{{ datiArticolo.ubicazione2 }}</td>
                  </tr>

                  <tr>
                    <td style="width: 60%">Qtà scaffale</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaScaffale }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 60%">Qtà mag. esterno</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaUbicazione2 }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 60%">Qtà terzista</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaTerzista }}
                    </td>
                  </tr>

                  <tr>
                    <td style="width: 60%">Qtà Ricevuta</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaRicevuta }}
                    </td>
                  </tr>

                  <tr>
                    <td>Disponibilità: {{ datiArticolo.unMisura }}<span style="font-size:9px; color:red;">{{
                      (dettaglioPrezzi.scortaNonGestita ? ' SCORTA NON GESTITA' : '') }}</span></td>
                    <td style="color: red">
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaDisponibile }}
                    </td>
                  </tr>

                  <tr>
                    <td>Qta lavorazione</td>
                    <td style="color: red"></td>
                  </tr>
                </table>
              </v-card>

              <!-- Mesi -->
              <v-card class="elevation-0">
                <table class="tableDett">
                  <tr style="height: 20px">
                    <td></td>
                    <td>
                      {{ getMese(0) }}
                    </td>
                    <td>
                      {{ getMese(1) }}
                    </td>
                    <td>
                      {{ getMese(2) }}
                    </td>
                    <td>Suc</td>
                  </tr>

                  <tr>
                    <td style="width: 50%">Qtà cliente</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaOrdCliMese1 }}
                    </td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaOrdCliMese2 }}
                    </td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaOrdCliMese3 }}
                    </td>
                    <td>{{ dettaglioPrezzi.dettaglioMagazzino.qtaOrdCliSucc }}</td>
                  </tr>
                  <tr>
                    <td>Qtà evase</td>
                    <td>
                      {{
                        dettaglioPrezzi.dettaglioMagazzino.qtaEvasaStoccataMese1
                      }}
                    </td>
                    <td>
                      {{
                        dettaglioPrezzi.dettaglioMagazzino.qtaEvasaStoccataMese2
                      }}
                    </td>
                    <td>
                      {{
                        dettaglioPrezzi.dettaglioMagazzino.qtaEvasaStoccataMese3
                      }}
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Qtà fornitore</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaOrdForMese1 }}
                    </td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaOrdForMese2 }}
                    </td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaOrdForMese3 }}
                    </td>
                    <td>{{ dettaglioPrezzi.dettaglioMagazzino.qtaOrdForSucc }}</td>
                  </tr>
                  <tr>
                    <td>Qtà pre-ord.</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaPreOrdine }}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>Data inventario</td>
                    <td colspan="3">
                      {{
                        $i2kFunctions.formatISODate(
                          dettaglioPrezzi.dettaglioMagazzino.dataInventario
                        )
                      }}
                    </td>
                  </tr>
                </table>
              </v-card>

              <!-- Carrello-->
              <v-card class="pa-0 mt-8" elevation="0" v-if="origine != 'carrello' && role == 2">
                <div>
                  <v-text-field style="margin-top: -10px" label="Riferimento" hide-details v-model="carrello.riferimento">
                  </v-text-field>
                </div>
                <div>
                  <v-text-field label="Note" hide-details v-model="carrello.note">
                  </v-text-field>
                </div>

                <div>
                  <v-text-field value="1" label="Quantità" v-model="carrello.qta"
                    :rules="[rules.required, rules.quantita]" type="number" hide-details style="width: 80px"
                    @keyup="convertiScatolaPz">
                  </v-text-field>
                </div>

                <div>
                  <!-- Carrello -->
                  <!-- TEMPORANEAMENTE DISABILITATO -->
                  <!--      <v-btn
                    class="mt-4"
                    color="primary"
                    style="width: 100%"
                    v-on:click="
                      insCarrello();
                      floatingModalPermanent(false);
                    "
                  >
                    <v-icon class="mr-2" left style="display: inline-flex">
                      shopping_cart
                    </v-icon>
                    Aggiungi al carrello
                  </v-btn> -->
                </div>
              </v-card>

              <v-card class="pa-0 mt-8" elevation="0" v-if="origine != 'carrello' && role == 6">
                <v-btn class="mt-4" color="primary" style="width: 100%" v-on:click="
                  insCarrello();
                floatingModalPermanent(false);
                ">
                  <v-icon class="mr-2" left style="display: inline-flex">
                    check
                  </v-icon>
                  Conferma
                </v-btn>
              </v-card>
            </v-col>

            <!-- Internet  -->
            <v-col cols="6" v-if="role == 1">
              <!-- Prezzi -->
              <v-card flat class="mt-4">
                <table class="tableDett" style="width: 100%">
                  <tr>
                    <td style="width: 60%">
                      Prezzo listino {{ datiArticolo.descUmConversione }}
                    </td>
                    <td>{{ getPrezzo(dettaglioPrezzi.prezzoListino) }} €</td>
                  </tr>
                  <tr>
                    <td>Sconto %</td>
                    <td>
                      {{ dettaglioPrezzi.sconto1 }}
                      {{ getSecondoSconto(dettaglioPrezzi.sconto2) }}
                    </td>
                  </tr>

                  <tr v-if="dettaglioPrezzi.netto">
                    <td>Prezzo netto</td>
                    <td>
                      {{
                        dettaglioPrezzi.netto
                        ? dettaglioPrezzi.prezzoScontato
                        : ""
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td>Prezzo scontato</td>
                    <td>{{ getPrezzo(dettaglioPrezzi.prezzoScontato) }} €</td>
                  </tr>

                  <tr>
                    <td>Disponibilità: {{ datiArticolo.unMisura }}</td>
                    <td>
                      {{ dettaglioPrezzi.dettaglioMagazzino.qtaScaffale }}
                    </td>
                  </tr>
                </table>
              </v-card>

              <!-- Carrello-->
              <v-card class="pa-0 mt-8" elevation="0" v-if="origine != 'carrello'">
                <div>
                  <v-text-field style="margin-top: -10px" label="Riferimento" hide-details v-model="carrello.riferimento">
                  </v-text-field>
                </div>
                <div>
                  <v-text-field label="Note" hide-details v-model="carrello.note">
                  </v-text-field>
                </div>

                <div>
                  <v-text-field value="1" label="Quantità" v-model="carrello.qta"
                    :rules="[rules.required, rules.quantita]" type="number" hide-details style="width: 80px"
                    @keyup="convertiScatolaPz">
                  </v-text-field>
                </div>

                <div v-if="datiArticolo.umOrdiniWeb">
                  <v-text-field value="1" label="Nr.Scatole" v-model="qtaScatola" dense
                    :rules="[rules.required, rules.quantita]" type="number" hide-details
                    style="width: 80px; margin-top: 20px" @keyup="convertiPzScatola">
                  </v-text-field>
                  {{ scrittaOrdineMinimo() }}
                </div>

                <div>
                  <!-- Carrello -->
                  <!-- TEMPORANEAMENTE DISABILITATO -->
                  <!--      <v-btn
                    class="mt-4"
                    color="primary"
                    style="width: 100%"
                    v-on:click="
                      insCarrello();
                      floatingModalPermanent(false);
                    "
                  >
                    <v-icon class="mr-2" left style="display: inline-flex"
                      >shopping_cart</v-icon
                    >
                    Aggiungi al carrello
                  </v-btn> -->
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>

        <!-- Tipo serr distinta base -->
        <tipoSerrDialog :dialog="dialogTipoSerr" :cdArticolo="datiArticolo.cdArticolo" @chiudi="tipoSerrSelezionato"
          :codCliente="getClienteSelezionato()"></tipoSerrDialog>
      </v-tab-item>

      <!-- Ricevimenti -->
      <v-tab-item value="ricevimento">
        <!-- Scorta totale -->
        <v-spacer></v-spacer>
        <dettaglioOrdiniMastrino :aggiorna="caricaDatiScorta" :cdArticolo="datiArticolo.cdArticolo">
        </dettaglioOrdiniMastrino>
        <v-spacer></v-spacer>
      </v-tab-item>

      <!-- In lavorazione -->
      <v-tab-item value="lavorazione">
        <!-- Scorta totale -->
        <dettaglioOrdiniTerzisti :aggiorna="caricaDatiLav" :cdArticolo="datiArticolo.cdArticolo">
        </dettaglioOrdiniTerzisti>
      </v-tab-item>

      <!-- Scarti -->
      <v-tab-item value="scarti">
        <!-- <v-btn @click="caricaScarti()">carica</v-btn> -->
        <dettaglioOrdiniScarti :aggiorna="caricaDatiScarti" :cdArticolo="datiArticolo.cdArticolo"></dettaglioOrdiniScarti>
      </v-tab-item>

      <!-- Venduto -->
      <v-tab-item value="venduto">
        <dialogOrdinato :cdArticolo="datiArticolo.cdArticolo" :aggiorna="caricaDatiVen" :tipo="'cliente'"
          altezzaGrid="calc(46vh)">
        </dialogOrdinato>
      </v-tab-item>

      <!-- Acquistato -->
      <v-tab-item value="acquistato">
        <dialogOrdinato :cdArticolo="datiArticolo.cdArticolo" :aggiorna="caricaDatiFor" :tipo="'fornitore'"
          altezzaGrid="calc(46vh)">
        </dialogOrdinato>
      </v-tab-item>

      <!-- Listini -->
      <v-tab-item value="listini">
        <div style="height: 60px; background-color: #f5f5f5"></div>
      </v-tab-item>

      <!-- Ordini clienti -->
      <v-tab-item value="ordiniClienti"> </v-tab-item>

      <!-- Distinta base semplice -->
      <v-tab-item value="distintaBaseSemplice">
        <distintaSemplice :dati="distintaSempliceLista" :role="role" :group="group"
          @selezionaTabInfo="selezionaTabInfo()"></distintaSemplice>
      </v-tab-item>

      <!-- Distinta base dinamica -->
      <v-tab-item value="distintaBaseDinamica">
        <distintaDinamica :cdArticolo="distintaDinamicaArt" :role="role" :group="group"
          :tipoSerrDistintaParam="tipoSerrDistinta" :opzioniDistintaParam="opzioniDistinta"
          @selezionaTabInfo="selezionaTabInfo()" :codCliente="getClienteSelezionato()" :funzioneDb="getFunzioneDb">
        </distintaDinamica>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { ins_carrello } from "@/api/api.user.js";

import {
  ins_preferito,
  get_datiDettaglioPrezzi,
  get_datiArticolo,
  get_distintaBaseSemplice,
  get_tipiSerrDistinta,
  get_elaboraDistinta,
} from "@/api/api.catalogo.js";

import dettaglioOrdiniTerzisti from "@/components/dialog/catalogo/terzisti/DettaglioOrdiniForTerzisti.vue";
import dettaglioOrdiniMastrino from "@/components/dialog/catalogo/terzisti/DettaglioOrdiniForMastrino.vue";
import dettaglioOrdiniScarti from "@/components/dialog/catalogo/terzisti/DettaglioOrdiniForScarti.vue";
import dialogOrdinato from "@/components/dialog/catalogo/DettaglioOrdiniCliFor.vue";
import distintaSemplice from "@/components/dialog/catalogo/distintaBase/DistintaBaseSemplice.vue";
import distintaDinamica from "@/components/dialog/catalogo/distintaBase/DistintaBaseDinamica.vue";
import tipoSerrDialog from "@/components/dialog/catalogo/distintaBase/DistintaBaseTipoSerr.vue";

export default {
  components: {
    dettaglioOrdiniTerzisti,
    dettaglioOrdiniMastrino,
    dialogOrdinato,
    dettaglioOrdiniScarti,
    distintaSemplice,
    distintaDinamica,
    tipoSerrDialog,
  },

  props: {
    auth: { type: Boolean, default: false },
    role: { type: Number, default: 0 },
    group: { type: String, default: "" },
    floatingModal: { type: Object, default: () => ({}) },
    dialog: { type: Boolean, default: false },
    origine: { type: String, default: "" },
    cdArticolo: { type: String, default: "" },
  },

  data: () => ({
    rules: {
      required: (value) => !!value || "Richiesto.",
      quantita: (value) => {
        return (
          (/^\d+$/g.test(value) && parseInt(value) > 0) || "Numero non valido."
        );
      },
    },
    carrello: {
      cdArticolo: "",
      qta: 1,
      riferimento: "",
      note: "",
      tpRiga: "PRODOTTO",
    },

    qtaScatola: 0,

    datiArticolo: {},

    dettaglioPrezzi: {
      prezzoListino: 0,
      sconto1: 0,
      sconto2: 0,
      prezzoScontato: 0,

      dettaglioTerzisti: {
        qtaMagazzinoTerzista: 0,
        qtaContoProduzione: 0,
        disponibilitaTerzista: 0,
      },

      dettaglioMagazzino: {
        qtaOrdCliMese1: 0,
        qtaOrdCliMese2: 0,
        qtaOrdCliMese3: 0,
        qtaOrdCliSucc: 0,
        qtaOrdForMese1: 0,
        qtaOrdForMese2: 0,
        qtaOrdForMese3: 0,
        qtaOrdForSucc: 0,
        qtaPreOrdine: 0,
        qtaRicevuta: 0,
        qtaScaffale: 0,
        qtaTerzista: 0,
        dataInventario: "",
        qtaUbicazione2: 0
      },

      mastrinoArticolo: [],
    },

    pathImg: "",

    distintaSempliceLista: [],
    distintaDinamicaArt: "",
    dialogTipoSerr: false,
    tipoSerrDistinta: "",
    opzioniDistinta: "",
    attesaDisegnoDistinta: "",

    attesa: false,

    preferito: false,

    //tab selezione Informazioni/Scorta totale/In lavorazione
    activeTab: 0,
    caricaDatiScorta: false,
    caricaDatiLav: false,
    caricaDatiScarti: false,
    caricaDatiVen: false,
    caricaDatiFor: false,
  }),

  methods: {
    async caricaDatiDettaglio() {
      if (this.auth == false) return;

      // Reset
      this.dettaglioPrezzi.prezzoListino = 0;
      this.dettaglioPrezzi.sconto1 = 0;
      this.dettaglioPrezzi.sconto2 = 0;
      this.dettaglioPrezzi.prezzoScontato = 0;
      this.dettaglioPrezzi.dettaglioTerzisti = {};
      this.dettaglioPrezzi.mastrinoArticolo = [];
      this.dettaglioPrezzi.dettaglioMagazzino = {};
      this.tipoSerrDistinta = "";
      this.opzioniDistinta = "";
      this.pathImg = "";

      this.attesa = true;

      try {
        // -----------------------------------------
        // Caricamento dati articolo
        // -----------------------------------------
        let resArt = await get_datiArticolo(this.cdArticolo);
        this.datiArticolo = resArt.data;


        // -----------------------------------------
        // Controllo distinta base dinamica, per ricavare il prezzo
        // -----------------------------------------
        if (this.datiArticolo.distintaBaseDinamica || this.checkMan01) {
          // Tipo serramento, serve x capire se esistono multi tipi serramenti
          let resTipoSer = await get_tipiSerrDistinta(
            this.cdArticolo + "/" + this.getClienteSelezionato()
          );

          // Controllo singolo tipo serramento,non apro la dialog di scelta, ma utilizzo direttamente il tipo serr
          // NOTA: nel caso di MAN01 proseguo
          if (resTipoSer.data.length == 1) {
            this.attesa = false;
            // Singolo tipo serramento,non apro la dialog di scelta, ma utilizzo direttamente il tipo serr
            this.tipoSerrSelezionato(resTipoSer.data[0]);
            return;
          } else if (resTipoSer.data.length > 1) {
            this.attesa = false;
            // Apro la scelta del tipo serramento (la fisso a video, non flottante)
            this.floatingModalPermanent(true);
            this.dialogTipoSerr = true;
            return;
          } else {
            // Proseguo
          }
        }

        let par =
          this.datiArticolo.cdArticolo + "/" + this.getClienteSelezionato();

        // -----------------------------------------
        // Dettaglio prezzi-magazzino
        // -----------------------------------------
        let res = await get_datiDettaglioPrezzi(par);
        this.dettaglioPrezzi = res.data;
        this.pathImg = this.dettaglioPrezzi.pathImg; // NOTA: ho usato una variabile perchè non sempre aggiorna dall object
      } catch (error) {
        console.log(error);
      }
      this.attesa = false;
    },

    getClienteSelezionato() {
      let codAnagSel =
        !!this.cliente && Object.keys(this.cliente).length > 0
          ? this.cliente.cdAnagrafica
          : "";

      return codAnagSel;
    },

    getPrezzo(prezzo) {
      if (prezzo == null || prezzo == undefined || prezzo == "") return "";
      else return prezzo.toString().replace(".", ",");
    },

    getSecondoSconto(sconto) {
      if (sconto != null && sconto != undefined && sconto != "" && sconto != 0)
        return " + " + sconto;
      else return "";
    },

    async insCarrello() {
      if (this.role == 6) {
        this.$functionsApp.webViewCommand(
          "articoloSelezionato|" + this.cdArticolo
        );

        return;
      }

      // Controllo um di conversione
      if (this.datiArticolo.umOrdiniWeb.length > 0) {
        if (this.getScatola_daQta(true) == 0) {
          let str =
            "Quantità minima ordine : " +
            this.getQtaMin2() +
            " " +
            this.datiArticolo.unMisura;
          this.$toast.alert(str);
          return;
        }
      }

      try {
        let res = await ins_carrello({
          cdArticolo: this.datiArticolo.cdArticolo,
          qta: this.carrello.qta,
          riferimento: this.carrello.riferimento,
          note: this.carrello.note,
          tpRiga: this.carrello.tpRiga,
        });

        this.$store.commit("user/SET_CARRELLO", res.data);

        this.$toast.info("Articolo inserito nel carrello");
      } catch (error) {
        console.log(error);
      }
    },

    async insPreferiti() {
      let res = await ins_preferito(this.datiArticolo.cdArticolo);
      if (res.data == "OK") {
        this.dettaglioPrezzi.preferiti = !this.dettaglioPrezzi.preferiti;

        // NOTA: serve x aggiornare il bottone
        this.preferito = this.dettaglioPrezzi.preferiti;
      }
    },

    async caricaDistintaSemplice() {
      // Reset
      this.distintaSempliceLista = [];

      let res = await get_distintaBaseSemplice(this.datiArticolo.cdArticolo);
      this.distintaSempliceLista = res.data;
    },

    async tipoSerrSelezionato(item) {
      this.dialogTipoSerr = false;

      if (!!item == false) return;

      // Memorizzo il tipo serramento selezionato
      this.tipoSerrDistinta = item.tipoSerramento;

      this.attesa = true;

      // ------------------------------------------------
      // Elabora distinta: ricavo i componenti senza il disegno x velocizzare
      // ------------------------------------------------
      let res = await this.elaboraDistinta(false);

      // Prezzi distinta
      this.dettaglioPrezzi.prezzoListino = res.data.totale;
      this.dettaglioPrezzi.sconto1 = res.data.sconto1;
      this.dettaglioPrezzi.sconto2 = res.data.sconto2;
      this.dettaglioPrezzi.prezzoScontato = res.data.totaleGenerale;
      this.dettaglioPrezzi.dettaglioMagazzino.qtaScaffale =
        res.data.qtaScaffale;

      // Opzioni distinta
      this.opzioniDistinta = res.data.opzioni;

      this.attesa = false;

      // ------------------------------------------------
      // Elabora distinta: ricavo il disegno, dopo avere già caricato i prezzi
      // ------------------------------------------------
      this.attesaDisegnoDistinta = true;
      res = await this.elaboraDistinta(true);

      // Disegno
      this.pathImg = res.data.urlSvg;

      this.attesaDisegnoDistinta = false;
    },

    async elaboraDistinta(disegna) {
      let par = {
        codArticolo: this.datiArticolo.cdArticolo,
        tipoSerramento: this.tipoSerrDistinta,
        opzioni: "",
        disegna: disegna,
        codCliente: this.getClienteSelezionato(),
      };

      let res = await get_elaboraDistinta(par);

      return res;
    },

    onTabChange() {
      if (this.activeTab == "distintaBaseSemplice")
        this.caricaDistintaSemplice();
      if (this.activeTab == "distintaBaseDinamica")
        this.distintaDinamicaArt = this.datiArticolo.cdArticolo;
      else if (this.activeTab == "ricevimento")
        this.caricaDatiScorta = !this.caricaDatiScorta;
      else if (this.activeTab == "lavorazione")
        this.caricaDatiLav = !this.caricaDatiLav;
      else if (this.activeTab == "scarti")
        this.caricaDatiScarti = !this.caricaDatiScarti;
      else if (this.activeTab == "venduto")
        this.caricaDatiVen = !this.caricaDatiVen;
      else if (this.activeTab == "acquistato")
        this.caricaDatiFor = !this.caricaDatiFor;
    },

    selezionaTabInfo() {
      this.activeTab = 0;
    },

    floatingModalPermanent(vis) {
      this.$emit("floatingModalPermanent", vis);
    },

    getMese(nr) {
      let date = new Date();
      date.setMonth(date.getMonth() + nr);

      return date.toLocaleString("it-IT", {
        month: "short",
      });
    },

    /* -------------------------------------- */
    /* Conversioni um */
    /* -------------------------------------- */
    convertiScatolaPz() {
      // Reset
      this.qtaScatola = 0;

      // Controllo blum
      //controllaBlum();

      // Controllo presenza qta
      if (isNaN(this.carrello.qta)) {
        return false;
      }

      // Controllo um di conversione
      if (this.datiArticolo.umOrdiniWeb.length == 0) return;

      this.qtaScatola = this.getScatola_daQta(false);
    },

    convertiPzScatola() {
      // Reset
      this.carrello.qta = 0;

      // Controllo presenza qta
      if (isNaN(this.qtaScatola)) {
        return false;
      }

      // Controllo um di conversione
      if (this.datiArticolo.umOrdiniWeb.length == 0) return;

      this.carrello.qta = this.getQta_daScatola();
    },

    getScatola_daQta(checkPz) {
      var qtCalc = 0;
      var qtMin = checkPz ? this.getQtaMin2() : this.getQtaMin();

      qtCalc = parseFloat(this.carrello.qta) / qtMin;

      // Controllo articolo non divisibile
      if (this.datiArticolo.articoloNonDivisibile == "true") {
        if (Number.isInteger(qtCalc) == false) return 0;
      }

      // Arrotondo
      qtCalc = this.arrotonda(qtCalc, 2);

      if (qtCalc >= 1) return qtCalc;

      return 0;
    },

    getQta_daScatola() {
      var qtCalc = 0;
      var qtMin = this.getQtaMin();

      qtCalc = parseFloat(this.qtaScatola) * qtMin;

      // Arrotondo
      qtCalc = this.arrotonda(qtCalc, 2);

      if (qtCalc >= 1) return qtCalc;

      return 0;
    },

    getQtaMin() {
      if (this.datiArticolo.umOrdiniWeb == "BA")
        return parseFloat(this.datiArticolo.qtaPerPezzo);
      else return parseFloat(this.datiArticolo.qtScatola);
    },

    getQtaMin2() {
      if (
        this.datiArticolo.umOrdiniWeb == "PZ" ||
        this.datiArticolo.umOrdiniWeb == "BA"
      )
        return parseFloat(this.datiArticolo.qtaPerPezzo);
      else return parseFloat(this.datiArticolo.qtScatola);
    },

    arrotonda(valore, decimali) {
      var res = valore.toFixed(decimali);

      if (res.endsWith(".00")) res = parseInt(res);

      return res;
    },

    scrittaOrdineMinimo() {
      if (!!this.datiArticolo.umOrdiniWeb == false) return "";

      let str =
        " Q.tà minima " + this.getQtaMin2() + " " + this.datiArticolo.unMisura;

      return str;
    },

    controllaBlum() {
      if (codAzienda == "BLU") {
        if (parseFloat(this.qta) < this.qtaScatola)
          document.getElementById("sc1").value = 42;
        else document.getElementById("sc1").value = 50;

        return true;
      }

      return false;
    },
    /* -------------------------------------- */


    getColore() {
      if (this.group == "local") {
        // Intranet locale
        return {
          backgroundColor: this.$functionsApp.getColoreCatalogo(
            this.datiArticolo.obsoleto,
            this.datiArticolo.eliminato,
            this.datiArticolo.nonStampCatalogo,
            false
          ),
        };
      }
    },

    getTooltipPallino() {
      if (this.group == "local") {
        // Intranet locale
        return this.$functionsApp.getTooltipPallinoCatalogo(
          this.datiArticolo.obsoleto,
          this.datiArticolo.eliminato,
          this.datiArticolo.nonStampCatalogo,
          false
        );
      }

      return "";
    },
  },

  watch: {
    cdArticolo(val) {
      this.activeTab = 0;
      this.caricaDatiDettaglio();
    },
  },

  computed: {
    descUtente() {
      return this.$store.getters["user/getDescUser"];
    },

    cliente() {
      return this.$store.getters["app/getCliente"];
    },

    visDistintaSemplice() {
      return (
        // Internet- intranet
        (this.role == 1 || this.group == "local") &&
        this.datiArticolo.distintaBaseSemplice
      );
    },

    visDistintaDinamica() {
      if (this.group == "local" && this.checkMan01) return true;

      // Internet- intranet
      return (
        (this.role == 1 || this.group == "local") &&
        this.datiArticolo.distintaBaseDinamica
      );
    },

    checkMan01() {
      return (
        Object.keys(this.datiArticolo).length > 0 &&
        this.datiArticolo.funzioneDb.toUpperCase() == "MAN01"
      );
    },

    getFunzioneDb() {
      return Object.keys(this.datiArticolo).length > 0
        ? this.datiArticolo.funzioneDb
        : "";
    },
  },
};
</script>


<style scoped>
.ivaEsclusa {
  font-size: 10px;
  color: red;
  text-align: right;
}

.tableDett {
  width: 100%;
  border-collapse: collapse;
}

.tableDett tr {
  border-bottom: 1pt solid #e0e0e0;
  height: 30px;
}

.tableDett td:not(:first-child) {
  text-align: right;
  font-weight: bold;
  font-size: 13px;
}
</style>