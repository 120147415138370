<template >
  <div style="width: 100%; height: 100%">
    <!-- Filtri -->
    <FilterRimborsi
      ref="filtro"
      @caricaDati="caricaDati"
      :dati="dati"
      :autisti="autisti"
      @setKmInit="setKmInit"
      @setKmCosto="setKmCosto"
    ></FilterRimborsi>

    <!-- Mappa -->
    <div class="google-map" :id="mapName"></div>

  </div>
</template>

<script>
import FilterRimborsi from "@/components/intranet/FilterRimborsi.vue";
import gmapsInit from "@/utils/maps";


import { get_rimborsi } from "@/api/api.intranet.js";

export default {
  components: {
    FilterRimborsi,
  },

  data: () => ({
    dati: {},
    mapName: "google-map",
    map: null,
    bounds: null,
    infoWindow: null,
    markers: [],
    polylines: [],
    attesa: false,
    autisti: ["Franco", "Alberto", "Flavio", "Valter", "Lorenzo"],

  }),

  methods: {
    async caricaDati(data, autista) {
      // Reset
      this.dati = {};

      try {
        let res = await get_rimborsi(data + "/" + autista);

        this.dati = res.data;

        // Mappa
        this.caricaMappa();
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    caricaMappa() {
      // Reset
      this.pulisci();

      this.addMarker(this.dati.rimborsi);

      this.addPolyline(this.dati.punti);
    },

    addMarker(markerCoord) {
      let textMarkerSwap = "";

      // Img marker (svg)
      let MAP_MARKER =
        "M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z";

      let cont = 0;

      // Ciclo sulle coordinate
      markerCoord.forEach((coord, i) => {
        const position = new google.maps.LatLng(
          coord.latitudine,
          coord.longitudine
        );

        // ---- Controllo partenza\arrivo
        if (cont == 0) {
          // Partenza
          const marker = new google.maps.Marker({
            position,
            map: this.map,
            icon: { url: "/img/homeMap.png" },
            label: {
              color: "#FFF",
              fontWeight: "bold",
              fontSize: "14px",
              text: " ",
            },
          });
        } else if (cont == markerCoord.length - 1) {
          // Arrivo
        } else {
          // Consegne
          textMarkerSwap = coord.prog.toString();

          const marker = new google.maps.Marker({
            position,
            map: this.map,
            label: {
              color: "#000",
              fontWeight: "bold",
              fontSize: "15px",
              text: textMarkerSwap,
            },

            icon: {
              path: MAP_MARKER,
              scale: 1.4,
              fillColor: "red",
              fillOpacity: 1,
              labelOrigin: new google.maps.Point(0, -30),
            },
          });

          this.map.fitBounds(this.bounds.extend(position));

          let self = this;

          // Fumetto
          google.maps.event.addListener(marker, "mouseover", function () {
            self.infoWindow.setContent(coord.ragioneSociale);
            self.infoWindow.open(self.map, this);
          });
          google.maps.event.addListener(marker, "mouseout", function () {
            self.infoWindow.close();
          });

          // Inserisco il marker
          this.markers.push(marker);
        }

        cont++;
      });
    },

    addPolyline(arrPunti) {
      // Punti
      let punti = arrPunti.split(";");

      let lineVertices = new Array();

      for (let i = 0; i < punti.length - 1; i++) {
        if (punti[i] == "") continue;

        lineVertices[i] = new window.google.maps.LatLng(
          parseFloat(punti[i].split(",")[0]),
          parseFloat(punti[i].split(",")[1])
        );
      }

      let poly = new window.google.maps.Polyline({
        path: lineVertices,
        //strokeColor: colore,
      });

      poly.setMap(this.map);

      this.polylines.push(poly);
    },

    async initMappa() {
      // --------- Creazione mappa --------
      if (this.map == null) {
        const google = await gmapsInit(this.$load.getGoogleKey());

        const element = document.getElementById(this.mapName);
        const options = {
          zoom: 12,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        };

        this.map = new google.maps.Map(element, options);

        this.bounds = new google.maps.LatLngBounds();
        this.infoWindow = new google.maps.InfoWindow();
      }
    },

    setKmInit(val) {
      this.dati.kmIniziali = val;
    },

    setKmCosto(val) {
      this.dati.kmCosto = val;
    },

    pulisci() {
      // Marker
      for (let i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(null);
      }
      this.markers = [];

      // Polilinee
      for (let i = 0; i < this.polylines.length; i++) {
        this.polylines[i].setMap(null);
      }
      this.polylines = [];
    },
  },

  mounted() {
    this.pulisci();
    this.initMappa();
  },
};
</script>

<style scoped>
.google-map {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: gray;
  color: black;
}
</style>