export default {
    methods: {
        headerTabellaTestata() {
            return [
                {
                    text: "Img Prodotto", value: ""
                },
                {
                    text: "Cataloghi", value: ""
                },
                /* {
                    text: "Tipo Img", value: "tipoImg"
                }, */
                {
                    text: "CodTestata", value: "codTestata"
                },
                {
                    text: "Suffisso", value: "suffisso"
                },
                {
                    text: "Descrizione", value: "descrizione"
                },
                {
                    text: "Info", value: "informazioni"
                },
                /* {
                    text: "AltezzaImmagine", value: "altezzaImmagine"
                },
                {
                    text: "CampDivisione", value: "campDivisione"
                },
                {
                    text: "CampAllineamento", value: "campAllineamento"
                },
                {
                    text: "TipoRgDivisione", value: "tipoRgDivisione"
                },
                {
                    text: "CampRottura", value: "campRottura"
                },
                {
                    text: "RotturaTabelle", value: "rotturaTabelle"
                },
                {
                    text: "CodFornStampa", value: "codFornStampa"
                },
                {
                    text: "Bilanciamento", value: "bilanciamento"
                },
                {
                    text: "ParoleChiave", value: "paroleChiave"
                },
                {
                    text: "ChiaveRicerca", value: "chiaveRicerca"
                },
                {
                    text: "MarcheAlternative", value: "marcheAlternative"
                },
                {
                    text: "MarcheCorrelate", value: "marcheCorrelate"
                }, */
                {
                    text: "LogoAzienda", value: "logoAzienda"
                },
                /* {
                    text: "CodiceAzienda", value: "codiceAzienda"
                }, */
                /* {
                    text: "CodiceGruppo", value: "codiceGruppo"
                },
                {
                    text: "TipoComponente", value: "tipoComponente"
                },
                {
                    text: "DistintaBaseDinamica", value: "distintaBaseDinamica"
                }, */
                {
                    text: "ProgOrdine", value: "progOrdine"
                },
                /* {
                    text: "ParoleChiaveEscluse", value: "paroleChiaveEscluse"
                }, */
                {
                    text: "CatMerceologica", value: "catMerceologica"
                },
                /* {
                    text: "CatProgordine", value: "catProgordine"
                },
                {
                    text: "CatDescOrdine", value: "catDescOrdine"
                },
                {
                    text: "CatProgDescOrdine", value: "catProgDescOrdine"
                },
                {
                    text: "NascondiImg", value: "nascondiImg"
                }, */
                {
                    text: "DisattivaInternet", value: "disattivaInternet"
                },
                /* {
                    text: "Articoli_Eliminati", value: "articoli_Eliminati"
                },
                {
                    text: "Articoli_Obsoleti", value: "articoli_Obsoleti"
                },
                {
                    text: "Articoli_NonStampCatalogo", value: "articoli_NonStampCatalogo"
                },
                {
                    text: "Articoli_EliminatiPassword", value: "articoli_EliminatiPassword"
                },
                {
                    text: "Miniatura", value: "miniatura"
                },
                {
                    text: "CampiRotturaDivisione", value: "campiRotturaDivisione"
                },
                {
                    text: "CodCategoria_DA_CANC", value: "codCategoria_DA_CANC"
                },
                {
                    text: "CodSottoCategoria_DA_CANC", value: "codSottoCategoria_DA_CANC"
                },
                {
                    text: "CodIndiceCategoria_DA_CANC", value: "codIndiceCategoria_DA_CANC"
                },
                {
                    text: "ProgOrdinamentoCapitolo_DA_CANC", value: "progOrdinamentoCapitolo_DA_CANC"
                },
                {
                    text: "DescrizioniTecnicheSottoImg", value: "descrizioniTecnicheSottoImg"
                },
                {
                    text: "LottoMateriale", value: "lottoMateriale"
                },
                {
                    text: "SaltoPagina", value: "saltoPagina"
                },
                {
                    text: "FullTextRicerca", value: "fullTextRicerca"
                }, */
                {
                    text: "Caricato", value: "caricato"
                },
                /* {
                    text: "Catalogo", value: "catalogo"
                }, */
                {
                    text: "Outlet", value: "outlet"
                },
                {
                    text: "New", value: "new"
                },
                {
                    text: "Promozione", value: "promozione"
                },
                {
                    text: "Data Caricamento", value: "dataCaricamento"
                },
                {
                    text: "Data Modifica", value: "dataModifica"
                },
                {
                    text: "Utente di Modifica", value: "utenteModifica"
                },
            ]
        },

        headerTabellaMarchiAziende() {
            return [
                {
                    text: "Img Prodotto", value: ""
                },
                {
                    text: "CodiceAzienda", value: "codiceAzienda"
                },
                {
                    text: "NomeAzienda", value: "nomeAzienda"
                },
                {
                    text: "Sito", value: "sito"
                },
                {
                    text: "Descrizione", value: "descrizione"
                },
                /* {
                    text: "Descrizione_EN", value: "descrizione_EN"
                }, */
                {
                    text: "AttivaCodArtFor2", value: "attivaCodArtFor2"
                },
                {
                    text: "DisattivaInternet", value: "disattivaInternet"
                },
                /* {
                    text: "MarcheAlternative_", value: "marcheAlternative_"
                },
                {
                    text: "MarcheCorrelate_", value: "marcheCorrelate_"
                }, */
                {
                    text: "CodiceLinea", value: "codiceLinea"
                },
                /* {
                    text: "CtProgOrdine", value: "ctProgOrdine"
                }, */
                /* {
                    text: "DataValidita", value: "dataValidita"
                },
                {
                    text: "NumListino", value: "numListino"
                },
                {
                    text: "MeseListino", value: "meseListino"
                },
                {
                    text: "AnnoListino", value: "annoListino"
                }, */
                /* {
                    text: "ParolaChiave", value: "parolaChiave"
                }, */
            ]
        },

        headerTabellaLog() {
            return [
                { text: "Tabella", value: "tabella" },
                { text: "Colonna", value: "colonna" },
                { text: "Data Ora", value: "dataOra" },
                { text: "Note", value: "note" },
                { text: "Utente", value: "utente" },
                { text: "Operazione", value: "operazione" },
            ]
        },

        filtriTabelle() {
            return {
                CodTestata: "",
                Descrizione: "",
            }
        },

        filtriTabellaAziende() {
            return {
                CodAzienda: "",
                Descrizione: "",
            }
        },

        headerTabellaAziende() {
            return [
                { text: "CodiceAzienda", value: "codiceAzienda" },
                { text: "NomeAzienda", value: "nomeAzienda" },
                /* { text: "Sito", value: "sito" },
                { text: "Descrizione", value: "descrizione" },
                { text: "MarcheAlternative_", value: "marcheAlternative_" },
                { text: "MarcheCorrelate_", value: "marcheCorrelate_" },
                { text: "CodiceLinea", value: "codiceLinea" },
                { text: "CtProgOrdine", value: "ctProgOrdine" },
                { text: "DisattivaInternet", value: "disattivaInternet" },
                { text: "DataValidita", value: "dataValidita" },
                { text: "NumListino", value: "numListino" },
                { text: "MeseListino", value: "meseListino" },
                { text: "AnnoListino", value: "annoListino" },
                { text: "Descrizione_EN", value: "descrizione_EN" },
                { text: "ParolaChiave", value: "parolaChiave" },
                { text: "AttivaCodArtFor2", value: "attivaCodArtFor2" }, */
            ]
        },

        headerTabellaMerceologica() {
            return [
                { text: "CtMer", value: "ctMer" },
                { text: "CmDes", value: "CmDes" },
                /* { text: "Livellamento", value: "livellamento" },
                { text: "Obsoleto", value: "obsoleto" },
                { text: "Eliminato", value: "eliminato" },
                { text: "Gruppo", value: "gruppo" },
                { text: "Ordinamento", value: "ordinamento" },
                { text: "NoStampaCatalogo", value: "noStampaCatalogo" },
                { text: "DisattivaInternet", value: "disattivaInternet" },
                { text: "Parola", value: "parola" },
                { text: "Filtro", value: "filtro" },
                { text: "Valore", value: "valore" },
                { text: "CtStSet", value: "ctStSet" },
                { text: "MacroCat", value: "macroCat" },
                { text: "NoRicalcolaDBase", value: "noRicalcolaDBase" }, */
            ]
        },

        headerTabellaLineeProdotto() {
            return [
                { text: "CodiceLinea", value: "codiceLinea" },
                /* { text: "CodiceCatalogoCapitoli", value: "codiceCatalogoCapitoli" },
                { text: "Anno", value: "anno" }, */
                { text: "Descrizione", value: "descrizione" },
                /* { text: "EcoLinea", value: "ecoLinea" },
                { text: "Ricarico", value: "ricarico" },
                { text: "Aziende", value: "aziende" },
                { text: "Colore", value: "colore" },
                { text: "Mese", value: "mese" },
                { text: "TestoFinale", value: "testoFinale" }, */
            ]
        },
    },
}