<template>
  <v-dialog v-model="dialog" width="800">
    <v-card>
      <v-card-title>
        <v-spacer />
        <v-btn icon @click="chiudiDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        {{ lavorazioneSel }}
        <v-row class="ma-0 pa-0">
          <v-text-field
            hide-details
            v-model="lavorazioneSel.descrizione"
            label="Descrizione"
            style="max-width: 350px;"
          />
          <v-select
            hide-details
            v-model="lavorazioneSel.tipoInvio"
            label="Tipo invio"
            :items="tipiInvio"
            style="max-width: 150px"
          />
          <v-select
            v-model="lavorazioneSel.materialeBase"
            hide-details
            label="Materiale base"
            :items="tipiMaterialeBase"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn v-on:click="chiudiDialog()">Annulla</v-btn>
        <v-btn color="primary" v-on:click="salvaDati()">SALVA</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean, default: false },
    lavorazione: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data: () => ({
    lavorazioneSel: {},
    tipiInvio: ["CD", "CL"],
    tipiMaterialeBase: ["GR", "CS"],
  }),

  methods: {
    chiudiDialog() {
      this.$emit("chiudi");
    },

    salvaDati() {
      this.chiudiDialog();
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal) this.lavorazioneSel = Object.assign({}, this.lavorazione);
    },
  },
};
</script>
