<template>
    <div>
        <v-data-table :headers="headerTab" :items="dati" :mobile-breakpoint="0" no-data-text="Nessun dato disponibile"
            hide-default-header height="50vh">
            <template v-slot:header="{ props: { headers } }">
                <thead>
                    <tr>
                        <th v-for="(head, i) in headers" :key="i">
                            <span>{{ head.text }}</span>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>
                        {{ item.nrDocu }}
                    </td>
                    <td>
                        {{ item.datiDocu.tpDocu }}
                    </td>
                    <td>

                    </td>
                    <td>
                        {{ item.destinatario }}
                    </td>
                    <td>
                        {{ item.vettore }}
                    </td>
                    <td>
                        {{ item.tipoPorto }}
                    </td>
                    <!-- 
                    <td class="mr-0 pr-0">
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon @click="cancellaDocumento(item)">
                                    <v-icon>delete</v-icon>
                                </v-btn>
                            </template>
                            <span>Elimina documento</span>
                        </v-tooltip>
                    </td>
                    <td class="ma-0 pa-0"></td> -->
                </tr>
            </template>
        </v-data-table>


    </div>
</template>

<script>

import { get_docVendite } from "@/api/api.intranet.js"

export default {

    data: () => ({

        headerTab: [{
            text: "Numero documento",
            value: "nrDocu"
        },
        {
            text: "Tipo documento",
            value: "tpDocu"
        },
        {
            text: "Data documento",
            value: "dtDocuStampato"
        },

        {
            text: "Destinatario",
            value: "destinatario"
        },
        {
            text: "Vettore",
            value: "vettore"
        },
        {
            text: "Porto",
            value: "tipoPorto"
        },
        {
            text: "Azioni",
            value: "azioni",
            sortable: false,
            width: 80
        },
        ],

        dati: []
    }),

    methods: {

        async caricaDati() {

            let res = await get_ordiniVendite();

            this.dati = res.data
        }
    },

    mounted() {
        this.caricaDati()
    }
}

</script>