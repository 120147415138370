<template>
  <v-dialog v-model="dialog" persistent width="840">
    <v-card class="d-flex flex-column">
      <v-card-title> Cerca documento
        <v-spacer />
        <v-btn icon @click.prevent="chiudi()">
          <v-icon large>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="ma-0 pa-0">
          <v-btn icon @click.prevent="caricaDati()" class="mr-2">
            <v-icon>refresh</v-icon>
          </v-btn>

          <v-select :items="getAnni()" v-model="annoSel" style="max-width: 80px" class="ma-0 pa-0" label="Anno"
            hide-details />
          <v-spacer />

          <v-text-field label="Cerca" v-model="search" append-icon="search" @click:append="caricaDati()" clearable
            hide-details class="ma-0 pa-0" @keydown.enter="caricaDati()" />
        </v-row>

        <v-data-table :items="elencoDati" :headers="headersDati" no-data-text="Nessun dato disponibile"
          :items-per-page="100" height="52vh" fixed-header :loading="loadingDati"
          loading-text="Caricamento dati in corso" class="mt-2">
          <template v-slot:item="{ item }">
            <tr @click="selectRow(item)" :class="{ 'row-selected': item === rigaSel }" class="is-clickable">
              <td>{{ item.nrDocu }}</td>
              <td>{{ item.nrDocuStampato }}</td>
              <td>{{ item.tpDocu }}</td>
              <td>{{ $i2kFunctions.formatISODate(item.dtDocuStampato) }}</td>
              <td>{{ item.cdContatto }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-spacer />
      <v-card-actions>
        <v-spacer />
        <v-btn @click.prevent="chiudi()"> Annulla </v-btn>
        <v-btn @click.prevent="salva()" class="btn-conferma"> Conferma </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_elencoDocumenti } from "@/api/api.etichette.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
  },

  data: () => ({
    documentoSel: {},
    elencoDati: [],
    headersDati: [
      { text: "Num docu", value: "nrDocu" },
      { text: "Num docu stampato", value: "nrDocuStampato" },
      { text: "Tipo docu", value: "tpDocu" },
      { text: "Data docu", value: "dtDocuStampato" },
      { text: "Rag. Soc. Destinatario", value: "cdContatto" },
    ],
    search: "",
    annoSel: new Date().getFullYear(),

    rigaSel: {},
    loadingDati: false,
  }),

  methods: {
    async caricaDati() {
      this.loadingDati = true;
      let par = {
        Documento: this.search,
        anno: this.annoSel,
      };
      let res = await get_elencoDocumenti(par);
      this.elencoDati = res.data;
      this.loadingDati = false;
    },

    chiudi(nrDocuStampato, tpDocu, nrDocu) {
      if (nrDocu) this.$emit("chiudi", nrDocuStampato, tpDocu, nrDocu);
      this.$emit("chiudi");
    },

    getAnni() {
      let anno = new Date().getFullYear();
      return [anno - 1, anno];
    },

    selectRow(item) {
      this.rigaSel = item;
    },

    salva() {
      this.chiudi(this.rigaSel.nrDocuStampato, this.rigaSel.tpDocu, this.rigaSel.nrDocu);
    },
  },

  watch: {
    dialog() {
      if (this.dialog) this.caricaDati();
    },
  },
};
</script>