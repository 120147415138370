<template>
    <v-container>
        <v-row>
            <v-col cols="12" v-for="(item, i) in sommario" :key="i">
                {{ item.descrizione }}

                <router-link :to="gotoPage()" class="unstyle-link">

                    {{ item.descrizione }}
                </router-link>


            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    props: {
        codcategoria: { type: String, default: "" }

    },

    methods: {
        gotoPage() {
            return "/ricerca/" + this.$store.getters["ricerca/valoreRicerca"] + "/indice/" + this.codcategoria;
        }
    },

    mounted() {
        //console.log(this.codcategoria)
    },

    computed: {
        sommario() {
            return this.$store.getters["ricerca/sommario"];
        }
    }
}
</script>