<template>
  <div>
    <!-- Componenti\opzioni\disegno-->
    <v-container style="background-color: white" fluid class="pa-0">
      <v-row>
        <v-col cols="12" class="pb-0">
          <v-toolbar dense height="60" flat style="background-color: #f5f5f5" width="100%">

            <v-toolbar-items v-for="(item, i) in $i2kFunctions.groupByIndex(
              opzioniDistinta,
              'raggruppamento'
            )" :key="i">
              <v-menu bottom offset-y :close-on-click="false" :close-on-content-click="false" open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn style="color: grey; font-weight: bold" text v-bind="attrs" v-on="on" class="no-uppercase"
                    :disabled="attesa">
                    {{ item.key }}
                  </v-btn>
                </template>

                <v-list v-for="(valori, i) in opzioniDistinta.filter(
                  (f) => f.raggruppamento == item.key
                )" :key="i">
                  <v-list-item dense link>
                    <v-list-item-content @click="showDialogOpzioni(valori)">
                      <v-list-item-title>{{ valori.idOpzione }}
                      </v-list-item-title>
                      <v-list-item-subtitle>{{ getValoreSelezionatoOpzioni(valori.idOpzione) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>

            <v-spacer></v-spacer>

            <span style="color: #ea4155">Disegno</span>
            <v-btn light color="primary" class="ml-1" small fab @click="showDisegno = !showDisegno">
              <v-icon>{{ showDisegno ? "expand_less" : "expand_more" }}</v-icon>
            </v-btn>
          </v-toolbar>
        </v-col>


        <v-col cols="12" class="pt-0" id="divContenitore">
          <div :style="{ height: getHeightSvg }">
            <object id="svg1" style="width: 100%; height: 100%" :data="distinta.urlSvg" type="image/svg+xml"></object>
          </div>

          <div v-show="attesa" style="position: absolute; top: 50%; left: 50%; margin: auto">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>

          <div v-show="showDisegno">
            <v-data-table :items="articoli" hide-default-header no-data-text="" disable-pagination hide-default-footer
              fixed-header dense :headers="headersComponenti" disable-sort height="calc(100vh - 330px)"
              :show-expand="role == 2" single-expand item-key="prog"
              @item-expanded="aggiornaDatiFor = !aggiornaDatiFor">
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th v-for="(head, i) in headers" :key="i" @click="head.sortable ? changeSort(head.value) : ''">
                      <span v-html="head.text"></span>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item="{ item, expand, isExpanded }">
                <tr :id="item.codiceUtente">
                  <td style="width: 50px" class="pa-0">
                    <v-btn @click="expand(!isExpanded)" icon>
                      <v-icon dark>{{
                      isExpanded
                      ? "keyboard_arrow_down"
                      : "keyboard_arrow_right"
                      }}</v-icon>
                    </v-btn>
                  </td>

                  <td title="Codice Articolo">
                    {{ $functionsApp.getCodArticoloFormat(item.codiceUtente) }}
                  </td>
                  <td title="Descrizione">
                    {{ item.descrizione.replaceAll('"', "") }}
                  </td>
                  <td title="Unità di misura">
                    {{ item.unMisura }}
                  </td>
                  <td title="Quantità" style="text-align: right">
                    {{ item.cfImpVis }}
                  </td>
                  <td title="Prezzo" style="min-width: 90px; text-align: right">
                    {{ "€ " + item.prezzo }}
                  </td>
                  <td title="Sconto" style="text-align: right">
                    {{ item.sconti }}
                  </td>
                  <td title="Qtà scaffale" style="text-align: right">
                    {{ role == 1 ? "x" : item.qtaScaffale }}<!--item.qtaDisponibile -->
                  </td>
                </tr>
              </template>

              <!-- Expand -->
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length" style="background-color: grey" class="py-4 px-4">
                  <!-- Nota: se devo aggiornare delle props all'apertura\chiusura devo mettere la key-->

                  <dialogOrdinato :cdArticolo="item.codiceUtente" :aggiorna="aggiornaDatiFor" :tipo="'fornitore'"
                    style="height: 340px" altezzaGrid="calc(30vh)">
                  </dialogOrdinato>
                </td>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <tipoSerrDialog :dialog="dialogTipoSerr" :cdArticolo="cdArticolo" @chiudi="tipoSerrSelezionato"></tipoSerrDialog>

    <!-- Dialog modifica valore -->
    <v-dialog v-model="dialogOpzioni" width="500">
      <v-card>
        <v-card-title>
          <div style="width: 100%">
            {{ idOpzioneSelezionata.idOpzione }}
            <v-icon @click="dialogOpzioni = false" style="float: right">close</v-icon>
          </div>
        </v-card-title>
        <v-divider></v-divider>

        <v-list>
          <v-list-item v-for="(item, i) in getValoriOpzioni(
            idOpzioneSelezionata.idOpzione
          )" :key="i" link :class="{
            primary:
              getValoreSelezionatoOpzioni(idOpzioneSelezionata.idOpzione) ==
              item,
            'white--text':
              getValoreSelezionatoOpzioni(idOpzioneSelezionata.idOpzione) ==
              item,
          }">
            <v-list-item-content @click="setOpzioni(idOpzioneSelezionata.idOpzione, item)">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>

    <!-- Footer -->
    <v-footer app color="#fff" class="pa-2" elevation="2">
      <v-container style="background-color: #fff" class="ma-0 py-0" fluid>
        <v-row>
          <v-col cols="5">
            <v-btn color="primary" small @click="attivaTabInfo()">
              <v-icon class="mr-2" left style="display: inline-flex">shopping_cart</v-icon>
              Ordina
            </v-btn>
            <v-btn color="primary" class="ml-1" small right @click="elaboraDistinta()">
              <v-icon style="display: inline-flex">refresh</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="7" style="text-align: right" class="mt-2">
            Totale:<span class="primary--text mr-4 font-weight-bold">
              {{
              distinta.totale.toLocaleString("it-IT", {
              style: "currency",
              currency: "EUR",
              })
              }}</span>
            Sconti:<span class="primary--text mr-4 font-weight-bold">{{
            distinta.sconto1.toString() + "+" + distinta.sconto2.toString()
            }}</span>
            Tot.Generale:
            <span class="primary--text font-weight-bold">{{
            distinta.totaleGenerale.toLocaleString("it-IT", {
            style: "currency",
            currency: "EUR",
            })
            }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>

<script>
import {
  get_elaboraDistinta,
  get_tipiSerrDistinta,
  get_distintaBaseDinamica,
} from "@/api/api.catalogo.js";

import tipoSerrDialog from "@/components/dialog/catalogo/distintaBase/DistintaBaseTipoSerr.vue";
import dialogOrdinato from "@/components/dialog/catalogo/DettaglioOrdiniCliFor.vue";

export default {
  props: {
    role: { type: Number, default: 0 },
    group: { type: String, default: "" },
    cdArticolo: { type: String, default: "" },
    codCliente: { type: String, default: "" },
    tipoSerrDistintaParam: { type: String, default: "" },
    opzioniDistintaParam: { type: String, default: "" },
    funzioneDb: { type: String, default: "" },
  },

  components: {
    tipoSerrDialog,
    dialogOrdinato,
  },

  data: () => ({
    disegnoTecnico: "",

    attesa: false,

    headersComponenti: [
      { text: "Articolo", value: "codiceUtente" },
      { text: "Descrizione", value: "descrizione" },
      { text: "Um", value: "unMisura" },
      { text: "Coef.", value: "cfImpVis" },
      { text: "Prezzo", value: "prezzo" },
      { text: "Sconti", value: "sconti" },
      { text: "Disponibilità", value: "qtaScaffale" },
    ],

    distinta: { totale: 0, sconto1: 0, sconto2: 0, totaleGenerale: 0 },
    articoli: [],
    opzioni: [],
    opzioniDistinta: [],
    opzioniStr: "",

    tipoSerr: "",

    selectedPanel: null,

    dialogTipoSerr: false,

    showDisegno: true,

    dialogOpzioni: false,
    idOpzioneSelezionata: {},

    aggiornaDatiFor: false,
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.articoli = [];
      this.opzioni = [];
      this.opzioniStr = "";

      this.distinta = {
        totale: 0,
        sconto1: 0,
        sconto2: 0,
        totaleGenerale: 0,
        urlSvg: "",
      };
      this.tipoSerr = "";
      this.attesa = false;
      this.showDisegno = true;

      // Controllo se è già stato elaborata la distinta dal dettaglio prezzi
      if (this.tipoSerrDistintaParam) {
        this.tipoSerr = this.tipoSerrDistintaParam;
        this.caricaDatiDistintaDinamica();
      } else {
        // Carico i tipi serramento
        let res = await get_tipiSerrDistinta(
          this.cdArticolo + "/" + this.codCliente
        );

        // Controllo tipo serramento
        if (res.data.length == 1) {
          // Singolo: se è presente un solo tipo serramento utilizzo direttamente quello
          this.tipoSerr = res.data[0];
          this.elaboraDistinta();
        } else if (res.data.length > 1) {
          // Multi scela: apro la dialog di scelta
          this.dialogTipoSerr = true;
        } else if (this.funzioneDb == "MAN01") {
          // Tipologia man01
          this.tipoSerr = "";
          this.elaboraDistinta();
        }
      }
    },

    async elaboraDistinta() {
      // Reset
      this.distinta = { totale: 0, sconto1: 0, sconto2: 0, totaleGenerale: 0 };
      this.articoli = [];
      this.opzioni = [];

      let par = {
        codArticolo: this.cdArticolo,
        tipoSerramento: this.tipoSerr,
        opzioni: this.opzioniStr,
        disegna: true,
        l_Area: document.getElementById("divContenitore").offsetWidth,
        h_Area: document.getElementById("divContenitore").offsetHeight,
        codCliente: this.codCliente,
        funzioneDb: this.funzioneDb,
      };

      this.attesa = true;

      try {
        let res = await get_elaboraDistinta(par);

        this.distinta = res.data;

        // Componenti
        this.articoli = this.distinta.componentiDistinta;

        // Opzioni
        if (this.distinta.opzioni) {
          this.opzioni = this.distinta.opzioni.split("|");
        }
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    async caricaDatiDistintaDinamica() {
      // Reset
      this.distinta = { totale: 0, sconto1: 0, sconto2: 0, totaleGenerale: 0 };
      this.articoli = [];
      this.opzioni = [];
      this.opzioniDistinta = [];

      this.attesa = true;

      try {
        let res = await get_distintaBaseDinamica(
          this.cdArticolo + "/" + this.tipoSerr
        );

        this.distinta = res.data;

        // Componenti
        this.articoli = this.distinta.componentiDistinta;

        // Opzioni
        this.opzioniDistinta = this.distinta.opzioniDistinta;

        // Utilizzo le opzioni generate dal dettaglio prezzi
        this.opzioni = this.opzioniDistintaParam.split("|");
      } catch (error) {
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;
    },

    setOpzioni(idOpzione, valoreOpzione) {
      let arr = this.opzioni;
      let opzTrovato = false;

      let opz = "";

      for (var i = 0; i < arr.length; i++) {
        var arr2 = arr[i].split("$");

        if (arr2[0] == idOpzione) {
          opz += idOpzione + "$" + valoreOpzione + "|" //"^|";
          opzTrovato = true;
        } else opz += arr[i] + "|";
      }

      // Se non ho trovato l'opzione l'aggiungo
      if (opzTrovato == false) {
        opz += idOpzione + "$" + valoreOpzione + "^|";
      }

      this.opzioniStr = opz;

      this.dialogOpzioni = false;

      // Devo ricaricare componenti e disegno
      this.elaboraDistinta();
    },

    getValoriOpzioni(idOpzione) {
      let arr = this.opzioni;

      for (var i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split("$");

        if (arr2[0] == idOpzione) {
          return arr2[2].split("^");
        }
      }
    },

    getValoreSelezionatoOpzioni(idOpzione) {
      let arr = this.opzioni;

      for (var i = 0; i < arr.length; i++) {
        let arr2 = arr[i].split("$");

        if (arr2[0] == idOpzione) {
          return arr2[1].replace("^", "");
        }
      }

      return "";
    },

    chiudi() {
      this.$emit("chiudi");
    },

    tipoSerrSelezionato(row) {
      this.dialogTipoSerr = false;

      if (!!row == false) return;

      this.tipoSerr = row.tipoSerramento;

      this.elaboraDistinta();
    },

    showDialogOpzioni(opz) {
      this.idOpzioneSelezionata = opz;

      this.dialogOpzioni = true;
    },

    attivaTabInfo() {
      this.$emit("selezionaTabInfo");
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    cdArticolo(val) {
      if (val) {
        this.caricaDati();
      }
    },
  },

  computed: {
    getHeightSvg() {
      return this.showDisegno ? "calc(0vh)" : "calc(60vh)";
    },
  },
};
</script>

<style scoped>
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  font-size: 11px !important;
}
</style>
