var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    { 'data-table-detail': true },
    { 'active-permanent': _vm.floatingModal.isPermanent },
    { 'active-hover': _vm.floatingModal.isHover && !_vm.floatingModal.isPermanent },
    { hidden: !_vm.floatingModal.isHover && !_vm.floatingModal.isPermanent } ],staticStyle:{"background-color":"#fff","border-radius":"10px","width":"calc(22%) !important","max-width":"auto !important","height":"auto","z-index":"1000"},style:(_vm.floatingModal.transformStyle),attrs:{"id":"floating-div"}},[_c('div',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"no-gutters":""}},[_c('v-col',{style:([
                _vm.listaSottoCategorieCataloghi.length > 0
                    ? { 'border-right': '1px solid black' }
                    : {} ]),attrs:{"cols":"6"}},_vm._l((_vm.listaCategorieCataloghi),function(cat,i){return _c('v-row',{key:i,attrs:{"no-gutters":""}},[(cat.categoria === true)?_c('div',{staticClass:"outside ml-1 mr-1",style:({ backgroundColor: cat.coloreRgb })},[_c('div',{staticClass:"inside"},[_vm._v(_vm._s(cat.modulo))])]):_vm._e(),(cat.categoria === true)?_c('v-col',[_c('h4',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(cat.descrizione))])]):_vm._e()],1)}),1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},_vm._l((_vm.listaSottoCategoria),function(cat,i){return _c('v-row',{key:i,attrs:{"no-gutters":""}},[(cat.sottoCategoria === true)?_c('div',{staticClass:"ml-2",staticStyle:{"width":"9vh","height":"9vh"}},[_c('v-img',{staticClass:"mr-2",attrs:{"src":cat.src}})],1):_vm._e(),(cat.sottoCategoria === true)?_c('v-col',{attrs:{"cols":"5"}},[_c('h4',{staticStyle:{"color":"black"}},[_vm._v(_vm._s(cat.descrizione))])]):_vm._e()],1)}),1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }