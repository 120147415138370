<template src="./news.html"></template>
<script>

import NewsCard from "@/components/ui/NewsCard.vue";

import { get_news } from "@/api/api.news.js";

export default {
  name: "news-list",
  components:  {
    NewsCard
  },
  data: () => ({
    news: []
  }),
  methods: {
    caricaNews() {
      get_news("")
        .then(res => {
          this.news = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.caricaNews();
  }
};
</script>
