<template>
  <v-dialog v-model="dialog" persistent transition="dialog-top-transition" max-width="80%" min-height="1000px">
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-spacer></v-spacer>
          <v-btn hide-details text icon elevation="0" v-on:click="setCategorie">
            <v-icon large> close </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text class="ma-0 pa-0">
        <v-row no-gutters class="ma-0 pa-0">
          <!-- CATEGORIE -->
          <v-col cols="4" :style="[
            listaSottoCategorieCataloghi.length > 0
              ? { 'border-right': '1px solid black' }
              : {},
          ]">
            <v-row no-gutters v-for="(cat, i) in listaCategorieCataloghi" :key="i">
              <div class="outside ml-10 mr-10 mb-6" :style="{ backgroundColor: cat.coloreRgb }">
                <div class="inside">{{ cat.modulo }}</div>
              </div>
              <v-col cols="6">
                <h3 style="color: black">{{ cat.descrizione }}</h3>
              </v-col>
              <v-switch v-model="cat.categoria" @change="setCategoria(cat)" dense hide-details class="ma-1 pa-0 ml-10"
                :disabled="categoria == true && cat.categoria == false"></v-switch>
            </v-row>
          </v-col>
          <v-col cols="8">
            <!-- GRUPPO SELEZIONATO -->
            <v-row class="mt-3" no-gutters v-if="GruppoSelezionato != null">
              <div class="outsideB ml-10 mr-10 mb-5" :style="{ backgroundColor: GruppoSelezionato.coloreRgb }">
                <div class="inside" style="font-size: large;">{{ GruppoSelezionato.modulo }}</div>
              </div>
              <h3 style="color: black;">{{ GruppoSelezionato.descrizione }}</h3>
            </v-row>
            <!-- SOTTO CATEGORIE -->
            <v-row class="mt-3" no-gutters>
              <v-col cols="6">
                <v-row class="ml-10 mb-10" no-gutters v-for="(cat, i) in listaSottoCategoria1" :key="i">
                  <div style="width: 16vh; height: 8vh">
                    <v-img class=" mr-10" :src="cat.src"></v-img><!-- max-height="120" max-width="120" -->
                  </div>
                  <v-col cols="6">
                    <h3 style="color: black;">{{ cat.descrizione }}</h3>
                  </v-col>
                  <v-switch v-model="cat.sottoCategoria" @change="setSottoCategoria(cat)" dense hide-details
                    class="ma-1 pa-0 ml-0" :disabled="sottoCategoria == true && cat.sottoCategoria == false"></v-switch>
                </v-row>
              </v-col>
              <v-col cols="6" style="margin-top: -12vh;">
                <v-row class="ml-10 mb-10" no-gutters v-for="(cat, i) in listaSottoCategoria2" :key="i">
                  <div style="width: 16vh; height: 8vh">
                    <v-img class="mr-10" :src="cat.src"></v-img><!-- max-height="120" max-width="120" -->
                  </div>
                  <v-col cols="6">
                    <h3 style="color: black">{{ cat.descrizione }}</h3>
                  </v-col>
                  <v-switch v-model="cat.sottoCategoria" @change="setSottoCategoria(cat)" dense hide-details
                    class="ma-1 pa-0 ml-0" :disabled="sottoCategoria == true && cat.sottoCategoria == false"></v-switch>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-col cols="12">
          <v-row no-gutters>
            <v-spacer></v-spacer>
            <v-btn v-on:click="setCategorie" class="mr-2">ANNULLA</v-btn>
            <v-btn class="btn-conferma" v-on:click="conferma">CONFERMA</v-btn>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  get_listaCategorieCataloghi,
  get_listaSottoCategorieCataloghi,
  set_cataloghi,
} from "@/api/api.archivi.js";

export default {
  props: {
    dialog: { type: Boolean, default: null },
    codiceLinea: { type: String, default: "" },
    testataCategorie: { type: Object, default: null },
  },

  components: {},

  data: () => ({
    listaCategorieCataloghi: [],
    listaSottoCategorieCataloghi: [],
    categoria: false,
    Categoria: null,
    sottoCategoria: false,
    SottoCategoria: null,
    listaSottoCategoria1: [],
    listaSottoCategoria2: [],
    Testata: null,
    GruppoSelezionato: null,
  }),

  methods: {
    setCategorie() {
      this.$emit("setCategorie", "", this.Testata);
      this.resetCampi();
    },

    async caricaDati() {
      if (this.dialog == false) return;
      this.listaCategorieCataloghi = [];
      //let res = await get_listaCategorieCataloghi(this.codiceLinea);
      let cat = {
        CodiceLinea: this.codiceLinea,
        CodTestata: this.testataCategorie.codTestata,
        Suffisso: this.testataCategorie.suffisso,
        CodCategoria: this.testataCategorie.codCategoria,
      };
      let res = await get_listaCategorieCataloghi(cat);
      this.listaCategorieCataloghi = res.data;
      //set categorie con valore
      this.Testata = this.testataCategorie;
      let CategoriaSupp = null;
      this.listaCategorieCataloghi.forEach(element => {
        if (element.categoria === true) {
          this.categoria = true;
          this.Categoria = element;
          CategoriaSupp = this.Categoria;
        }
      });
      //set Sotto Categorie
      if (this.Categoria != null) {
        //setto Sotto Categorie
        let sottoCat = {
          CodiceLinea: this.Categoria.codLinea,
          CodTestata: this.testataCategorie.codTestata,
          Suffisso: this.testataCategorie.suffisso,
          CodCategoria: this.Categoria.codCategoria,
          CodSottoCategoria: this.testataCategorie.codSottoCategoria,
        };
        //setto Categoria selezionata
        this.setCategoriaSupp(this.Categoria);
        this.caricaSottoCategorie(sottoCat);
      }
      this.Categoria = CategoriaSupp;
    },

    async caricaSottoCategorie(item) {
      this.listaSottoCategorieCataloghi = [];
      this.listaSottoCategoria1 = [];
      this.listaSottoCategoria2 = [];
      let res = await get_listaSottoCategorieCataloghi(item);
      this.listaSottoCategorieCataloghi = res.data;
      //disabilito le Sotto Categorie con 'sottoCategoria' = false
      let sotCat = this.listaSottoCategorieCataloghi.filter((i) => i.sottoCategoria === true);
      if (sotCat.length > 0) {
        this.sottoCategoria = sotCat[0].sottoCategoria;
        this.SottoCategoria = sotCat[0];
      }

      for (let i = 0; i < this.listaSottoCategorieCataloghi.length; i++) {
        if (i < 5) {
          this.listaSottoCategoria1.push(this.listaSottoCategorieCataloghi[i]);
        }
        else {
          this.listaSottoCategoria2.push(this.listaSottoCategorieCataloghi[i]);
        }
      }
    },
    //CATEGOIRE
    setCategoriaSupp(cat) {
      this.GruppoSelezionato = cat;
      if (this.categoria === true && this.categoria == true) {
        this.Testata.codCategoria = 0;
        this.resetCampiSupp();
      }
      else {
        this.categoria = true;
        this.Categoria = cat;
        cat = {
          CodiceLinea: this.Categoria.codLinea,
          CodTestata: this.testataCategorie.codTestata,
          Suffisso: this.testataCategorie.suffisso,
          CodCategoria: this.Categoria.codCategoria,
          CodSottoCategoria: this.testataCategorie.codSottoCategoria,
        }
        this.caricaSottoCategorie(cat);
      }
    },
    setCategoria(cat) {
      this.GruppoSelezionato = cat;
      if (this.categoria === true && this.categoria == true) {
        this.Testata.codCategoria = 0;
        this.resetCampi();
      }
      else {
        this.categoria = true;
        this.Categoria = cat;
        cat = {
          CodiceLinea: this.Categoria.codLinea,
          CodTestata: this.testataCategorie.codTestata,
          Suffisso: this.testataCategorie.suffisso,
          CodCategoria: this.Categoria.codCategoria,
          CodSottoCategoria: this.testataCategorie.codSottoCategoria,
        }
        this.caricaSottoCategorie(cat);
      }
    },
    //SOTTO CATEGORIE
    setSottoCategoria(cat) {
      if (this.sottoCategoria === true) {
        this.sottoCategoria = false;
        this.SottoCategoria = null;
        this.Testata.codSottoCategoria = 0;
      }
      else {
        this.sottoCategoria = true;
        this.SottoCategoria = cat;
      }
    },

    resetCampiSupp() {
      this.sottoCategoria = false;
      this.SottoCategoria = null;
      this.listaSottoCategorieCataloghi = [];
      this.listaSottoCategoria1 = [];
      this.listaSottoCategoria2 = [];
    },

    resetCampi() {
      this.categoria = false;
      this.Categoria = null;
      this.sottoCategoria = false;
      this.SottoCategoria = null;
      this.listaSottoCategorieCataloghi = [];
      this.listaSottoCategoria1 = [];
      this.listaSottoCategoria2 = [];
      this.GruppoSelezionato = null;
    },

    async conferma() {
      if (this.Categoria != null) {
        this.Testata.codCategoria = this.Categoria.codCategoria;
        this.Testata.codiceLinea = this.Categoria.codLinea;
      } else return;
      if (this.SottoCategoria != null) this.Testata.codSottoCategoria = this.SottoCategoria.codSottoCategoria;

      let res = await set_cataloghi(this.Testata);
      if (res.data == "OK") {
        if (this.Categoria != null) this.Testata.codCategoria = this.Categoria.codCategoria;
        if (this.SottoCategoria != null) this.Testata.codSottoCategoria = this.SottoCategoria.codSottoCategoria;
        this.setCategorie();
      }
    },
  },

  mounted() { },

  watch: {
    dialog() {
      this.caricaDati();
    },
  },
};
</script>

<style scoped>
.outside {
  width: 5vh;
  height: 5vh;
  position: relative;
  display: table;
}

.inside {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: white;
}

.outsideB {
  width: 10vh;
  height: 10vh;
  position: relative;
  display: table;
}
</style>
