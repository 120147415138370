<template>
  <div>

    <v-tabs v-model="tab" align-with-title @change="onTabChange">
      <v-tabs-slider color="primary"></v-tabs-slider>

      <v-tab :key="1" style="font-weight: bold">Invia job</v-tab>
      <v-tab :key="2" style="font-weight: bold"> Posizione assi</v-tab>
      <v-tab :key="3" style="font-weight: bold">Archivio maniglie</v-tab>
      <v-tab :key="4" style="font-weight: bold">Utilità</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="1">
        <v-treeview
          v-model="tree"
          :items="items"
          activatable
          item-key="name"
          open-on-click
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon v-if="!item.file">
              {{ open ? "folder_open" : "folder" }}
            </v-icon>
            <v-icon v-else>
              <!--  {{ files[item.file] }} -->
            </v-icon>
          </template>
        </v-treeview>
      </v-tab-item>

      <v-tab-item :key="2" eager class="pa-4">
        <v-layout column>
          <v-row>
            <v-col><v-text-field outlined label="Asse X"></v-text-field></v-col>
            <!-- </v-row>
          <v-row> -->
            <v-col><v-text-field outlined label="Asse Y"></v-text-field></v-col>
            <!--  </v-row>
          <v-row> -->
            <v-col><v-text-field outlined label="Asse Z"></v-text-field></v-col>
          </v-row>
        </v-layout>

        <v-btn class="mx-4">Home position</v-btn>
      </v-tab-item>

      <v-tab-item :key="3" eager> </v-tab-item>

      <v-tab-item :key="4" eager>
        <div class="pa-4 ma-4">
          <v-btn @click="test()" class="mx-4">Messaggio test</v-btn>
          <v-btn @click="inviaJob()">Invia job test</v-btn>
        </div></v-tab-item
      >
    </v-tabs-items>
  </div>
</template>

<script>
import { set_commandLaser } from "@/api/api.intranet.js";


export default {

  data: () => ({
    tab: 0,

    tree: [],
    items: [
      {
        name: ".git",
      },
      {
        name: "node_modules",
      },
      {
        name: "public",
        children: [
          {
            name: "static",
            children: [
              {
                name: "logo.png",
                file: "png",
              },
            ],
          },
          {
            name: "favicon.ico",
            file: "png",
          },
          {
            name: "index.html",
            file: "html",
          },
        ],
      },
      {
        name: ".gitignore",
        file: "txt",
      },
      {
        name: "babel.config.js",
        file: "js",
      },
      {
        name: "package.json",
        file: "json",
      },
      {
        name: "README.md",
        file: "md",
      },
      {
        name: "vue.config.js",
        file: "js",
      },
      {
        name: "yarn.lock",
        file: "txt",
      },
    ],

  }),

  methods: {
    async inviaJob() {
      try {
        let par = { comando: "LoadJob" };
        let res = await set_commandLaser(par);

        this.$toast.info(res.data);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.error(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async test() {
      try {
        let par = { comando: "Test" };
        let res = await set_commandLaser(par);

        this.$toast.info(res.data);
      } catch (error) {
        console.log(this.$i2kFunctions.getErrorMsg(error));
        this.$toast.error(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    onTabChange() {
      //console.log(this.tab);
      /*   if (this.tab == 1) {
        // NOTA: ho utilizzato la props "eager" per fare il render del contenuto immediatamente
        //       altrimenti la $refs non andrebbe, bisognerebbe attendere il DOM

        console.log(this.$refs.txt1);
      } */
    },

    mounted() {
    },
  },
};
</script>