<template>
    <v-navigation-drawer width="380" fixed app mobile-breakpoint="400" :clipped="$vuetify.breakpoint.mdAndUp" right>
        <v-card-title class="pa-0 ml-1">
            <!--          <v-menu :close-on-content-click="false" v-model="calendar" :nudge-right="40" transition="scale-transition"
                offset-y>
                <template v-slot:activator="{ on }">
                    <v-text-field v-on="on" v-model="dataConsegnaFormat" label="Data di consegna" prepend-icon="event"
                        hide-details style="max-width: 150px !important" />
                </template>
                <v-date-picker v-model="dataConsegna" locale="it-it" first-day-of-week="1" event-color="primary"
                    class="b-date-picker" @input="calendar = false;" hide-details />
            </v-menu>-->

            <v-text-field v-model="settimana" label="Settimana" prepend-icon="event" hide-details
                style="max-width: 150px !important" />

            <v-btn icon dark small color="red" @click="caricaDati()" :disabled="attesa">
                <v-icon>arrow_forward</v-icon>
            </v-btn>

            <v-spacer />
            <!-- 
            <v-btn icon dark small color="red" @click="apriDialog('impostazioniGenerali', '')">
                <v-icon>settings</v-icon>
            </v-btn>
            <v-btn icon dark small color="red" @click="cercaPunto()">
                <v-icon>search</v-icon>
            </v-btn> -->

            <!-- Lista consegne -->
            <v-expansion-panels multiple class="mt-4">
                <v-expansion-panel v-for="(item, i) in giorni" :key="i">
                    <v-expansion-panel-header class="pa-0">
                        <div>

                            <v-checkbox label=" " hide-details style="max-width: 40px; margin-top: 10px; float: left"
                                :value="item" color="black" />

                            <div class="ma-0" style="height: 48px; padding-top: 15px; font-weight: bold">
                                {{ item }} {{ getDataGiorno(item) }}

                                <span style="float: right">
                                    {{ getTotOrdini(item) }}</span>
                            </div>

                            <!-- <div style="padding-left: 5px" v-html="getRiepilogo(item.autista)"></div> -->
                        </div>
                    </v-expansion-panel-header>

                    <v-expansion-panel-content id="innerExPan">
                        <v-card>
                            <v-card-text class="pa-0 pl-0 pb-2">


                                <v-container>
                                    <v-row wrap class="pt-4 pl-4">
                                        <!-- <v-btn icon small dark color="red" class="" @click="caricaDati(item.autista)">
                                            <v-icon>refresh</v-icon>
                                        </v-btn> -->

                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-data-table :headers="headers" :items="getListaOrdini(item)" class="elevation-0"
                                no-data-text="" hide-default-footer hide-default-header item-key="prog" disable-pagination>
                                <template v-slot:item="{ item }">
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td>{{ item.ragioneSociale }} </td>

                                        <!--  
                                            <td style="max-width: 25px !important" class="pa-0">
                                            <v-checkbox primary hide-details :input-value="item.selectedRows"
                                                @change="setRigaSel(item, $event)" class="ma-0"></v-checkbox>
                                        </td>

                                        <td style="width: 23px !important; text-align: left" class="pa-0" :style="{
                                            backgroundColor: item.scaricato ? '#32CD32' : '',
                                        }">
                                            {{ item.prog == -1 ? "" : item.prog }}
                                        </td>

                                        <td style="width: 50px !important" class="pa-0">
                                            {{ $functionsApp.getTimeFromDate(item.tempoPrevisto) }}
                                            <br />{{
                                                item.dataScarico
                                                ? $functionsApp.getTimeFromDate(item.dataScarico)
                                                : ""
                                            }}
                                        </td>

                                        <td style="height: 28px" class="pa-0" :style="{
                                                    backgroundColor: item.flagRitiro ? 'LightBlue' : '',
                                                }">
                                            <span style="font-weight: bold">{{
                                                getRagSoc(
                                                    item.cdAnagrafica,
                                                    item.ragioneSociale,
                                                    item.prog
                                                )
                                            }}</span><br />{{ item.localita }}
                                        </td>

                                        <td style="width: 50px !important; text-align: right" class="pa-0">
                                            {{ toMinuteString(item.totTempoConsegna) }}
                                        </td> -->
                                    </tr>
                                </template>
                            </v-data-table>

                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>


        </v-card-title>
    </v-navigation-drawer>
</template>


<script>

export default {
    props: {
        dati: { type: Array, default: () => [] },
        attesa: { type: Boolean, default: false },

    },

    data: () => ({
        headers: [
            {
                text: "Prog",
                align: "left",
                sortable: false,
                value: "prog",
            },
            {
                text: "Tempo",
                align: "left",
                sortable: false,
                value: "TempoPrevisto",
            },
            {
                text: "Rag.Soc",
                align: "left",
                sortable: false,
                value: "ragioneSociale",
            },
        ],

        giorni: ["lunedì", "martedì", "mercoledì", "giovedì", "venerdì"],

        settimana: 0
    }),


    methods: {

        caricaDati() {
            this.$emit("caricaDati", this.settimana);


        },

        getListaOrdini(giorno) {
            if (this.dati.length === 0) return;

            let res = [];

            // Copio l'array
            let arr = JSON.parse(JSON.stringify(this.dati));
            for (let key in arr) {
                res.push(arr[key]);
            }

            // Filtro per autista
            res = res.filter((f) => f.giorno.toLowerCase() == giorno.toLowerCase());

            return res

        },

        getTotOrdini(giorno) {
            return this.dati.filter((f) => f.giorno == giorno).length;
        },

        getDataGiorno(giorno) {
            let res = this.dati.find((f) => f.giorno == giorno);

            return res ? this.$i2kFunctions.formatDate_IT(new Date(res.dtGiorno)) : ''
        }

    },


    mounted() {
        this.settimana = this.$i2kFunctions.getWeek(new Date())
    }
}

</script>