<template>
  <v-dialog scrollable v-model="dialogComputed" width="400px">
    <v-card tile>
      <v-toolbar light dense flat>
        <v-toolbar-title>Orari</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>

        <v-select v-model="orarioSel" :items="orari" label="Tipo orario" :loading="attesa" />

        <v-text-field label="Ora passaggio" style="width: 100px" outline v-model="oraPassaggio" mask="##:##"
          :return-masked-value="true" />
      </v-card-text>
      
      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="closeDialog()">Annulla</v-btn>
        <v-btn class="btn-conferma" @click.native="conferma()">Conferma</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import { get_consegneOrari } from "@/api/api.intranet.js";

export default {
  props: {
    dialog: { type: Boolean },
    autista: { type: String },
    dataConsegna: { type: String },
    codAnag: { type: String },
  },
  data() {
    return {
      orarioSel: "",
      orari: ["", "Mattina", "Pomeriggio"],
      oraPassaggio: "",

      attesa: false,
    };
  },

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      this.attesa = true

      try {

        let res = await get_consegneOrari(this.dataConsegna + "/" + this.autista + "/" + this.codAnag)
        this.orarioSel = res.data.passaggio
        this.oraPassaggio = res.data.oraPassaggio

        if (this.oraPassaggio)
          this.oraPassaggio = this.oraPassaggio.toString().substr(11, 5)
        else
          this.oraPassaggio = "00:00";


      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false

    },

    conferma() {

      this.$emit("impostaOrario", this.orarioSel, this.oraPassaggio);

      this.closeDialog();
    },

    closeDialog() {
      this.$emit("chiudiDialog");
    },
  },
  mounted() {
    this.caricaDati();
  },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },

  watch: {
    dialog(v) {
      if (v) {
        this.caricaDati();
      }
    },
  },
};
</script>