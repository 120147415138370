<template src="./template.html"></template>

<script>
export default {
    data: () => ({
        headers: [
            {
                text: 'Dessert (100g serving)',
                align: 'start',
                sortable: false,
                value: 'name',
            },
            { text: 'Calories', value: 'calories' },
            { text: 'Fat (g)', value: 'fat' },
            { text: 'Carbs (g)', value: 'carbs' },
            { text: 'Protein (g)', value: 'protein' },
            { text: 'Iron (%)', value: 'iron' },
        ],
        desserts: [
            {
                name: 'Ice cream sandwich',
                calories: 200,
                fat: 9.0,
                carbs: 37,
                protein: 4.3,
                iron: 1,
            },
            {
                name: 'Gingerbread',
                calories: 400,
                fat: 16.0,
                carbs: 49,
                protein: 3.9,
                iron: 16,
            },
            {
                name: 'Jelly bean',
                calories: 400,
                fat: 0.0,
                carbs: 94,
                protein: 0.0,
                iron: 0,
            },
            {
                name: 'Lollipop',
                calories: 400,
                fat: 0.2,
                carbs: 98,
                protein: 0,
                iron: 2,
            },
            {
                name: 'Honeycomb',
                calories: 400,
                fat: 3.2,
                carbs: 87,
                protein: 6.5,
                iron: 45,
            },
            {
                name: 'Donut',
                calories: 500,
                fat: 25.0,
                carbs: 51,
                protein: 4.9,
                iron: 22,
            },
            {
                name: 'KitKat',
                calories: 500,
                fat: 26.0,
                carbs: 65,
                protein: 7,
                iron: 6,
            },
        ],

        dialog1: false,
        dialog2: false,
        dialog3: false,
    }),

    methods: {
    },
}
</script>