<template>
  <v-dialog v-model="dialog" width="800">
    <v-card width="800" :loading="attesa" class="pa-2">
      <v-text-field outlined v-model="descrizione" ref="input"></v-text-field>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text light @click="chiudi()">Annulla</v-btn>
        <v-btn color="primary" text light @click="conferma()" :disabled="attesa">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { update_descrizioneCatalogo } from "@/api/api.catalogo.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    tipo: { type: String, default: "" },
    dati: { type: Object, default: () => ({}) },
  },

  data: () => ({
    descrizione: "",

    attesa: false,
  }),

  methods: {
    caricaDati() {
      // Reset
      this.descrizione = "";

      if (!this.dialog) return;

      setTimeout(() => this.$refs.input.focus(), 500);

      if (this.tipo == "ubicazione") this.descrizione = this.dati.ubicazione;
      else if (this.tipo == "ubicazione2") this.descrizione = this.dati.ubicazione2;
      else this.descrizione = this.dati.descrizione;
    },

    async conferma() {
      this.attesa = true;

      try {
        let data = Object.assign({}, this.dati);

        if (this.tipo == "articolo") {
          data.descrizione = this.descrizione;
        } else if (this.tipo == "testata") {
          data.descrizione = this.descrizione;
        } else if (this.tipo == "ubicazione" || this.tipo == "ubicazione2") {
          data.ubicazione = this.descrizione;
        }
        let res = await update_descrizioneCatalogo(this.tipo, data);
      } catch (error) {
        console.log(error);
      }

      this.attesa = false;

      this.chiudi(true);
    },

    chiudi(aggiorna) {
      this.$emit("chiudi", aggiorna);
    },
  },

  mounted() {
    this.caricaDati();
  },

  watch: {
    dialog() {
      this.caricaDati();
    },
  },
};
</script>
