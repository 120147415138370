import request from '@/services/interceptor.js'



// --------------------
// News
// --------------------
export const get_news = (par) => {
    return request({
        url: '/news/' + par,
        method: 'get',
    })
};
