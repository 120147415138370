<template>
    <v-dialog scrollable v-model="dialogComputed" width="400px">
        <v-card tile :loading="attesa">
            <v-toolbar light dense flat>
                <v-toolbar-title>Impostazioni</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="closeDialog()">
                    <v-icon>close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-switch label="Gps" hide-details v-model="gps" />
                        </v-col>

                        <v-col>
                            <v-select label="Software" :items="softwareArr" v-model="software" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click.native="closeDialog()">Annulla</v-btn>
                <v-btn class="btn-conferma" @click.native="conferma()">Conferma</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

import {
    get_consegneImpostazioni,
    set_consegneImpostazioni,
} from "@/api/api.intranet.js";


export default {
    props: {
        dialog: { type: Boolean },
        dataConsegna: { type: String },
    },

    data() {
        return {
            aggiorna: false,
            attesa: false,
            gps: false,
            softwareArr: ["TomTom", "Verso"],
            software: ''

        };
    },

    methods: {

        async caricaDati() {

            // Reset
            this.aggiorna = false;


            if (this.dialog == false) return;

            this.attesa = true

            try {
                let res = await get_consegneImpostazioni("generali/" + this.dataConsegna);
                this.gps = res.data.gps
                this.software = res.data.softwareElaborazione;

            } catch (error) {
                this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
            }

            this.attesa = false
        },

        async conferma() {

            try {
                let par = {
                    softwareElaborazione: this.software,
                    dtConsegna: this.dataConsegna,
                    gps: this.gps,
                }
                let res = await set_consegneImpostazioni('generali', par);

            } catch (error) {
                this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
            }

            this.closeDialog(true);
        },

        closeDialog(aggiorna) {
            this.$emit("chiudiDialog", aggiorna);
        },
    },

    mounted() {
        this.caricaDati();
    },

    watch: {
        dialog(v) {
            if (v) {
                this.caricaDati();
            }
        },
    },
    computed: {
        dialogComputed: {
            get() {
                return this.dialog;
            },
            set(value) {
                this.closeDialog();
            },
        },
    },

}

</script>