<template>
  <v-container fluid>
    <v-layout justify-center column>
      <v-card>
        <v-row class="mt-1">
          <!-- Refresh -->
          <div class="ml-5">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn @click="caricaDati()" text fab small v-on="on" elevation="0" class="mr-5 mt-2">
                  <v-icon color="primary">refresh</v-icon>
                </v-btn>
              </template>
              <span>Ricarica</span>
            </v-tooltip>
          </div>
          <!-- Data Inizio-->
          <div id="dataPicker">
            <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field color="primary" v-model="dateFormattedInizio" label="Data Inizio" append-icon="edit"
                  v-bind="attrs" @blur="dateInizio = parseDate(dateFormattedInizio)" v-on="on"></v-text-field>
              </template>
              <v-date-picker color="primary" v-model="dateInizio" @input="menu1 = false"></v-date-picker>
            </v-menu>
          </div>
          <!-- Data Fine-->
          <div id="dataPicker" class="ml-5">
            <v-menu ref="menu2" v-model="menu2" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field color="primary" v-model="dateFormattedFine" label="Data Fine" append-icon="edit"
                  v-bind="attrs" @blur="dateFine = parseDate(dateFormattedFine)" v-on="on"></v-text-field>
              </template>
              <v-date-picker color="primary" v-model="dateFine" @input="menu2 = false"></v-date-picker>
            </v-menu>
          </div>

          <v-spacer></v-spacer>

          <!-- Ricerca -->
          <div class="mr-5">
            <v-text-field style="width: 500px !important" color="primary" v-model="search" append-icon="search"
              label="Cerca"></v-text-field>
          </div>
        </v-row>
      </v-card>



      <!-- Tabella EMail -->
      <v-data-table :search="search" class="mt-5" dense :headers="headersTabella" fixed-header :items="pagineScorri"
        :page.sync="page" :items-per-page="pagineScorri.length" hide-default-header hide-default-footer
        loading-text="Caricamento dati..." height="calc(100vh - 200px)" no-data-text="Nessun dato disponibile">
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th id="headerTabella" v-for="(head, i) in headers" :key="i">
                <span>{{ head.text }}</span>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td id="allineamentoTestoColonna">{{ item.progressivo }}</td>
            <td>{{ item.dataDocumento.substr(0, 10) }}</td>
            <td>{{ item.tipoDocumento }}</td>
            <td id="allineamentoTestoColonna">{{ item.numeroDocumento }}</td>
            <td id="allineamentoTestoColonna">{{ item.cdAnagrafica }}</td>
            <td>{{ item.ragioneSociale }}</td>
            <td>{{ item.eMail }}</td>
            <td>
              <v-icon v-if="item.stato == 'OK'" color="green">done</v-icon>
              <v-icon v-else color="red">close</v-icon>
            </td>
          </tr>
        </template>

        <!-- Footer aggiuntivo -->
        <template v-slot:footer>
          <div> Tot. Record: <b>{{ pagineScorri.length }}</b></div>
        </template>
      </v-data-table>

    </v-layout>
  </v-container>
</template>

<script>
import { get_emailInviate } from "@/api/api.intranet.js";

export default {
  name: "emailInviate",


  data: (vm) => ({
    dateInizio: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFine: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    dateFormattedInizio: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    dateFormattedFine: vm.formatDate(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ),
    menu1: false,
    menu2: false,

    search: "",
    page: 1,
    headersTabella: [
      { text: "Progressivo", value: "progressivo" },
      { text: "Data Documento", value: "dataDocumento" },
      { text: "Tipo Documento", value: "tipoDocumento" },
      { text: "N° Documento", value: "numeroDocumento" },
      { text: "Cod. Anagrafica", value: "cdAnagrafica" },
      { text: "Rag. Sociale", value: "ragioneSociale" },
      { text: "EMail", value: "eMail" },
      { text: "Stato", value: "stato" },
    ],

    FiltriDate: { DataInizio: "", DataFine: "" },

    pagineScorri: [],
  }),

  methods: {

    async caricaDati() {

      // Reset
      this.pagineScorri = []

      this.FiltriDate = { DataInizio: this.dateInizio, DataFine: this.dateFine };

      if (this.FiltriDate.DataInizio <= this.FiltriDate.DataFine) {
        let res = await get_emailInviate(this.FiltriDate);
        this.pagineScorri = res.data;

      } else this.$toast.error("Intervallo date non valido!");


    },


    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },



  },


  mounted() {
    this.caricaDati();
  },

  watch: {
    dateInizio() {
      this.dateFormattedInizio = this.formatDate(this.dateInizio);
      this.caricaDati();
    },

    dateFine() {
      this.dateFormattedFine = this.formatDate(this.dateFine);
      this.caricaDati();
    },
  },

  computed: {
    computedDateFormattedInizio() {
      return this.formatDate(this.dateInizio);
    },

    computedDateFormattedFine() {
      return this.formatDate(this.dateFine);
    },

  },
};
</script>

<style scoped>
#headerTabella {
  height: 50px;
  font-size: 14px;
  background-color: #e40520;
  color: white;
}

#allineamentoTestoColonna {
  text-align: end;
}

#dataPicker {
  width: 160px;
}
</style>
