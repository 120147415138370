var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"height":"60px","background-color":"#f5f5f5"}}),_c('v-data-table',{attrs:{"items":_vm.dati,"headers":_vm.headers,"height":"350","disable-pagination":"","hide-default-footer":"","item-key":"cdFiglio","show-expand":"","single-expand":"","dense":""},on:{"item-expanded":function($event){_vm.aggiornaDatiFor = !_vm.aggiornaDatiFor}},scopedSlots:_vm._u([{key:"item.cdFiglio",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pr-0",domProps:{"innerHTML":_vm._s(_vm.$functionsApp.getCodArticoloFormat(item.cdFiglio))}})]}},{key:"item.descrizione",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pr-0",domProps:{"innerHTML":_vm._s(_vm.$functionsApp.convertiTestoGrassetto(item.descrizione))}})]}},{key:"item.prezzoVendita",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pr-0",staticStyle:{"text-align":"right"},domProps:{"textContent":_vm._s('€ ' + item.prezzoVendita)}})]}},{key:"item.qtaDisponibile",fn:function(ref){
var item = ref.item;
return [(_vm.group == 'local')?_c('td',{staticClass:"pr-0",staticStyle:{"text-align":"right"},domProps:{"textContent":_vm._s(item.qtaDisponibile)}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-4",staticStyle:{"background-color":"grey"},attrs:{"colspan":headers.length}},[_c('dialogOrdinato',{staticClass:"mb-4 mx-4",staticStyle:{"height":"240px"},attrs:{"cdArticolo":item.cdFiglio,"aggiorna":_vm.aggiornaDatiFor,"tipo":'fornitore',"altezzaGrid":"calc(20vh)"}})],1)]}}])}),_c('v-footer',{staticClass:"pa-2",attrs:{"app":"","color":"#fff","elevation":"2"}},[_c('v-container',{staticClass:"ma-0 py-0",staticStyle:{"background-color":"#fff","bottom":"0px"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.attivaTabInfo()}}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"display":"inline-flex"},attrs:{"left":""}},[_vm._v("shopping_cart")]),_vm._v(" Ordina ")],1)],1),_c('v-col',{staticClass:"mt-2",staticStyle:{"text-align":"right"},attrs:{"cols":"10"}},[_vm._v(" Totale:"),_c('span',{staticClass:"primary--text mr-4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.getTotale))]),_vm._v(" Totale scontato: "),_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.getTotaleScontato))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }