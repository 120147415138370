<template>
    <div>
        <!-- Filtri -->
        <FilterOrdiniPrev ref="filtro" @caricaDati="caricaDati" :dati="dati" :attesa="attesa">
        </FilterOrdiniPrev>

        <!-- Mappa -->
        <!--  <div v-show="!attesa" class="google-map" :id="mapName"></div> -->



    </div>
</template>

<script>
import FilterOrdiniPrev from "@/components/intranet/FilterOrdiniPreparaz.vue";

import {
    get_ordiniPreparazione
} from "@/api/api.intranet.js";

export default {
    components: {
        FilterOrdiniPrev
    },
    data: () => ({
        dati: [],
        attesa: false,
    }),

    methods: {
        async caricaDati(sett) {
            if (this.attesa) return;

            // Reset
            this.dati = [];
            // this.$refs.filtro.pulisci();

            this.attesa = true;

            //   this.dataSel = data;

            // Ricavo i dati
            try {
                let resp = await get_ordiniPreparazione(sett);
                this.dati = resp.data;

                /*         // Autisti
                        for (let index = 0; index < this.dati.length; index++) {
                          this.autisti.push(this.dati[index].autista);
                        }
                
                        // Impostazioni generali
                        let resImpostaz = await get_consegneImpostazioni("generali");
                        this.impostazioni = resImpostaz.data; */


                // Mappa
                //this.caricaMappa();
            } catch (error) {
                this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
            }

            this.attesa = false;
        },
    }
}
</script>