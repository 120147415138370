// Debug 
const url_debug = 'http://localhost:63170';    // Localhost
//const url_debug = "https://www.battisti.cloud"   // Internet
//const url_debug = "http://192.168.90.204:2082"    // intranet
//const url_debug = "http://www.battisti.cloud:2082" // internet Test


const GOOGLE_API_KEY = 'AIzaSyCjIpVF-K9a0LsbhCXnkeWMowWmP-8iFHQ';
const url_kml = 'https://www.battisti.cloud/userfiles/public/kml'

export default {

  getUrl() {
    if (window.location.host.includes("localhost"))
      return url_debug

    return window.location.protocol + "//" + window.location.host
  },

  getUrlApi() {

    let url = this.getUrl();

    if (url.includes("localhost"))
      url += "/api"
    else
      url += "/api/api"

    return url
  },


  getUrl_Update() {

    let url = this.getUrlApi();

    if (window.location.host.includes("localhost"))
      url = ""
    else
      url += "/utilita/update"

    return url
  },

  getGoogleKey() {
    return GOOGLE_API_KEY
  },

  getUrl_Kml() {
    return url_kml
  }
}