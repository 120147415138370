var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-0",staticStyle:{"background-color":"white"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-toolbar',{staticStyle:{"background-color":"#f5f5f5"},attrs:{"dense":"","height":"60","flat":"","width":"100%"}},[_vm._l((_vm.$i2kFunctions.groupByIndex(
            _vm.opzioniDistinta,
            'raggruppamento'
          )),function(item,i){return _c('v-toolbar-items',{key:i},[_c('v-menu',{attrs:{"bottom":"","offset-y":"","close-on-click":false,"close-on-content-click":false,"open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"no-uppercase",staticStyle:{"color":"grey","font-weight":"bold"},attrs:{"text":"","disabled":_vm.attesa}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.key)+" ")])]}}],null,true)},_vm._l((_vm.opzioniDistinta.filter(
                function (f) { return f.raggruppamento == item.key; }
              )),function(valori,i){return _c('v-list',{key:i},[_c('v-list-item',{attrs:{"dense":"","link":""}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.showDialogOpzioni(valori)}}},[_c('v-list-item-title',[_vm._v(_vm._s(valori.idOpzione)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.getValoreSelezionatoOpzioni(valori.idOpzione))+" ")])],1)],1)],1)}),1)],1)}),_c('v-spacer'),_c('span',{staticStyle:{"color":"#ea4155"}},[_vm._v("Disegno")]),_c('v-btn',{staticClass:"ml-1",attrs:{"light":"","color":"primary","small":"","fab":""},on:{"click":function($event){_vm.showDisegno = !_vm.showDisegno}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showDisegno ? "expand_less" : "expand_more"))])],1)],2)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","id":"divContenitore"}},[_c('div',{style:({ height: _vm.getHeightSvg })},[_c('object',{staticStyle:{"width":"100%","height":"100%"},attrs:{"id":"svg1","data":_vm.distinta.urlSvg,"type":"image/svg+xml"}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.attesa),expression:"attesa"}],staticStyle:{"position":"absolute","top":"50%","left":"50%","margin":"auto"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDisegno),expression:"showDisegno"}]},[_c('v-data-table',{attrs:{"items":_vm.articoli,"hide-default-header":"","no-data-text":"","disable-pagination":"","hide-default-footer":"","fixed-header":"","dense":"","headers":_vm.headersComponenti,"disable-sort":"","height":"calc(100vh - 330px)","show-expand":_vm.role == 2,"single-expand":"","item-key":"prog"},on:{"item-expanded":function($event){_vm.aggiornaDatiFor = !_vm.aggiornaDatiFor}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
              var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(head,i){return _c('th',{key:i,on:{"click":function($event){head.sortable ? _vm.changeSort(head.value) : ''}}},[_c('span',{domProps:{"innerHTML":_vm._s(head.text)}})])}),0)])]}},{key:"item",fn:function(ref){
              var item = ref.item;
              var expand = ref.expand;
              var isExpanded = ref.isExpanded;
return [_c('tr',{attrs:{"id":item.codiceUtente}},[_c('td',{staticClass:"pa-0",staticStyle:{"width":"50px"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(_vm._s(isExpanded ? "keyboard_arrow_down" : "keyboard_arrow_right"))])],1)],1),_c('td',{attrs:{"title":"Codice Articolo"}},[_vm._v(" "+_vm._s(_vm.$functionsApp.getCodArticoloFormat(item.codiceUtente))+" ")]),_c('td',{attrs:{"title":"Descrizione"}},[_vm._v(" "+_vm._s(item.descrizione.replaceAll('"', ""))+" ")]),_c('td',{attrs:{"title":"Unità di misura"}},[_vm._v(" "+_vm._s(item.unMisura)+" ")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"title":"Quantità"}},[_vm._v(" "+_vm._s(item.cfImpVis)+" ")]),_c('td',{staticStyle:{"min-width":"90px","text-align":"right"},attrs:{"title":"Prezzo"}},[_vm._v(" "+_vm._s("€ " + item.prezzo)+" ")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"title":"Sconto"}},[_vm._v(" "+_vm._s(item.sconti)+" ")]),_c('td',{staticStyle:{"text-align":"right"},attrs:{"title":"Qtà scaffale"}},[_vm._v(" "+_vm._s(_vm.role == 1 ? "x" : item.qtaScaffale))])])]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{staticClass:"py-4 px-4",staticStyle:{"background-color":"grey"},attrs:{"colspan":headers.length}},[_c('dialogOrdinato',{staticStyle:{"height":"340px"},attrs:{"cdArticolo":item.codiceUtente,"aggiorna":_vm.aggiornaDatiFor,"tipo":'fornitore',"altezzaGrid":"calc(30vh)"}})],1)]}}])})],1)])],1)],1),_c('tipoSerrDialog',{attrs:{"dialog":_vm.dialogTipoSerr,"cdArticolo":_vm.cdArticolo},on:{"chiudi":_vm.tipoSerrSelezionato}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialogOpzioni),callback:function ($$v) {_vm.dialogOpzioni=$$v},expression:"dialogOpzioni"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(_vm.idOpzioneSelezionata.idOpzione)+" "),_c('v-icon',{staticStyle:{"float":"right"},on:{"click":function($event){_vm.dialogOpzioni = false}}},[_vm._v("close")])],1)]),_c('v-divider'),_c('v-list',_vm._l((_vm.getValoriOpzioni(
          _vm.idOpzioneSelezionata.idOpzione
        )),function(item,i){return _c('v-list-item',{key:i,class:{
          primary:
            _vm.getValoreSelezionatoOpzioni(_vm.idOpzioneSelezionata.idOpzione) ==
            item,
          'white--text':
            _vm.getValoreSelezionatoOpzioni(_vm.idOpzioneSelezionata.idOpzione) ==
            item,
        },attrs:{"link":""}},[_c('v-list-item-content',{on:{"click":function($event){return _vm.setOpzioni(_vm.idOpzioneSelezionata.idOpzione, item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item))])],1)],1)}),1)],1)],1),_c('v-footer',{staticClass:"pa-2",attrs:{"app":"","color":"#fff","elevation":"2"}},[_c('v-container',{staticClass:"ma-0 py-0",staticStyle:{"background-color":"#fff"},attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.attivaTabInfo()}}},[_c('v-icon',{staticClass:"mr-2",staticStyle:{"display":"inline-flex"},attrs:{"left":""}},[_vm._v("shopping_cart")]),_vm._v(" Ordina ")],1),_c('v-btn',{staticClass:"ml-1",attrs:{"color":"primary","small":"","right":""},on:{"click":function($event){return _vm.elaboraDistinta()}}},[_c('v-icon',{staticStyle:{"display":"inline-flex"}},[_vm._v("refresh")])],1)],1),_c('v-col',{staticClass:"mt-2",staticStyle:{"text-align":"right"},attrs:{"cols":"7"}},[_vm._v(" Totale:"),_c('span',{staticClass:"primary--text mr-4 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.distinta.totale.toLocaleString("it-IT", { style: "currency", currency: "EUR", })))]),_vm._v(" Sconti:"),_c('span',{staticClass:"primary--text mr-4 font-weight-bold"},[_vm._v(_vm._s(_vm.distinta.sconto1.toString() + "+" + _vm.distinta.sconto2.toString()))]),_vm._v(" Tot.Generale: "),_c('span',{staticClass:"primary--text font-weight-bold"},[_vm._v(_vm._s(_vm.distinta.totaleGenerale.toLocaleString("it-IT", { style: "currency", currency: "EUR", })))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }