<template>
  <div class="item">
    <div style="height: 20px;">
      <!-- Logo azienda -->
      <div style="float: right;">
        <img :src="supplierImagePath" alt="" class="supplier-image" />
      </div>

      <!-- Pallini obsoleto\fuori cat\eliminato-->
      <div class="pallinoInfoProdotti" style="float: right; left:230px; height: 18px;  width: 18px !important;"
        :style="getColore()">
      </div>
    </div>


    <!-- Immagine prodotto -->
    <div class="product-preview__square" :style="{ backgroundImage: 'url(\'' + productImagePath + '\')' }"></div>



    <!-- Testata + descrizione -->
    <div class="prod-description">
      <p class="prod-description__code" :title="role == 2 ? 'Suffisso: ' + testata.suffisso.toString() : ''">
        {{
          testata.codTestata.substring(0, 3) +
          " " +
          testata.codTestata.substring(3)
        }}
      </p>
      <p style="margin-bottom: 0px!important;" v-html="$functionsApp.convertiTestoGrassetto(testata.descrizione)"></p>
    </div>

  </div>
</template>

<script>

export default {
  name: "product-chip",

  props: {
    productImagePath: { type: String, default: "" },
    supplierImagePath: { type: String, default: "" },
    testata: { type: Object, default: () => ({}) },
    auth: { type: Boolean, default: false },
    role: { type: Number, default: 0 },
    group: { type: String, default: "" },
  },



  data: () => ({
    floatingModal: {
      transformStyle: { transform: "" },
      isPermanent: false,
      isHover: false,
    },
  }),

  methods: {
    getColore() {
      if (this.role != 2 && this.role != 4) return "";

      let col = this.$functionsApp.getColoreCatalogo(
        this.testata.articoli_Obsoleti,
        this.testata.articoli_Eliminati,
        this.testata.articoli_NonStampCatalogo,
        this.testata.disattivaInternet
      );

      return {
        backgroundColor: col,
      };
    },
  },

};
</script>

<style scoped>
.item {
  height: 100%;
  user-select: none;
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 0;
  min-width: 180px;
  width: 100%;
  border-bottom: 2px solid #EEEEEE;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
  cursor: pointer;
}

.item .product-preview__square {
  width: 50%;
  padding-top: 100% !important;
  background-size: contain;
  background-position: center center;
  margin-top: 30px;
}

.item .product-preview {
  max-width: 50%;
}

.item .supplier-image {
  height: 20px;
  width: auto;
  margin-top: -8px;

}

.item .prod-description {
  width: 50%;
  flex-direction: column;
  justify-self: space-around;
}

.item .prod-description .prod-description__code,
.item .prod-description .prod-description__caption {
  margin: 0;
}

.item .prod-description .prod-description__code {
  margin-top: 25px;
  font-weight: bold;
  color: #ff0017;
}

.checkbox-select {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}

@media screen and (min-width: 600px) {
  .item {
    flex-direction: column;
    position: relative;
    flex-grow: 1;
  }

  .item .product-preview__square {
    width: 100%;
    padding-top: 100%;
  }

  .item .product-preview {
    width: 180px;
    height: 180px;
  }

  .item .supplier-image {
    position: absolute;
    top: 0.5em;
    left: 0.5em;
  }

  .item .prod-description {
    width: 100%;
  }

  .item .prod-description .prod-description__code,
  .item .prod-description .prod-description__caption {
    margin: 0;
  }

  .item .prod-description .prod-description__caption {
    font-size: 1.3em;
  }


}
</style>
