<template>
  <v-container>
    <v-tabs v-model="activeTab">
      <v-tab :key="1" @click="initGeneraEti()"> Creazione spedizione </v-tab>
      <v-tab :key="2" @click="caricaElencoSpedizioni()">
        Elenco spedizioni
      </v-tab>
      <v-tab :key="3" v-if="role == 2" @click="caricaErrori()">
        Gestione errori
      </v-tab>
    </v-tabs>

    <v-tabs-items :value="activeTab" touchless>
      <v-tab-item :key="1">
        <genera-etichette :refresh="caricaDatiGenEti" />
      </v-tab-item>

      <v-tab-item :key="2">
        <elenco-spedizioni :refresh="caricaElencoSped" />
      </v-tab-item>

      <v-tab-item :key="3">
        <gestione-errori :caricaDati="caricaDatiErrori" />
      </v-tab-item>

    </v-tabs-items>
  </v-container>
</template>

<script>
import mixinSped from "@/views/intranet/etichette/mixinEtichette.js";

import GeneraEtichette from "@/views/intranet/etichette/GeneraEtichette.vue";
import GestioneErrori from "@/views/intranet/etichette/GestioneErrori.vue";
import ElencoSpedizioni from "@/views/intranet/etichette/ElencoSpedizioni.vue";

export default {
  mixins: [mixinSped],
  components: {
    GeneraEtichette,
    GestioneErrori,
    ElencoSpedizioni,
  },

  data: () => ({
    activeTab: 0,

    caricaDatiGenEti: false,
    caricaDatiErrori: false,
    caricaElencoSped: false,

    label: "",
  }),

  methods: {
    // elenco spedizioni (tab 2)
    initGeneraEti() {
      this.caricaDatiGenEti = !this.caricaDatiGenEti;
    },

    // elenco spedizioni (tab 2)
    caricaElencoSpedizioni() {
      this.caricaElencoSped = !this.caricaElencoSped;
    },
    // elenco errori (tab 3)
    caricaErrori() {
      this.caricaDatiErrori = !this.caricaDatiErrori;
    },
  },

  mounted() {
    this.initGeneraEti();
  },

  computed: {
    role() {
      return this.$store.getters["user/getRole"];
    },
  },
};
</script>