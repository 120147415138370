<template>
    <v-dialog v-model="dialog" width="60%" style="min-height: 100% !important" v-if="dialog" persistent>
        <v-card>
            <v-card-title>
                <v-btn v-tooltip.bottom="'Elimina'" color="primary" hide-details text fab small elevation="0"
                    v-on:click="eliminaReferente(ReferenteDett)">
                    <v-icon>delete</v-icon>
                </v-btn>

                <h3 v-if="ReferenteDett != null">
                    Dati Referente: {{ NominativoRef.nome }} {{ NominativoRef.cognome }}
                </h3>
                <h3 v-else>Dati Referente:</h3>

                <v-spacer></v-spacer>
                <v-btn text icon elevation="0" @click="nuovoReferente">
                    <v-icon> close </v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
                <v-row no-gutters class="mt-5">
                    <v-col cols="3">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.nome" label="Nome referente:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.cognome"
                            label="Cognome referente:" validate-on-blur>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.mansione" label="Mansione:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.stato" label="Stato:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-2">
                    <v-col cols="5">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.indirizzo" label="Indirizzo:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                    <v-col cols="1">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.cap" label="Cap:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                    <v-col cols="5">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.localita" label="Località:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                    <v-col cols="1">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.provincia" label="Provincia:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-2">
                    <v-col cols="3">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.telefono" label="Telefono:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                    <v-col cols="3">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.cell" label="Cell:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field class="ma-1 pa-1" hide-details v-model="ReferenteDett.mail" label="Mail:"
                            validate-on-blur>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters class="mt-2">
                    <v-col cols="12">
                        <v-textarea class="ma-1 pa-1" no-resize rows="5" hide-details label="Note:"
                            v-model="ReferenteDett.note"></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-btn small id="buttonAnnulla" hide-details class="mr-1" @click="nuovoReferente">ANNULLA</v-btn>
                    <v-btn small id="buttonConferma" hide-details class="btn-conferma" @click="salva">CONFERMA</v-btn>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import { salva_referente, elimina_referente } from "@/api/api.intranet.js";

export default {
    props: {
        dialogReferente: { type: Boolean, default: false },
        referenteSel: { type: Object, default: null },
        NominativoRef: { type: Object, default: null },
    },

    components: {},

    data: () => ({
        dialog: false,
        ReferenteDett: null,
    }),

    methods: {
        nuovoReferente() {
            this.$emit("nuovoReferente");
        },

        async salva() {
            //recupero cdAnagrafica cliente
            let cliente = this.$store.getters["app/getCliente"];
            this.ReferenteDett.cdAnagrafica = cliente.cdAnagrafica;
            let res = await salva_referente(this.ReferenteDett);
            if (res.data == "OK") {
                this.$toast.info("Dati salvati correttamente");
                //carico dati aggiornati
                this.$emit("addNuovoReferente", this.ReferenteDett, 0);
            } else this.$toast.alert("Errore nel salvataggio. Riprovare");
        },

        async eliminaReferente(referente) {
            let confirm = await this.$confirm.open(
                "Elimina",
                "Eliminare il referente?"
            );

            if (!confirm) return;
            else {
                let res = await elimina_referente(referente);
                if (res.data == "OK") {
                    this.$toast.info("Referente eliminato");
                    //carico dati aggiornati
                    this.$emit("addNuovoReferente", this.ReferenteDett, 1);
                    this.nuovoReferente();
                }
                else
                    if (res.data != null) this.$toast.alert(res.data);
                    else this.$toast.alert("Errore nel salvataggio. Riprovare");
            }
        },
    },

    watch: {
        dialogReferente() {
            this.dialog = this.dialogReferente;
        },

        referenteSel() {
            this.ReferenteDett = this.referenteSel;
        }
    },
};
</script>