<template>
  <v-navigation-drawer width="380" fixed app mobile-breakpoint="400" :clipped="$vuetify.breakpoint.mdAndUp" right>
    <v-card-title class="pa-0 ml-1">
      <v-menu :close-on-content-click="false" v-model="calendar" :nudge-right="40" transition="scale-transition" offset-y>
        <template v-slot:activator="{ on }">
          <v-text-field v-on="on" v-model="dataConsegnaFormat" label="Data di consegna" prepend-icon="event" hide-details
            style="max-width: 150px !important" />
        </template>
        <v-date-picker v-model="dataConsegna" locale="it-it" first-day-of-week="1" event-color="primary"
          class="b-date-picker" @input="calendar = false;" hide-details />
      </v-menu>

      <v-btn icon dark small color="red" @click="caricaDati()" :disabled="attesa">
        <v-icon>arrow_forward</v-icon>
      </v-btn>

      <v-spacer />

      <v-btn icon dark small color="red" @click="apriDialog('impostazioniGenerali', '')">
        <v-icon>settings</v-icon>
      </v-btn>
      <v-btn icon dark small color="red" @click="cercaPunto()">
        <v-icon>search</v-icon>
      </v-btn>

      <!-- Lista consegne -->
      <v-expansion-panels multiple class="mt-4">
        <v-expansion-panel v-for="(item, i) in dati" :key="i">
          <v-expansion-panel-header class="pa-0">
            <div :style="{ borderLeft: '12px solid ' + item.colore }">
              <v-checkbox label=" " hide-details style="max-width: 40px; margin-top: 10px; float: left"
                v-model="checkAutisti" :value="item.autista" @click.native.stop.prevent="visMappaAutista" color="black" />

              <div class="ma-0" style="height: 48px; padding-top: 15px; font-weight: bold">
                {{ item.autista }}

                <span style="float: right">
                  {{ getTotConsegne(item.autista) }}</span>
              </div>

              <div style="padding-left: 5px" v-html="getRiepilogo(item.autista)"></div>
            </div>
          </v-expansion-panel-header>

          <v-expansion-panel-content id="innerExPan">
            <v-card :style="{ borderLeft: '12px solid ' + item.colore }">
              <v-card-text class="pa-0 pl-0 pb-2">

                <v-switch label="Blocca" hide-details @change="impostazioniGiro(item.autista, null, $event)"
                  :input-value="getImpostazione(item.autista, 'bloccato')" style="float: left; margin-left: 10px">
                </v-switch>

                <v-container>
                  <v-row wrap class="pt-4 pl-4">
                    <v-btn icon small dark color="red" class="" @click="caricaDati(item.autista)">
                      <v-icon>refresh</v-icon>
                    </v-btn>

                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn icon small dark color="red" class="ml-0 mr-0" v-on="on">
                          <v-icon>settings</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="apriDialog('impostazioni', item.autista)">Impostazioni
                        </v-list-item>
                        <v-list-item @click="apriDialog('autista', item.autista)">Cambia autista
                        </v-list-item>
                        <v-list-item @click="apriDialog('sposta', item.autista)">Sposta consegna
                        </v-list-item>
                        <v-list-item @click="apriDialog('spostamenti', item.autista)">Spostamenti
                        </v-list-item>
                        <v-list-item @click="apriDialog('orari', item.autista)">Ora passaggio
                        </v-list-item>
                      </v-list>
                    </v-menu>

                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-btn icon small dark color="red" v-on="on">
                          <v-icon>print</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item v-on:click="stampa('bolle', item.autista)">Stampa bolle
                        </v-list-item>
                        <v-list-item v-on:click="stampa('schedaPassaggi', item.autista)">Scheda passaggi
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-divider></v-divider>

              <v-data-table :headers="headers" :items="getListaConsegne(item.autista)" class="elevation-0" no-data-text=""
                hide-default-footer hide-default-header item-key="prog" disable-pagination>
                <template v-slot:item="{ item }">
                  <tr>
                    <td style="max-width: 25px !important" class="pa-0">
                      <v-checkbox primary hide-details :input-value="item.selectedRows" @change="setRigaSel(item, $event)"
                        class="ma-0"></v-checkbox>
                    </td>

                    <td style="width: 23px !important; text-align: left" class="pa-0" :style="{
                      backgroundColor: item.scaricato ? '#32CD32' : '',
                    }">
                      {{ item.prog == -1 ? "" : item.prog }}
                    </td>

                    <td style="width: 50px !important" class="pa-0">
                      {{ $functionsApp.getTimeFromDate(item.tempoPrevisto) }}
                      <br />{{
                        item.dataScarico
                        ? $functionsApp.getTimeFromDate(item.dataScarico)
                        : ""
                      }}
                    </td>

                    <td style="height: 28px" class="pa-0" :style="{
                          backgroundColor: item.flagRitiro ? 'LightBlue' : '',
                        }">
                      <span style="font-weight: bold">{{
                        getRagSoc(
                          item.cdAnagrafica,
                          item.ragioneSociale,
                          item.prog
                        )
                      }}</span><br />{{ item.localita }}
                    </td>

                    <td style="width: 50px !important; text-align: right" class="pa-0">
                      {{ toMinuteString(item.totTempoConsegna) }}
                    </td>
                  </tr>
                </template>
              </v-data-table>

            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <DialogAutisti :dialog="dialogAutista" @chiudiDialog="chiudiDialogAutista" @impostaAutista="impostaAutista" />

      <DialogSpostaConsegna :dialog="dialogSposta" @chiudiDialog="chiudiDialogSposta" @cambiaProg="cambiaProgConsegna"
        :cliente="getCliente" />

      <DialogImpostazioni :dialog="dialogImpostaz" @chiudiDialog="chiudiDialogImpostaz" :autista="autistaSel"
        :dataConsegna="getData()" />

      <DialogListaSpostamenti :dialog="dialogListaSpostamenti" @chiudiDialog="chiudiDialogSpostamenti"
        :autista="autistaSel" :dataConsegna="getData()" />

      <DialogCerca :dialog="dialogCerca" @chiudiDialog="chiudiDialogCerca" />

      <DialogImpostazioniGenerali :dialog="dialogImpostazGen" @chiudiDialog="chiudiDialogImpostazGen"
        :dataConsegna="getData()" />

      <DialogOrari :dialog="dialogOrari" @chiudiDialog="chiudiDialogOrari" :dataConsegna="getData()" :autista="autistaSel"
        @impostaOrario="impostaOrario" :codAnag="getCodCliente()" />
    </v-card-title>
  </v-navigation-drawer>
</template>

<script>
import {
  set_cambioAutista,
  set_spostaConsegna,
  set_consegneImpostazioni,
  set_consegneOrari
} from "@/api/api.intranet.js";

import DialogAutisti from "@/components/dialog/consegne/CambioAutista";
import DialogSpostaConsegna from "@/components/dialog/consegne/SpostaConsegna";
import DialogImpostazioni from "@/components/dialog/consegne/ImpostazioniConsegne";
import DialogListaSpostamenti from "@/components/dialog/consegne/ListaSpostamenti";
import DialogCerca from "@/components/dialog/consegne/CercaLocalita";
import DialogImpostazioniGenerali from "@/components/dialog/consegne/ImpostazioniConsegneGenerali";
import DialogOrari from "@/components/dialog/consegne/OraConsegna";


export default {
  components: {
    DialogAutisti,
    DialogSpostaConsegna,
    DialogImpostazioni,
    DialogListaSpostamenti,
    DialogCerca,
    DialogImpostazioniGenerali,
    DialogOrari
  },

  props: {
    dati: { type: Array, default: () => [] },
    attesa: { type: Boolean, default: false },
    attesaSalva: { type: Boolean, default: false },
    autisti: { type: Array, default: () => [] },
    gps: { type: Boolean, default: false },
  },

  data: () => ({
    apiElaborazione: ["TomTom"],
    apiElaborazioneSel: "TomTom",


    autistaSel: "",
    checkAutisti: [],

    calendar: false,
    dataConsegna: null,
    dataConsegnaFormat: null,

    dialogAutista: false,
    dialogSposta: false,
    dialogImpostaz: false,
    dialogListaSpostamenti: false,
    dialogCerca: false,
    dialogImpostazGen: false,
    dialogOrari: false,

    selectedRows: [],
    headers: [
      {
        text: "Prog",
        align: "left",
        sortable: false,
        value: "prog",
      },
      {
        text: "Tempo",
        align: "left",
        sortable: false,
        value: "TempoPrevisto",
      },
      {
        text: "Rag.Soc",
        align: "left",
        sortable: false,
        value: "ragioneSociale",
      },
    ],
  }),

  methods: {
    init() {
      // Reset
      this.autistaSel = "";

      // Data consegna giorno successivo
      this.dataConsegna = new Date(new Date().setDate(new Date().getDate()))
        .toISOString()
        .substr(0, 10);

      this.dataConsegnaFormat = this.$i2kFunctions.formatISODate(
        new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()
      );
    },

    getData() {
      return this.$i2kFunctions.formatITDateToISO(this.dataConsegnaFormat);
    },

    caricaDati(autista) {
      // Reset
      this.selectedRows = [];

      let data = this.getData();

      // Controllo autista selezionato
      if (autista) {
        this.$emit(
          "caricaDatiConsegna",
          data,
          autista
        );
      } else {
        this.$emit("caricaDati", data);
      }
    },

    async impostazioniGiro(autista, _inverti, _blocca) {

      // Ricavo la data selezionata
      let data = this.getData();

      // Impostazioni
      let impostaz = this.dati.find((f) => f.autista == autista).impostazioni;
      if (_inverti != null) impostaz.inverti = _inverti;
      if (_blocca != null) impostaz.bloccato = _blocca;
      // ----

      try {
        let res = await set_consegneImpostazioni(
          data + "/" + autista,
          impostaz
        );

        // Ricarico i dati
        this.caricaDati(autista);
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    stampa(tipo, autista) {
      this.$functionsApp.webViewCommand(
        "stampaConsegne|" + tipo + "|" + this.getData() + "|" + autista
      );
    },

    async impostaAutista(autista) {
      let data = this.getData();

      let arr = this.selectedRows;

      if (arr.length == 0) return;

      try {
        let res = await set_cambioAutista(data + "/" + autista, arr);

        // Ricarico i dati
        this.caricaDati();
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async impostaOrario(passaggio, ora) {

      // NOTA: faccio solo una riga x volta
      let riga = this.selectedRows[0];
      if (riga.length == 0) return;


      try {
        let par = {
          dtConsegna: this.getData(),
          modalitaConsegna: riga.modalitaConsegna,
          cdAnagrafica: this.getCodCliente(),
          passaggio: passaggio,
          oraPassaggio: ora
        }
        let res = await set_consegneOrari(par);

        // Ricarico i dati
        this.caricaDati();
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    async cambiaProgConsegna(prog) {
      let data = this.getData();

      // NOTA: faccio solo una riga x volta
      let riga = this.selectedRows[0];
      if (riga.length == 0) return;

      let autista = riga.modalitaConsegna;

      try {
        let res = await set_spostaConsegna(
          data + "/" + autista + "/" + prog,
          riga
        );

        // Ricarico i dati
        this.caricaDati(autista);
      } catch (error) {
        this.$toast.info(this.$i2kFunctions.getErrorMsg(error));
      }
    },

    verificaRigaSel(autista) {
      // Filtro x autista attualmente selezionato
      let filtro = this.selectedRows.filter(
        (s) => s.modalitaConsegna.toLowerCase() == autista.toLowerCase()
      );

      if (filtro.length == 0) {
        this.$toast.info("Nessuna consegna selezionata");
        return false;
      }

      this.selectedRows = filtro;

      return true;
    },

    getCodCliente() {

      if (this.selectedRows.length > 0)
        return this.selectedRows[0].cdAnagrafica
      else
        return ''
    },

    apriDialog(tipo, autista) {
      if (tipo != "spostamenti" && tipo != "impostazioni" && tipo != "impostazioniGenerali") {
        if (!this.verificaRigaSel(autista)) return;
      }

      this.autistaSel = autista;

      if (tipo == "autista") this.dialogAutista = true;
      else if (tipo == "sposta") this.dialogSposta = true;
      else if (tipo == "spostamenti") this.dialogListaSpostamenti = true;
      else if (tipo == "impostazioni") this.dialogImpostaz = true;
      else if (tipo == "impostazioniGenerali") this.dialogImpostazGen = true;
      else if (tipo == "orari") this.dialogOrari = true;
    },

    chiudiDialogAutista() {
      this.dialogAutista = false;
    },

    chiudiDialogSposta() {
      this.dialogSposta = false;
    },

    chiudiDialogImpostaz(aggiorna) {
      this.dialogImpostaz = false;

      if (aggiorna) this.caricaDati(this.autistaSel);
    },

    chiudiDialogSpostamenti(aggiorna) {
      this.dialogListaSpostamenti = false;

      if (aggiorna) this.caricaDati(this.autistaSel);
    },

    chiudiDialogCerca(dati) {
      this.$emit("cercaLocalita", dati);

      this.dialogCerca = false;
    },

    chiudiDialogImpostazGen(aggiorna) {
      this.dialogImpostazGen = false;

      if (aggiorna) this.caricaDati('');
    },

    chiudiDialogOrari(aggiorna) {
      this.dialogOrari = false;

      if (aggiorna) this.caricaDati(this.autistaSel);
    },


    getListaConsegne(autista) {
      if (this.dati.length === 0) return;

      let res = [];

      // Copio l'array
      let arr = JSON.parse(JSON.stringify(this.dati));
      for (let key in arr) {
        res.push(arr[key]);
      }

      // Filtro per autista
      res = res.filter((f) => f.autista.toLowerCase() == autista.toLowerCase());

      res = res[0];

      // Lista consegne
      let listaCons = res.listaConsegne;

      // Pausa pranzo
      let progPausa = res.progPausaPranzo;

      // Inserisco la pausa pranzo
      if (progPausa != 0 && listaCons.length > 0) {
        let str = this.getData() + "T12:30:00";
        let tempo = new Date(str);

        let item = listaCons.splice(progPausa, 0, {
          prog: -1,
          tempoPrevisto: tempo,
          ragioneSociale: "*** PAUSA PRANZO ***",
          dataScarico: null,
        });

      }

      return listaCons;
    },

    visMappaAutista() {
      this.$emit("autistiSel", this.checkAutisti);
    },

    getRiepilogo(autista) {
      if (!!this.dati == false) return "";

      let filtro = this.dati.find((f) => f.autista == autista);

      let totViaggio = filtro.tempoViaggio;
      if (
        filtro.progPausaPranzo != 0 &&
        filtro.impostazioni.noPausaPranzo == false
      )
        totViaggio += filtro.impostazioni.pausaPranzo;

      let km = filtro.km;
      let secViaggio = this.toTimeString(totViaggio);
      let secConsegne = this.toTimeString(filtro.secondi);

      let res = "Km: " + Math.round(km, 0).toString();
      res += " Viaggio:" + secViaggio.toString().substring(0, 5);
      res += "  Totale:" + secConsegne.toString().substring(0, 5);
      res += " <br> Peso:" + filtro.peso.toString() + " Kg";
      return res;
    },

    getTotConsegne(autista) {
      let filtro = this.dati.filter((f) => f.autista == autista);

      if (filtro[0].listaConsegne.length > 0)
        return filtro[0].listaConsegne.length - 2;
      else
        return 0
    },

    getImpostazione(autista, valore) {
      let filtro = this.dati.find((f) => f.autista == autista);

      return filtro.impostazioni[valore];
    },

    toTimeString(seconds) {
      return new Date(seconds * 1000)
        .toUTCString()
        .match(/(\d\d:\d\d:\d\d)/)[0];
    },

    toMinuteString(seconds) {
      if (!!seconds == false || seconds == 0) return "";

      let s = this.toTimeString(seconds);
      return s.substr(3, 5);
    },

    pulisci() {
      this.checkAutisti = [];
      this.selectedRows = [];
    },

    setRigaSel(riga, ev) {
      if (ev) this.selectedRows.push(riga);
      else this.selectedRows.splice(this.selectedRows.indexOf(riga), 1);
    },

    getRagSoc(codCli, ragSoc, prog) {
      if (codCli == "000001" || codCli == "001758") {
        return prog == 0 ? "PARTENZA" : "ARRIVO";
      }

      return ragSoc;
    },


    cercaPunto() {
      this.dialogCerca = true;
    },
  },

  mounted() {
    this.init();
  },

  watch: {
    dataConsegna(val) {
      this.dataConsegnaFormat = this.$i2kFunctions.formatISODate(
        this.dataConsegna
      );
    },

    autisti(old, nuovo) {
      // if (old.length == 0 || this.checkAutisti.length == 0) {
      // this.checkAutisti = nuovo;
      // }
      if (this.checkAutisti.length == 0) this.checkAutisti = nuovo;
    },
  },

  computed: {
    getCliente() {
      // Filtro x autista attualmente selezionato
      let filtro = this.selectedRows.filter(
        (s) => s.modalitaConsegna.toLowerCase() == this.autistaSel.toLowerCase()
      );

      if (filtro.length > 0) {
        return filtro[0].ragioneSociale;
      }

      return "";
    },
  },
};
</script>

<style >
#innerExPan>* {
  padding: 0px;
}
</style>



