<template>
    <div :class="[
        { 'data-table-detail': true },
        { 'active-permanent': floatingModal.isPermanent },
        { 'active-hover': floatingModal.isHover && !floatingModal.isPermanent },
        { hidden: !floatingModal.isHover && !floatingModal.isPermanent },]" :style="floatingModal.transformStyle"
        style="background-color: #fff;
      border-radius: 10px;
      width: calc(22%) !important;
      max-width: auto !important;
      height: auto;
      z-index: 1000;
    " id="floating-div">

        <div class="ma-0 pa-0">
            <v-row no-gutters class="ma-0 pa-0">
                <!-- CATEGORIE -->
                <v-col cols="6" :style="[
                    listaSottoCategorieCataloghi.length > 0
                        ? { 'border-right': '1px solid black' }
                        : {},
                ]">
                    <v-row no-gutters v-for="(cat, i) in listaCategorieCataloghi" :key="i">
                        <div v-if="cat.categoria === true" class="outside ml-1 mr-1"
                            :style="{ backgroundColor: cat.coloreRgb }">
                            <div class="inside">{{ cat.modulo }}</div>
                        </div>
                        <v-col v-if="cat.categoria === true">
                            <h4 style="color: black">{{ cat.descrizione }}</h4>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <!-- SOTTO CATEGORIE -->
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-row no-gutters v-for="(cat, i) in listaSottoCategoria" :key="i">
                                <div v-if="cat.sottoCategoria === true" class="ml-2" style="width: 9vh; height: 9vh;">
                                    <v-img class="mr-2" :src="cat.src"></v-img>
                                </div>
                                <v-col v-if="cat.sottoCategoria === true" cols="5">
                                    <h4 style="color: black;">{{ cat.descrizione }}</h4>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import {
    get_listaCategorieCataloghi,
    get_listaSottoCategorieCataloghi,
} from "@/api/api.archivi.js";

export default {
    props: {
        floatingModal: { type: Object, default: () => ({}) },
        dialogDettCategorie: { type: Boolean, default: null },
        codiceLinea: { type: String, default: "" },
        testataCategorie: { type: Object, default: null },
    },

    components: {},

    data: () => ({
        listaCategorieCataloghi: [],
        listaSottoCategorieCataloghi: [],
        categoria: false,
        Categoria: null,
        sottoCategoria: false,
        SottoCategoria: null,
        listaSottoCategoria: [],
        Testata: null,
    }),

    methods: {
        async caricaDati() {
            this.listaCategorieCataloghi = [];
            let cat = {
                CodiceLinea: this.codiceLinea,
                CodTestata: this.testataCategorie.codTestata,
                Suffisso: this.testataCategorie.suffisso,
                CodCategoria: this.testataCategorie.codCategoria,
            };
            let res = await get_listaCategorieCataloghi(cat);
            this.listaCategorieCataloghi = res.data;
            //set categorie con valore
            this.Testata = this.testataCategorie;
            let CategoriaSupp = null;
            this.listaCategorieCataloghi.forEach(element => {
                if (element.categoria === true) {
                    this.categoria = true;
                    this.Categoria = element;
                    CategoriaSupp = this.Categoria;
                }
            });
            //set Sotto Categorie
            if (this.Categoria != null) {
                //setto Sotto Categorie
                let sottoCat = {
                    CodiceLinea: this.Categoria.codLinea,
                    CodTestata: this.testataCategorie.codTestata,
                    Suffisso: this.testataCategorie.suffisso,
                    CodCategoria: this.Categoria.codCategoria,
                    CodSottoCategoria: this.testataCategorie.codSottoCategoria,
                };
                //setto Categoria selezionata
                this.setCategoriaSupp(this.Categoria);
                this.caricaSottoCategorie(sottoCat);
            }
            this.Categoria = CategoriaSupp;
        },

        async caricaSottoCategorie(item) {
            this.listaSottoCategorieCataloghi = [];
            this.listaSottoCategoria = [];
            let res = await get_listaSottoCategorieCataloghi(item);
            this.listaSottoCategorieCataloghi = res.data;
            //disabilito le Sotto Categorie con 'sottoCategoria' = false
            let sotCat = this.listaSottoCategorieCataloghi.filter((i) => i.sottoCategoria === true);
            if (sotCat.length > 0) {
                this.sottoCategoria = sotCat[0].sottoCategoria;
                this.SottoCategoria = sotCat[0];
            }

            for (let i = 0; i < this.listaSottoCategorieCataloghi.length; i++) {
                this.listaSottoCategoria.push(this.listaSottoCategorieCataloghi[i]);
            }
        },

        //CATEGOIRE
        setCategoriaSupp(cat) {
            if (this.categoria === true && this.categoria == true) {
                this.Testata.codCategoria = 0;
                this.resetCampiSupp();
            }
            else {
                this.categoria = true;
                this.Categoria = cat;
                cat = {
                    CodiceLinea: this.Categoria.codLinea,
                    CodTestata: this.testataCategorie.codTestata,
                    Suffisso: this.testataCategorie.suffisso,
                    CodCategoria: this.Categoria.codCategoria,
                    CodSottoCategoria: this.testataCategorie.codSottoCategoria,
                }
                this.caricaSottoCategorie(cat);
            }
        },
        setCategoria(cat) {
            if (this.categoria === true && this.categoria == true) {
                this.Testata.codCategoria = 0;
                this.resetCampi();
            }
            else {
                this.categoria = true;
                this.Categoria = cat;
                cat = {
                    CodiceLinea: this.Categoria.codLinea,
                    CodTestata: this.testataCategorie.codTestata,
                    Suffisso: this.testataCategorie.suffisso,
                    CodCategoria: this.Categoria.codCategoria,
                    CodSottoCategoria: this.testataCategorie.codSottoCategoria,
                }
                this.caricaSottoCategorie(cat);
            }
        },
        //SOTTO CATEGORIE
        setSottoCategoria(cat) {
            if (this.sottoCategoria === true) {
                this.sottoCategoria = false;
                this.SottoCategoria = null;
                this.Testata.codSottoCategoria = 0;
            }
            else {
                this.sottoCategoria = true;
                this.SottoCategoria = cat;
            }
        },

        resetCampiSupp() {
            this.sottoCategoria = false;
            this.SottoCategoria = null;
            this.listaSottoCategorieCataloghi = [];
            this.listaSottoCategoria = [];
        },

        floatingModalPermanent(vis) {
            this.$emit("floatingModalPermanent", vis);
        },
    },

    mounted() {
    },

    watch: {
        dialogDettCategorie() {
            this.caricaDati();
        },
    },
};
</script>

<style scoped>
.outside {
    width: 8vh;
    height: 8vh;
    position: relative;
    display: table;
}

.inside {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: white;
}
</style>