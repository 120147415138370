<template src="./extranet.html"></template>

<script>
export default {
  name: "extranet",
  title: 'Documenti',

  data: () => ({
    activeTab: "",
    tabs: [
      { id: "1", name: "Ordini", route: "ordini" },
      { id: "2", name: "DDT", route: "ddt" },
      { id: "3", name: "Fatture", route: "fatture" },
      { id: "4", name: "Web", route: "web" }
    ]
  }),

  methods: {
    updateRoute(val) {
      //console.log("updateroute")
      this.$router.push(val);
    }
  },


  computed: {
    auth() {
      return this.$store.getters["auth/isAuthenticated"];
    }
  },

  watch: {
    auth() {
      this.$router.push({ path: "/" });
    }
  }
};
</script>
