<template>
  <article style="max-width:1300px;margin:0 auto">
    <v-container>
      <v-layout flex>
        <v-flex xs12>
          <p class="display-1 display-bold">News</p>
        </v-flex>
      </v-layout>
      <v-carousel v-if="news.immagini" hide-delimiters class="my-3">
        <v-carousel-item v-for="(immagine,i) in news.immagini" :key="i" :src="immagine"></v-carousel-item>
      </v-carousel>
      <v-layout flex justify-center class="my-5">
        <v-flex xs12 md8>
          <h1>{{news.titolo_lungo}}</h1>
          <p>Pubblicato il {{$i2kFunctions.formatDate_IT(new Date(news.data))}}</p>
          <div v-html="news.sottotitolo"></div>
          <div v-html="news.testo" class="pt-3"></div>
          <a :href="news.fonte" v-if="news.fonte">(fonte)</a>
        </v-flex>
      </v-layout>
    </v-container>
  </article>
</template>

<script>
import { get_news } from "@/api/api.news.js";

export default {
  name: "news-item",

  props: {
    azienda: { type: String, default: "" },
    prog: { type: String, default: "0" }
  },

  data: () => ({
    news: {}
  }),

  methods: {
    caricaDati() {
      get_news(this.azienda + "/" + this.prog)
        .then(res => {
          this.news = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  mounted() {
    window.scrollTo(0, 0);

    this.caricaDati();
  }
};
</script>

<style scoped>
b + br {
  margin-top: 16px;
}
</style>
