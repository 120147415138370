<template>
  <v-dialog
    v-model="getDialog"
    @keydown.esc="chiudi()"
    min-width="600px"
    max-width="600px"
  >
    <v-toolbar class="pa-0 pl-2" style="height: 48px">
      <v-toolbar-title> Aggiungi ai preferiti </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn text icon>
        <v-icon v-on:click="chiudi">close</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card
      class="pa-0 elevation-0"
      style="width: auto; height: 100%; border-radius: 0"
    >
      <v-container fluid align-center space-between style="height: 250px">
        <v-col>
          <v-autocomplete
            v-model="clienteSel"
            label="Clienti"
            :items="getClienti"
            itemText="ragioneSociale"
            class="mx-3"
            return-object
          ></v-autocomplete>
        </v-col>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text light @click="aggiungiPreferito()">
          Aggiungi
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { set_testataPreferita } from "@/api/api.catalogo.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    testata: { type: Object, default: null },
  },

  data: () => ({
    clienteSel: {},
  }),

  methods: {
    chiudi() {
      this.$emit("chiudi");
    },

    async aggiungiPreferito() {
      let data = {
        DatiUtente: this.clienteSel,
        Testata: this.testata,
      };
      let res = await set_testataPreferita(data);
      if (res.data != "OK") this.$toast.alert("Errore. " + res.data);
      else this.chiudi();
    },
  },
  computed: {
    getClienti() {
      return this.$store.getters["app/getClienti"];
    },

    getDialog: {
      get: function () {
        return this.dialog;
      },
      set: function (value) {
        if (!value) {
          this.chiudi();
        }
      },
    },
  },
};
</script>