<template src="./lottiProduzione.html"></template>

<script>
import { get_lotti, get_elenco_stampanti, get_etichetta_stampa, get_dettaglio_lotto } from "@/api/api.lottiProd.js";

import objectArchivi from "@/views/intranet/archivi/objectArchivi.js";

export default {

  components: {},

  mixins: [objectArchivi],
  data: () => ({
    headerProps: {
      sortByText: "Ordina per",
    },
    loadingTabella: false,
    page: 1,
    pageCount: 0,
    search: "",
    headerLotti: [{ text: "Fornitore", value: "ragioneSociale" },
    { text: "DDT", value: "documento" },
    { text: "Data documento", value: "dtDocumento" },],
    listaLotti: [],
    filtri: {},

    testataSel: null,

    elencoStampanti: ["192.168.90.188", "192.168.90.71", "192.168.90.164"],
    stampanteSel: "192.168.90.188",

    selected_device: null,
    devices: [],

    headersDettaglio: [/* { text: "Numero riga", value: "nrRigaDocu" }, */
      { text: "Articolo", value: "cdArticoloPadre" },
      { text: "Descrizione prodotto", value: "descrizioneProdotto" },
      { text: "Lotto", value: "numLotto" },
      { text: "Quantità", value: "qta" }],
    righeDettaglio: [],
  }),

  methods: {
    async caricaDati() {
      this.loadingTabella = true;

      this.listaLotti = [];
      let res = await get_lotti(this.filtri);
      this.listaLotti = res.data;

      this.loadingTabella = false;
    },

    refresh() {
      this.filtri = this.filtriTabelle();
      this.caricaDati();
    },

    selectRow(item) {
      this.testataSel = item;
      this.caricaDettaglio();
    },

    async caricaDettaglio() {
      let res = await get_dettaglio_lotto(this.testataSel);
      this.righeDettaglio = res.data;
    },

    async stampaEtichetta(tipologia) {
      let res = await get_etichetta_stampa(this.testataSel, tipologia);
      if (res.data.messaggio == undefined || res.data.messaggio == null) {
        this.$toast.alert("Errore. Impossibile stampare l'etichetta");
        return;
      }
      if (res.data.messaggio != "OK") {
        this.$toast.alert(res.data.messaggio);
        return;
      }
      this.printBarcode(res.data.label);
    },

    async printBarcode(zpl) {
      try {
        const axios = require('axios');
        const url = "http://" + this.stampanteSel + "/pstprnt";
        const response = await axios.post(url, zpl);
        console.log('API call response:', response.data);
      } catch (error) {
        console.error(error);
      }
    },

    async caricaStampanti() {
      let res = await get_elenco_stampanti();
      this.elencoStampanti = res.data;
    },

  },

  created() {
    //this.caricaStampanti();
  },

  mounted() {
    this.caricaDati();
    this.filtri = this.filtriTabelle();
  },

  watch: {},

  computed: {
    getDati() {
      let res = this.listaLotti;
      return res;
    },
  },
};
</script>

<style scoped>
.parent {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
