<template>
    <v-dialog v-model="getDialog" width="700" @keydown.esc="chiudi()" style="min-height: 100% !important" persistent>
        <v-card width="700" :loading="attesa" class="pa-0">
            <v-toolbar class="pa-0 pl-2">
                <v-toolbar-title>Descrizioni tecniche</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn text @click="salva()">
                    <v-icon>save</v-icon>Salva
                </v-btn>

                <v-spacer></v-spacer>

                <v-btn text icon>
                    <v-icon @click="chiudi()">close</v-icon>
                </v-btn>
            </v-toolbar>

            <v-container style="background-color: white;">
                <vue-editor :editorToolbar="customToolbar" placeholder="Scrivi qui..." v-model="desc"></vue-editor>

                <!--  <v-textarea v-text="dati.descrizione" readonly auto-grow></v-textarea> -->
            </v-container>

        </v-card>
    </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { get_datiTecniciArticolo, salva_datiTecniciArticolo } from "@/api/api.catalogo.js";
export default {

    props: {
        dialog: { type: Boolean, default: false },
        codArticolo: { type: String, default: "" },
    },

    components: {
        VueEditor,
    },

    data: () => ({
        desc: "",

        customToolbar: [
            ["bold"],
            [{ list: "bullet" }],
        ],

        attesa: false
    }),

    methods: {

        async caricaDati() {

            if (this.dialog == false)
                return

            this.attesa = true

            // Reset
            this.desc = ""

            let res = await get_datiTecniciArticolo(this.codArticolo)
            this.desc = res.data

            this.attesa = false
        },

        async salva() {

            try {
                let datiSwap = { cdArticolo: this.codArticolo, descrizioneTecnicaHtml: this.desc }

                let res = await salva_datiTecniciArticolo(datiSwap);

                this.chiudi()

            } catch (error) {
                console.log(error);
                this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
            }
        },

        chiudi() {

            this.$emit("chiudi");
        },

        deleteRow() {

        },

        addRow() {

        }
    },

    mounted() {
        this.caricaDati()
    },
    computed: {

        getDialog: {
            get: function () {
                return this.dialog;
            },
            set: function (value) {
                if (!value) {
                    this.chiudi();
                }
            },
        },
    },

    watch: {
        dialog() {
            this.caricaDati();
        },
    },
}

</script>