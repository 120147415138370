import Vue from 'vue'

export default {


  // ----------- Auth ------------- //
  getToken() {
    return localStorage.getItem('token')
  },

  getRefToken() {
    return localStorage.getItem('ref_token')
  },

  getDataExpiredToken() {
    return localStorage.getItem('exp_data')
  },

  getAuthHeader() {
    return 'Bearer ' + this.getToken()
  },


  checkRemember() {
    return !!localStorage.getItem('ref_token')
  },

  checkAuth() {
    return !!localStorage.getItem('token')
  },

  getExpiration(tokenExpiration) {

    var now = new Date();
    now.setMinutes(now.getMinutes() + Math.ceil((tokenExpiration / 60)))

    return now;
  },

  setStorageAuth(data) {

    localStorage.setItem('token', data.access_token);
    localStorage.setItem('exp_data', this.getExpiration(data.expires_in))

    if (data.refresh_token)
      localStorage.setItem('ref_token', data.refresh_token)
  },
  // --------------------------------- //




  // ----------- Ricerca ------------- //
  getFiltriRicerca() {
    if (localStorage.getItem('filtriRicerca')) {
      let s = localStorage.getItem('filtriRicerca').split(",")

      return Vue.prototype.$functionsApp.splitBooleanArray(s)
    }

    return []
  },

  setStorageFiltriRicerca(arr) {
    localStorage.setItem('filtriRicerca', arr);
  },
  // --------------------------------- //




  clearStorage() {
    localStorage.clear();
  },





}