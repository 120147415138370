import request from '@/services/interceptor.js'


// --------------------
// Consegne
// --------------------
export const get_consegne = (data) => {
    return request({
        url: '/intranet/consegne/' + data,
        method: 'get',
    })
}

export const get_consegneSingola = (par) => {
    return request({
        url: '/intranet/consegne/' + par,
        method: 'get',
    })
}

export const get_gps = (data) => {
    return request({
        url: '/intranet/consegne/gps/' + data,
        method: 'get',
    })
}

export const get_autisti = () => {
    return request({
        url: '/intranet/consegne/autisti',
        method: 'get',
    })
}


export const get_consegneImpostazioni = (par) => {
    return request({
        url: '/intranet/consegne/impostazioni/' + par,
        method: 'get'
    })
}

export const set_consegneImpostazioni = (par, data) => {
    return request({
        url: '/intranet/consegne/impostazioni/' + par,
        method: 'post',
        data: data
    })
}

export const set_cambioAutista = (par, data) => {
    return request({
        url: '/intranet/consegne/autisti/cambio/' + par,
        method: 'post',
        data: data
    })
}

export const set_spostaConsegna = (par, data) => {
    return request({
        url: '/intranet/consegne/sposta/' + par,
        method: 'post',
        data: data
    })
}

export const get_spostamenti = (par) => {
    return request({
        url: '/intranet/consegne/sposta/' + par,
        method: 'get',
    })
}

export const del_spostamenti = (data) => {
    return request({
        url: '/intranet/consegne/sposta/',
        method: 'delete',
        data: data
    })
}

export const get_cercaLocalita = (par) => {
    return request({
        url: '/intranet/consegne/cerca/' + par,
        method: 'get',
    })
}

export const get_consegneOrari = (par) => {
    return request({
        url: '/intranet/consegne/orari/' + par,
        method: 'get'
    })
}

export const set_consegneOrari = (data) => {
    return request({
        url: '/intranet/consegne/orari',
        method: 'post',
        data: data
    })
}





// --------------------
// Rimborsi
// --------------------
export const get_rimborsi = (param) => {
    return request({
        url: '/intranet/rimborsi/' + param,
        method: 'get',
    })
}

export const save_rimborsi = (data) => {
    return request({
        url: '/intranet/rimborsi',
        method: 'post',
        data: data
    })
}

export const del_rimborsi = (data) => {
    return request({
        url: '/intranet/rimborsi',
        method: 'delete',
        data: data
    })
}

export const calcola_rimborsi = (par) => {
    return request({
        url: '/intranet/rimborsi/calcola/' + par,
        method: 'get',
    })
}

export const salva_rimborsiCosti = (par) => {
    return request({
        url: '/intranet/rimborsi/costo/' + par,
        method: 'get',
    })
}

export const stampa_rimborsi = (par) => {
    return request({
        url: '/intranet/rimborsi/stampa/' + par,
        method: 'get',
        responseType: 'blob'
    })
}

export const save_kmCalcolati = (par) => {
    return request({
        url: '/intranet/rimborsi/km/' + par,
        method: 'get',
    })
}




// --------------------
// Clienti
// --------------------
export const get_clienti = () => {
    return request({
        url: '/intranet/clienti',
        method: 'get',
    })
}


export const get_clienteReferenti = (data) => {
    return request({
        url: '/intranet/clienti/toolbar/referenti',
        method: 'post',
        data: data
    })
}

export const get_cliente = (par) => {
    return request({
        url: '/intranet/clienti/' + par,
        method: 'get',
    })
}

export const set_referente = (data) => {
    return request({
        url: '/intranet/clienti/referenti',
        method: 'post',
        data: data
    })
}

export const salva_referente = (data) => {
    return request({
        url: '/intranet/clienti/referenti/dati',
        method: 'post',
        data: data
    })
}

export const elimina_referente = (data) => {
    return request({
        url: '/intranet/clienti/referenti/dati/elimina',
        method: 'post',
        data: data
    })
}




// --------------------
// Documenti
// --------------------
export const get_areaDoc = (par) => {
    return request({
        url: '/intranet/aree/' + par,
        method: 'get',
    })
}

export const get_documenti = (data) => {
    return request({
        url: '/intranet/documenti',
        method: 'post',
        data: data,
    })
}

export const get_scansioni = (par) => {
    return request({
        url: '/intranet/documenti/' + par,
        method: 'get',

    })
}


export const save_documenti = (par, data) => {
    return request({
        url: '/intranet/documenti/' + par,
        method: 'post',
        data: data
    })
}

export const del_documenti = (par, data) => {
    return request({
        url: '/intranet/documenti/' + par,
        method: 'delete',
        data: data
    })
}



// --------------------
// Dispositivi
// --------------------
export const get_dispositivi = () => {
    return request({
        url: '/intranet/dispositivi/lista',
        method: 'get',
    })
}

export const set_dispositivo = (data) => {
    return request({
        url: '/intranet/dispositivi/set',
        method: 'post',
        data: data
    })
}

export const del_dispositivo = (par) => {
    return request({
        url: '/intranet/dispositivi/del/' + par,
        method: 'delete',
    })
}

export const get_dispositivo = (par) => {
    return request({
        url: '/intranet/dispositivo/' + par,
        method: 'get',
    })
}


export const save_immagine_dispositivo = (par, data) => {
    return request({
        url: '/intranet/dispositivo/upload/immagine/' + par,
        method: 'post',
        data: data,
        noLoadingBar: true
    })
}


export const get_immagine_dispositivo = (par) => {
    return request({
        url: '/intranet/dispositivo/download/immagine/' + par,
        method: 'get',
        noLoadingBar: true
    })
}

export const del_immagine_dispositivo = (par) => {
    return request({
        url: 'intranet/dispositivo/elimina/immagine/' + par,
        method: 'delete',
        noLoadingBar: true,
    })
}


// --------------------
// Magazzino automatico
// --------------------
export const set_comandoPLC = (data) => {
    return request({
        url: '/intranet/magazzino/command',
        method: 'post',
        data: data,
    })
}

export const get_connessionePlc = () => {
    return request({
        url: '/intranet/magazzino/ping',
        method: 'get',
    })
}

export const get_struttura_magazzino = () => {
    return request({
        url: '/intranet/magazzino/infocassetti',
        method: 'get',
    })
}


// --------------------
// Laser
// --------------------
export const set_commandLaser = (par) => {
    return request({
        url: '/intranet/laser/command',
        method: 'post',
        data: par
    })
}



// --------------------
// Azienda
// --------------------
export const get_ordineCliente = (par) => {
    return request({
        url: '/intranet/azienda/ordinecliente' + par,
        method: 'get',
    })
}

// --------------------
// Centro di lavoro
// --------------------

export const esegui_comando = (data) => {
    return request({
        url: '/intranet/centrolavoro/esegui',
        method: 'post',
        data: data,
        noLoadingBar: true,
    })
}

export const get_elencoProgrammi = () => {
    return request({
        url: '/intranet/centrolavoro/elenco',
        method: 'get',
    })
}

export const ping_cnc = () => {
    return request({
        url: '/intranet/centrolavoro/ping',
        method: 'get',
    })
}

/* export const get_info_cnc = (data) => {
    return request({
        url: '/intranet/centrolavoro/info',
        method: 'get',
        data: data,
        noLoadingBar: true,
    })
} */


// ------------------------
// Riscontro EMail Inviate
// ------------------------
export const get_emailInviate = (data) => {
    return request({
        url: '/intranet/riscontro/EMail',
        method: 'post',
        data: data,
    })
}



// ------------------------
// Ordini
// ------------------------
export const get_ordiniVendite = (data) => {
    return request({
        url: '/intranet/ordini/vendite',
        method: 'post',
        data: data,
    })
}

export const get_ordiniPreparazione = (sett) => {
    return request({
        url: '/intranet/ordini/preparazione/' + sett,
        method: 'get',
    })
}


// ------------------------
// Comuni
// ------------------------
export const get_zoneComuni = (par) => {
    return request({
        url: '/intranet/comuni/zone/' + par,
        method: 'get',
    })
}

export const set_zoneComuni = (data) => {
    return request({
        url: '/intranet/comuni/zone',
        method: 'post',
        data: data,
    })
}