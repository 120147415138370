export default {
    methods: {
        getHeadersTabella() {
            return [
                { text: "IP", value: "ip" },
                { text: "Nome", value: "nome" },
                { text: "Tipo", value: "tipo", class: "pr-0 pl-0" },
                { text: "Nome utente", value: "nomeUtente" },
                { text: "Switch", value: "switch" },
                { text: "Porta switch", value: "portaSwitch" },
                { text: "Patch panel", value: "patchPanel" },
                { text: "Porta patch panel", value: "portaPatchPanel", class: "pr-0" },
                { text: "Pozzetto", value: "pozzetto", class: "pr-0 pl-0" },
                { text: "Porta pozzetto", value: "portaPozzetto" },
                { text: "Azioni", value: "", class: "pr-0" }]
        },

        getHeadersTabellaDettaglio() {
            return [
                { text: "MAC", value: "mac" },
                { text: "Seriale", value: "seriale" },
                { text: "IMEI", value: "imei" },
                { text: "OS", value: "os" },
                { text: "Fornitore", value: "fornitore" },
                { text: "Localizzazione", value: "localizzazione" },
                { text: "Azioni", value: "", class: "pr-0" }]
        },

        getEditItem() {
            return {
                id: "",
                ip: "",
                mac: "",
                seriale: "",
                imei: "",
                os: "",
                nome: "",
                tipo: "",
                switch: "",
                portaSwitch: "",
                patchPanel: "",
                portaPatchPanel: "",
                pozzetto: "",
                portaPozzetto: "",
                nomeUtente: "",
                fornitore: "",
            }
        },

        getElencoTipi() {
            return [
                "",
                "Access Point",
                "Cella",
                "Cordless",
                "Cuffia",
                "PC",
                "Magazzino",
                "Router",
                "Server",
                "Stampante",
                "Switch",
                "Telecamera",
                "Telefono",
                "Terminalino",
                "UPS",
                "Altro",
            ]
        },

    }
}