<template>
    <v-dialog v-model="getDialog" width="500" @keydown.esc="chiudi()" persistent>
        <v-card width="100%" class="pa-1">

            <v-checkbox label="Obsoleti" v-model="parametri.obsoleto"></v-checkbox>
            <v-checkbox label="Fuori catalogo" v-model="parametri.nonStampCatalogo"></v-checkbox>
            <v-checkbox label="Eliminati" v-model="parametri.eliminato"></v-checkbox>
            <v-checkbox label="Disattiva internet" v-model="parametri.disattivaInternetTestata"></v-checkbox>
            <v-checkbox label="Visualizza prezzi" v-model="parametri.visualizzaPrezzi"></v-checkbox>
            <v-checkbox label="Stampa correlati" v-model="parametri.visualizzaCorrelati"></v-checkbox>
            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn color="#E40520" text @click="GeneraPdfProdotto" :loading="attesa">
                    Ok
                </v-btn>
                <v-btn color="#E40520" text @click="chiudi">
                    Annulla
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import {
    get_pdfProdottoPar,
} from "@/api/api.pdf.js";

export default {
    props: {
        dialog: { type: Boolean, default: false },
        testata: { type: String, default: '' },
        suffisso: { type: Number, default: 0 },
    },

    data: () => ({

        parametri: { obsoleto: false, eliminato: false, nonStampCatalogo: false, disattivaInternetTestata: false, visualizzaPrezzi: false },

        attesa: false
    }),

    methods: {

        carica() {
            if (!this.dialog)
                return
        },

        async GeneraPdfProdotto() {

            let str = this.testata + "/" + this.suffisso;

            this.attesa = true;
            try {
                let res = await get_pdfProdottoPar(str, this.parametri);

                window.open(res.data, "_blank");
            } catch (error) {
                console.log(error);
            }

            this.attesa = false;

        },


        chiudi() {
            this.$emit("chiudi", false);
        }
    },

    computed: {

        getDialog: {
            get: function () {
                return this.dialog;
            },
            set: function (value) {
                if (!value) {
                    this.chiudi();
                }
            },
        },


    },

    watch: {
        dialog() {
            this.carica()
        }
    }
}
</script>