import request from '@/services/interceptor.js'

//STAMPA PDF PRODOTTO
export const get_pdfProdotto = (par) => {
    return request({
        url: '/pdf/catalogo/' + par,
        method: 'get'
    })
};

export const get_pdfProdottoPar = (par, data) => {
    return request({
        url: '/pdf/catalogo/' + par,
        method: 'post',
        data: data,
    })
};


export const get_pdfLinee = () => {
    return request({
        url: '/pdf/catalogo/linee',
        method: 'get'
    })
};