<template>
    <DialogParoleChiave :dialog="dialogParoleChiave" :testateSelected="arrTestataParoleChiave" @chiudi="chiudiParoleChiave">
    </DialogParoleChiave>
</template>

<script>
import DialogParoleChiave from "@/components/dialog/catalogo/ParoleChiave.vue";
import {
    get_testata,
} from "@/api/api.catalogo.js";

export default {
    components: {
        DialogParoleChiave
    },


    data: () => ({
        dialogParoleChiave: false,
        arrTestataParoleChiave: []
    }),

    methods: {
        async elabora() {
            if (this.$route.query.comando == "dialogParoleChiave") {

                let par = this.$route.query.testata + "/" + this.$route.query.suff

                let res = await get_testata(par);

                this.arrTestataParoleChiave = new Array(res.data)
                this.dialogParoleChiave = true
            }
        },

        chiudiParoleChiave() {
            this.dialogParoleChiave = false;
        },
    },

    mounted() {
        this.elabora()

    }
};
</script>