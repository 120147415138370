<template>
  <v-dialog v-model="dialog" width="400">
    <v-card width="400" :loading="attesa" class="pa-2">
      <!-- <v-text-field outlined v-model="descrizione"></v-text-field> -->
      <template>
        <v-container fluid class="pb-0 mt-0">
          <v-col class="mt-0 pt-0">
            <v-radio-group v-model="selezioneGruppo">
              <v-col class="ma-0 pa-0">
                <v-row v-for="(item, i) in elencoRadio" :key="i" :class="item.radio.class">
                  <svg :width="item.colore.width" :height="item.colore.height" :class="item.colore.class">
                    <rect :width="item.colore.width" :height="item.colore.height" :style="item.colore.style" />
                  </svg>
                  <v-radio :label="item.radio.testo" :value="item.radio.valore">
                  </v-radio>
                </v-row>
              </v-col>
            </v-radio-group>
          </v-col>
        </v-container>

        <v-text-field label="Password" required :type="showText ? 'text' : 'password'"
          :append-icon="showText ? 'visibility' : 'visibility_off'" @click:append="showText = !showText"
          v-model="password" :rules="[
            () => password.length > 0 || 'Il campo password è obbligatorio',
          ]"></v-text-field>
      </template>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text light @click="chiudi()">Annulla</v-btn>
        <v-btn color="primary" text light @click="conferma()" :disabled="attesa">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { del_testataCatalogo } from "@/api/api.catalogo.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    dati: { type: Object, default: null },
    tipologia: { type: Number, default: -1 },
  },

  data: () => ({
    descrizione: "",
    password: "",
    showText: false,
    attesa: false,
    selezioneGruppo: 1,

    elencoRadio: [],
  }),

  methods: {
    caricaDati() {
      this.password = "";
      this.selezioneGruppo = 1;
      if (!this.dialog) return;
    },

    caricaRadio() {
      this.elencoRadio = [
        /* Testata vuota (senza prodotti) */
        {
          radio: {
            class: "mt-0",
            testo: "Cancella testata vuota",
            valore: 6,
          },
          colore: {
            width: 20,
            height: 20,
            class: "mr-1",
            style: "fill: transparent; stroke-width: 3",
          },
        },
      ];
      this.selezioneGruppo = 6;
      if (this.tipologia == 1) {
        this.elencoRadio.unshift(
          {
            /* Tutte le maniglie */
            radio: {
              class: "mb-2",
              testo: "Cancella tutti gli articoli",
              valore: 0,
            },
            colore: {
              width: 20,
              height: 20,
              class: "mr-1",
              style: "fill: red; stroke-width: 3",
            },
          },
          {
            /* Maniglie eliminate */
            radio: {
              class: "mt-0 mb-2",
              testo: "Cancella gli articoli eliminati",
              valore: 1,
            },
            colore: {
              width: 20,
              height: 20,
              class: "mr-1",
              style: "fill: blue; stroke-width: 3",
            },
          },
          {
            /* Maniglie obsolete */
            radio: {
              class: "mt-0 mb-2",
              testo: "Cancella gli articoli obsoleti",
              valore: 2,
            },
            colore: {
              width: 20,
              height: 20,
              class: "mr-1",
              style: "fill: orange; stroke-width: 3",
            },
          },
          {
            /* Maniglie fuori catalogo */
            radio: {
              class: "mt-0 mb-2",
              testo: "Cancella gli articoli fuori catalogo",
              valore: 4,
            },
            colore: {
              width: 20,
              height: 20,
              class: "mr-1",
              style: "fill: yellow; stroke-width: 3",
            },
          },
          /* Codice parte fornitore */
          {
            radio: {
              class: "mt-0 mb-2",
              testo: "Cancella codice parte fornitore",
              valore: 3,
            },
            colore: {
              width: 20,
              height: 20,
              class: "mr-1",
              style: "fill: transparent; stroke-width: 3",
            },
          },
          /* Codice parte fornitore */
          {
            radio: {
              class: "mt-0 mb-2",
              testo: "Cancella righe Vuote e di Divisione",
              valore: 5,
            },
            colore: {
              width: 20,
              height: 20,
              class: "mr-1",
              style: "fill: transparent; stroke-width: 3",
            },
          },
          {
            radio: {
              class: "mt-0 mb-2",
              testo: "Cancella righe Vuote",
              valore: 7,
            },
            colore: {
              width: 20,
              height: 20,
              class: "mr-1",
              style: "fill: transparent; stroke-width: 3",
            },
          },
          {
            radio: {
              class: "mt-0 mb-2",
              testo: "Cancella righe Divisione non utilizzate",
              valore: 8,
            },
            colore: {
              width: 20,
              height: 20,
              class: "mr-1",
              style: "fill: transparent; stroke-width: 3",
            },
          }
        );
        this.selezioneGruppo = 1;
      }
    },

    async conferma() {
      if (
        this.selezioneGruppo < 0 ||
        this.selezioneGruppo == null ||
        this.selezioneGruppo == undefined
      ) {
        this.$toast.alert("Nessuna tipologia di maniglia selezionata");
        return;
      }
      try {
        let confirm = false;

        if (this.password == null || this.password == "") {
          this.$toast.alert("Inserire una password valida");
          return;
        } else if (this.password != "bat") {
          this.$toast.alert("Password non corretta");
          return;
        }
        confirm = await this.$confirm.open(
          "Elimina",
          "Questa operazione cancellerà tutti gli articoli. Continuare?"
        );

        if (this.dati == null) return;
        if (confirm == false) return;
        this.attesa = true;

        let res = await del_testataCatalogo(this.selezioneGruppo, this.dati);
        if (res.data == "OK") {
          this.$toast.info("Articoli eliminati");
          this.$emit("chiudi", true);
        }
      } catch (error) {
        console.log(error);
      }

      this.attesa = false;

      this.chiudi(true);
    },

    async chiudi(aggiorna) {
      this.selezioneGruppo = 1;

      this.$emit("chiudi", aggiorna);
    },
  },

  watch: {
    dialog() {
      if (this.dialog) {
        this.caricaDati();
        this.caricaRadio();
      }
    },
  },
};
</script>