var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-0",staticStyle:{"height":"600px"}},[_c('filtro',{on:{"filtroDati":_vm.filtroDati}}),_c('div',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.getDati,"item-key":"cdArticolo","disable-pagination":"","loading":_vm.attesa,"hide-default-footer":"","single-expand":"","height":"350","no-data-text":"Nessun dato"},on:{"item-expanded":_vm.rowExpand},scopedSlots:_vm._u([{key:"item.cdArticolo",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.$functionsApp.getCodArticoloFormat(item.cdArticolo))}})]}},{key:"item.descrizione",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pr-0",domProps:{"innerHTML":_vm._s(_vm.$functionsApp.convertiTestoGrassetto(item.descrizione))}})]}},{key:"item.dtConsConfermata",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pl-0 pr-0"},[_c('span',[_vm._v(" "+_vm._s(item.dtConsConfermata))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.ApriModificaData(item)}}},[_c('v-icon',[_vm._v(" edit")])],1)],1)]}},{key:"item.pathImg",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"pl-0 pr-0"},[_c('img',{staticStyle:{"width":"100px"},attrs:{"src":item.pathImg}})])]}}])})],1),_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.dialogModifica),callback:function ($$v) {_vm.dialogModifica=$$v},expression:"dialogModifica"}},[_c('v-card',{staticClass:"pa-2",attrs:{"width":"400"}},[_c('v-card-title',{staticClass:"mt-0 pt-0 mb-4 pl-1"},[_vm._v(" Data di consegna confermata ")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"max-width":"150px !important"},attrs:{"label":"Data consegna","prepend-icon":"event","hide-details":""},model:{value:(_vm.newDataConsegna_IT),callback:function ($$v) {_vm.newDataConsegna_IT=$$v},expression:"newDataConsegna_IT"}},on))]}}]),model:{value:(_vm.calendar),callback:function ($$v) {_vm.calendar=$$v},expression:"calendar"}},[_c('v-date-picker',{staticClass:"b-date-picker",attrs:{"locale":"it-it","first-day-of-week":"1","event-color":"black","hide-details":""},on:{"input":function($event){_vm.calendar = false}},model:{value:(_vm.newDataConsegna),callback:function ($$v) {_vm.newDataConsegna=$$v},expression:"newDataConsegna"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","light":""},on:{"click":function($event){return _vm.chiudiModificaData()}}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"color":"primary","text":"","light":"","disabled":_vm.attesa},on:{"click":function($event){return _vm.confermaModifica()}}},[_vm._v(" Ok ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }