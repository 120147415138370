<template src="./activateAccount.html"></template>

<script>
import { account_activation } from "@/api/api.account.js";

export default {
  data: () => ({
    e1: true,
    alertElaborazione: "",
    tipo: "success",
    disabilitaBtn: false,

    cred: {
      Code: "",
      Password: ""
    }
  }),

  methods: {
    attiva() {
      if (this.checkQueryStringCode() == false) return false;

      // Validazione
      if (this.$refs.formPwd.validate() == false) {
        return;
      }

      this.cred.Code = this.$route.query.code;

      this.disabilitaBtn = true;
      this.alertElaborazione = "";

      // Attivazione
      account_activation(this.cred)
        .then(res => {
          // Vado alla pagina home
          this.$router.push({ path: "/" });

        })
        .catch(err => {
          this.tipo = "warning";
          this.alertElaborazione = this.$i2kFunctions.getErrorMsg(err);

          this.disabilitaBtn = false;
        });
    },

    checkQueryStringCode() {
      if (this.$route.query.code && this.$route.query.code.length > 0) {
        return true;
      }

      return false;
    }
  },

  mounted() {
    // Controllo querystring
    if (this.checkQueryStringCode() == false) {
      this.alertElaborazione = "Token non valido";
      this.tipo = "warning";
      return;
    }
  }
};
</script>
