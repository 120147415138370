<template>
  <div>
    <v-tabs fixed-tabs background-color="indigo" dark>
      <v-tab key="catalogo" :to="{ path: 'ricerca' }">Catalogo</v-tab>
      <v-tab key="mappa" to="/consegne">Mappa</v-tab>
      <v-tab>Telefoni</v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  data: () => ({
    tab: "",
  }),
};
</script>