<template>
  <v-dialog v-model="dialog" width="400" persistent>
    <v-card width="400" :loading="attesa">
      <v-container>
        <v-row class="pa-0">
          <v-col>CdPar1</v-col>
          <v-col>CdPar2</v-col>
          <v-col>CdPar3</v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col><v-checkbox v-model="cdPa1" @click="setOrdinamento()"></v-checkbox></v-col>
          <v-col><v-checkbox v-model="cdPa2" @click="setOrdinamento()"></v-checkbox></v-col>
          <v-col><v-checkbox v-model="cdPa3" @click="setOrdinamento()"></v-checkbox></v-col>
        </v-row>
        <v-row class="ma-0">
          <v-col><v-text-field v-model="ordinamento" label="Ordinamento"></v-text-field></v-col>
          <v-col></v-col>
          <v-col> <v-checkbox v-model="pulisci" label="Elimina dati salvati"></v-checkbox></v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text light @click="chiudi()">Annulla</v-btn>
        <v-btn color="primary" text light @click="conferma()" :disabled="attesa">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  get_ordinamentoCatalogo,
  set_ordinamentoCatalogo,
} from "@/api/api.catalogo.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    testata: { type: String, default: "" },
    suffisso: { type: Number, default: 0 },
  },

  data: () => ({
    ordinamento: "",
    pulisci: false,

    cdPa1: false,
    cdPa2: false,
    cdPa3: false,

    attesa: false,
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;

      this.attesa = true;

      // Reset
      this.cdPa1 = false;
      this.cdPa2 = false;
      this.cdPa3 = false;
      this.pulisci = false

      let res = await get_ordinamentoCatalogo(
        this.testata + "/" + this.suffisso
      );

      this.ordinamento = res.data.toString();

      // Seleziono le checkbox
      for (let i = 0; i <= 3; i++) {
        if (this.ordinamento.toString().substring(i, 1) == "1")
          this.cdPa1 = true;
        if (this.ordinamento.toString().substring(i, 1) == "2")
          this.cdPa2 = true;
        if (this.ordinamento.toString().substring(i, 1) == "3")
          this.cdPa3 = true;
      }

      this.attesa = false;
    },

    async conferma() {
      this.attesa = true;

      let data = {
        CodTestata: this.testata,
        Suffisso: this.suffisso,
        CampiRotturaDivisione: this.ordinamento,
        PulisciCampiRotturaDivisione: this.pulisci
      };

      try {
        let res = await set_ordinamentoCatalogo(data);
      } catch (error) {
        console.log(error);
        this.$toast.alert(this.$i2kFunctions.getErrorMsg(error));
      }

      this.attesa = false;

      this.chiudi(true);
    },

    chiudi(aggiorna) {
      this.$emit("chiudi", aggiorna);
    },

    setOrdinamento() {
      let ord = "";
      if (this.cdPa1) ord = "1";
      if (this.cdPa2) ord += "2";
      if (this.cdPa3) ord += "3";

      // Aggiungo gli zeri alla fine
      for (let i = ord.length + 1; i <= 3; i++) {
        ord += "0";
      }

      this.ordinamento = ord
    }


  },

  mounted() {
    this.caricaDati();
  },



  watch: {
    dialog() {
      this.caricaDati();
    },
  },
};
</script>