<template>
  <v-dialog v-model="dialog" persistent max-width="50%">
    <v-card>
      <v-card-title>
        <v-row no-gutters>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                class="mr-5 mt-2"
                hide-details
                @click="refresh"
                text
                fab
                small
                v-on="on"
                elevation="0"
              >
                <v-icon color="primary" large>refresh</v-icon>
              </v-btn>
            </template>
            <span>Ricarica</span>
          </v-tooltip>
          <div>
            <v-text-field
              hide-details
              style="width: auto !important"
              color="primary"
              v-model="search"
              append-icon="search"
              label="Cerca"
            ></v-text-field>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            hide-details
            text
            icon
            elevation="0"
            class="mt-2"
            v-on:click="caricaAziende"
          >
            <v-icon large> close </v-icon>
          </v-btn>
        </v-row>
      </v-card-title>

      <v-card-text class="ma-0 pa-0">
        <v-row no-gutters>
          <v-col>
            <v-data-table
              dense
              height="40vh"
              :headers="headersTabella"
              :items="listaAziende"
              :items-per-page="listaAziende.length"
              class="elevation-0"
              :search="search"
              :loading="loadingTabella"
              loading-text="Caricamento dati..."
              no-data-text="Nessun dato disponibile"
              mobile-breakpoint="0"
              fixed-header
              hide-default-header
              hide-default-footer
              :page.sync="page"
              @page-count="pageCount = $event"
              style="cursor: pointer; border-bottom: 1px solid black"
            >
              <template v-slot:header="{ props: { headers } }">
                <thead>
                  <tr>
                    <th v-for="(head, i) in headers" :key="i">
                      <span v-html="head.text"></span>
                    </th>
                  </tr>
                </thead>
              </template>

              <template v-slot:item="{ item }">
                <tr
                  :class="{ 'row-selected': item === azienda }"
                  v-on:click.prevent="selectRow(item)"
                  v-on:dblclick.prevent="selectRowDouble(item)"
                >
                  <td>
                    {{ item.codiceAzienda }}
                  </td>
                  <td>
                    {{ item.nomeAzienda }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-layout justify-center column>
          <v-row no-gutters class="ml-3">
            <div class="mt-4">
              <span>
                Tot. Record: <b>{{ listaAziende.length }}</b></span
              >
            </div>
            <v-spacer />
            <div class="pa-1 mt-2">
              <v-btn class="btn-conferma" v-on:click="conferma">CONFERMA</v-btn>
            </div>
          </v-row>
        </v-layout>
      </v-card-text>

      <v-card-actions> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_listaAziende } from "@/api/api.archivi.js";

import objectArchivi from "@/views/intranet/archivi/objectArchivi.js";

export default {
  props: {
    dialog: { type: Boolean, default: null },
  },

  components: {},

  mixins: [objectArchivi],
  data: () => ({
    headerProps: {
      sortByText: "Ordina per",
    },
    loadingTabella: false,
    page: 1,
    pageCount: 0,
    search: "",
    headersTabella: [],
    listaAziende: [],
    filtri: {},

    azienda: {},
  }),

  methods: {
    caricaAziende() {
      this.search = "";
      this.$emit("caricaAziende", this.azienda);
      this.CaricaDati();
    },

    refresh() {
      this.search = "";
      this.filtri = this.filtriTabellaAziende();
      this.CaricaDati();
    },

    async CaricaDati() {
      this.loadingTabella = true;

      this.listaAziende = [];
      /* let par = {
        CodiceAzienda: this.filtri.CodiceAzienda,
      }; */
      let res = await get_listaAziende(); //par
      this.listaAziende = res.data;

      this.loadingTabella = false;
    },

    selectRow(i) {
      this.azienda = i;
    },

    selectRowDouble(i) {
      this.azienda = i;
      this.caricaAziende();
    },

    conferma() {
      this.caricaAziende();
    },
  },

  created() {},

  mounted() {
    this.CaricaDati();
    this.headersTabella = this.headerTabellaAziende();
    this.filtri = this.filtriTabellaAziende();
  },

  computed: {
    GetLoading() {
      return this.$store.getters["app/getLoading"];
    },
  },

  watch: {},
};
</script>

<style scoped></style>
