<template>
  <modal name="modal" :adaptive="true" :width="1800" :height="1125" :resizable="true" :draggable="false"
    :clickToClose="false" :minWidth="1300" :minHeight="1100">
    <v-card-title class="elevation-0" style="border-top-left-radius: 10px !important;">
      <div class="ml-3">
        <v-btn icon v-on:click="ricaricaFiltri" elevation="0">
          <v-icon color="primary">refresh</v-icon>
        </v-btn>
      </div>
      <v-row v-if="clickedRow" class="ma-0 pa-0">
        <v-text-field hide-details label="Codice Testata" :readonly="true"
          style="font-weight: bold; max-width:200px !important" v-model="clickedRow.codTestata" class="ml-8" />

        <v-text-field hide-details label="Suffisso" type="number" :readonly="true"
          style="font-weight: bold; max-width:100px !important" v-model="clickedRow.suffisso" class="ml-4 pr-4" />

        <v-text-field hide-details label="Descrizione" :readonly="true" style="font-weight: bold"
          v-model="clickedRow.descrizione" class="mr-8" />
      </v-row>

      <v-btn text icon>
        <v-icon v-on:click="chiudi" color="#000">close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card class="elevation-1">
      <div class="pa-0 elevation-0 ma-0" style="background-color: #FFF">
        <v-container fluid align-center space-between style="height: 100%">

          <v-row class="ma-0 pa-0">
            <gestione-ricerca ref="gestioneRicerca" :testataSel="clickedRow" />
          </v-row>

        </v-container>

      </div>

    </v-card>

    <v-card-actions>
      <v-spacer />
      <v-btn @click="annulla()">Annulla</v-btn>
      <v-btn color="primary" @click="conferma()">Conferma</v-btn>
    </v-card-actions>
  </modal>
</template>

<script>
import { } from "@/api/api.catalogo.js";
import GestioneRicerca from "@/components/intranet/GestioneRicerca2.vue";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    testateSelected: Array,
  },

  components: {
    GestioneRicerca,
  },

  data: () => ({
    clickedRow: {},
  }),

  methods: {
    chiudi() {
      this.$emit("chiudi");
      this.$modal.hide("modal");
    },

    async rowClick(item) {
      this.clickedRow = item;
    },

    async ricaricaFiltri() {
      await this.$refs.gestioneRicerca.caricaDati(true);
    },

    async conferma() {
      await this.$refs.gestioneRicerca.salvaDati(true);
    },

    annulla() {
      this.$emit("chiudi");
      this.$modal.hide("modal");
    },
  },

  watch: {
    dialog() {
      this.rowClick(this.testateSelected[0]);
      this.$modal.show("modal");
    },
  },

  mounted() {
    this.$modal.hide("modal");
  }
};
</script>