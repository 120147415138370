<template>
  <v-card tile class="text-xs footer footer-on-top">
    <v-container fluid style="max-width: 1300px; margin: auto">
      <v-card-text class="pa-0">
        <v-layout row wrap align-baseline>
          <!-- <v-flex xs12 mb-2>
            <img src="~@/assets/img/battisti.svg" alt="Battisti" class="mb-2" style="width: 140px" />
          </v-flex> -->

          <v-flex xs12 sm4>
            <p>
              <strong>Battisti Francesco snc</strong>
              <br />Via San Martino, 78
              <br />12032 Barge (CN)
              <br />Tel.
              <a href="tel:+390175518900" class="unstyle-link">+39 0175 518900</a>
              <br />PEC
              <a href="mailto:battistisnc@legalmail.it" class="unstyle-link">battistisnc@legalmail.it</a>
              <br />
              <a href="mailto:info@battisti.it" class="unstyle-link">info@battisti.it</a>
              <br />
              <a href="https://www.facebook.com/ferramenta.battisti/" alt="Facebook" class="unstyle-link">
                <span class="social-icon-link icon-facebook-squared" tilte="Facebook"></span>
              </a>
              <a href="https://www.instagram.com/battisti.it/" alt="Instagram" class="unstyle-link">
                <span class="social-icon-link icon-instagram" tilte="Instagram"></span>
              </a>
              <a href="https://www.linkedin.com/company/ferramenta-battisti/" alt="LinkedIn" class="unstyle-link">
                <span class="social-icon-link icon-linkedin-squared" tilte="LinkedIn"></span>
              </a>
            </p>
          </v-flex>
          <v-flex xs12 sm4>
            <p>
              C.F./P.I. 01845870045
              <br />Reg. Imprese 01845870045
              <br />R.E.A. Cuneo 139172
              <br />Cod. destinatario A4707H7
            </p>
          </v-flex>
          <v-flex xs12 sm4>
            <p>
              <a href="http://www.battisti.it/privacy.aspx" target="_blank" class="unstyle-link">Informativa sulla
                privacy</a>
              <br />
              <a href="http://www.battisti.it/cookies.aspx" target="_blank" class="unstyle-link">Informativa sui
                cookies</a>
              <br />
              <a href="http://www.battisti.it/condizionivendita.aspx" target="_blank" class="unstyle-link">Condizioni di
                vendita</a>
              <br />
              <a href="http://www.contributiconcessi.com/c/3/Battisti-Francesco-Snc" class="unstyle-link"
                target="_blank">Contributi concessi</a>
            </p>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
.footer-on-top {
  font-size: 0.85em;
  bottom: 0;
  background-color: #f0f0f0 !important;
}

.social-icon-link {
  font-size: 18px;
}
</style>