<template>
  <v-dialog v-model="dialog" width="1100" persistent>
    <v-card>
      <v-card-title>
        Inserimento numero documento
        <v-spacer />
        <v-btn icon @click="chiudi()">
          <v-icon large>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text v-if="datiCorriere.datiDocu">
        <v-row class="ma-0 pa-0">
          <v-select :items="getAnni()" v-model="datiCorriere.datiDocu.anno" style="width: 50px !important" class="pr-4"
            label="Anno" />

          <!-- Numero documento -> carica tutti i dati del destinatario -->
          <v-text-field v-model="datiCorriere.datiDocu.nrDocuStampato" ref="txtNrDocu" id="txtNrDocu" clearable
            class="pr-4" label="Numero documento" append-icon="search" @click:append="cercaDocumento()"
            style="width: 150px" @keydown.enter="caricaDatiDocumento()" required />

          <!-- Tipo documento (ordine fornitore, vendita, ecc...)-->
          <v-text-field v-model="datiCorriere.datiDocu.tpDocu" readonly label="Tipo documento" class="pr-4"
            style="width: 100px !important" required />

          <!-- Data documento stampato -->
          <v-text-field :value="getDtDocuStampato()" readonly label="Data documento" class="pr-4"
            style="width: 50px !important" required />

          <v-text-field v-model="datiCorriere.datiDocu.cdContatto" clearable label="Codice cliente" class="pr-4"
            required style="width: 150px" />
        </v-row>

        <v-row class="ml-2 mr-2">
          <v-text-field v-model="datiCorriere.vettore" readonly label="Vettore" class="pr-4"
            style="width: 100px !important" required />

          <v-text-field v-model="datiCorriere.tipoPorto" readonly label="Porto" class="pr-4" style="width: 100px"
            required />

          <v-text-field v-model="datiCorriere.destinatario" readonly label="Destinatario" class="ml-2 mr-4" required />
        </v-row>

        <v-row class="ml-2 mr-2">
          <!-- indirizzo destinatario -->
          <v-text-field v-if="datiCorriere.altraDestinazione != null && datiCorriere.altraDestinazione != ''"
            v-model="datiCorriere.altraDestinazione" readonly label="Altra Destinazione" class="pr-4" />

          <v-text-field v-else v-model="datiCorriere.indirizzoDestinatario" readonly label="Indirizzo Destinatario"
            class="pr-4" />

          <v-text-field v-model="datiCorriere.cap" readonly label="Cap" class="pr-4" style="max-width: 100px" required>
          </v-text-field>

          <v-text-field v-model="datiCorriere.localita" readonly label="Località" class="pr-4" required
            style="max-width: 350px" />

          <v-text-field v-model="datiCorriere.provincia" readonly label="Provincia" class="pr-4" required
            style="max-width: 100px" />

          <v-text-field v-model="datiCorriere.nazione" readonly label="Nazione" class="pr-4" style="max-width: 120px"
            required />
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn v-on:click="chiudi()"> annulla </v-btn>
        <v-btn class="btn-conferma" v-on:click="salva()" v-on:keyup.enter="salva()">
          aggiungi
        </v-btn>
      </v-card-actions>
    </v-card>
    <cerca-documento :dialog="dialogCercaDocu" @chiudi="chiudiCercaDocumento" />
  </v-dialog>
</template>

<script>
import { get_datiDocumento, get_datiCliente } from "@/api/api.etichette.js";
import CercaDocumento from "./CercaDocumento.vue";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    corriere: { type: Object, default: null },
  },
  components: {
    CercaDocumento,
  },
  data: () => ({
    dialogCercaDocu: false,
    datiCorriere: {},
  }),

  methods: {
    getAnni() {
      if (this.datiCorriere.datiDocu == undefined) return [];
      return [this.datiCorriere.datiDocu.anno - 1, this.datiCorriere.datiDocu.anno];
    },

    salva() {
      this.$emit("salvaDati", this.datiCorriere);
    },

    chiudi() {
      this.$emit("chiudi");
    },

    async caricaDatiDocumento() {
      if (!this.dialog) return;
      if (
        (this.datiCorriere.datiDocu.nrDocu == null ||
          this.datiCorriere.datiDocu.nrDocu == "") &&
        (this.datiCorriere.datiDocu.nrDocuStampato == null ||
          this.datiCorriere.datiDocu.nrDocuStampato == "")
      ) {
        this.$toast.alert("Numero documento non valido");
        return;
      }

      // salvo la descrizione del spedizione
      let descrizione = this.datiCorriere.descrCodiceSpedizione;
      if (
        this.datiCorriere.datiDocu.cdContatto == "" ||
        this.datiCorriere.datiDocu.cdContatto == null
      )
        this.datiCorriere.datiDocu.cdContatto = descrizione.split(" ")[1];

      let res = await get_datiDocumento(this.datiCorriere.datiDocu);

      if (res.status == 200 && res.data != null && res.data != "") {
        this.datiCorriere = res.data;

        this.datiCorriere.descrCodiceSpedizione = descrizione;

        //se non ci sono i dati della destinazione alternativa recupero i dati del cliente
        if (this.datiCorriere.altraDestinazione == "") {
          res = await get_datiCliente(this.datiCorriere.datiDocu.cdContatto);
          this.datiCorriere.destinatario = res.data.destinatario;
          this.datiCorriere.indirizzoDestinatario = res.data.indirizzoDestinatario;
          this.datiCorriere.cap = res.data.cap;
          this.datiCorriere.localita = res.data.localita;
          this.datiCorriere.provincia = res.data.provincia;
          this.datiCorriere.nazione = res.data.nazione;
        }
      } else
        this.$toast.alert(
          "Numero documento non valido per il cliente " +
          this.datiCorriere.datiDocu.cdContatto
        );
    },

    getDtDocuStampato() {
      return this.$i2kFunctions.formatISODate(
        this.datiCorriere.datiDocu.dtDocuStampato.substring(0, 10)
      );
    },

    async setTxtFocus() {
      this.datiCorriere.datiDocu.nrDocu = "";

      await this.wait(400);
      this.$refs.txtNrDocu.focus();
    },

    async wait(millisecs) {
      let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
      await wait(millisecs);
    },

    cercaDocumento() {
      this.dialogCercaDocu = true;
    },

    chiudiCercaDocumento(nrDocuStampato, tpDocu, nrDocu) {
      this.dialogCercaDocu = false;
      if (nrDocu) {
        this.datiCorriere.datiDocu.nrDocuStampato = nrDocuStampato;
        this.datiCorriere.datiDocu.nrDocu = nrDocu;
        this.datiCorriere.datiDocu.tpDocu = tpDocu;
        this.caricaDatiDocumento();
      }
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal) {
        this.datiCorriere = this.corriere;
        this.datiCorriere.datiDocu.nrDocuStampato = "";
        this.setTxtFocus();
      }
    },
  },
};
</script>