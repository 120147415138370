<template>
    <v-navigation-drawer width="380" fixed app mobile-breakpoint="400" :clipped="$vuetify.breakpoint.mdAndUp" right>

        <v-btn dark small color="red" @click="caricaDati()" :disabled="attesa" class="ma-2">
            Visualizza
            <v-icon>arrow_forward</v-icon>
        </v-btn>

        <v-data-table dense fixed-header height="calc(100vh - 150px)" disable-pagination hide-default-footer
            :items="zone" :headers="headersZone">

            <!-- Personalizzo la colonna descrizione in formato HTML -->
            <!-- eslint-disable-next-line -->
            <template v-slot:item.coloreHtml="{ item }">
                <span style="width: 40px; height: 10px; display: block;" :style="{ backgroundColor: item.coloreHtml }"></span>
            </template>

        </v-data-table>
    </v-navigation-drawer>
</template>


<script>
export default {

    props: {
        zone: { type: Array, default: () => [] },
    },

    data: () => ({
        attesa: false,

        headersZone: [{
            text: "Zona",
            value: "zona_Passaggio",
            sortable: false,
            width: 40
        },
        {
            text: "Comuni",
            value: "contComuni",
            sortable: false,
            width: 40
        },
        {
            text: "Descrizione",
            value: "descrizioneZona",
            sortable: false,
        },
        {
            text: "Colore",
            value: "coloreHtml",
            sortable: false,
        },
        ]
    }),

    methods: {
        caricaDati() {
            this.$emit("caricaDati");
        }
    }
}
</script>