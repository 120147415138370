<template>
    <v-container fluid class="pt-0">
        <v-row class="ma-0 pa-0 mt-2">
            <v-col style="border-bottom: 1px solid black;" class="ma-0 pa-0">
                <div style="height: 26px;">
                    <span v-if="elencoTestate && elencoTestate.length > 0">
                        {{ elencoTestate.length }} record</span>
                </div>
                <v-data-table dense mobile-breakpoint="0" fixed-header :items="elencoTestate" :headers="headersTestate"
                    :items-per-page="999" hide-default-footer height="29vh" no-data-text="Nessun dato disponibile">
                    <template v-slot:item="{ item }">
                        <tr class="is-clickable" :class="{ 'row-selected': item === testataSel }"
                            v-on:click.prevent="selectRow(item)">
                            <td>
                                <v-row class="ma-0 pa-0">
                                    <v-col class="ma-0 pa-0" cols="4">
                                        {{ item.nrDocuStampato }}
                                    </v-col>
                                    <v-col class="ma-0 pa-0" cols="2">
                                        <v-icon v-if="item.noteDocumento" class="is-clickable"
                                            :class="{ 'row-selected': item === testataSel }" @click="apriInfoNote(item)"
                                            v-tooltip.right="'Note documento'">text_snippet</v-icon>
                                    </v-col>
                                </v-row>
                            </td>
                            <td>
                                <v-row class="ma-0 pa-0" style="max-width: 150px !important;">
                                    {{ item.nrBolla }}
                                    <v-spacer />
                                    <v-icon v-if="item.pathExcel" @click="apriFile(item.pathExcel)"
                                        class="mr-4">grid_on</v-icon>
                                    <v-icon v-if="item.pathDDT"
                                        @click="apriDocumento(item.pathDDT)">picture_as_pdf</v-icon>
                                </v-row>
                            </td>
                            <td>{{ item.nrDocuTerzista == 0 ? '' : item.nrDocuTerzista }} </td>
                            <td>{{ item.tpDocu }}</td>
                            <td>{{ $i2kFunctions.formatISODate(item.dtDocu) }}</td>
                            <td>{{ $i2kFunctions.formatISODate(item.dtBolla) }}</td>
                            <td>{{ item.ragioneSociale }}</td>
                            <td><v-btn icon @click=" modificaRigo()"> <v-icon> edit </v-icon></v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
            <v-col class="mb-1 pb-0 mt-2 pt-0">
                <v-row class="ma-0 pa-0" v-if="righeDettaglio.length">
                    <span>Dettaglio documento <strong>{{ testataSel.nrDocuStampato }}</strong></span>
                    <!-- <v-btn icon small dense hide-details class="mt-0 pt-0 ml-4"
                    v-tooltip.top="'Esporta EXCEL'"><v-icon>grid_on</v-icon></v-btn> -->
                </v-row>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
            <v-col class="ma-0 pa-0" style="border-bottom: 1px solid black;" v-if="righeDettaglio.length > 0">
                <v-data-table height="35vh" dense mobile-breakpoint="0" fixed-header :items="righeDettaglio"
                    :headers="headersDettaglio" hide-default-footer :items-per-page="999"
                    no-data-text="Selezionare un documento per caricare i dati" class="table-vertical-divider">
                    <template v-slot:item="{ item }">
                        <tr>
                            <td class="is-clickable mr-0 pr-0" v-on:click.prevent="selectDettaglio(item)">
                                {{ item.nrRigaDocu }}</td>
                            <td class="mr-0 pr-0">
                                <v-simple-checkbox v-if="item.tipo == 'PRODOTTO'" v-model="item.selezionato"
                                    color="primary" hide-details small dense />
                            </td>
                            <td class="is-clickable pl-2" style="min-width: 170px;"
                                v-on:click.prevent="selectDettaglio(item)">
                                {{ $functionsApp.getCodArticoloFormat(item.cdArticoloPadre) }}
                            </td>
                            <td class="is-clickable pl-2" v-on:click.prevent="selectDettaglio(item)">
                                {{ item.descrizioneProdotto }}
                            </td>
                            <td class="mr-0 pr-0">
                                <v-switch small v-if="item.tipo == 'PRODOTTO'" v-model="item.stampaEtichette"
                                    color="primary" hide-details class="mt-0" @click="attivaStampa(item)" />
                            </td>
                            <td class="mr-0 pr-0 pl-2">
                                <v-text-field v-if="item.tipo == 'PRODOTTO'" hide-details small class="mt-0 mb-1"
                                    style="max-width: 60px !important;" @focus="$event.target.select()"
                                    v-model="item.numeroEtichette" :disabled="!item.stampaEtichette"
                                    @input="cambiaNumEtichette()" :id="'txtNumeroEtichette' + item.nrRigaDocu"
                                    :ref="'txtNumeroEtichette' + item.nrRigaDocu" />
                            </td>
                            <td class="is-clickable pl-2" v-on:click.prevent="selectDettaglio(item)">
                                {{ item.codLavorazione }}</td>
                            <td class="is-clickable pl-2" v-on:click.prevent="selectDettaglio(item)">
                                {{ item.descrizioneAggiuntiva }}</td>
                            <td class="is-clickable mr-1 pr-1"
                                style="align-items: end; text-align: end; min-width:110px !important;"
                                v-on:click.prevent="selectDettaglio(item)">
                                <span v-if="item.qtOrdinata != 0" class="mr-2">{{ item.qtOrdinata }}
                                    {{ item.unMisura }}</span>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
            <v-col class="ma-0 pa-0" v-else>
                <div>Selezionare un documento per caricare i dati</div>
            </v-col>
        </v-row>

        <modal name="infoNote" :width="800" :height="270" :draggable="true" :clickToClose="true" :minWidth="500"
            :minHeight="250">
            <v-card-title class="pb-0 pt-1 pr-1">
                <v-spacer />
                <v-btn icon @click=chiudiInfoNote()><v-icon>close</v-icon></v-btn>
            </v-card-title>
            <v-card-text style="border-top: solid 1px grey;">
                <v-textarea readonly v-model="noteDocumento" label="Note" :rows="7" />
            </v-card-text>
        </modal>

        <v-dialog v-model="dialogModificaRigo" max-width="700px">
            <v-card>
                <v-card-title>
                    <span>Numero DDT <strong>{{ testataSel.nrBolla }}</strong>
                        del {{ $i2kFunctions.formatISODate(testataSel.dtBolla) }}</span>
                    <v-spacer />
                    <v-btn icon @click="chiudiDialog()"><v-icon>close</v-icon></v-btn>
                </v-card-title>

                <v-card-text>
                    <v-row class="ma-0 pa-0">
                        <v-text-field type="number" v-model="testataSel.nrDocuTerzista" label="Numero ordine SIMAPAC"
                            hide-details ref="TXTnumOrdTerzista" @focus="$event.target.select()" />
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer />
                    <v-btn v-on:click="chiudiDialog()">Annulla</v-btn>
                    <v-btn color="primary" v-on:click="salvaDatiRigo()">SALVA</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <dialog-dettaglio-prezzi :auth="auth" :role="role" :group="group" :floatingModal="floatingModal"
            origine="terzisti" :cdArticolo="dettaglioSel.cdArticolo" @floatingModalPermanent="floatingModalPermanent" />

        <pdf-viewer :dialog="previewDialog.show" :src="previewDialog.src" :type="previewDialog.type"
            @chiudi="closePdfDialog" />
    </v-container>
</template>

<script>
import { get_terzisti, post_terzisti } from "@/api/api.lottiProd.js";
import dialogDettaglioPrezzi from "@/components/dialog/catalogo/DettaglioPrezzi.vue";
import PdfViewer from "@/components/dialog/Viewer.vue";

export default {
    name: 'DettaglioDocumenti',
    props: {
        search: { type: String, default: "" },
        elencoTestate: { type: Array, default: () => { return [] } },
        clear: { type: Boolean, default: false },
        soloOrdiniAperti: { type: Boolean, default: false },
    },
    components: {
        dialogDettaglioPrezzi, PdfViewer
    },
    data: () => ({
        dialogModificaRigo: false,

        headersTestate: [
            { text: "Numero documento", value: "nrDocuStampato" },
            { text: "Numero DDT", value: "nrBolla", witdh: 200 },
            { text: "Numero doc interno", value: "nrDocuTerzista" },
            { text: "Tipo documento", value: "tpDocu" },
            { text: "Data documento", value: "dtDocu" },
            { text: "Data DDT", value: "dtBolla" },
            { text: "Ragione sociale", value: "ragioneSociale" },
            { text: "", value: "modifica", sortable: false },],
        righeDettaglio: [],
        headersDettaglio: [
            { text: "Numero riga", value: "nrRigaDocu", class: "mr-0 pr-0" },
            { text: "Seleziona", value: "", class: "mr-0 pr-0" },
            { text: "Articolo", value: "cdArticolo", class: "ml-0 pl-0" },
            { text: "Descrizione prodotto", value: "descrizioneProdotto", class: "ml-0 pl-0" },
            { text: "Stampa etichette", value: "", sortable: false, class: "mr-0 pr-0" },
            { text: "Num etichette", value: "", sortable: false, class: "ml-0 pl-0" },
            { text: "Cod. lavorazione", value: "codLavorazione", class: "ml-0 pl-0" },
            { text: "Lavorazione", value: "descrizioneAggiuntiva", class: "ml-0 pl-0" },
            { text: "Quantità", value: "qtordinata", class: "mr-2 pr-4", align: "end" }],

        testataSel: {},
        dettaglioSel: {},

        // dialog info articolo
        floatingModal: {
            transformStyle: { transform: "" },
            isPermanent: false,
            isHover: false,
        },

        noteDocumento: "",

        previewDialog: {
            show: false,
            src: "",
            type: "",
        },
    }),

    methods: {

        floatingModalPermanent(vis) {
            this.floatingModal.isPermanent = vis;
        },

        selectDettaglio(item) {
            this.dettaglioSel = item;
            this.floatingModal.isPermanent = true;
        },

        chiudiDialog() {
            this.dialogModificaRigo = false;
        },

        async caricaDettaglio() {
            let res = await get_terzisti("dettaglio/elenco/" + this.testataSel.nrDocu + (this.soloOrdiniAperti ? "/1" : "/0"));
            this.righeDettaglio = res.data;
        },

        selectRow(item) {
            this.testataSel = item;
            this.caricaDettaglio();
            this.$emit("handleTestataSel", this.testataSel);
        },

        async attivaStampa(item) {
            if (!item.stampaEtichette) return;
            else {
                if (item.numeroEtichette == null || item.numeroEtichette == "" || item.numeroEtichette == 0) item.numeroEtichette = 1;
                let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
                await wait(75);
                this.$refs["txtNumeroEtichette" + item.nrRigaDocu].focus();
                this.$emit("handleNumEtichette", this.righeDettaglio);
            }
        },

        cambiaNumEtichette() {
            this.$emit("handleNumEtichette", this.righeDettaglio);
        },

        modificaRigo() {
            this.dialogModificaRigo = true;
        },

        async salvaDatiRigo() {
            let res = await post_terzisti("lavorazione/numerazione", this.testataSel);
            if (res.data == "OK") {
                this.$toast.info("Dati salvati");
                this.dialogModificaRigo = false;
            } else this.$toast.error("Errore. " + res.data);
        },

        apriInfoNote(item) {
            if (item.noteDocumento != null && item.noteDocumento != "") {
                this.noteDocumento = item.noteDocumento;
                this.$modal.show("infoNote");
            }
        },

        chiudiInfoNote() {
            this.noteDocumento = "";
            this.$modal.hide("infoNote");
        },

        apriDocumento(path) {
            if (path != "" || path != null) {
                this.previewDialog.show = true;
                this.previewDialog.src = path;
                this.previewDialog.type = "pdf";
            }
        },

        apriFile(pathFile) {
            window.open(pathFile, "_blank");
        },

        closePdfDialog() {
            this.previewDialog.show = false;
        },

        salvaHeaders() {
            this.$emit("salvaHeaders", this.headersTestate);
        }
    },

    mounted() {
        this.salvaHeaders();
    },

    watch: {
        clear: function () {
            this.testataSel = {};
            this.righeDettaglio = [];
        },

        dialogModificaRigo(newVal) {
            if (newVal) this.$nextTick(() => this.$refs.TXTnumOrdTerzista.focus());
        }
    },

    computed: {
        auth() {
            return this.$store.getters["auth/isAuthenticated"];
        },

        role() {
            return this.$store.getters["user/getRole"];
        },

        group() {
            return this.$store.getters["user/getGroup"];
        },
    },
}
</script>