<template>
  <v-container fill-height fluid>
    <v-row justify="center" align="center">
      <v-col cols="12">
        <v-card class="pa-1 justify-center text-center" flat>
          <div v-show="attesaVerifica" style="font-size: 20px">
            Caricamento....
          </div>

          <v-alert :value="!!alertElaborazione" :type="tipo" transition="scale-transition" style="width: 100%"
            v-html="alertElaborazione">
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get_ordineCliente } from "@/api/api.intranet.js";
import storage from "@/services/storage.js";

export default {
  data: () => ({
    alertElaborazione: "",
    tipo: "warning",
    attesaVerifica: false,

    cred: {
      username: "",
      password: "",
    },
  }),

  methods: {
    async verificaToken() {
      // NOTA IMPORTANTE: la mounted di questo componente non attende che finisca
      //                  la funzione verificaToken, e passa alla mounted del genitore Main.vue
      //                  tutti i controlli e caricamenti devono essere fatti qui dentro
      //                  ho gestito da Vuex l'attesa con waitLoginAzienda (che leggo da Main.vue)

      try {
        // Controllo querystring
        if (this.checkQueryStringCode() == false) {
          this.alertElaborazione = "Token non valido";
          this.tipo = "warning";
          return;
        }
        if (this.checkQueryStringCliente() == false) {
          this.alertElaborazione = "Cliente non valido";
          this.tipo = "warning";
          return;
        }


        this.attesaVerifica = true;
        this.$store.commit("app/setWaitLoginAzienda", true);

        let par =
          "?api_key=" +
          this.$route.query.api_key +
          "&codCliente=" +
          this.$route.query.codCliente;

        // Ricavo le credenziali
        let res = await get_ordineCliente(par);
        let resDati = res.data;

        // Controllo login
        if (storage.checkAuth() == false) {
          await this.$store.dispatch("auth/login", resDati.accessCred);
        }

        // Cliente selezionato
        this.$store.commit("app/setCliente", resDati.anagrafica);

        // Ricerca
        let url =
          "/ricerca/" +
          (this.$route.query.ricerca ? this.$route.query.ricerca + "/" : "") +
          "prodotti";

        this.$router.push({ path: url });
      } catch (error) {
        console.log("OrdiniClienti:" + error);
        this.alertElaborazione = this.$i2kFunctions.getErrorMsg(error);
      }

      this.attesaVerifica = false;
      this.$store.commit("app/setWaitLoginAzienda", false);
    },

    checkQueryStringCode() {
      if (
        this.$route.query.api_key &&
        this.$route.query.api_key.length > 0
      ) {
        return true;
      }
    },


    checkQueryStringCliente() {
      if (
        this.$route.query.codCliente &&
        this.$route.query.codCliente.length > 0
      ) {
        return true;
      }

      return false;
    },
  },

  mounted() {
    this.verificaToken();
  },
};
</script>