<template>
  <v-card color="white" class="elevation-0">
    <toolBarfiltro @aggiornaDati="filtroDati"></toolBarfiltro>

    <v-data-table :headers="getHeader" :items="getDati" item-key="name" class="elevation-0" disable-pagination
      :loading="attesa" hide-default-footer fixed-header :height="altezzaGrid" loading-text="Caricamento..."  no-data-text="Nessun dato trovato">
      <!-- Personalizzo la colonna data -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.dtDocuStampato="{ item }">
        {{ new Date(item.dtDocuStampato).toLocaleDateString("it-IT") }}
      </template>
      <!-- Personalizzo la colonna data -->
      <!-- eslint-disable-next-line -->
      <template v-slot:item.dataPrevistaConsegna_For="{ item }">
        {{
          item.dataPrevistaConsegna_For != null ? new Date(item.dataPrevistaConsegna_For).toLocaleDateString("it-IT") : ''
        }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.ragioneSociale="{ item }">
        {{ $functionsApp.convertiTestoMinuscolo(item.ragioneSociale) }}
      </template>
    </v-data-table>

    <v-footer class="elevation-0" color="white"><v-container class="pa-0">
        <v-row class="text-right">
          <v-col cols="8"><span v-show="tipo == 'fornitore'">Ordinato cliente: {{ getOrdinatoCliente }}</span></v-col>
          <v-col cols="2">Totale :</v-col>
          <v-col cols="2" style="font-weight: bold"> {{ getTotale }}</v-col>
        </v-row></v-container></v-footer>
  </v-card>
</template>

<script>
import { get_datiDettaglioOrdinato } from "@/api/api.catalogo.js";
import toolBarfiltro from "@/components/ui/FiltroDettaglioPrezzi.vue";

export default {
  components: {
    toolBarfiltro,
  },
  props: {
    cdArticolo: { type: String, default: "" },
    aggiorna: { type: Boolean, default: false },
    tipo: { type: String, default: "" },
    altezzaGrid: { type: String, default: "" },
  },
  data: () => ({
    headersCliente: [
      {
        text: "Codice",
        value: "cdAnagrafica",
        sortable: true,
      },
      {
        text: "Ragione Sociale",
        value: "ragioneSociale",
        sortable: true,
      },
      {
        text: "Nr. ordine",
        value: "nrDocuStampato",
        sortable: true,
        align: "end",
      },
      { text: "Data", value: "dtDocuStampato", sortable: false },
      {
        text: "Qta evasa.",
        value: "qtEvasaStoccata",
        sortable: true,
        align: "end",
      },
      {
        text: "Qta ord.",
        value: "qtOrdinata",
        sortable: true,
        align: "end",
      },
      {
        text: "Rif.",
        value: "rfCliente",
        sortable: true,
        align: "end",
      },
    ],

    headersFor: [
      {
        text: "Ragione Sociale",
        value: "ragioneSociale",
        sortable: false,
      },
      {
        text: "Numero",
        value: "nrDocuStampato",
        width: "12%",
        sortable: false,
        align: "end",
      },
      {
        text: "Data consegna",
        value: "dataPrevistaConsegna_For",
        sortable: false,
      },
      {
        text: "Qta ord.",
        value: "qtOrdFor",
        width: "15%",
        sortable: false,
        align: "end",
      },
    ],

    dati: [],
    totRicevuto: 0,

    attesa: false,

    datiFiltro: {},
    ordinatoCliente: [],

    search: "",
  }),

  methods: {
    async caricaDati() {
      // Reset
      this.dati = [];
      this.ordinatoCliente = [];

      this.attesa = true;

      let res = await get_datiDettaglioOrdinato(
        this.tipo + "/" + this.cdArticolo
      );
      this.dati = res.data;

      // Controllo fornitore, per caricare l'ordinato cliente
      if (this.tipo == "fornitore") {
        res = await get_datiDettaglioOrdinato("cliente/" + this.cdArticolo);
        this.ordinatoCliente = res.data;
      }

      this.attesa = false;
    },

    filtroDati(par) {
      this.search = par;
    },
  },

  mounted() {
    this.caricaDati();
  },

  computed: {
    getHeader() {
      return this.tipo == "cliente" ? this.headersCliente : this.headersFor;
    },

    getDati() {
      let res = this.dati;

      // Ricerca
      if (this.search && this.search.filtro) {
        res = res.filter(
          (f) =>
            f.ragioneSociale
              .toString()
              .toLowerCase()
              .includes(this.search.filtro.toLowerCase()) ||
            f.nrDocuStampato
              .toString()
              .toLowerCase()
              .includes(this.search.filtro.toLowerCase())
        );
      }

      // Data inizio cliente
      if (this.tipo == "cliente" && this.search.dataInizio) {
        res = res.filter(
          (f) =>
            f.dtDocuStampato.toString().substring(0, 7) >=
            this.search.dataInizio.toString().substring(0, 7)
        );
      }

      // Data inizio fornitore
      if (this.tipo == "fornitore" && this.search.dataInizio) {
        res = res.filter(
          (f) =>
            f.dataPrevistaConsegna_For.toString().substring(0, 7) >=
            this.search.dataInizio.toString().substring(0, 7)
        );
      }

      // Data fine cliente
      if (this.tipo == "cliente" && this.search.dataFine) {
        res = res.filter(
          (f) =>
            f.dtDocuStampato.toString().substring(0, 7) <=
            this.search.dataFine.toString().substring(0, 7)
        );
      }

      // Data fine fornitore
      if (this.tipo == "fornitore" && this.search.dataFine) {
        res = res.filter(
          (f) =>
            f.dataPrevistaConsegna_For.toString().substring(0, 7) <=
            this.search.dataFine.toString().substring(0, 7)
        );
      }

      return res;
    },

    getTotale() {
      if (Object.keys(this.getDati).length == 0) return 0;

      if (this.tipo == "cliente")
        return this.getDati
          .map((f) => f.qtOrdinata)
          .reduce((acc, amount) => acc + amount);
      else
        return this.getDati
          .map((f) => f.qtOrdFor)
          .reduce((acc, amount) => acc + amount);
    },

    getOrdinatoCliente() {
      if (this.ordinatoCliente.length == 0) return 0;

      return this.ordinatoCliente
        .map((f) => f.qtOrdinata)
        .reduce((acc, amount) => acc + amount);
    },
  },

  watch: {
    aggiorna() {
      this.caricaDati();
    },
  },
};
</script>