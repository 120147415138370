<template>
  <v-container fluid class="pt-0">
    <v-row class="ma-0 pa-0 mt-2">
      <v-col style="border-bottom: 1px solid black;" class="ma-0 pa-0">
        <div style="height: 26px;">
          <v-row class="ma-0 pa-0 mb-2">
            <span v-if="elencoLavorazioni && elencoLavorazioni.length > 0">
              {{ elencoLavorazioni.length }} record
            </span>
            <!-- <v-spacer />
            <v-btn small> Modifica lavorazioni </v-btn>
            <v-btn small> Modifica materiali base </v-btn> -->
          </v-row>
        </div>
        <v-data-table
          dense
          mobile-breakpoint="0"
          :items="elencoLavorazioni"
          :headers="headersTestate"
          :items-per-page="999"
          hide-default-footer
          :search="search"
          height="60vh"
          fixed-header
          no-data-text="Nessun dato disponibile"
        >
          <template v-slot:item="{ item }">
            <tr
              class="is-clickable"
              :class="{ 'row-selected': item === lavorazioneSel }"
              v-on:click.prevent="selectRow(item)"
            >
              <td>{{ item.descrizione }}</td>
              <td>{{ item.tipoInvio }}</td>
              <td>{{ item.descrizioneBase }}</td>
              <td>{{ item.materialeBase }}</td>
              <td>{{ item.descrizioneLavorazione }}</td>
              <!-- <td>
                <v-btn icon @click="modificaRigo(item)">
                  <v-icon> edit </v-icon>
                </v-btn>
              </td> -->
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <dialog-modifica-lavorazione
      :lavorazione="lavorazioneSel"
      :dialog="dialogModifica"
      @chiudi="chiudiDialog"
    />
  </v-container>
</template>

<script>
import DialogModificaLavorazione from "@/components/dialog/terzisti/DialogModificaLavorazione.vue";
export default {
  name: "GestioneLavorazioni",

  components: {
    DialogModificaLavorazione,
  },

  props: {
    search: { type: String, default: "" },
    elencoLavorazioni: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data: () => ({
    headersTestate: [
      { text: "Descrizione", value: "descrizione" },
      { text: "Tipo invio", value: "tipoInvio" },
      { text: "Base", value: "descrizioneBase" },
      { text: "Materiale base", value: "materialeBase" },
      { text: "Lavorazioni", value: "descrizioneLavorazione" },
      /* { text: "", value: "azioni" }, */
    ],

    lavorazioneSel: {},

    dialogModifica: false,
  }),

  methods: {
    selectRow(item) {
      this.lavorazioneSel = item;
    },

    modificaRigo(item) {
      this.selectRow(item);
      this.dialogModifica = true;
    },

    chiudiDialog() {
      this.dialogModifica = false;
    },
  },
};
</script>
