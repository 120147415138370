var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.testataSel != true)?_c('v-layout',{attrs:{"justify-center":"","column":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 mt-2",attrs:{"hide-details":"","text":"","fab":"","small":"","elevation":"0"},on:{"click":_vm.refresh}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("refresh")])],1)]}}],null,false,2837982368)},[_c('span',[_vm._v("Ricarica")])]),_c('v-text-field',{attrs:{"disabled":_vm.loadingTabella,"hide-details":"","color":"primary","append-icon":"search","label":"Ragione Sociale"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.caricaDati($event)},"click:append":_vm.caricaDati},model:{value:(_vm.filtri.RagioneSociale),callback:function ($$v) {_vm.$set(_vm.filtri, "RagioneSociale", $$v)},expression:"filtri.RagioneSociale"}}),_c('v-spacer'),_c('v-text-field',{attrs:{"disabled":_vm.loadingTabella,"hide-details":"","color":"primary","append-icon":"search","label":"Cerca"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1):_vm._e(),_c('v-row',{staticClass:"ma-0 pa-0 mt-2"},[_c('v-col',[_c('v-data-table',{staticClass:"elevation-0 mt-5",staticStyle:{"border-bottom":"1px solid black"},attrs:{"dense":"","headers":_vm.headerLotti,"items":_vm.listaLotti,"items-per-page":100,"search":_vm.search,"loading":_vm.loadingTabella,"loading-text":"Caricamento dati...","no-data-text":"Nessun dato disponibile","mobile-breakpoint":"0","fixed-header":"","hide-default-header":"","page":_vm.page,"height":"35vh","hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(head,i){return _c('th',{key:i},[_c('span',{domProps:{"innerHTML":_vm._s(head.text)}})])}),0)])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"is-clickable",class:{ 'row-selected': item === _vm.testataSel },on:{"click":function($event){$event.preventDefault();return _vm.selectRow(item)}}},[_c('td',[_vm._v(_vm._s(item.ragioneSociale))]),_c('td',[_vm._v(_vm._s(item.documento))]),_c('td',[_vm._v(_vm._s(_vm.$i2kFunctions.formatISODate(item.dtDocumento)))])])]}}])})],1)],1),_c('v-row',{staticClass:"ma-0 pa-0 mt-4"},[_c('v-col',[(_vm.righeDettaglio)?_c('v-data-table',{attrs:{"height":"45vh","dense":"","mobile-breakpoint":"0","fixed-header":"","items":_vm.righeDettaglio,"headers":_vm.headersDettaglio,"hide-default-footer":"","items-per-page":999,"no-data-text":"Selezionare un ordine per caricare i dati"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$functionsApp.getCodArticoloFormat(item.articolo)))]),_c('td',[_vm._v(_vm._s(item.descrizione))]),_c('td',[_vm._v(_vm._s(item.numLotto))]),_c('td',[_vm._v(_vm._s(item.qta))])])]}}],null,false,1340799143)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }