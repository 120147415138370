<template src="./marchiAzienda.html"></template>

<script>
import {
  get_aziende,
  edit_marchio,
  add_marchio,
  delete_marchio,
  getMarchio,
} from "@/api/api.archivi.js";

import DialogLineaProdotto from "@/components/dialog/archivi/DialogLineaProdotto.vue";
import LogOperazioni from "@/views/intranet/LogOperazioni.vue";

import objectArchivi from "@/views/intranet/archivi/objectArchivi.js";

export default {
  components: { DialogLineaProdotto, LogOperazioni },

  mixins: [objectArchivi],
  data: () => ({
    headerProps: {
      sortByText: "Ordina per",
    },
    loadingTabella: false,
    page: 1,
    pageCount: 0,
    search: "",
    headerAnagrafiche: [],
    listaProdotti: [],
    listaProdottiSupp: [],
    filtri: {},
    testata: {},
    testataSel: false,
    aggiungi: false,
    campoReadOnly: false,
    rules: {
      required: (value) => !!value || "Campo obbligatorio.",
    },
    logo: "",
    tabs: 0,
    storico: false,

    menuDocDx: {
      show: false,
      x: 0,
      y: 0,
      activatedBy: "",
    },
    codiceMarchioAzienda: "",
    codiceMarchioAziendaTestata: "",

    //dialog
    dialogLineaProdotto: false,

    //form valodate
    valid: true,
  }),

  methods: {
    async caricaDati() {
      this.loadingTabella = true;

      this.listaProdotti = [];
      let par = {
        CodiceAzienda: this.filtri.CodiceAzienda,
        Descrizione: this.filtri.Descrizione,
      };
      let res = await get_aziende(par);
      this.listaProdotti = res.data;

      await this.caricaLoghiAziende();

      this.apriMarchio(); //dettaglio del marchio --> da TestataProdotto.vue

      this.loadingTabella = false;
    },

    async caricaLoghiAziende() {
      for (let i = 0; i < this.listaProdotti.length; i++) {
        this.listaProdotti[i].logo =
          "http://www.battisti.cloud:2082/CATALOGO%20FM/SitoBattisti/Immagini/MarchiAziende/" +
          this.listaProdotti[i].codiceAzienda +
          ".png";
      }
    },

    refresh() {
      this.aggiungi = false;
      this.campoReadOnly = false;
      this.filtri = this.filtriTabelle();
      this.caricaDati();
      this.resetTabella();
    },

    selectRow(i) {
      this.testata = i;
    },

    async selectRowDouble() {
      this.testataSel = true;
      this.aggiungi = false;
      //chiamata per descirzioneLinea
      let res = await getMarchio(this.testata);
      this.testata = res.data;
      this.logo =
        "http://www.battisti.cloud:2082/CATALOGO%20FM/SitoBattisti/Immagini/MarchiAziende/" +
        this.testata.codiceAzienda +
        ".png";
    },

    async salvaDati() {
      if (this.$refs.form.validate() == false) return;
      else {
        let confirm = await this.$confirm.open("Salva dati", "Salvare i dati correnti?");
        if (!confirm) return;

        if (this.aggiungi === true) {
          //insert
          let res = await add_marchio(this.testata);
          if (res.data == "OK") {
            //this.resetTabella();
            this.refresh();
            this.aggiungi = false;
          } else this.$toast.error("Errore. " + res.data);
        } else {
          //update
          let res = await edit_marchio(this.testata);
          if (res.data == "OK") {
            //this.resetTabella();
            this.refresh();
          } else this.$toast.error("Errore. " + res.data);
        }
      }
    },

    caricaLinee(linea) {
      if (this.dialogLineaProdotto === false) this.dialogLineaProdotto = true;
      else this.dialogLineaProdotto = false;
      if (linea.codiceLinea != "" && linea.codiceLinea != null) {
        this.testata.codiceLinea = linea.codiceLinea;
        this.testata.descrizioneLinea = linea.descrizione;
      }
    },

    goToLink(link) {
      if (link != undefined && link != null) window.open("http://" + link, "_blank");
    },

    resetTabella() {
      //this.testata = {};
      this.testataSel = false;
      this.campoReadOnly = false;
    },

    async eliminaTestata() {
      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare questo marchio azienda?"
      );
      if (!confirm) return;

      let res = await delete_marchio(this.testata);
      if (res.data == "OK") {
        this.resetTabella();
        this.refresh();
      } else this.$toast.error("Errore nell'eliminazione");
    },

    aggiungiTestata() {
      this.testata = {};
      this.logo = "";
      this.aggiungi = true;
      this.testataSel = true;
      this.campoReadOnly = true;
    },

    modificaTestata() {
      if (this.testata != null) this.selectRowDouble();
    },

    onScroll() {
      const output = document.getElementById("wheel");
    },

    apriMenuDocDx(e, item) {
      e.preventDefault();
      this.codiceMarchioAzienda = item.codiceAzienda;
      this.menuDocDx.show = false;
      this.menuDocDx.x = e.clientX;
      this.menuDocDx.y = e.clientY;
      this.$nextTick(() => {
        this.menuDocDx.show = true;
      });
    },

    apriImmagine() {
      this.$functionsApp.webViewCommand("marchiAzienda|" + this.codiceMarchioAzienda);
    },

    apriMarchio() {
      let urlParams = new URLSearchParams(window.location.search);
      if (urlParams.size === 1) {
        this.codiceMarchioAziendaTestata = urlParams.get("name");//get valore parametro
        let t = this.listaProdotti.filter(
          (f) => f.codiceAzienda === this.codiceMarchioAziendaTestata
        );
        this.testata = t[0];//setto la testata filtrata
        this.selectRowDouble(); //apro dettaglio marchio
      }
    },
  },

  created() { },

  mounted() {
    document.addEventListener("wheel", this.onScroll);
    this.caricaDati();
    this.headerAnagrafiche = this.headerTabellaMarchiAziende();
    this.filtri = this.filtriTabellaAziende();
  },

  watch: {},

  computed: {
    getDati() {
      let res = this.listaProdotti;
      return res;
    },
  },
};
</script>

<style scoped>
#allineamentoTestoColonna {
  text-align: end;
}

.parent {
  position: relative;
}

.bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
