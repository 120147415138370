<template>
  <v-dialog scrollable v-model="dialogComputed" width="400px">
    <v-card tile>
      <v-toolbar light dense flat>
        <v-toolbar-title>Spostamenti</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-data-table :headers="headers" :items="dati" class="elevation-1 ml-2" no-data-text="" hide-default-footer
        item-key="prog" disable-pagination hide-default-header>

        <template v-slot:item="{ item }">
          <tr>
            <td style="max-width:25px!important;" class="pa-0">

            </td>
            <td style="width:23px!important;">{{ item.valoreScambio }}</td>
            <td style="height:28px;">{{ item.ragioneSociale }}</td>
            <td style="height:28px;">
              <v-btn color="primary" @click.native="elimina(item)">Elimina</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="closeDialog()">Annulla</v-btn>

      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import { get_spostamenti, del_spostamenti } from "@/api/api.intranet.js";

export default {
  props: {
    dialog: { type: Boolean },
    autista: { type: String },
    dataConsegna: { type: String },
  },
  data() {
    return {
      attesa: false,

      aggiorna: false,

      dati: [],
      selectedRows: [],
      headers: [
        {
          text: "Prog",
          align: "left",
          sortable: false,
          value: "progScambio",
        },
        {
          text: "Rag.Soc",
          align: "left",
          sortable: false,
          value: "ragioneSociale",
        },
      ],
    };
  },

  methods: {
    async caricaDati() {
      // Reset
      this.dati = [];
      this.aggiorna = false;

      if (this.dialog == false) return;

      let res = await get_spostamenti(this.dataConsegna + "/" + this.autista);

      this.dati = res.data;
    },

    async elimina(riga) {
      let res = await del_spostamenti(riga);

      this.caricaDati();

      this.aggiorna = true;
    },

    getValoreSpostamento(item) {
      //if (item.tipoScambio=="Orario")return tiem
    },

    setRigaSel(riga, ev) {
      if (ev) this.selectedRows.push(riga);
      else this.selectedRows.splice(this.selectedRows.indexOf(riga), 1);
    },

    closeDialog() {
      this.$emit("chiudiDialog", this.aggiorna);
    },
  },
  mounted() {
    this.caricaDati();
  },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },

  watch: {
    dialog(v) {
      if (v) {
        this.caricaDati();
      }
    },
  },
};
</script>