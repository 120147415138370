<template>
  <v-dialog v-model="dialogTracking" width="800" scrollable @keydown.esc="chiudi()" persistent>
    <v-card style="width: 800px; height: 600px">
      <v-card-title>
        <v-btn icon @click.prevent="caricaDatiTracking()" class="mr-4">
          <v-icon>refresh</v-icon>
        </v-btn>

        <span>Tracking spedizione {{ spedTracking }}</span>

        <v-spacer />
        <v-btn icon @click.prevent="chiudi()">
          <v-icon large>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>

        <v-container v-if="datiTracking.ttParcelIdResponse != undefined &&
          datiTracking.ttParcelIdResponse.executionMessage.code == -11">
          <v-icon style="color: rgb(255, 217, 25)">warning</v-icon>
          <span>Spedizione non trovata</span>
        </v-container>
        <v-container v-else-if="datiTracking.ttParcelIdResponse != undefined" style="background-color: #fff">
          <v-row>
            <v-col cols="6">
              Codice spedizione:
              {{ datiTracking.ttParcelIdResponse.bolla.dati_spedizione.spedizione_id }}
              <br />Data:
              {{ datiTracking.ttParcelIdResponse.bolla.dati_spedizione.spedizione_data }}
              <br />Servizio:
              {{ datiTracking.ttParcelIdResponse.bolla.dati_spedizione.servizio }}
              <br />Filiale di arrivo:
              <a :href="datiTracking.ttParcelIdResponse.bolla.dati_spedizione
                .filiale_arrivo_URL
                " target="_blank">{{ datiTracking.ttParcelIdResponse.bolla.dati_spedizione.filiale_arrivo }}</a>
              <br />
              <span v-if="datiTracking.ttParcelIdResponse.bolla.dati_spedizione
                .stato_sped_parte1
                " style="font-weight: bold">
                <span>{{ datiTracking.ttParcelIdResponse.bolla.dati_spedizione.stato_sped_parte1 }}
                  {{ datiTracking.ttParcelIdResponse.bolla.dati_spedizione.descrizione_stato_sped_parte1 }}
                </span>
                <br />
                <span>{{ datiTracking.ttParcelIdResponse.bolla.dati_spedizione.stato_sped_parte2 }}
                  {{ datiTracking.ttParcelIdResponse.bolla.dati_spedizione.descrizione_stato_sped_parte2 }}
                </span>
              </span>
              <span v-if="datiTracking.ttParcelIdResponse.bolla.dati_consegna.data_consegna_merce">
                Data consegna merce:
                <span style="font-weight: bold">
                  {{ datiTracking.ttParcelIdResponse.bolla.dati_consegna.data_consegna_merce }}</span>
                ore
                {{ datiTracking.ttParcelIdResponse.bolla.dati_consegna.ora_consegna_merce }}
              </span>
            </v-col>
            <v-col>
              Destinatario:
              {{ datiTracking.ttParcelIdResponse.bolla.destinatario.localita }}
              ({{ datiTracking.ttParcelIdResponse.bolla.destinatario.sigla_provincia }})
              <span style="width: 100%; text-align: end; align-items: end">
                <br />Colli:
                <b>{{ datiTracking.ttParcelIdResponse.bolla.merce.colli }}</b>
                <br />Peso (KG):
                <b>{{ datiTracking.ttParcelIdResponse.bolla.merce.peso_kg }}</b>
                <br />Volume m3:
                <b>{{ datiTracking.ttParcelIdResponse.bolla.merce.volume_m3 }}</b>
                <br />Natura merce:
                {{ datiTracking.ttParcelIdResponse.bolla.merce.natura_merce }}
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col v-if="datiTracking.ttParcelIdResponse.contatore_eventi > 0">
              <v-data-table :headers="headersTracking" :items="datiTracking.ttParcelIdResponse.lista_eventi"
                hide-default-footer>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.evento.data }}</td>
                    <td>
                      <span v-if="item.evento.ora != '00.00'">{{ item.evento.ora }}</span>
                    </td>
                    <td>{{ item.evento.filiale }}</td>
                    <td>{{ item.evento.descrizione }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_tracking } from "@/api/api.etichette.js";

export default {
  props: {
    dialogTracking: { type: Boolean, default: false },
    spedTracking: { type: String, default: "" },
  },

  data: () => ({
    datiTracking: {},

    headersTracking: [
      { text: "Data", value: "data", sortable: false },
      { text: "Ora", value: "ora", sortable: false },
      { text: "Filiale", value: "filiale", sortable: false },
      { text: "Evento", value: "descrizione", sortable: false },
    ],
  }),

  methods: {
    chiudi() {
      this.$emit("chiudi");
    },

    tracciaSpedizione() {
      if (this.spedTracking == null || this.spedTracking == "") return;
      this.caricaDatiTracking();
    },

    async caricaDatiTracking() {
      this.datiTracking = {};
      let res = await get_tracking(this.spedTracking);
      this.datiTracking = res.data;
    },
  },

  watch: {
    dialogTracking(newVal) {
      if (newVal) this.caricaDatiTracking();
    },
  },
};
</script>