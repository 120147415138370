<template>
  <v-container fluid fill-height>
    <v-col>
      <v-row>
        <v-btn icon @click.prevent="caricaErrori()" class="mt-2 mr-4">
          <v-icon> refresh</v-icon>
        </v-btn>
        <v-menu :close-on-content-click="false" v-model="calendarMonth" :nudge-right="40" transition="scale-transition"
          offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field class="mr-4" v-on="on" readonly v-model="filtroMese" label="Mese" prepend-icon="event"
              hide-details style="max-width: 160px !important" clearable>
            </v-text-field>
          </template>

          <v-date-picker v-model="filtroMese" locale="it-it" first-day-of-week="1" event-color="black"
            class="b-date-picker" type="month" @input="calendarMonth = false" hide-details>
          </v-date-picker>
        </v-menu>

        <v-menu :close-on-content-click="false" v-model="calendarDay" :nudge-right="40" transition="scale-transition"
          offset-y>
          <template v-slot:activator="{ on }">
            <v-text-field class="mr-4" v-on="on" readonly v-model="filtroData" label="Giorno" prepend-icon="event"
              hide-details style="max-width: 160px !important" clearable>
            </v-text-field>
          </template>

          <v-date-picker v-model="filtroData" locale="it-it" first-day-of-week="1" event-color="black"
            class="b-date-picker" @input="calendarDay = false" hide-details>
          </v-date-picker>
        </v-menu>
        <v-spacer />
        <v-text-field v-model="search" clearable append-icon="search" class="" label="Cerca" />
      </v-row>

      <span> Record: {{ getErrori.length }} </span>

      <v-data-table :items="getErrori" :headers="headersTabella" no-data-text="Nessun dato disponibile"
        :items-per-page="300" :search="search" height="68vh"></v-data-table>
    </v-col>
  </v-container>
</template>

<script>
import { get_erroriSpedizioni } from "@/api/api.etichette.js";

export default {
  props: {
    caricaDati: { type: Boolean, default: false },
  },

  data: () => ({
    elencoErrori: [],
    headersTabella: [
      { value: "codiceSpedizione", text: "Codice spedizione", sortable: true },
      {
        value: "descrizioneCodice",
        text: "Descrizione Codice",
        sortable: true,
      },
      { value: "utente", text: "Codice utente", sortable: true },
      { value: "ragioneSociale", text: "Utente", sortable: true },
      { value: "dataOra", text: "Data", sortable: true },
      { value: "codice", text: "Codice erorre", sortable: true },
      { value: "descrizione", text: "Descrizione erorre", sortable: false },
    ],
    search: "",

    filtroMese: "",
    filtroData: "",
    calendarMonth: false,
    calendarDay: false,
  }),

  methods: {
    async caricaErrori() {
      let res = await get_erroriSpedizioni();
      this.elencoErrori = res.data;
    },
  },

  computed: {
    getErrori() {
      if (
        (this.filtroMese == null || this.filtroMese == "") &&
        (this.filtroData == null || this.filtroData == "")
      )
        return this.elencoErrori;
      else {
        let dt = new Date(this.filtroMese + "-01");

        let sd = new Date(dt.getFullYear(), dt.getMonth(), 1);
        let ed = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
        if (this.filtroData != null && this.filtroData != "") {
          sd = new Date(this.filtroData);
          ed = new Date(this.filtroData);
        }

        return this.elencoErrori.filter((d) => {
          if (d.dataOra == null || d.dataOra == "") return true;
          let time = new Date(
            this.$i2kFunctions.formatITDateToISO(d.dataOra.substring(0, 10))
          );
          return d.dataOra == "" ? true : sd <= time && time <= ed;
        });
      }
    },
  },

  mounted() {
    //this.caricaErrori();
  },

  watch: {
    caricaDati(newVal, oldVal) {
      if (newVal != oldVal) this.caricaErrori();
    },
  },
};
</script>