<template>
  <!-- Menu DX -->
  <v-navigation-drawer width="280" app  fixed v-model="leftBarVis" mobile-breakpoint="900" :clipped="true"
    :class="{ drawer: true }" class="pa-2" touchless left color="#F6F6F6" >

    <!-- expansion panel DX -->
    <v-expansion-panels v-model="panels" accordion :multiple="panelMultiplo" flat>
      <v-expansion-panel v-for="(filtro) in filtriComputed" :key="filtro.filtro" style="background-color: #F6F6F6;">
        <v-expansion-panel-header style="max-height: 20px!important; min-height: 20px!important; padding-left: 5px;">
          <strong>{{ filtro.filtro }}</strong>
        </v-expansion-panel-header>

        <v-expansion-panel-content style="padding-bottom: 10px;">

          <div v-for="item in getFiltroValore(filtro)" :key="filtro.filtro + '-' + item.valore"
            @contextmenu.stop="menuOpen($event, item.valore)" style="height:23px!important;  display: flex; ">

            <div style="width:85%;">
              <!--   <v-checkbox :label="item.valore" @change="setChecked(item, $event)" :value="item.valore"
                @input="item.valore = $event" :multiple="true" :disabled="item.nrValori == 0 && item.valore != 'Tutti'"
                :class="{ 'checkbox-disabled': item.nrValori == 0 }" hide-details style="margin: 0px!important;" dense>
              </v-checkbox> -->

              <v-checkbox :label="item.valore" @change="setChecked(item, $event)" :value="item.chiave" :multiple="true"
                v-model="check_selected_model" :disabled="item.nrValori == 0 && item.valore != 'Tutti'" hide-details
                style="margin: 0px!important; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;" dense>
              </v-checkbox>
            </div>

            <div style="width:15%; text-align:right; padding-right:4px; color:grey; font-size: 14px; padding-top: 7px;">
              {{ getNrValore(item) }}
            </div>

          </div>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-menu :position-x="menu.x" :position-y="menu.y" v-model="menu.show" v-if="role == 2">
      <v-list>
        <v-list-item @click="editCheck($event, menu.activatedBy)">
          <v-list-item-title>Modifica</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="editing.dlgOpen" max-width="290">
      <v-card class="pa-3">
        <v-card-text>
          <v-text-field v-model="editing.value"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-navigation-drawer>
</template>

<script>


import { mapState } from "vuex";

export default {
  props: {
    filtri: {
      type: Array,
      default: () => {
        return [];
      },
    },
    check_selected: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filterpanel: {
      type: Array,
      default: () => {
        return [];
      },
    },
    filtroZeroVis: { type: Boolean, default: false },
    panelMultiplo: { type: Boolean, default: false },
  },

  data: () => ({
    filterpanelSwap: [],
    menu: {
      show: false,
      x: 0,
      y: 0,
      activatedBy: "",
    },
    editing: {
      dlgOpen: false,
      value: "",
    },

    espandi: true,

    check_selected_model: []

  }),

  methods: {

    setChecked(item, ev) {
      this.$emit("avviaFiltro", item);
    },

    menuOpen(e, val) {
      e.preventDefault();
      this.menu.show = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;
      this.menu.activatedBy = val;
      this.$nextTick(() => {
        this.menu.show = true;
      });
    },
    editCheck(e, val) {
      this.editing.value = val;
      this.editing.dlgOpen = true;
    },

    getLabel(item) {
      return (
        item.valore +
        (item.valore == "Tutti" ? "" : " (" + item.nrValori.toString() + ")")
      );
    },

    getNrValore(item) {
      return item.valore == "Tutti"
        ? ""
        : (item.nrValori.toString() == "0" ? "" : item.nrValori.toString());
    },

    arraymove(arr, fromIndex, toIndex) {
      var element = arr[fromIndex];
      arr.splice(fromIndex, 1);
      arr.splice(toIndex, 0, element);
    },

    getFiltroValore(filtro) {
      return filtro.valoriFiltri.filter((f) => (this.filtroZeroVis ? f.nrValori >= 0 : f.nrValori > 0) || f.valore == 'Tutti')
    },

  },

  computed: {
    leftBarVis: {
      get() {
        return this.$store.getters["app/filterDrawerVisibility"];
      },
      // NOTA: la set viene impostata da vuetify
      set(valore) {
        this.$store.commit("app/setFilterDrawer", valore);
      },
    },

    panels: {
      get() {
        return this.filterpanel;
      },
      set(valore) {
        // Varibile di swap necessaria x il set
        this.filterpanelSwap.push(valore);
      },
    },


    ...mapState({
      role: (state) => state.user.role,
    }),

    filtriComputed() {
      let arr = [];

      // Controllo filtri attivi
      if (this.check_selected.length == 0) {
        arr = this.filtri;
      } else {
        if (this.filtri) {
          for (let index = 0; index < this.filtri.length; index++) {
            // Controllo presenza valori
            if (this.filtri[index].valoriFiltri.filter((f2) => f2.nrValori > 0).length > 0 || this.filtroZeroVis) {
              arr.push(this.filtri[index]);
            }
          }
        }
      }



      // Ciclo sui filtri
      for (var i = 0; i < arr.length; i++) {

        // Prima lettera maiuscola
        var first = arr[i].filtro;
        arr[i].filtro =
          first.slice(0, 1).toUpperCase() + first.slice(1).toLowerCase();

        // Inserimento Tutti
        if (arr[i].valoriFiltri.length > 1 && arr[i].valoriFiltri[0].valore != "Tutti")
          arr[i].valoriFiltri.splice(0, 0, { valore: "Tutti", filtro: arr[i].filtro, chiave: arr[i].filtro + "#Tutti", nrValori: 0, ordinamento: 0 });
        else if (arr[i].valoriFiltri.length == 1 && arr[i].valoriFiltri[0].valore == "Tutti")
          arr[i].valoriFiltri.splice(0, 1);

      }

      return arr;
    },



  },

  watch: {
    check_selected(val) {

      this.check_selected_model = []

      val.forEach(element => {
        this.check_selected_model.push(element.filtro + '#' + element.valore);
      });

    }
  }


};
</script>

<style scoped>
.drawer {
  z-index: 6 !important;
  border-left: 1px solid #ccc;
  border-right: none !important;
}

.checkbox-list {
  list-style-type: none;
  padding-left: 0;
}

.checkbox-list__item .v-input--selection-controls {
  margin-top: 8px;
}

@media screen and (min-width: 960px) {
  .drawer {
    z-index: 2 !important;
  }
}
</style>
