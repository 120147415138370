<template>
  <v-dialog v-model="dialog" width="360">
    <v-card width="360" :loading="attesa" class="pa-2">
      <template>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                type="number"
                v-model="qta"
                disabled
                label="Quantità attuale"
              ></v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                type="number"
                autofocus
                v-model="qtaNew"
                label="Nuova quantità"
                @focus="$event.target.select()"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text light @click="chiudi()">Annulla</v-btn>
        <v-btn
          color="primary"
          text
          light
          @click="conferma()"
          :disabled="attesa"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { set_nuovaQuantita } from "@/api/api.catalogo.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
    qta: { type: Number, default: 0 },
    codArticolo: { type: String, default: "" },
  },

  data: () => ({
    qtaNew: 0,
    attesa: false,
  }),

  methods: {
    chiudi() {
      this.$emit("chiudi", true);
    },

    async conferma() {
      let par = {
        CdArticolo: this.codArticolo,
        QtScatola: this.qtaNew,
      };

      let res = await set_nuovaQuantita(par);
      if (res.data == "OK") {
        this.$toast.info("Quantita aggiornato");
        this.chiudi();
      } else this.$toast.alert("Errore: " + res.data);
    },
  },
};
</script>