var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"550","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-card-title',[_vm._v(" Cerca spedizione "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.chiudi()}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("close")])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-checkbox',{staticClass:"ml-4",staticStyle:{"max-width":"130px"},attrs:{"label":"Annullate","color":"red lighten-1"},model:{value:(_vm.filtroAnnullato),callback:function ($$v) {_vm.filtroAnnullato=$$v},expression:"filtroAnnullato"}}),_c('v-checkbox',{staticStyle:{"max-width":"130px"},attrs:{"label":"Confermate","color":"green darken-1"},model:{value:(_vm.filtroConfermato),callback:function ($$v) {_vm.filtroConfermato=$$v},expression:"filtroConfermato"}}),_c('v-spacer'),_c('v-checkbox',{staticClass:"mr-2",staticStyle:{"max-width":"190px"},attrs:{"label":"Solo anno corrente","color":"primary"},model:{value:(_vm.filtroAnno),callback:function ($$v) {_vm.filtroAnno=$$v},expression:"filtroAnno"}})],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-autocomplete',{staticClass:"ml-4 mr-4",attrs:{"item-text":"descrCodiceSpedizione","item-value":"descrCodiceSpedizione","items":_vm.getElencoSpedizioni,"label":"cerca","clearable":"","no-filter":"","search-input":_vm.search,"no-data-text":"Nessun spedizione disponibile per i filtri selezionati"},on:{"input":function($event){return _vm.salva()},"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.descrCodiceSpedizione)+", "+_vm._s(item.cdContatto)+" ")]),_c('v-list-item-subtitle',[_vm._v(" consegna: "+_vm._s(item.dtConsegna.substring(0, 10))+" ")])],1),(item.annullata)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" clear ")])]}}],null,true)},[_c('span',[_vm._v(" Spedizione annullata ")])])],1):(item.confermata)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green darken-1"}},'v-icon',attrs,false),on),[_vm._v(" check ")])]}}],null,true)},[_c('span',[_vm._v(" Spedizione confermata ")])])],1):(item.inviato)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"amber darken-1"}},[_vm._v(" save ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Spedizione inviata a BRT ma non ancora confermata ")])])],1):_vm._e()]}}]),model:{value:(_vm.codice),callback:function ($$v) {_vm.codice=$$v},expression:"codice"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){$event.preventDefault();return _vm.chiudi()}}},[_vm._v(" Annulla ")]),_c('v-btn',{staticClass:"btn-conferma",on:{"click":function($event){$event.preventDefault();return _vm.salva()}}},[_vm._v(" Carica dati ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }