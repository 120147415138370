<template>
    <v-dialog v-model="getDialog" width="500" @keydown.esc="chiudi()" persistent>
        <v-card width="100%" :loading="attesa" class="pa-1">
            <v-card-title v-text="titolo" class="pa-0"></v-card-title>
            <v-text-field v-model="valore" class="px-2"></v-text-field>

            <v-card-actions>

                <v-spacer></v-spacer>
                <v-btn color="#E40520" text @click="salva">
                    Salva
                </v-btn>
                <v-btn color="#E40520" text @click="chiudi">
                    Annulla
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>

export default {
    props: {
        dialog: { type: Boolean, default: false },
        valorePar: { type: String, default: "" },
        titolo: { type: String, default: "" },
    },

    data: () => ({
        valore: "",
        attesa: false
    }),

    methods: {

        carica() {
            if (!this.dialog)
                return

            this.valore = this.valorePar
        },

        salva() {
            this.$emit("chiudi", true, this.valore)
        },
        chiudi() {
            this.$emit("chiudi", false);
        }
    },

    computed: {

        getDialog: {
            get: function () {
                return this.dialog;
            },
            set: function (value) {
                if (!value) {
                    this.chiudi();
                }
            },
        },


    },

    watch: {
        dialog() {
            this.carica()
        }
    }

}
</script>
