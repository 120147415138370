export default {
    methods: {
        getHeadersDatiSped() {
            return [{
                text: "Numero documento",
                value: "nrDocu"
            },
            {
                text: "Tipo documento",
                value: "tpDocu"
            },
            {
                text: "Data documento",
                value: "dtDocuStampato"
            },

            {
                text: "Destinatario",
                value: "destinatario"
            },
            {
                text: "Vettore",
                value: "vettore"
            },
            {
                text: "Porto",
                value: "tipoPorto"
            },
            {
                text: "Azioni",
                value: "azioni",
                sortable: false,
                width: 80
            },
            ]
        },

        getHeadersSped() {
            return [{
                text: "stato",
                value: "pallino",
                sortable: false,
                class: "ml-0 pl-0"
            },
            {
                text: "Seleziona",
                value: "selezionato",
                sortable: false,
                class: "ml-0 pl-0",
            },
            {
                text: "Cod Spedizione",
                value: "codiceSpedizione"
            },
            {
                text: "Descrizione codice",
                value: "descrCodiceSpedizione"
            },
            {
                text: "Data partenza",
                value: "dtPartenza",
                sortable: false
            },
            {
                text: "Data consegna",
                value: "dtConsegna",
                sortable: false
            },
            {
                text: "Destinatario",
                value: "cdContatto"
            },
            {
                text: "Num. documenti",
                value: "numDocumenti",
                sortable: false
            },
            {
                text: "Num. colli",
                value: "numColli",
                sortable: false
            },
            {
                text: "Peso (kg)",
                value: "peso",
                sortable: false
            },
            {
                text: "Annulla",
                value: "",
                sortable: false
            },
            {
                text: "Elimina",
                value: "",
                sortable: false
            },
            {
                text: "",
                value: "",
                sortable: false
            },
            ];
        },

        getHeadersDettaglioSped() {
            return [{
                text: "Numero docu",
                value: "nrDocu"
            },
            {
                text: "Documento",
                value: "docu",
                sortable: false
            },
            {
                text: "Destinatario",
                value: "destinatario"
            },
            {
                text: "Località",
                value: "localita"
            },
            {
                text: "Indirizzo",
                value: "indirizzoDestinatario"
            },
            {
                text: "Vettore",
                value: "vettore"
            },
            {
                text: "Porto",
                value: "tipoPorto"
            },
            {
                text: "",
                value: "",
                sortable: false
            },
            ];
        },

        getElencoStampanti() {
            return [
                "\\\\Win2016-WSUS01\\ET - Banco1",
                "\\\\Win2016-WSUS01\\ET - Banco2",
                "\\\\Win2016-WSUS01\\ET - Maniglie1",
            ]
        },

        setCorriereDefault() {
            return {
                datiDocu: this.setDatiDocuDefault(),
                codiceSpedizione: "",
                descrCodiceSpedizione: "",
                altraDestinazione: "",
                vettore: 2,
                destinatario: "",
                indirizzoDestinatario: "",
                cap: "",
                localita: "",
                provincia: "",
                nazione: "",
                tipoPorto: "",
                contrassegno: false,
                valoreContrassegno: "",
                nomeContatto: "",
                telefonoContatto: "",
                celluareContatto: "",
                mailContatto: "",
                spedizioneConfermata: false,
                spedizioneAnnullata: false,
                stato: -1,
                gravita: "",
                descrizione: "",
                messaggio: "",
                etichette: "",
                dtConsegna: "",
                dtPartenza: "",
            };
        },

        setDatiDocuDefault() {
            return {
                nrDocu: "",
                cdContatto: "",
                tpDocu: "",
                dtDocuStampato: "1900-01-01",
                anno: new Date().getFullYear(),
                numColli: 0,
                peso: 0.0,
                colli_Barre: 0,
                colli_Scatole: 0,
                colli_Latte: 0,
                colli_Fasci: 0,
                colli_Pallet: 0,
                colli_Altro: 0,
            }
        },

        getElencoAnni() {
            let anni = [];
            for (let i = new Date().getFullYear(); i >= 2022; i--) {
                anni.push(i);
            }
            return anni;
        },
    }
}