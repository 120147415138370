<template src="./generaEtichette.html"></template>

<script>
import {
  set_datiEtichette,
  del_documento,
  get_spedizione,
  set_spedizione,
  stampa_etichette,
  get_messaggioStampa,
  get_infoSpedizione,
  del_spedizione,
  get_messaggioSpedizione,
} from "@/api/api.etichette.js";

import NumeroDocumento from "@/components/dialog/etichette/NuovoNumDocu.vue";
import NuovaSpedizione from "@/components/dialog/etichette/NuovaSpedizione.vue";
import CercaSpedizione from "@/components/dialog/etichette/CercaSpedizione.vue";
import TipologiaColli from "@/components/dialog/etichette/TipologiaColli.vue";

import mixinSped from "@/views/intranet/etichette/mixinEtichette.js";

export default {
  props: {
    refresh: { type: Boolean, default: false },
  },

  mixins: [mixinSped],
  components: {
    NumeroDocumento,
    NuovaSpedizione,
    CercaSpedizione,
    TipologiaColli,
  },

  data: () => ({
    corriere: {},

    dialogNumDocu: false,
    dialogNuovaSped: false,
    dialogCercaSped: false,
    dialogTipoColli: false,

    calendarP: false,
    calendarC: false,

    elencoDatiCSped: [],
    headersDatiSped: [],

    elencoStampanti: [],
    stampanteSel: "\\\\Win2016-WSUS01\\ET - Banco1",

    info: "",
    search: "",

    invioDirettoStampa: false,
  }),

  methods: {
    // salvataggio dati nuova posizione spedizione
    async salvaDati(datiCorriere) {
      let salva = true;
      if (datiCorriere == null) datiCorriere = this.corriere;
      else {
        // sono nel caso della conferma documento dalla dialog NuovoNumDocu
        // controllo se gli indirizzi dei documenti corrispondono perché per lo stesso cliente si potrebbe avere un diverso indirizzo
        if (this.elencoDatiCSped.length > 0) {
          this.elencoDatiCSped.forEach(el => {
            let dest = datiCorriere.indirizzoDestinatario;
            if (datiCorriere.altraDestinazione != null && datiCorriere.altraDestinazione != '')
              dest = datiCorriere.altraDestinazione;
            if (dest != el.indirizzoDestinatario) {
              this.$toast.alert("Attenzione. Gli indirizzi dei documenti non corrispondo");
              salva = false;
            }
          });
        }
      }
      if (!salva) return;
      datiCorriere.datiDocu.nrDocu = datiCorriere.datiDocu.nrDocuStampato;

      let res = await set_datiEtichette(datiCorriere);
      if (res.data != "OK") this.$toast.alert(res.data);
      else {
        this.$toast.info("Spedizione inserita");
        this.dialogNuovaSped = false;
        this.dialogNumDocu = false;
        this.caricaDatiSpedizione(datiCorriere.descrCodiceSpedizione);
      }
    },

    annulla() {
      this.info = "";
      this.elencoDatiCSped = [];
      this.corriere = this.setCorriereDefault();
    },

    async caricaDatiSpedizione(descr) {
      this.chiudiDialogCercaSped();
      if (descr != null && descr != "")
        this.corriere.descrCodiceSpedizione = descr;

      let codice = "";
      if (
        this.corriere.descrCodiceSpedizione != null &&
        this.corriere.descrCodiceSpedizione != undefined &&
        this.corriere.descrCodiceSpedizione != "" &&
        this.corriere.descrCodiceSpedizione.split(" ").length > 2
      ) {
        codice = this.corriere.descrCodiceSpedizione.split(" ")[2];
        this.corriere.codiceSpedizione = codice;
      }

      if (codice == "") return;
      let res = await get_spedizione(this.corriere.descrCodiceSpedizione);
      this.elencoDatiCSped = res.data;

      this.caricaInfoSpedizione();
    },

    async caricaInfoSpedizione() {
      let res = await get_infoSpedizione(this.corriere.descrCodiceSpedizione);

      try {
        this.info = res.data.descrizione;
        this.corriere.datiDocu.peso = res.data.peso;
        this.corriere.datiDocu.numColli = res.data.numColli;
        this.corriere.datiDocu.colli_Barre = res.data.colli_Barre;
        this.corriere.datiDocu.colli_Scatole = res.data.colli_Scatole;
        this.corriere.datiDocu.colli_Latte = res.data.colli_Latte;
        this.corriere.datiDocu.colli_Fasci = res.data.colli_Fasci;
        this.corriere.datiDocu.colli_Pallet = res.data.colli_Pallet;
        this.corriere.datiDocu.colli_Altro = res.data.colli_Altro;
        //conversione data consegna
        this.corriere.dtConsegna =
          res.data.dtConsegna != null && res.data.dtConsegna.length >= 10
            ? this.$i2kFunctions.formatITDateToISO(
              res.data.dtConsegna.substring(0, 10)
            )
            : "";

        //conversione data partenza
        this.corriere.dtPartenza =
          res.data.dtPartenza != null && res.data.dtPartenza.length >= 10
            ? this.$i2kFunctions.formatITDateToISO(
              res.data.dtPartenza.substring(0, 10)
            )
            : "";
      } catch (error) {
        this.$toast.error("Errore nel caricamento dei dati. Riprovare");
        window.location.reload();
      }

    },

    //invio spedizione brt per creazione etichette
    async creaSpedizione() {
      if (!this.spedizioneSelezionata()) return;
      if (!this.checkValori()) return;
      if (this.getSommaColli() <= 0) {
        this.$toast.alert("Inserire la tipologia di colli");
        return;
      }

      this.corriere.vettore = 2;
      let confirm = await this.$confirm.open(
        "Conferma invio",
        "Vuoi inviare la spedizione a BRT?"
      );

      if (!confirm) return;

      let msg = await get_messaggioSpedizione(this.corriere);
      if (msg.data.message != "OK") {
        let confirm = await this.$confirm.open("Attenzione", msg.data.message);
        if (!confirm) return;
        if (msg.data.action == 1) {
          //annullo la spedizione
          let par = {
            CodiceSpedizione: this.corriere.codiceSpedizione,
            DescrCodiceSpedizione: this.corriere.descrCodiceSpedizione,
          };
          let res = await set_spedizione("annulla", par);
          if (res.data != "OK") {
            this.$toast.alert(res.data);
            return;
          }
        }
      }

      //creo la nuova spedizione
      let res = await set_spedizione("crea", this.corriere);
      if (res.data != "OK") this.$toast.alert(res.data);
      else this.$toast.info("Spedizione inviata.");
    },

    async apriDialogDocu() {
      if (!this.spedizioneSelezionata()) return;
      this.dialogNumDocu = true;
    },

    spedizioneSelezionata() {
      if (
        this.corriere.codiceSpedizione == "" ||
        this.corriere.codiceSpedizione == null
      ) {
        this.$toast.alert("Nessuna spedizione selezionata");
        return false;
      }
      return true;
    },

    checkValori() {
      let check = true;
      if (this.corriere.datiDocu.numColli <= 0) {
        this.$toast.alert("Attenzione. Numero colli non valido");
        check = false;
      }
      if (this.corriere.datiDocu.numColli > 30) {
        this.$toast.alert("Attenzione. Il numero massimo di colli accettati da BRT è 30");
        check = false;
      }
      if (this.corriere.datiDocu.peso <= 0) {
        this.$toast.alert("Attenzione. Peso spedizione non valido");
        check = false;
      }

      if (
        this.corriere.dtConsegna == null ||
        this.corriere.dtConsegna == "" ||
        new Date(this.corriere.dtConsegna + "T23:59:59") < new Date(Date.now())
      ) {
        this.$toast.alert("Attenzione. Data di consegna non valida");
        check = false;
      }

      if (
        this.corriere.dtPartenza == null ||
        this.corriere.dtPartenza == "" ||
        new Date(this.corriere.dtPartenza + "T23:59:59") < new Date(Date.now())
      ) {
        this.$toast.alert("Attenzione. Data di partenza non valida");
        check = false;
      }
      return check;
    },

    chiudiDialogDocu() {
      this.corriere.datiDocu.nrDocu = "";
      this.dialogNumDocu = false;
      this.caricaDatiSpedizione();
    },

    async nuovaSpedizione() {
      this.annulla();
      this.dialogNuovaSped = true;
    },

    chiudiDialogNuovaSped() {
      this.dialogNuovaSped = false;
      this.caricaDatiSpedizione();
    },

    salvaNuovaSped(datiSped) {
      if (
        datiSped.datiDocu.nrDocu == null ||
        datiSped.datiDocu.nrDocu == undefined ||
        datiSped.datiDocu.nrDocu == "" ||
        datiSped.descrCodiceSpedizione == ""
      ) {
        this.$toast.alert("Numero documento non valido");
        return;
      } else {
        this.salvaDati(datiSped);
        this.caricaDatiSpedizione();
      }
    },

    cercaSpedizione() {
      this.dialogCercaSped = true;
    },

    chiudiDialogCercaSped() {
      this.dialogCercaSped = false;
    },

    async wait(millisecs) {
      let wait = (ms) => new Promise((r, j) => setTimeout(r, ms));
      await wait(millisecs);
    },

    //operazioni documenti
    async cancellaDocumento(item) {
      let confirm = await this.$confirm.open(
        "Elimina",
        "Vuoi eliminare il documento dalla spedizione?"
      );

      if (!confirm) return;

      let res = await del_documento(item);
      if (res.data == "OK") {
        this.$toast.info("Documento eliminato");
        this.caricaDatiSpedizione();
      }
    },

    async cancellaSpedizione() {
      let msg = "Vuoi cancellare la spedizione corrente?";
      msg += "?<br>L'eliminazione è permanente. Continuare?";
      let confirm = await this.$confirm.open("Elimina", msg);
      if (!confirm) return;
      let par = this.corriere;
      if (par.dtPartenza == null || par.dtPartenza == "")
        par.dtPartenza = "1970-01-01";
      let res = await del_spedizione(par);
      if (res.data == "OK") {
        this.$toast.info("Spedizione cancellata");
        this.annulla();
      } else this.$toast.alert(res.data);
    },

    stampaEtichette() {
      if (!this.spedizioneSelezionata()) return;

      if (this.invioDirettoStampa) {
        this.stampaDiretta();
      } else {
        this.stampaEtichetteAPI();
      }
    },

    async stampaDiretta() {
      let par = {
        codiceSpedizione: this.corriere.codiceSpedizione,
        stampante: this.stampanteSel,
        anno: this.corriere.datiDocu.anno,
        tipologia: "diretta"
      };
      if (!(await this.confermaStampa(par))) return;

      let res = await stampa_etichette(par);
      if (res.data != null && res.data != undefined) {
        if (res.data.length > 0) {
          if (res.data[0].ParcelId == "###MSG###" && res.data[0].Contenuto != "") {
            this.$toast.alert(
              "Errore stampa etichette: " + res.data[0].Contenuto == undefined
                ? ""
                : res.data[0].Contenuto
            );
            return;
          } else {
            for (let i = 1; i < res.data.length; i++) {
              //console.log(res.data[i]);
              if (res.data[i].Contenuto != null && res.data[i].Contenuto != undefined)
                this.printBarcode(res.data[i].Contenuto);
            }
          }
        }
      }
    },

    async stampaEtichetteAPI() {
      let par = {
        codiceSpedizione: this.corriere.codiceSpedizione,
        stampante: this.stampanteSel,
        anno: this.corriere.datiDocu.anno,
        tipologia: "API"
      };
      if (!(await this.confermaStampa(par))) return;

      let res = await stampa_etichette(par);
      if (res.data != "OK") {
        this.$toast.alert(
          "Errore stampa etichette: " + (res.data.Message == undefined
            ? res.data
            : res.data.Message)
        );
        return;
      } else {
        this.$toast.info("Etichette inviate alla stampante");
        this.nuovaSpedizione();
      }
    },

    async printBarcode(zpl) {
      try {
        let stpte = "";
        if (this.stampanteSel.includes("Banco1")) stpte = "192.168.90.97"
        else if (this.stampanteSel.includes("Banco2")) stpte = "192.168.90.71"
        else if (this.stampanteSel.includes("Maniglie1")) stpte = "192.168.90.187"

        if (!this.$functionsApp.validateIP(stpte)) return;
        const axios = require('axios');
        let url = "http://" + stpte + "/pstprnt";
        const response = await axios.post(url, zpl);
        console.log('API call response:', response.data);
      } catch (error) {
        console.error(error);
      }
      //console.log(zpl);
    },

    async confermaStampa(par) {
      let res = await get_messaggioStampa(par);
      let confirm = true;
      if (res.data != "OK") {
        confirm = await this.$confirm.open(
          "Attenzione",
          res.data + "\nContinuare?"
        );
      }
      return confirm;
    },

    async salvaSpedizione() {
      if (!this.checkValori()) return;
      //salvo le date, il peso e il numero di colli della spedizione
      if (
        this.corriere.datiDocu.peso == null ||
        this.corriere.datiDocu.peso == ""
      )
        this.corriere.datiDocu.peso = 0;
      if (
        this.corriere.datiDocu.numColli == null ||
        this.corriere.datiDocu.numColli == ""
      )
        this.corriere.datiDocu.numColli = 0;
      this.corriere.anno = this.corriere.datiDocu.anno;
      let res = await set_spedizione("salva", this.corriere);
      if (res.data != "OK") this.$toast.alert(res.data);
    },

    changeDataPartenza() {
      this.corriere.vettore = 2;
      let newVal = this.corriere.dtPartenza;
      if (newVal == null || newVal == "") return;

      let date = new Date(newVal);
      if (date.getDay() == 6 || date.getDay() == 0) {
        this.corriere.dtPartenza = "";
        return;
      }
      this.corriere.dtConsegna = this.$functionsApp
        .getNextGiornoLav(date)
        .toISOString()
        .substring(0, 10);
      this.salvaSpedizione();
    },

    apriTipologiaColli() {
      if (!this.spedizioneSelezionata()) return;
      this.dialogTipoColli = true;
    },

    chiudiTipologiaColli(datiColli) {
      if (datiColli == null) {
        this.dialogTipoColli = false;
        return;
      }
      this.corriere.datiDocu.colli_Scatole = datiColli.colli_Scatole;
      this.corriere.datiDocu.colli_Fasci = datiColli.colli_Fasci;
      this.corriere.datiDocu.colli_Barre = datiColli.colli_Barre;
      this.corriere.datiDocu.colli_Latte = datiColli.colli_Latte;
      this.corriere.datiDocu.colli_Pallet = datiColli.colli_Pallet;
      this.corriere.datiDocu.colli_Altro = datiColli.colli_Altro;

      this.corriere.datiDocu.numColli = this.getSommaColli();
      this.dialogTipoColli = false;

      this.salvaSpedizione();
    },

    getSommaColli() {
      return (
        parseInt(this.corriere.datiDocu.colli_Scatole) +
        parseInt(this.corriere.datiDocu.colli_Fasci) +
        parseInt(this.corriere.datiDocu.colli_Barre) +
        parseInt(this.corriere.datiDocu.colli_Latte) +
        parseInt(this.corriere.datiDocu.colli_Pallet) +
        parseInt(this.corriere.datiDocu.colli_Altro)
      );
    },

    init() {
      this.annulla();
      this.corriere = this.setCorriereDefault();
      this.headersDatiSped = this.getHeadersDatiSped();
      this.elencoStampanti = this.getElencoStampanti();

      if (this.getCodiceSpedizione != "" && this.getCodiceSpedizione != null && this.getCodiceSpedizione != undefined) {
        this.corriere.descrCodiceSpedizione = this.getCodiceSpedizione;
        this.caricaDatiSpedizione();
      }
    },
  },

  computed: {
    getCodiceSpedizione() {
      return this.$store.getters["app/getSpedizioneBRT"];
    }
  },

  watch: {
    "corriere.datiDocu.nrDocu": function (newVal) {
      if (newVal == "" || newVal == null) {
        this.corriere.datiDocu = this.setDatiDocuDefault();
        this.corriere.vettore = 2;
        this.corriere.tipoPorto = "";
        this.corriere.destinatario = "";
        this.corriere.indirizzoDestinatario = "";
        this.corriere.cap = "";
        this.corriere.localita = "";
        this.corriere.provincia = "";
        this.corriere.nazione = "";
      }
    },

    refresh(newVal, oldVal) {
      if (newVal != oldVal) this.init();
    },

    "corriere.descrCodiceSpedizione": function () {
      this.$store.commit("app/setSpedizioneBRT", this.corriere.descrCodiceSpedizione);
    }
  },
};
</script>