<template>
  <v-dialog v-model="dialog" width="550" persistent>
    <v-card class="ma-0 pa-0">
      <v-card-title>
        Cerca spedizione
        <v-spacer></v-spacer>
        <v-btn icon @click="chiudi()">
          <v-icon large>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-row class="ma-0 pa-0">
        <v-checkbox class="ml-4" style="max-width: 130px" label="Annullate" color="red lighten-1"
          v-model="filtroAnnullato" />
        <v-checkbox style="max-width: 130px" label="Confermate" color="green darken-1" v-model="filtroConfermato" />
        <v-spacer />
        <v-checkbox style="max-width: 190px" label="Solo anno corrente" color="primary" v-model="filtroAnno"
          class="mr-2" />
      </v-row>

      <v-row class="ma-0 pa-0">
        <v-autocomplete item-text="descrCodiceSpedizione" item-value="descrCodiceSpedizione" v-model="codice"
          v-bind:items="getElencoSpedizioni" label="cerca" class="ml-4 mr-4" clearable no-filter @input="salva()"
          :search-input.sync="search" no-data-text="Nessun spedizione disponibile per i filtri selezionati">
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.descrCodiceSpedizione }}, {{ item.cdContatto }}
              </v-list-item-title>
              <v-list-item-subtitle>
                consegna: {{ item.dtConsegna.substring(0, 10) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="item.annullata">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="red lighten-1" v-on="on" v-bind="attrs">
                    clear
                  </v-icon>
                </template>
                <span> Spedizione annullata </span>
              </v-tooltip>
            </v-list-item-action>

            <v-list-item-action v-else-if="item.confermata">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="green darken-1" v-bind="attrs" v-on="on">
                    check
                  </v-icon>
                </template>
                <span> Spedizione confermata </span>
              </v-tooltip>
            </v-list-item-action>

            <v-list-item-action v-else-if="item.inviato">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" icon>
                    <v-icon color="amber darken-1"> save </v-icon>
                  </v-btn>
                </template>
                <span> Spedizione inviata a BRT ma non ancora confermata </span>
              </v-tooltip>
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-row>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.prevent="chiudi()"> Annulla </v-btn>
        <v-btn @click.prevent="salva()" class="btn-conferma"> Carica dati </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { get_elencoSpedizioni } from "@/api/api.etichette.js";

export default {
  props: {
    dialog: { type: Boolean, default: false },
  },
  data: () => ({
    //popola la select per la ricerca delle spedizioni
    elencoSpedizioni: [],
    codice: "",
    filtroAnnullato: false,
    filtroConfermato: false,
    filtroAnno: true,
    search: "",
  }),

  methods: {
    async caricaDati() {
      if (!this.dialog) return;
      let data = {
        annullato: this.filtroAnnullato,
        confermato: this.filtroConfermato,
        annoCorrente: this.filtroAnno,
      };
      let res = await get_elencoSpedizioni(data);
      this.elencoSpedizioni = res.data;
    },

    salva() {
      if (this.codice == null || this.codice == "") return;
      this.$emit("salvaDati", this.codice);
    },

    chiudi() {
      this.$emit("chiudi");
    },
  },

  computed: {
    getElencoSpedizioni() {
      if (this.search == null || this.search == "")
        return this.elencoSpedizioni;

      let s = this.search.toLowerCase();
      return this.elencoSpedizioni.filter(
        (c) =>
          c.descrCodiceSpedizione.toLowerCase().includes(s) ||
          c.cdContatto.toLowerCase().includes(s) ||
          c.dtConsegna.toLowerCase().includes(s)
      );
    },
  },

  watch: {
    dialog(newVal) {
      if (newVal) this.caricaDati();
    },

    filtroAnnullato() {
      this.caricaDati();
    },

    filtroConfermato() {
      this.caricaDati();
    },

    filtroAnno() {
      this.caricaDati();
    }
  },
};
</script>