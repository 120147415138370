<template src="./home.html"></template>

<script>
import NewsCard from "@/components/ui/NewsCard.vue";

import { get_news } from "@/api/api.news.js";

export default {
  components: {
    NewsCard,

  },

  data: () => ({
    carouselIndex: 0,
    imgTopCarousel: [
      {
        src: "../img/maniglie-eco-1.jpg",
        txt: "<h1 class='display-3 display-bold'>Ferramenta professionale</h1><span class='display-1'>Design e qualità</span>",
      },
      {
        src: "../img/magazzino-2.jpg",
        txt: "<h1 class='display-3 display-bold'>Qualità e competenza</h1>",
      },
      {
        src: "../img/blum-anta.jpg",
        txt: "<h1 class='display-3 display-bold'>Soluzioni di design</h1><span class='display-1'>Scopri i nostri prodotti</span>",
      },
      {
        src: "../img/magazzino-4.jpg",
        txt: "<h1 class='display-3 display-bold'>Innovazione</h1><span class='display-1'>Al servizio dei nostri clienti</span>",
      },
    ],

    news: [],

    newsCaricate: false,
    newsOffsetLeft: "0px",
    newsInterval: {},

  }),

  methods: {
    async caricaNews() {
      try {
        let res = await get_news("");

        this.news = res.data;
        this.newsCaricate = true;
      } catch (error) {
        console.log(error);
      }
    },

  },

  mounted() {
    // Scroll top pagina
    window.scrollTo(0, 0);
    this.caricaNews();
  },

  computed: {
    role() {
      return this.$store.getters["user/getRole"];
    },

    getImg() {
      let res = []
      if (this.role != 1)
        res.push(this.imgTopCarousel[0])
      else
        res = this.imgTopCarousel


      return res
    }
  }
};
</script>

