<template>
  <v-dialog scrollable v-model="dialogComputed" width="400px">
    <v-card tile>
      <v-toolbar light dense flat>
        <v-toolbar-title>Sposta consegna</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="closeDialog()">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        {{ cliente }}
        <v-text-field label="Prog" style="width:100px;" outline v-model="prog" reverse type="number" min="1" />

      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn @click.native="closeDialog()">Annulla</v-btn>
        <v-btn class="btn-conferma" @click.native="conferma()">Conferma</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: { type: Boolean },
    cliente: { type: String },
  },
  data() {
    return {
      prog: 0,

      attesa: false,
    };
  },

  methods: {
    caricaDati() {
      // Reset
      this.prog = 0;
    },

    conferma() {
      if (!!this.prog == false) {
        this.$toast.info("Progressivo non valido");
        return;
      }

      this.$emit("cambiaProg", this.prog);

      this.closeDialog();
    },

    closeDialog() {
      this.$emit("chiudiDialog");
    },
  },
  mounted() {
    this.caricaDati();
  },

  computed: {
    dialogComputed: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.closeDialog();
      },
    },
  },

  watch: {
    dialog(v) {
      if (v) {
        this.caricaDati();
      }
    },
  },
};
</script>